// @todo: add error handling, replace <any> with corresponding types
import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {Cart} from '../models/cart';
import {Article} from '../models/article';
import {Order} from '../models/order';
import {SodaApiService} from './soda-api.service';
import {Observable} from 'rxjs';

/**
 *
 * Service that interacts with cart api
 */
@Injectable()
export class CartService {
    private API_PATH = 'carts';

    constructor(private http: SodaApiService) {}

    /**
     * Get list of carts
     * @returns
     */
    getCarts(): Observable<Cart[]> {
        const url = this.API_PATH;

        return this.http.get<Cart[]>(url);
    }

    /**
     * Get cart by id
     * @param id cart id
     * @returns
     * @memberof CartService
     */
    getCart(id): Observable<Cart> {
        const url = `${this.API_PATH}/${id}`;

        return this.http.get<any>(url).pipe(
            map((cart: Cart) => {
                if (cart && !Array.isArray(cart.articles)) {
                    cart.articles = [];
                }
                return cart;
            })
        );
    }

    /**
     * Creates a new cart
     * @param data cart data
     * @returns
     */
    addCart(data): Observable<any> {
        const url = this.API_PATH;

        return this.http.post(url, data);
    }

    /**
     * Updates a cart
     * @param id cart id
     * @param data to be updated with
     * @returns
     */
    updateCart(id, data): Observable<any> {
        const url = `${this.API_PATH}/${id}`;
        return this.http.patch(url, data)
    }

    /**
     * Deletes a cart
     * @param id cart id
     * @returns
     */
    deleteCart(id) {
        const url = `${this.API_PATH}/${id}`;

        return this.http.delete(url);
    }

    /**
     * Gets cart articles
     * @param cartId cart id
     * @returns
     */
    getArticles(cartId: number): Observable<Cart[]> {
        const url = `${this.API_PATH}/${cartId}/articles`;

        return this.http.get<Cart[]>(url);
    }

    /**
     * Get an article by id from cart
     * @param cartId cart id
     * @param articleId article id
     * @returns
     */
    getArticle(cartId: number, articleId: number): Observable<any> {
        const url = `${this.API_PATH}/${cartId}/articles/${articleId}`;

        return this.http.get(url);
    }

    /**
     * Adds an article to a cart
     * @param cartId cart id
     * @param assetId asset id
     * @param calculatorOptions
     * @returns
     */
    addArticle(
        cartId: number,
        assetId: string,
        calculatorOptions?
    ): Observable<any> {
        const url = `${this.API_PATH}/${cartId}/articles`;

        const body = {
            assetId: assetId,
            calculatorArguments: calculatorOptions
        };

        return this.http.post(url, body);
    }

    /**
     * Updates an article
     * @param cartId cart id
     * @param  articleId article id
     * @param article article data to be updated
     * @returns
     */
    updateArticle(
        cartId: number,
        articleId: number,
        article: Article
    ): Observable<any> {
        const url = `${this.API_PATH}/${cartId}/articles/${articleId}`;

        return this.http.patch(url, article);
    }

    /**
     * Deletes an article from cart
     * @param cartId cart id
     * @param  articleId article id
     * @returns
     */
    deleteArticle(cartId: number, articleId: number): Observable<any> {
        const url = `${this.API_PATH}/${cartId}/articles/${articleId}`;

        return this.http.delete(url);
    }

    // @todo: refactor method. Not quite understandable what is going on here
    /**
     * Checks out cart
     * @param cartId
     * @param  articleIdList
     * @returns
     */
    checkoutCart(cartId: number, articleIdList: number[]): Observable<Order> {
        const url = `${this.API_PATH}/${cartId}/checkout`;
        let params = new HttpParams();

        if (articleIdList.length > 0) {
            params = params.set('article_ids', articleIdList.join(','));
        }

        // are we really sending the { headers: headers } as request body?
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');

        return this.http.post<any>(url, { headers: headers }, { params });
    }
}
