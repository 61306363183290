import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

// todo: filters

@Directive({selector: '[appFileSelect]'})
export class FileSelectDirective {

    @Output() public filesSelected: EventEmitter<any> = new EventEmitter();

    protected element: ElementRef;

    public constructor(element: ElementRef) {
        this.element = element;
    }

    public isEmptyAfterSelection(): boolean {
        return !!this.element.nativeElement.attributes.multiple;
    }

    @HostListener('change')
    public onChange(): any {
        // let files = this.uploader.isHTML5 ? this.element.nativeElement[0].files : this.element.nativeElement[0];

        const selectedFiles = this.element.nativeElement.files;
        const files = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            files.push(selectedFiles[i]);
        }
        // if(!this.uploader.isHTML5) this.destroy();
        this.filesSelected.next(files);
        if (this.isEmptyAfterSelection()) {
            this.element.nativeElement.value = '';
        }
    }
}
