import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import * as marked from 'marked';


@Pipe({ name: 'markdownToHtml' })
export class MarkdownToHtmlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    public transform(markdown: string): SafeHtml {
        if (markdown == null) return '';
        const renderer = new marked.Renderer();
        renderer.link = function(href, title, text) {
            const link = marked.Renderer.prototype.link.call(this, href, title, text);
            return link.replace(/<a/g, '<a target="_blank" ');
        };
        marked.setOptions({
            renderer: renderer
        });
        return this.domSanitizer.bypassSecurityTrustHtml(marked.parse(markdown));
    }

}
