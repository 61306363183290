/**
 * The associated media application model
 */
export class AssociatedMedia {
    additionalType: string;
    contentUrl: string;
    fileFormat: string;
    width: number;
    height: number;
}
