import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';

@Component({
    selector: 'st-search-bar-popover',
    templateUrl: './search-bar-popover.component.html',
    styleUrls: ['./search-bar-popover.component.scss']
})
export class SearchBarPopoverComponent extends AssetFilterPartialComponent {
    @Input() popoverText;
    @Input() visible = false;

}
