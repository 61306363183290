import {Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {
    CMSBlockDataColumn,
    CMSHorizontalPositionType,
    CMSRawContentComponent,
    CMSVerticalPositionType
} from '../../models/cms.model';
import {CmsBlockContent} from '../cms-block/cms-block-content';

@Component({
    selector: 'lib-cms-row',
    templateUrl: './cms-row.component.html',
    styleUrls: ['./cms-row.component.css']
})
export class CmsRowComponent extends CmsBlockContent implements OnInit  {

    @Input() set children(data: CMSRawContentComponent<CMSBlockDataColumn>[]) {
        if (data) {
            this.parseChildrenData(data);
        }
    }

    columns: CmsColumn[] = [];

    @HostBinding('style.height') height = 'auto';

    columnHoverClasses: {
        [id: string]: boolean
    } = {};

    constructor(el: ElementRef) {
        super(el);
    }

    ngOnInit() {

    }

    /**
     * Parse the children of the current block to render those correctly
     * @param data
     */
    parseChildrenData(data: CMSRawContentComponent<CMSBlockDataColumn>[]) {
        data.map((item, index) => {
            const classes = [];
            Object.keys(item.data.width).map(key => {
                if (item.data.width[key]) {
                    classes.push(key + ((item.data.width[key] !== 'auto') ? ('-' + item.data.width[key]) : ''));
                }
            });
            if (item.data.customClass) {
                classes.push(item.data.customClass);
            }
            if ( item.data.contentPositioning ) {
                classes.push('column-content-container');

                classes.push('content-' + item.data.contentPositioning.horizontal);

                if (item.data.contentPositioning.vertical === CMSVerticalPositionType.Center) {
                    classes.push('content-middle');
                } else {
                    classes.push('content-' + item.data.contentPositioning.vertical);
                }
            }

            const joinedClasses = classes.join(' ');

            this.columns.push({
                id: index,
                children: item.children,
                class: joinedClasses,
                attributes: (item.metaData) ? {
                    dataBlokC: JSON.stringify(item.metaData),
                    dataBlokUid: item.metaData.id + '-' + item.metaData.uid
                } : null,
                border: item.data.border,
                backgroundColor: item.data.backgroundColor,
                spacing: item.data.spacing
            });
        });
    }

    onMouseOver(index: number, event: Event) {
        event.stopPropagation();
        if (this.isDraftView) {
            this.columnHoverClasses[index] = true;
        }
    }

    onMouseOut(index: number, event: Event) {
        event.stopPropagation();
        if (this.isDraftView) {
            this.columnHoverClasses[index] = false;
        }
    }
}

export interface CmsColumn {
    id: number;
    children: any[];
    class: string;
    attributes: {
        dataBlokC: string;
        dataBlokUid: string;
    };
    border: {
        color: string;
        width: number;
        radius: number;
    };
    backgroundColor: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };
}
