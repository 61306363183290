import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Collection } from '../../models/collection';
import {ActivatedRoute} from '@angular/router';
import {filter, map, pluck} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

/**
 * Component for selectin one or multiple categroies based on the input value
 */
@Component({
    selector: 'st-asset-category-selector',
    templateUrl: './asset-category-selector.component.html',
    styleUrls: ['./asset-category-selector.component.scss']
})
export class AssetCategorySelectorComponent implements OnInit {
    form: FormGroup;
    collections: Partial<Collection>[];
    groupedCategoryList: { [key: string]: Partial<Collection>[] };
    @Input() categories: any;
    @Input() selectAllTranslation: string;
    @Output() changes = new EventEmitter();

    selectedCategories$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

    constructor(private fb: FormBuilder, private route: ActivatedRoute) {
        this.route.queryParams
            .pipe(
                pluck('category'),
                filter(Boolean),
                map(coll => Array.isArray(coll) ? coll : [coll]),
                map(coll => coll.reduce((collections, collectionId) => {
                    return [...collections, ...collectionId.split(';')];
                }, []))
            ).subscribe(selectedCollections =>
            this.selectedCategories$.next(selectedCollections)
        );
    }

    async ngOnInit() {
        this.groupedCategoryList = this.categories
            .sort((c1, c2) => c1.name.localeCompare(c2.name))
            .reduce((acc, curr) => {
                const char = curr.name.charAt(0).toLocaleUpperCase();
                acc[char] = [...(acc[char] || []), curr];

                return acc;
            }, {} as { [key: string]: Partial<Collection>[] });

        const controlsConfig = this.categories.reduce(
            (config, collection) => ({
                ...config,
                [collection.id]: false
            }),
            {}
        );
        this.form = this.fb.group(controlsConfig);

        this.selectedCategories$.subscribe(categories => {
            const newValues = categories.reduce((list, category) => {
                list[category] = true;
                return list;
            }, {});
            this.form.patchValue(newValues);
            /*const value = collections.reduce((controls, curr) => ({
                ...controls,
                [collections[curr].id]: [collections[curr].isSelected]
            }), {});

            this.form.patchValue(value);*/
        });
    }

    /**
     * Called when the user hits the submit button
     */
    onSubmit() {
        const categories = Object.keys(this.form.value)
            .filter(key => !!this.form.value[key]);
        this.changes.emit(categories);
    }

    /**
     * Select all categories
     */
    selectAll() {
        const controls = Object.keys(this.form.getRawValue());

        this.form.setValue(controls.reduce((list, item) => {
            list[item] = true;
            return list;
        }, {}));
    }
}
