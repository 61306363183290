import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Service that interacts with the categories api
 */
@Injectable()
export class CategoriesService {
    private API_PATH = 'categories';
    loadedCategories: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(private http: SodaApiService) {
        this.fetchCategories().then(res => this.loadedCategories.next(res));
    }

    /**
     * Gets the list of available categories
     * @returns
     */
    public fetchCategories(): Promise<any> {
        return this.http
            .get(this.API_PATH)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Gets the single category by id
     * @returns
     */
    public fetchCategoryById(id: number | string): Promise<any> {
        return this.http
            .get(this.API_PATH + `/${id}`)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Gets the loadedCategories observable
     * @returns
     */
    public getLoadedCategories(): Observable<number> {
        return this.loadedCategories.asObservable();
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
