import {APP_INITIALIZER, Inject, InjectionToken, ModuleWithProviders, NgModule, PLATFORM_ID, Provider} from '@angular/core';
import { AssetSearchInputComponent } from './components/asset-search-input/asset-search-input.component';
import { UserLoginService } from './services/user-login.service';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatIconRegistry,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule
} from '@angular/material';
import {CommonModule, CurrencyPipe, isPlatformServer} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuggestService } from './services/suggest.service';
import { SodaApiServiceProvider } from './services/soda-api.service.provider';
import { Ng2Webstorage } from 'ngx-webstorage';
import { AssetListComponent } from './components/asset-list/asset-list.component';
import { MasonryGridAssetThumbComponent } from './components/masonry-grid-asset-thumb/masonry-grid-asset-thumb.component';
import { RemoveFileExtensionPipe } from './pipes/remove-file-extension.pipe';
import { PreferencesService } from './services/preferences.service';
import { LightboxService } from './services/lightbox.service';
import { UserDataService } from './services/user-data.service';
import { CartService } from './services/cart.service';
import { CartDataService } from './services/cart-data.service';
import { DragulaModule } from 'ng2-dragula';
import { SupplierDataService } from './services/supplier-data.service';
import { CollectionDataService } from './services/collection-data.service';
import { AssetService } from './services/asset.service';
import { AssetSearchComponent } from './components/asset-search/asset-search.component';
import { SupplierService } from './services/supplier.service';
import { WindowRefService } from './services/window-ref.service';
import { LightboxDataService } from './services/lightbox-data.service';
import { UserService } from './services/user.service';
import { GridAssetThumbComponent } from './components/grid-asset-thumb/grid-asset-thumb.component';
import { ListAssetThumbComponent } from './components/list-asset-thumb/list-asset-thumb.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ImageUtilsService } from './services/image-utils.service';
import { RequestPasswordResetComponent } from './components/auth/request-password-request/request-password-reset.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { SignupComponent } from './components/auth/signup/signup.component';
import { CountryService } from './services/country.service';
import { SignupVerificationComponent } from './components/auth/signup-verification/signup-verification.component';
import { AuthenticationService } from './services/authentication.service';
import { LoginComponent } from './components/auth/login/login.component';
import { LocationRefService } from './services/location-ref.service';
import { AssetDetailComponent } from './components/asset-detail/asset-detail.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LightboxBarComponent } from './components/lightbox-bar/lightbox-bar.component';
import { CalculatorService } from './services/calculator.service';
import { AssetCalculatorComponent } from './components/asset-calculator/asset-calculator.component';
import { ClipboardModule } from 'ngx-clipboard';
import { DurationPipe } from './pipes/duration.pipe';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { OrderService } from './services/order.service';
import { OrderDataService } from './services/order-data.service';
import { GalleriesOverviewComponent } from './components/galleries-overview/galleries-overview.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { CollectionService } from './services/collection.service';
import { AssetSearchFilterComponent } from './components/asset-search-filter/asset-search-filter.component';
import { AssetSearchBreadcrumbsComponent } from './components/asset-search-breadcrumbs/asset-search-breadcrumbs.component';
import { CartCheckoutComponent } from './components/cart-checkout/cart-checkout.component';
import { CartIndicatorComponent } from './components/cart-indicator/cart-indicator.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutSuccessComponent } from './components/checkout-success/checkout-success.component';
import { SignOutButtonComponent } from './components/auth/sign-out-button/sign-out-button.component';
import { AssetModalDetailComponent } from './components/asset-modal-detail/asset-modal-detail.component';
import { UserIndicatorComponent } from './components/user-indicator/user-indicator.component';
import { AssetSearchResultCountComponent } from './components/asset-search-result-count/asset-search-result-count.component';
import { WebseriesListComponent } from './components/webseries-list/webseries-list.component';
import { WebseriesThumbComponent } from './components/webseries-thumb/webseries-thumb.component';
import { WebseriesService } from './services/webseries.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShareModule } from '@ngx-share/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AssetSearchDateFilterComponent } from './components/asset-search-date-filter/asset-search-date-filter.component';
import { AssetColorFilterComponent } from './components/asset-color-filter/asset-color-filter.component';
import { PairsPipe } from './pipes/pairs.pipe';
import { AssetSearchLicenseFilterComponent } from './components/asset-search-license-filter/asset-search-license-filter.component';
import { AssetSearchOrientationFilterComponent } from './components/asset-search-orientation-filter/asset-search-orientation-filter.component';
import { AssetSearchSizeFilterComponent } from './components/asset-search-size-filter/asset-search-size-filter.component';
import { LayoutSwitchComponent } from './components/layout-switcher/layout-switch.component';
import { PreviewSwitchComponent } from './components/preview-switcher/preview-switch.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SDK_OPTIONS, SdkOptions } from './models/sdk-options';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetSearchPeopleFilterComponent } from './components/asset-search-people-filter/asset-search-people-filter.component';
import { AssetSearchReleaseFilterComponent } from './components/asset-search-release-filter/asset-search-release-filter.component';
import { AssetCollectionsFilterComponent } from './components/asset-collections-filter/asset-collections-filter.component';
import { AssetCollectionsSelectorComponent } from './components/asset-collections-selector/asset-collections-selector.component';
import { BackgroundColorSwitcherComponent } from './components/background-color-switcher/background-color-switcher.component';
import { AssetSearchExclusiveFilterComponent } from './components/asset-search-exclusive-filter/asset-search-exclusive-filter.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WebseriesSliderComponent } from './components/webseries-slider/webseries-slider.component';
import { WebseriesGridComponent } from './components/webseries-grid/webseries-grid.component';
import { WebseriesGridFilterComponent } from './components/webseries-grid-filter/webseries-grid-filter.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { DisplayAssetComponent } from './components/display-asset/display-asset.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieBarComponent } from './components/cookie-bar/cookie-bar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NewsletterService } from './services/newsletter.service';
import { AssetBackgroundSectionComponent } from './components/asset-background-section/asset-background-section.component';
import { AssetKeywordsSelectorComponent } from './components/asset-keywords-selector/asset-keywords-selector.component';
import { LightboxGalleriesComponent } from './components/lightbox-galleries/lightbox-galleries.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CustomMomentDateAdapter } from './adapters/custom-moment-date.adapter';
import { GalleryIndicatorComponent } from './components/gallery-indicator/gallery-indicator.component';
import { SearchBarPopoverComponent } from './components/search-bar-popover/search-bar-popover.component';
import {DownloadLogsService} from './services/download-log.service';
import {DownloadListComponent} from './components/download-list/download-list.component';
import {MarketingService} from './services/marketing.service';
import {ContactFormService} from './services/contact-form.service';
import { AssetSearchMediaTypeFilterComponent } from './components/asset-search-media-type-filter/asset-search-media-type-filter.component';
import {RouterModule} from '@angular/router';
import {CapitalizePipe} from './pipes/captializel.pipe';
import { LightboxAddViewComponent } from './components/lightbox-add-view/lightbox-add-view.component';
import { AssetSearchPeopleNumberFilterComponent } from './components/asset-search-people-number-filter/asset-search-people-number-filter.component';
import { AssetSearchPaginationComponent } from './components/asset-search-pagination/asset-search-pagination.component';
import { CmsContainerComponent } from './components/cms-container/cms-container.component';
import { CmsBlocksComponent } from './components/cms-blocks/cms-blocks.component';
import { CmsBlockComponent } from './components/cms-block/cms-block.component';
import { CmsRowComponent } from './components/cms-row/cms-row.component';
import {
    BUTTON_COMPONENT,
    HEADING_COMPONENT,
    RICH_TEXT_COMPONENT,
    ROW_COMPONENT,
    SLIDER_COMPONENT,
    SECTION_COMPONENT,
    HTML_COMPONENT,
    registerInjectionTokensForDynamicComponents,
    ICON_COMPONENT,
    MENU_COMPONENT,
    MENU_ITEM_COMPONENT,
    MENU_GROUP_COMPONENT, VIDEO_COMPONENT, IMAGE_COMPONENT,
    FAQ_COMPONENT,
    FAQ_ENTRY_COMPONENT,
    CONTENT_TEASER_COMPONENT
} from './components/dynamic-components';
import { CmsRichTextComponent } from './components/cms-rich-text/cms-rich-text.component';
import {MarkdownToHtmlPipe} from './pipes/markdown.pipe';
import { CmsHeadingComponent } from './components/cms-heading/cms-heading.component';
import { StoryblokDirective } from './directives/storyblok.directive';
import {CMSPreviewComponent} from './components/cms-preview/cms-preview.component';
import { CmsSliderComponent } from './components/cms-slider/cms-slider.component';
import { CmsSectionComponent } from './components/cms-section/cms-section.component';
import { CmsButtonComponent } from './components/cms-button/cms-button.component';
import {CmsIconComponent} from './components/cms-icon/cms-icon.component';
import {CmsHTMLComponent} from './components/cms-html/cms-html.component';
import {SanitizeHtmlPipe} from './pipes/sanitize-html.pipe';
import { CmsMenuComponent } from './components/cms-menu/cms-menu.component';
import { CmsMenuItemComponent } from './components/cms-menu-item/cms-menu-item.component';
import { CmsMenuGroupComponent } from './components/cms-menu-group/cms-menu-group.component';
import {CartPromotionCodeComponent} from './components/cart-promotion-code/cart-promotion-code.component';
export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: ''
    },
    position: 'bottom-right',
    theme: 'classic',
    revokeBtn: '<span></span>',
    revokable: false,
    type: 'opt-in',
    content: {
        link: ''
    }
};
import {CmsVideoComponent} from './components/cms-video/cms-video.component';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {ImageSimilarityOverlayComponent} from './components/image-similarity-overlay/image-similarity-overlay.component';
import {FileSelectDirective} from './directives/file-select.directive';
import {ImageSimilarityService} from './services/image-similarity.service';
import {ImageTypePipe} from './pipes/image-type.pipe';
import {DragAndDropService} from './services/drag-and-drop.service';
import {EventTrackerService} from './services/event-tracker.service';
import {CmsImageComponent} from './components/cms-image/cms-image.component';
import {AssetCategorySelectorComponent} from './components/asset-category-selector/asset-category-selector.component';
import { AssetSearchKeywordFilterComponent } from './components/asset-search-keyword-filter/asset-search-keyword-filter.component';
import { AssetSearchCategoriesFilterComponent } from './components/asset-search-categories-filter/asset-search-categories-filter.component';
import { AssetSearchUploadTimeFilterComponent } from './components/asset-search-upload-time-filter/asset-search-upload-time-filter.component';
import { AssetPreviewOverlayComponent } from './components/asset-preview-overlay/asset-preview-overlay.component';
import {AssetPreviewService} from './services/asset-preview.service';
import { AssetSearchRestrictionsFilterComponent } from './components/asset-search-restrictions-filter/asset-search-restrictions-filter.component';
import { CategoriesService } from './services/categories.service';
import { CmsFaqComponent } from './components/cms-faq/cms-faq.component';
import { CmsFaqEntryComponent } from './components/cms-faq-entry/cms-faq-entry.component';
import { MatSelectHeightDirective } from './directives/mat-select-height.directive';
import { AssetHighResPopoverComponent } from './components/asset-high-res-popover/asset-high-res-popover.component';
import {LineBreakPipe} from './pipes/line-break.pipe';
import {BreakpointService} from './services/breakpoint.service';
import {LangInterceptor} from './interceptors/lang.interceptor';
import { LazysizesService } from './services/lazysizes.service';
import { StoryblokRenderingPipe } from './pipes/storyblok-rendering.pipe';
import { BreakpointMapPipe } from './pipes/breakpoint-map.pipe';
import { CmsContentTeaserComponent } from './components/cms-content-teaser/cms-content-teaser.component';

export function initStoryblokFactory(sdkOptions: SdkOptions, platformId: any): () => Promise<void> {
    const storyblokInitializer = (): Promise<void> => new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sdkOptions.storyBlok.token}`;

        script.onload = () => {
            resolve();
        };

        script.onerror = () => {
            reject();
        };

        document.getElementsByTagName('head')[0].appendChild(script);
    });
    return (isPlatformServer(platformId)) ? () => Promise.resolve() : storyblokInitializer;
}


@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        Ng2Webstorage,
        MatInputModule,
        MatAutocompleteModule,
        DragulaModule.forRoot(),
        InfiniteScrollModule,
        ShareModule.forRoot(),
        ClipboardModule,
        TranslateModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatSelectModule,
        MatIconModule,
        MatSelectModule,
        SwiperModule,
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    declarations: [
        AssetSearchInputComponent,
        AssetListComponent,
        MasonryGridAssetThumbComponent,
        GridAssetThumbComponent,
        ListAssetThumbComponent,
        RemoveFileExtensionPipe,
        AssetSearchComponent,
        LoginComponent,
        RequestPasswordResetComponent,
        ResetPasswordComponent,
        SignupComponent,
        SignupVerificationComponent,
        AssetDetailComponent,
        ProfileComponent,
        LightboxBarComponent,
        AssetCalculatorComponent,
        DurationPipe,
        CurrencySymbolPipe,
        PairsPipe,
        ImageTypePipe,
        SignOutButtonComponent,
        GalleriesOverviewComponent,
        OrderListComponent,
        AssetSearchFilterComponent,
        AssetSearchBreadcrumbsComponent,
        CartCheckoutComponent,
        CartIndicatorComponent,
        CartComponent,
        AssetModalDetailComponent,
        UserIndicatorComponent,
        CheckoutSuccessComponent,
        AssetModalDetailComponent,
        AssetSearchResultCountComponent,
        WebseriesListComponent,
        WebseriesThumbComponent,
        AssetSearchDateFilterComponent,
        AssetColorFilterComponent,
        AssetSearchLicenseFilterComponent,
        AssetSearchOrientationFilterComponent,
        AssetSearchKeywordFilterComponent,
        AssetSearchSizeFilterComponent,
        LayoutSwitchComponent,
        PreviewSwitchComponent,
        LanguageSwitcherComponent,
        AssetSearchPeopleFilterComponent,
        AssetSearchReleaseFilterComponent,
        AssetCollectionsFilterComponent,
        AssetCollectionsSelectorComponent,
        AssetSearchExclusiveFilterComponent,
        LanguageSwitcherComponent,
        BackgroundColorSwitcherComponent,
        WebseriesSliderComponent,
        WebseriesGridComponent,
        WebseriesGridFilterComponent,
        OutsideClickDirective,
        DisplayAssetComponent,
        CookieBarComponent,
        AssetBackgroundSectionComponent,
        SpinnerComponent,
        AssetKeywordsSelectorComponent,
        LightboxGalleriesComponent,
        DownloadListComponent,
        GalleryIndicatorComponent,
        SearchBarPopoverComponent,
        AssetSearchMediaTypeFilterComponent,
        CapitalizePipe,
        LightboxAddViewComponent,
        AssetSearchPeopleNumberFilterComponent,
        AssetSearchPaginationComponent,
        CmsContainerComponent,
        CmsBlocksComponent,
        CmsBlockComponent,
        CmsRowComponent,
        CmsRichTextComponent,
        MarkdownToHtmlPipe,
        CmsHeadingComponent,
        CmsSliderComponent,
        StoryblokDirective,
        CMSPreviewComponent,
        CmsSectionComponent,
        CmsButtonComponent,
        CmsIconComponent,
        CmsImageComponent,
        CmsHTMLComponent,
        SanitizeHtmlPipe,
        CmsMenuComponent,
        CmsMenuItemComponent,
        CmsMenuGroupComponent,
        CmsFaqComponent,
        CmsFaqEntryComponent,
        AssetSearchPaginationComponent,
        CartPromotionCodeComponent,
        CmsVideoComponent,
        ImageSimilarityOverlayComponent,
        FileSelectDirective,
        AssetCategorySelectorComponent,
        AssetSearchCategoriesFilterComponent,
        AssetSearchUploadTimeFilterComponent,
        AssetPreviewOverlayComponent,
        AssetSearchRestrictionsFilterComponent,
        MatSelectHeightDirective,
        AssetHighResPopoverComponent,
        LineBreakPipe,
        StoryblokRenderingPipe,
        BreakpointMapPipe,
        CmsContentTeaserComponent
    ],
    providers: [
        SodaApiServiceProvider,
        UserLoginService,
        SuggestService,
        PreferencesService,
        LightboxService,
        UserDataService,
        CartService,
        CartDataService,
        SupplierDataService,
        CollectionDataService,
        AssetService,
        SupplierService,
        WindowRefService,
        LightboxDataService,
        UserService,
        ImageUtilsService,
        CollectionService,
        CountryService,
        AuthenticationService,
        LocationRefService,
        CalculatorService,
        OrderService,
        OrderDataService,
        WebseriesService,
        NewsletterService,
        DownloadLogsService,
        MarketingService,
        ContactFormService,
        ImageSimilarityService,
        DragAndDropService,
        EventTrackerService,
        AssetPreviewService,
        CategoriesService,
        BreakpointService,
        LazysizesService,
        { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: [
                        'YYYY-M-D',
                        'D.M.YYYY',
                        'D/M/YYYY',
                    ],
                },
                display: {
                    dateInput: 'L',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: DateAdapter,
            useClass: CustomMomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: SDK_OPTIONS,
            useValue: {}
        },
        { provide: ROW_COMPONENT, useValue: CmsRowComponent},
        { provide: RICH_TEXT_COMPONENT, useValue: CmsRichTextComponent},
        { provide: HEADING_COMPONENT, useValue: CmsHeadingComponent},
        { provide: SLIDER_COMPONENT, useValue: CmsSliderComponent},
        { provide: SECTION_COMPONENT, useValue: CmsSectionComponent},
        { provide: BUTTON_COMPONENT, useValue: CmsButtonComponent},
        { provide: VIDEO_COMPONENT, useValue: CmsVideoComponent},
        { provide: ICON_COMPONENT, useValue: CmsIconComponent},
        { provide: IMAGE_COMPONENT, useValue: CmsImageComponent},
        { provide: HTML_COMPONENT, useValue: CmsHTMLComponent},
        { provide: MENU_COMPONENT, useValue: CmsMenuComponent},
        { provide: MENU_ITEM_COMPONENT, useValue: CmsMenuItemComponent},
        { provide: MENU_GROUP_COMPONENT, useValue: CmsMenuGroupComponent},
        { provide: FAQ_COMPONENT, useValue: CmsFaqComponent},
        { provide: FAQ_ENTRY_COMPONENT, useValue: CmsFaqEntryComponent},
        { provide: CONTENT_TEASER_COMPONENT, useValue: CmsContentTeaserComponent},
    ],
    exports: [
        AssetSearchInputComponent,
        AssetSearchComponent,
        AssetListComponent,
        AssetDetailComponent,
        LoginComponent,
        SignupComponent,
        SignupVerificationComponent,
        RequestPasswordResetComponent,
        ResetPasswordComponent,
        ProfileComponent,
        OrderListComponent,
        GalleriesOverviewComponent,
        LightboxBarComponent,
        AssetSearchInputComponent,
        AssetSearchComponent,
        AssetListComponent,
        AssetSearchFilterComponent,
        AssetSearchBreadcrumbsComponent,
        CartComponent,
        RemoveFileExtensionPipe,
        CurrencyPipe,
        DurationPipe,
        CurrencySymbolPipe,
        CartIndicatorComponent,
        UserIndicatorComponent,
        CheckoutSuccessComponent,
        CartCheckoutComponent,
        CartIndicatorComponent,
        AssetSearchResultCountComponent,
        WebseriesListComponent,
        WebseriesThumbComponent,
        AssetSearchDateFilterComponent,
        AssetColorFilterComponent,
        AssetSearchLicenseFilterComponent,
        AssetSearchOrientationFilterComponent,
        AssetSearchKeywordFilterComponent,
        AssetSearchSizeFilterComponent,
        LayoutSwitchComponent,
        PreviewSwitchComponent,
        LanguageSwitcherComponent,
        AssetSearchPeopleFilterComponent,
        AssetSearchReleaseFilterComponent,
        AssetCollectionsFilterComponent,
        AssetCollectionsSelectorComponent,
        AssetSearchExclusiveFilterComponent,
        BackgroundColorSwitcherComponent,
        WebseriesSliderComponent,
        WebseriesGridComponent,
        WebseriesGridFilterComponent,
        DisplayAssetComponent,
        CookieBarComponent,
        AssetBackgroundSectionComponent,
        SpinnerComponent,
        SwiperModule,
        AssetKeywordsSelectorComponent,
        LightboxGalleriesComponent,
        DownloadListComponent,
        MatMomentDateModule,
        // MatNativeDateModule,
        OutsideClickDirective,
        GalleryIndicatorComponent,
        SearchBarPopoverComponent,
        AssetSearchMediaTypeFilterComponent,
        LightboxAddViewComponent,
        AssetSearchPeopleNumberFilterComponent,
        CmsContainerComponent,
        MarkdownToHtmlPipe,
        CMSPreviewComponent,
        CmsRowComponent,
        CmsRichTextComponent,
        CmsHeadingComponent,
        CmsSliderComponent,
        CmsSectionComponent,
        CmsButtonComponent,
        SanitizeHtmlPipe,
        CmsMenuComponent,
        CmsMenuItemComponent,
        CmsMenuGroupComponent,
        CartPromotionCodeComponent,
        ImageSimilarityOverlayComponent,
        FileSelectDirective,
        AssetCategorySelectorComponent,
        AssetSearchCategoriesFilterComponent,
        AssetSearchUploadTimeFilterComponent,
        AssetSearchRestrictionsFilterComponent,
        MatSelectHeightDirective,
        StoryblokRenderingPipe,
        BreakpointMapPipe
    ],
    entryComponents: [
        AssetCollectionsSelectorComponent,
        AssetCategorySelectorComponent,
        WebseriesSliderComponent,
        CmsRowComponent,
        CmsRichTextComponent,
        CmsHeadingComponent,
        CmsSliderComponent,
        CmsSectionComponent,
        CmsButtonComponent,
        CmsIconComponent,
        CmsHTMLComponent,
        CmsImageComponent,
        CmsMenuComponent,
        CmsMenuItemComponent,
        CmsMenuGroupComponent,
        CmsVideoComponent,
        AssetPreviewOverlayComponent,
        CmsFaqComponent,
        CmsFaqEntryComponent,
        CmsContentTeaserComponent
    ]
})
export class SdkModule {

    constructor(
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
        private matIconRegistry: MatIconRegistry,
        private sanitzier: DomSanitizer,
        private dateAdapter: DateAdapter<Date>) {

        registerInjectionTokensForDynamicComponents();

        Object.keys(DEFAULT_ICONS).forEach(iconName => {
            this.matIconRegistry.addSvgIconLiteral(`st-${iconName}`,
                this.sanitzier.bypassSecurityTrustHtml(DEFAULT_ICONS[iconName])
            );
        });
        if (this.sdkOptions.icons) {
            Object.keys(this.sdkOptions.icons).forEach(iconName => {
                this.matIconRegistry.addSvgIcon(`st-${iconName}`,
                    this.sanitzier.bypassSecurityTrustResourceUrl(this.sdkOptions.baseUrl + this.sdkOptions.icons[iconName])
                );
            });
        }
        cookieConfig.cookie.domain = this.sdkOptions.domain;

        this.dateAdapter.setLocale(window.navigator.language);
    }

    static forRoot(options: SdkOptions, additionalProviders: Provider[] = []): ModuleWithProviders {
        return {
            ngModule: SdkModule,
            providers: [
                {
                    provide: SDK_OPTIONS,
                    useValue: options
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: initStoryblokFactory,
                    multi: true,
                    deps: [SDK_OPTIONS, PLATFORM_ID]
                },
                ...additionalProviders
            ]
        };
    }

}

export const DEFAULT_ICONS = {
    cart: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path d="M224.826 160.42l30.211-90.946c1.67-5.053.83-7.58 0-9.264-3.36-4.21-10.07-4.21-10.91-4.21H72.15l-4.195-21.895C67.116 29.052 62.92 24 56.21 24H7.55C2.514 24 0 26.525 0 30.735v12.632c0 5.053 2.515 5.895 7.55 5.895h41.105l31.046 132.21c-5.035 5.895-7.55 13.474-7.55 20.211 0 16 12.58 30.316 28.52 30.316 14.26 0 26.01-14.316 27.685-22.736h60.4c2.521 8.42 10.91 22.736 28.521 22.736 15.94 0 28.53-13.474 28.53-28.632 0-16-10.07-29.473-28.53-29.473-7.55 0-16.78 4.21-20.97 10.105h-74.661c-5.875-8.42-12.585-10.947-20.135-10.947l-.84-5.895h113.257c8.388 0 10.058-3.369 10.898-6.737zm-7.549 32c5.88 0 10.91 4.212 10.91 10.106s-5.03 10.948-10.91 10.948c-5.87 0-10.9-5.053-10.9-10.948s5.03-10.105 10.9-10.105zm-106.536 10.106c0 5.895-5.035 10.948-10.91 10.948-5.87 0-10.065-5.053-10.065-10.948S93.96 191.58 99.83 191.58c5.875 0 10.91 5.053 10.91 10.947z"/>
        </svg>`,
    user: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path d="M127.754 70.957c-17.495 0-31.664 14.182-31.664 31.66 0 17.467 14.169 31.644 31.664 31.644 17.487 0 31.64-14.177 31.64-31.644 0-17.478-14.153-31.66-31.64-31.66z"/>
          <path d="M127.752 21.834C69.211 21.834 21.585 69.459 21.585 128s47.626 106.166 106.167 106.166S233.918 186.541 233.918 128 186.293 21.834 127.752 21.834zm61.258 162.799c-4.785-14.857-19.248-28.443-38.229-33.726-7.14-1.977-14.903-3.08-23.04-3.08-8.135 0-15.9 1.103-23.04 3.083-18.984 5.285-33.424 18.871-38.203 33.727-13.78-14.893-22.224-34.794-22.224-56.637 0-46.03 37.448-83.478 83.478-83.478 46.03 0 83.477 37.448 83.477 83.478 0 21.84-8.439 41.741-22.22 56.633z"/>
        </svg>`,
    download: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.509 256">
          <path d="M188.019 145.565c-6.347 0-11.49 5.141-11.49 11.49v50.457H22.98v-50.457c0-6.349-5.138-11.49-11.489-11.49-6.346 0-11.49 5.141-11.49 11.49v61.947c0 6.346 5.144 11.49 11.49 11.49H188.02c6.346 0 11.49-5.144 11.49-11.49v-61.947c0-6.349-5.144-11.49-11.49-11.49z"/>
          <path d="M91.628 171.482a11.497 11.497 0 0 0 8.126 3.367c3.047 0 5.968-1.205 8.122-3.367l46.332-46.334c4.49-4.484 4.49-11.76 0-16.246-4.482-4.488-11.763-4.488-16.247 0l-26.719 26.72V36.997c0-6.347-5.142-11.49-11.488-11.49-6.347 0-11.49 5.143-11.49 11.49v98.621l-26.712-26.715c-4.489-4.488-11.766-4.486-16.25-.002-4.484 4.489-4.484 11.764-.002 16.248l46.328 46.332z"/>
        </svg>`,
    addToLightbox: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.251 256">
          <path d="M115.251 24v93h93V24h-93zm71 71h-49V46h49v49zM115.251 232h93v-93h-93v93zm22-71h49v49h-49v-49zM0 232h93v-93H0v93zm22-71h49v49H22v-49zM57.5 24h-22v35.5H0v22h35.5V117h22V81.5H93v-22H57.5z"/>
        </svg>`,
    removeFromLightbox: `<svg class="deleteFromGallery" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208 256">
          <path d="M115 24v93h93V24h-93zm71 71h-49V46h49v49zM115 232h93v-93h-93v93zm22-71h49v49h-49v-49zM0 232h93v-93H0v93zm22-71h49v49H22v-49zM0 59h93v22H0z"/>
        </svg>`,
    share: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.885 256">
          <path d="M51.067 74.78l16.375-16.375v97.288h18V58.406l16.373 16.374a8.97 8.97 0 0 0 6.364 2.636 9 9 0 0 0 6.364-15.364L76.442 23.95 38.339 62.052a9 9 0 0 0 0 12.728 9 9 0 0 0 12.728 0zm57.113 12.406c-4.971 0-9 4.029-9 9s4.029 9 9 9h26.705v108.951H18V105.186h26.703c4.971 0 9-4.029 9-9s-4.029-9-9-9H0v144.951h152.885V87.186H108.18z"/>
        </svg>`,
    question: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.873 256">
          <path d="M106.333 21.667C47.701 21.667 0 69.367 0 128s47.7 106.333 106.333 106.333c58.632 0 106.333-47.7 106.333-106.333S164.965 21.667 106.333 21.667zm0 190.666C59.831 212.333 22 174.501 22 128s37.831-84.333 84.333-84.333c46.501 0 84.333 37.831 84.333 84.333s-37.832 84.333-84.333 84.333z"/>
          <path d="M103.28 158.256c-3.2 0-6.16 1.32-8.881 3.96-2.72 2.641-4.081 5.882-4.081 9.722 0 3.735 1.32 6.936 3.96 9.603 2.641 2.666 5.828 4 9.562 4 3.681 0 6.868-1.334 9.562-4 2.694-2.667 4.041-5.894 4.041-9.682 0-3.733-1.334-6.934-4-9.602-2.669-2.667-6.055-4.001-10.163-4.001zM132.727 78.394c-7.042-5.383-15.391-8.075-25.046-8.075-7.629 0-17.016 2.135-28.165 6.402v22.713c8.748-5.806 16.91-8.71 24.485-8.71 3.413 0 6.507.878 9.281 2.636 2.773 1.758 4.161 4.156 4.161 7.191 0 1.972-.654 3.904-1.96 5.794-1.308 1.891-4.042 4.835-8.202 8.83-5.76 5.486-9.602 10.147-11.522 13.983-1.92 3.836-2.88 9.163-2.88 15.982v4.474h21.844v-1.918c0-4.37 1-8.112 3-11.23 2-3.118 5.614-7.075 10.842-11.871 5.388-4.849 9.189-8.992 11.402-12.43 2.213-3.437 3.321-7.607 3.321-12.51 0-8.792-3.52-15.88-10.561-21.261zM242.633 232.15l52.668-48.173-52.668-47.64z"/>
        </svg>`,
    sort: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1305.382 1450.792"><path d="M1301.502 985.043l-350.559 458.445a18.562 18.562 0 0 1-14.771 7.303c-5.745 0-11.219-2.73-14.731-7.303l-350.57-458.445a18.636 18.636 0 0 1-3.887-11.395c0-3.891 1.177-7.902 3.69-11.273 5.059-6.68 13.826-9.188 21.617-6.117l231.902 92.175V71.303c0-10.34 8.769-18.388 19.025-18.388h186.024c10.255 0 18.95 8.048 18.95 18.388v977.13l231.939-92.175c7.782-3.07 16.482-.562 21.511 6.117a18.768 18.768 0 0 1-.14 22.668zM734.614 488.405a18.867 18.867 0 0 0 3.751-11.273c0-3.993-1.297-8.036-3.872-11.405L383.925 7.292A18.597 18.597 0 0 0 369.189 0c-5.787 0-11.233 2.709-14.774 7.292L3.866 465.728c-5.128 6.68-5.147 15.917-.1 22.678 5.018 6.68 13.699 9.188 21.481 6.117l231.946-92.185v977.141c0 10.33 8.688 18.437 18.965 18.437H462.2c10.257 0 18.992-8.106 18.992-18.437V402.337l231.922 92.185c7.763 3.071 16.43.563 21.5-6.117z"/></svg>`,
    chevronRight: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158 262" ><g><path d="M19.5,261.2c-4.7,0-9.3-1.9-13-5.6c-7.4-7.4-7.4-18.6,0-26L105,131L6.5,32.4C-1,25-1,13.8,6.5,6.4s18.6-7.4,26,0L157.1,131L32.5,255.6C28.8,259.4,24.1,261.2,19.5,261.2z"/></g></svg>`,
    restriction: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 666.68 666.667"><path d="M360.555 399.036c0 15.019-12.186 27.204-27.203 27.204-15.019 0-27.204-12.185-27.204-27.204V181.424c0-15.017 12.185-27.204 27.204-27.204 15.017 0 27.203 12.187 27.203 27.204zm-27.203 113.348c-15.019 0-27.204-12.185-27.204-27.204s12.185-27.204 27.204-27.204c15.017 0 27.203 12.185 27.203 27.204s-12.186 27.204-27.203 27.204zm0-512.39C149.231-.005 0 149.228 0 333.365 0 517.414 149.23 666.68 333.352 666.68c184.085 0 333.315-149.267 333.315-333.316 0-184.137-149.23-333.37-333.315-333.37"/></svg>`
};
