import {Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { startWith, switchMap } from 'rxjs/operators';

import {UserDataService} from './user-data.service';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Service that stores user preferences
 * Uses LocalStorageService
 */
@Injectable()
export class PreferencesService {
    constructor(
        private user: UserDataService,
        private storage: LocalStorageService
    ) {}

    /**
     * Gets a prop
     * @param name
     * @returns
     */
    get(name: string) {
        return this.storage.retrieve(this.getName(name));
    }

    /**
     * Sets a prop
     * @param name
     * @param value
     */
    set(name: string, value) {
        this.storage.store(this.getName(name), value);
    }

    /**
     * Removes a prop
     * @param name
     */
    clear(name: string) {
        this.storage.clear(this.getName(name));
    }

    /**
     * Creates an Observable to which one can subscribe
     * @param name
     * @returns
     */
    observe(name: string): Observable<any> {
        return this.user.user.pipe(
            switchMap(() => this.storage.observe(this.getName(name)).pipe(
                startWith(this.get(name))
            ))
        );
    }

    /**
     * Returns name?
     * @param name
     * @returns
     */
    getName(name: string) {
        const user = this.user.getUser();
        // @todo: check this kind of userId assignment
        const userId = user && user['id'] > 0 ? user['id'] : 0;

        return `${userId}_${name}`;
    }
}
