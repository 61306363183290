import {Inject, Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {map} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {SDK_OPTIONS, SdkOptions} from '../models/sdk-options';

/**
 * Service that interacts with the country api
 */
@Injectable()
export class CountryService {
    private API_PATH = 'countries';

    constructor(private http: SodaApiService, @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {}

    /**
     * Gets the list of available countries
     * @returns
     */
    public getCountries(): Promise<any> {
        return this.http
            .get(this.API_PATH)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Gets the list of available sorted countries
     * @returns
     */
    public getSortedCountries(): Observable<any> {
        return from(this.getCountries()).pipe(
            map(countries => {
                this.sdkOptions.defaultCountries.map(countryId => {
                    const countryFirst = countries.filter(country => countryId === country.id)[0];
                    countries = countries.filter(country => countryId != country.id)
                    countries.unshift(countryFirst);
                });
                return countries;
            })
        );
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
