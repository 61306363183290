import {
    Component,
    OnDestroy,
    ViewEncapsulation,
    Input, OnInit, Inject
} from '@angular/core';
import {
    FormBuilder,
    FormGroup
} from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { startWith, takeUntil, skip, map } from 'rxjs/operators';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { AssetService } from '../../services/asset.service';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';


@Component({
    selector: 'st-asset-search-keyword-filter',
    templateUrl: './asset-search-keyword-filter.component.html',
    styleUrls: ['./asset-search-keyword-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AssetSearchKeywordFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    keywords$: Observable<Array<{ [key: string]: string }>>;

    isOccurrencesEnabled$: Observable<boolean>;

    defaultFormData: { [key: string]: boolean } = {};

    q = '';

    qArray: string[] = [];

    @Input() set filter(filter) {
        if (filter && filter.q) {
            this.q = filter.q;
            this.qArray = this.splitKeyword(filter.q);
        }
    }

    constructor(private formBuilder: FormBuilder,
                private assetService: AssetService,
                @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {
        super();
        this.keywords$ = this.assetService.getLoadedKeywordsTotal().pipe(
            map(keywords => keywords.filter(_keyword => this.qArray.indexOf(_keyword.name) === -1))
        );
        this.isOccurrencesEnabled$ = of(this.sdkOptions.assetSearchFilter && this.sdkOptions.assetSearchFilter.isOccurrencesEnabled);
        this.keywords$.subscribe(keywords => {
            this.defaultFormData = keywords.reduce((obj, key) => ({
                ...obj,
                [key.name]: false
            }), {});
            this.setupForm();
        });
    }

    ngOnInit(): void {
        this.setupForm();
    }

    setupForm() {
        this.form = this.formBuilder.group(this.defaultFormData);
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    onChange() {
        const selectedFormValues = Object.keys(this.form.value).reduce((_keyword, key) => {
            if (!!this.form.value[key]) {
                _keyword = [..._keyword, '"' + key + '"'];
            }
            return _keyword;
        }, []);
        this.changes.emit({
            q: this.q + ' ' + selectedFormValues.join(' ')
        });
    }

    splitKeyword(keyword: string): string[] | null {
        return keyword.match(/(?:[^\s"]+|"[^"]*")+/g).map(_keyword => _keyword.replace(/"/g, ''));
    }
}
