import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import {Lightbox} from '../../models/lightbox';
import {Asset} from '../../models/asset';
import {LightboxService} from '../../services/lightbox.service';
import {LightboxDataService} from '../../services/lightbox-data.service';
import {AssetActionPlugin} from '../../models/asset-action-plugin';

@Component({
    selector: 'st-lightbox-add-view',
    templateUrl: './lightbox-add-view.component.html',
    styleUrls: ['./lightbox-add-view.component.scss']
})
export class LightboxAddViewComponent implements OnInit {

    @Input() lightboxId: string;

    @Input() hidePreviewLink: boolean;

    @Input() assetActionPlugins: AssetActionPlugin[];

    @Output() toggleSimilaritySearch: EventEmitter<string> = new EventEmitter<string>();

    @Output() lightboxAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() previewLinkClicked: EventEmitter<Asset> = new EventEmitter<Asset>();

    /**
     * Current lightbox
     */
    lightbox: Lightbox;

    /**
     * Flat taht indicated whether the lightbox is loading or not
     */
    loading: boolean;

    constructor(private lightboxService: LightboxService, private lightboxDataService: LightboxDataService) {}

    ngOnInit() {
        this.loading = true;
        this.lightboxService.getLightbox(this.lightboxId).toPromise().then((lightbox) => {
            this.lightbox = lightbox;
            this.loading = false;
        }).catch(() => {
            this.loading = false;
        });
    }

    /**
     * Gets the assets of the lightbox
     * @param lightbox
     */
    getAssets(lightbox: Lightbox) {
        const assets: Asset[] = [];

        if (!lightbox || !lightbox.assets || !lightbox.assets.length) {
            return assets;
        }

        for (const lightboxAsset of lightbox.assets) {
            if (lightboxAsset && lightboxAsset.asset) {
                assets.push(lightboxAsset.asset);
            }
        }

        return assets;
    }

    /**
     * Toggles the similarity search
     * @param asset
     */
    onToggleSimilaritySearch(asset: Asset) {
        this.toggleSimilaritySearch.next(asset.id);
    }

    /**
     * Adds lightbox to the users lgithboxes
     * @param lightbox
     */
    addLightbox(lightbox: Lightbox) {
        const cleanedLightbox = this.lightboxService.cleanLightboxForDuplication(lightbox);

        this.lightboxDataService.createLightbox(cleanedLightbox).then(() => {
            this.lightboxAdded.emit(true);
        });
    }

    /**
     * Returns the number of assets in the lightbox
     * @param lightbox
     */
    getAssetQuantity(lightbox: Lightbox) {
        if (lightbox && lightbox.assets && lightbox.assets.length) {
            return lightbox.assets.length;
        } else {
            return 0;
        }
    }

    /**
     * Called when the preview link was clicked
     * @param asset
     */
    onPreviewLinkClicked(asset: Asset) {
        this.previewLinkClicked.next(asset);
    }
}
