import {Component, ElementRef, OnInit} from '@angular/core';
import {CmsBlockContent} from '../cms-block/cms-block-content';

@Component({
    selector: 'st-cms-heading',
    templateUrl: './cms-heading.component.html',
    styleUrls: ['./cms-heading.component.css']
})
export class CmsHeadingComponent extends CmsBlockContent implements OnInit {

    classes: string;

    constructor(el: ElementRef) {
        super(el);
    }

    ngOnInit() {
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }
    }

    /**
     * Get the top level styles
     */
    getStyles() {
        let styleObj = {};
        if (!this.data) {
            return null;
        }
        styleObj = {
            ...styleObj,
            color: this.data.color,
            textAlign: this.data.position
        };

        if (this.data.spacing) {
            styleObj = {
                ...styleObj,
                marginLeft: this.data.spacing.marginLeft + 'px',
                marginTop: this.data.spacing.marginTop + 'px',
                marginRight: this.data.spacing.marginRight + 'px',
                marginBottom: this.data.spacing.marginBottom + 'px',
                paddingTop: this.data.spacing.paddingTop + 'px',
                paddingBottom: this.data.spacing.paddingBottom + 'px'
            };
        }

        return styleObj;
    }


    /**
     * Get the background styles for the inner content
     */
    getBackgroundStyles() {
        let styleObj = {};
        if (!this.data) {
            return null;
        }
        styleObj = {
            ...styleObj,
            display: 'inline-block',
            backgroundColor: this.data.backgroundColor,
            paddingRight: (this.data.backgroundColor ? '5' : '') + 'px',
            paddingLeft: (this.data.backgroundColor ? '5' : '') + 'px',
        };
        return styleObj;
    }
}
