import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'st-cms-blocks',
    templateUrl: './cms-blocks.component.html',
    styleUrls: ['./cms-blocks.component.css']
})
export class CmsBlocksComponent implements OnInit {

    @Input() blocks: any[];

    @Input() isDraftView: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
