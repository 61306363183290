import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input,
    LOCALE_ID,
    Inject
} from '@angular/core';
import {
    FormBuilder,
    FormGroup
} from '@angular/forms';
import { Subject } from 'rxjs';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { DateAdapter, MatDatepickerInputEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';
import * as _moment from 'moment';
import {debounceTime, takeUntil} from 'rxjs/operators';
const moment = _moment;

export const DEFAULT_DATA = {
    creationDateFrom: '',
    creationDateTo: '',
    creationMonthsAgo: null,
    uploadDateFrom: '',
    uploadDateTo: '',
    uploadMonthsAgo: null
};

@Component({
    selector: 'st-asset-search-date-filter',
    templateUrl: './asset-search-date-filter.component.html',
    styleUrls: ['./asset-search-date-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AssetSearchDateFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    private latestValue = DEFAULT_DATA;

    @Input() set filter(filter) {
        if (filter && filter.datesFilter) {

            const datesFilter = filter.datesFilter;
            const dates = Object.keys(datesFilter).reduce((acc, curr) => {
                if (!!datesFilter[curr]) {
                    acc[curr] = datesFilter[curr];
                }
                return acc;
            }, {});

            const newValue = {
                ...DEFAULT_DATA,
                ...dates
            };

            this.latestValue = newValue;
            this.form.setValue(newValue, {emitEvent: false});
        }
    }

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<Date>
    ) {
        super();
        this.form = this.formBuilder.group(DEFAULT_DATA);
    }

    ngOnInit() {
        this.translateService.onLangChange.pipe(takeUntil(this.componentDestroyed$)).subscribe(({lang}) => {
            this.dateAdapter.setLocale(lang);
        });
        this.form.valueChanges.pipe(debounceTime(200), takeUntil(this.componentDestroyed$)).subscribe(value => {
            const oldValue = {...this.latestValue};
            this.latestValue = {...value};
            let newValue = {
                ...this.latestValue
            };
            if (!oldValue.creationMonthsAgo && value.creationMonthsAgo) {
                newValue = {
                    ...newValue,
                    creationDateFrom: '',
                    creationDateTo: '',
                    creationMonthsAgo: value.creationMonthsAgo
                };
            } else if (!oldValue.uploadMonthsAgo && value.uploadMonthsAgo) {
                newValue = {
                    ...newValue,
                    uploadDateFrom: '',
                    uploadDateTo: '',
                    uploadMonthsAgo: value.uploadMonthsAgo
                };
            } else if (((typeof oldValue.creationDateFrom === 'string') && (typeof value.creationDateFrom !== 'string'))) {
                newValue = {
                    ...newValue,
                    creationDateFrom: value.creationDateFrom,
                    creationDateTo: (value.creationDateTo.length) ? value.creationDateTo : '',
                    creationMonthsAgo: null
                };
            } else if (((typeof oldValue.creationDateTo === 'string') && (typeof value.creationDateTo !== 'string'))) {
                newValue = {
                    ...newValue,
                    creationDateTo: value.creationDateTo,
                    creationDateFrom: (value.creationDateFrom.length) ? value.creationDateFrom : '',
                    creationMonthsAgo: null
                };
            } else if (((typeof oldValue.uploadDateFrom === 'string') && (typeof value.uploadDateFrom !== 'string'))) {
                newValue = {
                    ...newValue,
                    uploadDateFrom: value.uploadDateFrom,
                    uploadDateTo: (value.uploadDateTo.length) ? value.uploadDateTo : '',
                    uploadMonthsAgo: null
                };
            } else if (((typeof oldValue.uploadDateTo === 'string') && (typeof value.uploadDateTo !== 'string'))) {
                newValue = {
                    ...newValue,
                    uploadDateTo: value.uploadDateTo,
                    uploadDateFrom: (value.uploadDateFrom.length) ? value.uploadDateFrom : '',
                    uploadMonthsAgo: null
                };
            }
            this.latestValue = {...newValue};
            if (!_.isEqual(oldValue, newValue)) {
                this.onChange(newValue);
            }
        });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    onChange(value) {
        this.changes.emit({ datesFilter: value });
    }

    onDateInput(e: Event) {
        const input = e.target as HTMLInputElement;
        if (input.value.trim().length === 0) {
            this.changes.emit({datesFilter: this.form.value});
        }
    }

    onInputChange(e: MatDatepickerInputEvent<_moment.Moment>) {
        if (!e.value || !e.value.isValid()) {
            this.form.patchValue(this.form.value);
        }
    }

}
