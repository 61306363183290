import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input, Inject
} from '@angular/core';
import {
    FormBuilder,
    FormGroup
} from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil, skip, map, take } from 'rxjs/operators';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { AssetService } from '../../services/asset.service';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';

export const DEFAULT_DATA = {
    v: { name: 'vertical', value: false },
    h: { name: 'horizontal', value: false },
    q: { name: 'square', value: false },
    p: { name: 'panoramic', value: false },
    pv: { name: 'skyscraper', value: false }
};

@Component({
    selector: 'st-asset-search-orientation-filter',
    templateUrl: './asset-search-orientation-filter.component.html',
    styleUrls: ['./asset-search-orientation-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AssetSearchOrientationFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    private _orientations: string[];

    @Input()
    set orientations(orientations) {
        this._orientations = orientations;
        this.data = orientations.reduce((map, orientation) => ({
            ...map,
            [orientation]: { ...DEFAULT_DATA[orientation] }
        }), {});
        this.defaultFormData = orientations.reduce((map, orientation) => ({
            ...map,
            [orientation]: DEFAULT_DATA[orientation].value
        }), {});
        const formValue = { ...this.defaultFormData, ...(this.form ? this.form.value : {}) };
        this.form = this.formBuilder.group(
            Object.keys(formValue)
                  .filter(key => orientations.indexOf(key) !== -1)
                  .reduce((map, key) => ({
                      ...map,
                      [key]: formValue[key]
                  }), {})
        );
    }

    get orientations() {
        return this._orientations;
    }

    isOccurrencesEnabled$: Observable<boolean>;

    occurrences$: Observable<{ [key: string]: number }>;

    data: { [key: string]: { name: string, value: boolean } };

    defaultFormData: { [key: string]: boolean };

    @Input() set filter(filter) {
        if (filter && filter.orientation) {
            const formValue = filter.orientation.map(orientation => ({
                [orientation]: true
            })).reduce((value, curr) => ({
                ...curr,
                ...value
            }), {});

            this.form.setValue(
                { ...this.defaultFormData, ...formValue },
                { emitEvent: false }
            );
        }
    }

    constructor(private formBuilder: FormBuilder,
                private assetService: AssetService,
                @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {
        super();
        this.isOccurrencesEnabled$ = of(this.sdkOptions.assetSearchFilter && this.sdkOptions.assetSearchFilter.isOccurrencesEnabled);
        this.isOccurrencesEnabled$.pipe(take(1)).subscribe(enabled => {
            if ( enabled && !this.occurrences$ ) {
                this.occurrences$ = this.assetService.getLoadedOccurrencesTotal();
            }
        });
        if (!this.orientations) {
            this.orientations = Object.keys(DEFAULT_DATA);
        }
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    onChange() {
        const orientation = Object.keys(this.form.value).reduce((_orientation, key) => {
            if (!!this.form.value[key]) {
                _orientation = [..._orientation, key];
            }
            return _orientation;
        }, []);

        this.changes.emit({
            orientation
        });
    }
}
