import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {Observable} from 'rxjs';

@Injectable()
export class NewsletterService {
    private API_PATH = 'newsletter';

    constructor(private http: SodaApiService) {}

    sendNewsletterRequest(accountInfo: NewsletterRequestModel): Observable<any> {
        return this.http.post(this.API_PATH, accountInfo)
    }

    verifyNewsletterAccount(id: number, token: string): Observable<any> {
        const url = `${this.API_PATH}/${id}/confirm/${token}`;
        return this.http.post(url, {});
    }
    
    deactivateNewsletterAccount(id: number, token: string): Observable<any> {
        const url = `${this.API_PATH}/${id}/disable/${token}`;
        return this.http.post(url, {})
    }
}

export interface NewsletterRequestModel {
    id?: number;
    email: string;
    gender?: string;
    familyName: string;
    givenName: string;
    language?: string;
}
