import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {SodaApiService} from './soda-api.service';

/**
 * Service that interacts with the suggest api
 */
@Injectable()
export class SuggestService {
    private API_PATH = 'suggestions';

    constructor(private http: SodaApiService) {}

    /**
     * Gets possible suggestions matching request query
     * @param keyword
     * @returns
     */
    public getSuggestions(keyword: string): Observable<string[]> {
        const params = new HttpParams({
            fromObject: {
                keyword
            }
        });

        return this.http.get<any>(this.API_PATH, { params });
    }
}
