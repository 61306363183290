import {Pipe, PipeTransform} from '@angular/core';

/**
 * The pipe transforms the given value into the same value but with the first letter capitalized
 */
@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value && value !== '') {
      value.charAt(0).toUpperCase();
    }

    return value;
  }

}
