import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {AssetPreviewData, AssetPreviewService, CONTAINER_DATA} from '../../services/asset-preview.service';
import {AssetService} from '../../services/asset.service';
import {take} from 'rxjs/operators';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import {RemoveFileExtensionPipe} from '../../pipes/remove-file-extension.pipe';
import {transliterate as tr} from 'transliteration';
import * as _ from 'lodash';

@Component({
    selector: 'st-asset-preview-overlay',
    templateUrl: './asset-preview-overlay.component.html',
    styleUrls: ['./asset-preview-overlay.component.scss'],
    providers: [RemoveFileExtensionPipe]
})
export class AssetPreviewOverlayComponent implements OnInit {

    imgSrc: string;

    imageLoader = true;

    constructor(private assetPreviewService: AssetPreviewService, @Inject(CONTAINER_DATA) public componentData: AssetPreviewData, private assetService: AssetService, @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions, private removeFileExtensionPipe: RemoveFileExtensionPipe,
    ) {
    }

    async ngOnInit() {
        if (this.sdkOptions.searchGridData.assetUrlSuffix) {
            this.imgSrc = await this.assetService.getAssetPreview(this.componentData.assetId).pipe(take(1)).toPromise() + this.sdkOptions.searchGridData.assetUrlSuffix + this.getImageUrl();

        } else {
            this.imgSrc = await this.assetService.getAssetPreview(this.componentData.assetId).pipe(take(1)).toPromise() + '/' + this.componentData.assetName;
        }
    }

    getImageUrl() {
        const prefix = tr(`${this.sdkOptions.searchGridData.assetNamePrefix}${this.removeFileExtensionPipe.transform(this.componentData.assetName)} - `);
        let caption = tr(this.componentData.assetCaption.trim().replace(/[\/\\:*?"<>|]/g, '-'));
        const suffix = tr('.jpg');
        const maxLength = 240 - prefix.length - suffix.length;
        const captionLength = caption.length;
        const totalLength = captionLength + prefix.length + suffix.length;
        if (totalLength > 240) {
            caption = tr(_.truncate(caption, {
                'length': maxLength
            })).replace(/\./g, '');
        }

        return tr(`${prefix}${caption}${this.sdkOptions.searchGridData.assetNameSuffix}`);
    }

    onOverlayClose() {
        this.assetPreviewService.close();
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.assetPreviewService.close();
    }
}
