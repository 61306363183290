import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Asset } from '../models/asset';
import { SodaApiService } from './soda-api.service';
import { formatDate, isPlatformServer } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {ImageSimilarityService} from './image-similarity.service';
import {SDK_OPTIONS, SdkOptions} from '../models/sdk-options';
import * as FormData from 'form-data';

export interface AssetOptions {
    [key: string]: any;
}

export interface AssetsResponse {
    data: Asset[];
    total: number;
    facets: { [key: string]: any }[]; // @todo: there is no model definition for Facet right now. Implement it
    searchPending: boolean;
}

/**
 * Service that interacts with the asset api
 */
    // @todo: replace <any> with correct interfaces in class methods
@Injectable()
export class AssetService {
    private API_PATH = 'assets';

    loadedAssetsTotal: BehaviorSubject<number> = new BehaviorSubject(null);

    loadedFacetedTotal: BehaviorSubject<any> = new BehaviorSubject(null);

    latestLoadingAssetsRequestId: number;

    getLoadedAssetsTotal(): Observable<number> {
        return this.loadedAssetsTotal.asObservable();
    }

    getLoadedFacetedTotal(): Observable<any> {
        return this.loadedFacetedTotal.asObservable();
    }

    getLoadedKeywordsTotal(): Observable<Array<{[key: string]: string}>> {
        return this.loadedFacetedTotal.pipe(
            map(res => res && res.keywords
                ? Object.keys(res.keywords).map(_key => ({ name: _key, value: res.keywords[_key] }))
                : [])
        );
    }

    getLoadedRestrictionsTotal(): Observable<{[key: string]: number}> {
        return this.loadedFacetedTotal.pipe(
            map(res => res && res.visibility_search ? res.visibility_search : [])
        );
    }
    getLoadedOccurrencesTotal(): Observable<{[key: string]: number}> {
        return this.loadedFacetedTotal.pipe(
            map(res => res && res.orientation ? res.orientation : {})
        );
    }

    getLoadedCategoriesTotal(): Observable<Array<{[key: string]: string}>> {
        return this.loadedFacetedTotal.pipe(
            map(res => res && res.fcategories
                ? Object.keys(res.fcategories).map(_key => ({ id: _key, value: res.fcategories[_key] }))
                : [])
        );
    }

    constructor(private http: SodaApiService, @Inject(LOCALE_ID) private locale: string, @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
                private translate: TranslateService, @Inject(PLATFORM_ID) private platformId: Object, private imageSimilarityService: ImageSimilarityService) {
    }


    // This method creates params for the url
    // and maps the inconsistencies between backend and frontend
    private createParams(options: AssetOptions) {
        const keysMap = {
            searchKey: 'search',
            supplierId: 'supplier_id',
            orgId: 'org_id'
        };

        const colorsMap = {
            bw: 'black-white',
            mch: 'monochrome',
            color: 'color'
        };

        const datesMap = {
            creationDateFrom: 'creation_date_from',
            creationDateTo: 'creation_date_to',
            uploadDateFrom: 'upload_date_from',
            uploadDateTo: 'upload_date_to'
        };

        const releaseMap = {
            mr: 'model_released',
            pr: 'property_released',
        };

        const paramsObj = { ...options };

        // Basically we loop through our mapping,
        // find out if options has defined current iteratee key
        // assign its value to the paramsObj and remove the old key
        Object.keys(keysMap).forEach(key => {
            if (!!options[key]) {
                Object.assign(paramsObj, { [keysMap[key]]: options[key] });
                delete paramsObj[key];
            }
        });
        // Check if there is a color defined
        // and assign the mapped one
        if (!!paramsObj.color) {
            paramsObj.color = colorsMap[paramsObj.color];
        }

        if (paramsObj['aiplus']) {
            const ids = this.imageSimilarityService.getSimilarImages();
            delete paramsObj['aiplus'];
            if (ids && ids.length) {
                paramsObj['picIds'] = ids.join(',');
            }
        }

        if (paramsObj['exclusive']) {
            paramsObj['exclusive'] = true;
        }

        if (!!paramsObj.datesFilter) {
            const datesFilterParams = Object.keys(paramsObj.datesFilter).reduce((acc, key) => {
                if (paramsObj.datesFilter[key]) {
                    acc[datesMap[key]] = paramsObj.datesFilter[key];
                }
                return acc;
            }, {});
            Object.assign(paramsObj, { ...datesFilterParams });
            delete paramsObj.datesFilter;
        }


        if (!!paramsObj['sort_color']) {
            paramsObj['sort_color'] = `#${paramsObj['sort_color']}`;
            paramsObj['sort'] = 'color';
        }

        if (!!paramsObj.release) {
            paramsObj.release.map((release: string) => { paramsObj[releaseMap[release]] = true; });
            delete paramsObj.release;
        }

        if (!!paramsObj.collection) {
            paramsObj.collection = paramsObj.collection.replace(/;/g, ',');
        }

        if (!!paramsObj.category) {
            paramsObj.category = (Array.isArray(paramsObj.category)) ? paramsObj.category.join(',') : paramsObj.category;
        }

        return new HttpParams({ fromObject: paramsObj });
    }

    /**
     * Get assets
     */
    public getAssets(options: AssetOptions): Observable<any> {
        const params = this.createParams(options);
        const requestId = Math.random();
        this.latestLoadingAssetsRequestId = requestId;
        return this.http.get<AssetsResponse>(this.API_PATH, { params }).pipe(
            switchMap(({ data, facets, total, searchPending }) => {
                if (data && data.length === 0) {
                    this.setAssetsMeta(0, null, requestId, options.page);
                    return of({
                        assets: [],
                        total: 0,
                        faceted: null,
                        searchPending: false
                    });
                }
                const sizes$ = combineLatest(...data.map(asset => {
                    if (asset.width === 0) {
                        return this.getAssetSize(asset.associatedMedia[0].contentUrl);
                    } else {
                        return of({ width: asset.width, height: asset.height });
                    }
                }));
                return sizes$.pipe(
                    map(sizes => {
                        this.setAssetsMeta(total, facets, requestId, options.page);
                        return {
                            assets: data.map((asset, i) => {
                                return {
                                    ...asset,
                                    ...sizes[i]
                                };
                            }),
                            total,
                            faceted: facets,
                            searchPending
                        };
                    })
                );
            })
        );
    }

    /**
     * Get an asset by id
     */
    public getAsset(id: string): Promise<any> {
        const url = `${this.API_PATH}/${id}`;
        return this.http
                   .get(url)
                   .toPromise()
                   .catch(this.handleError);
    }

    /**
     * Get similar assets
     * @param id asset id
     * @param options options from which  the HttpRequest params are created
     */
    public getSimilarAssets(id: string, options: any): Promise<any> {
        const params = this.createParams(options);
        const url = `${this.API_PATH}/${id}/similar`;

        return this.http
                   .get<AssetsResponse>(url, { params })
                   .pipe(
                       map(({ data, total }) => ({
                           assets: data,
                           total
                       }))
                   )
                   .toPromise()
                   .catch(this.handleError);
    }

    public getSimilarAssetsFromSimilaritySearch(assetUrl: string, similarItemsQtty = 20) {
        const formData = new FormData();
        formData.append('image_url', assetUrl);
        formData.append( 'similar_items', String(similarItemsQtty));
        return this.imageSimilarityService.getSimilarityImages(formData);
    }

    /**
     * Get the download url of the asset
     * @param id asset id
     * @param sizeType asset size type
     * @returns
     */
    public download(id: string, sizeType: string) {
        const params = this.createParams({ download: true });
        const url = `${this.API_PATH}/${id}/files/${sizeType}`;

        return this.http
                   .get<any>(url, { params })
                   .toPromise()
                   .then(response => {
                       this.http.download(response.downloadUrl);
                   })
                   .catch(this.handleError);
    }

    /**
     * Get the download url of the asset
     * @param id
     * @param usage
     * @param subscriptionId
     */
    public highResDownload(id: string, usage: string, subscriptionId: number) {
        const params = this.createParams({ usage, subscriptionId, download: 1 });
        const url = `${this.API_PATH}/${id}/files/hires`;
        return this.http
                   .get<any>(url, { params })
                   .toPromise()
                   .then(response => {
                       this.http.download(response.downloadUrl);
                   })
                   .catch(this.handleError);
    }

    getAssetSize(imageSrc: string): Observable<AssetSize> {
        if (isPlatformServer(this.platformId)) {
            return of({
                width: 0,
                height: 0
            });
        }
        const mapLoadedImage = (event): AssetSize => {
            return {
                width: event.target.width,
                height: event.target.height
            };
        };
        const image = new Image();
        const loadedImg$ = fromEvent(image, 'load').pipe(take(1), map(mapLoadedImage));
        image.src = imageSrc;
        return loadedImg$;
    }

    /**
     * Custom error handler
     * @param error catched error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    /**
     * Get the search filter configuration for a given filter
     * @param data
     * @param nextPage
     * @param itemsPerPage
     */
    getSearchFilters(data: any, nextPage?: number, itemsPerPage?: number) {
        const searchFilters = {
            'page': nextPage,
            'itemsPerPage': itemsPerPage
        };


        if (data['sold'] === true || data['sold'] === false) {
            searchFilters['sold'] = data['sold'];
        }

        if (data['q']) {
            searchFilters['searchKey'] = data['q'];
        }

        if (data['cop']) {
            searchFilters['copyright'] = data['cop'];
        }

        if (data['pgid']) {
            searchFilters['supplierId'] = data['pgid'];
        }

        if (data['orgid']) {
            searchFilters['orgId'] = data['orgid'];
        }

        if (data['license']) {
            searchFilters['license'] = data['license'];
        }

        if (data['peopleCategory']) {
            searchFilters['category'] = data['peopleCategory'];
        }

        if (data['peopleCategory']) {
            searchFilters['category'] = data['peopleCategory'];
        }

        if (data['category']) {
            searchFilters['category'] = (searchFilters['category']) ? [...searchFilters['category'], ...data['category']] : data['category'];
        }

        if (data['orientation']) {
            const orientation =
             Array.isArray(data['orientation'])
                ? data['orientation'].join(',')
                : [data['orientation']];

            searchFilters['orientation'] = orientation;
        }

        if (data['size']) {
            let minRes;
            if (data['size'] === 'geA5') {
                minRes = 4335040; // 1748 x 2480
            } else if (data['size'] === 'geA4') {
                minRes = 8699840; // 2480 x 3508
            } else if (data['size'] === 'geA3') {
                minRes = 17403188; // 3508 x 4961
            } else {
                minRes = 34806376; // 4961 x 7016
            }
            searchFilters['resolution_min'] = minRes;
        }

        if (data['coll']) {
            searchFilters['collection'] = data['coll'].join(',');
        }

        if (data['datesFilter']) {
            const datesFilter = {...data.datesFilter};
            Object.keys(datesFilter).forEach(key => {
                if (datesFilter[key] && key !== 'creationMonthsAgo' && key !== 'uploadMonthsAgo') {
                    datesFilter[key] = formatDate(datesFilter[key], 'yyyy-MM-dd', this.locale);
                }
            });

            if ((datesFilter['creationMonthsAgo'] === '1' ||
                datesFilter['creationMonthsAgo'] === '3' ||
                datesFilter['creationMonthsAgo'] === '6' ||
                datesFilter['creationMonthsAgo'] === '12')) {
                const creationDate = new Date();

                creationDate.setMonth(creationDate.getMonth() - (+datesFilter['creationMonthsAgo']));
                creationDate.setHours(0, 0, 0);
                creationDate.setMilliseconds(0);
                delete datesFilter.creationMonthsAgo;
                delete datesFilter.creationDateTo;
                datesFilter.creationDateFrom = formatDate(creationDate, 'yyyy-MM-dd', this.locale);
            }

            if ((datesFilter['uploadMonthsAgo'] === '1' ||
                datesFilter['uploadMonthsAgo'] === '3' ||
                datesFilter['uploadMonthsAgo'] === '6' ||
                datesFilter['uploadMonthsAgo'] === '12')) {
                const uploadDate = new Date();

                uploadDate.setMonth(uploadDate.getMonth() - (+datesFilter['uploadMonthsAgo']));
                uploadDate.setHours(0, 0, 0);
                uploadDate.setMilliseconds(0);
                delete datesFilter.uploadMonthsAgo;
                delete datesFilter.uploadDateTo;
                datesFilter.uploadDateFrom = formatDate(uploadDate, 'yyyy-MM-dd', this.locale);
            }

            if (datesFilter.creationDateFrom || datesFilter.creationDateTo) {
                searchFilters['sort'] = '-dateCreated';

            } else if (datesFilter.uploadDateFrom || datesFilter.uploadDateTo) {
                searchFilters['sort'] = '-uploadDate';
            }

            Object.assign(searchFilters, { datesFilter: datesFilter } );
        }

        if (data['release']) {
            searchFilters['release'] = data['release'];
        }

        if (data['mediaType']) {
            searchFilters['image_type'] = [data['mediaType']];
        }

        if (data['exclusive']) {
            searchFilters['exclusive'] = data['exclusive'];
        }

        if (data['color']) {
            if (data['color'] === 'bw') {
                searchFilters['color'] = false;
            } else {
                searchFilters['sort_color'] = data['color'];
            }
        }

        if (data['keywords']) {
            searchFilters['keywords'] = data['keywords'].join(',');
        }

        if (data['peopleNumber']) {
            let keyword = '';
            const availableLanguages = [...this.sdkOptions.availableLanguages];
            availableLanguages.splice(availableLanguages.indexOf('en'), 1);
            const secondLanguage = availableLanguages.length ? availableLanguages[0] : '';
            switch (data['peopleNumber']) {
                case '0':
                    keyword = (this.translate.currentLang && this.translate.currentLang === 'en') ?
                        this.sdkOptions.assetSearchFilter.peopleFilter['0']['en'] :
                        this.sdkOptions.assetSearchFilter.peopleFilter['0'][secondLanguage];
                    break;
                case '1':
                    keyword = (this.translate.currentLang && this.translate.currentLang === 'en') ?
                        this.sdkOptions.assetSearchFilter.peopleFilter['1']['en'] :
                        this.sdkOptions.assetSearchFilter.peopleFilter['1'][secondLanguage];
                    break;
                case '2':
                    keyword = (this.translate.currentLang && this.translate.currentLang === 'en') ?
                        this.sdkOptions.assetSearchFilter.peopleFilter['2']['en'] :
                        this.sdkOptions.assetSearchFilter.peopleFilter['2'][secondLanguage];
                    break;
                case '3':
                    keyword = (this.translate.currentLang && this.translate.currentLang === 'en') ?
                        this.sdkOptions.assetSearchFilter.peopleFilter['3']['en'] :
                        this.sdkOptions.assetSearchFilter.peopleFilter['3'][secondLanguage];
                    break;
                case '4':
                    keyword = (this.translate.currentLang && this.translate.currentLang === 'en') ?
                        this.sdkOptions.assetSearchFilter.peopleFilter['4']['en'] :
                        this.sdkOptions.assetSearchFilter.peopleFilter['4'][secondLanguage];
                    break;
                case '5+':
                    keyword = (this.translate.currentLang && this.translate.currentLang === 'en') ?
                        this.sdkOptions.assetSearchFilter.peopleFilter['5']['en'] :
                        this.sdkOptions.assetSearchFilter.peopleFilter['5'][secondLanguage];
                    break;
            }
            searchFilters['searchKey'] = (searchFilters['searchKey']) ? searchFilters['searchKey'] + ' ' + keyword : keyword;
        }

        if (data['webseries']) {
            searchFilters['webseries'] = data['webseries'];
        }

        if (data['aiplus']) {
            searchFilters['aiplus'] = data['aiplus'];
        }

        if (data['restrictions']) {
            searchFilters['downloadable'] = true;
        }
        return searchFilters;
    }

    private setAssetsMeta(total: number, facets: any, requestId: number, currentPage: number) {
        if (this.latestLoadingAssetsRequestId === requestId) {
            this.loadedAssetsTotal.next(total);
            if (currentPage === 1) {
                this.loadedFacetedTotal.next(facets);
            }
        }
    }

    getAssetPreview(assetId: string): Observable<string> {
        const params = new HttpParams();
        params.append('download', 'false');
        return this.http.get<any>(this.API_PATH + `/${assetId}/files/layoutLarge`, {params: params}).pipe(map(data => {
            if (data.downloadUrl) {
                return this.sdkOptions.apiPath.slice(0, -1) + data.downloadUrl;
            }
        }));
    }
}

export interface AssetSize {
    width: number;
    height: number;
}
