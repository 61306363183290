import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AssetFilterPartialComponent} from '../asset-filter-partial-component/asset-filter-partial.component';
import {Subject} from 'rxjs';
import {skip, startWith, takeUntil} from 'rxjs/operators';

export const DEFAULT_DATA = {
    peopleNumber: ''
};

export const DEFAULT_PEOPLE_NUMBERS_DATA = {
    any: 'any',
    peopleNumberZero: '0',
    peopleNumberOne: '1',
    peopleNumberTwo: '2',
    peopleNumberThree: '3',
    peopleNumberFour: '4',
    peopleNumberFivePlus: '5+'
};

export const DEFAULT_PEOPLE_NUMBERS = Object.keys(DEFAULT_PEOPLE_NUMBERS_DATA);

@Component({
    selector: 'st-asset-search-people-number-filter',
    templateUrl: './asset-search-people-number-filter.component.html',
    styleUrls: ['./asset-search-people-number-filter.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(
                () => AssetSearchPeopleNumberFilterComponent
            ),
            multi: true
        }
    ]
})
export class AssetSearchPeopleNumberFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy, ControlValueAccessor {

    form: FormGroup;
    componentDestroyed$ = new Subject<boolean>();

    private _peopleNumbers: string[];

    peopleNumbersValues: {[key: string]: string};

    @Input()
    set peopleNumbers(peopleNumbers) {
        this._peopleNumbers = [...DEFAULT_PEOPLE_NUMBERS].filter(_peopleNumber => peopleNumbers.indexOf(_peopleNumber) !== -1);
        this.peopleNumbersValues = this._peopleNumbers.reduce((obj, _peopleNumber) => ({
            ...obj,
            [_peopleNumber]: DEFAULT_PEOPLE_NUMBERS_DATA[_peopleNumber]
        }), {});
    }

    get peopleNumbers() {
        return this._peopleNumbers;
    }

    @Input() set filter(filter) {
        if (filter) {
            this.form.setValue({
                peopleNumber: filter.peopleNumber || 'any'
            }, {emitEvent: false});
        }
    }

    constructor(private formBuilder: FormBuilder) {
        super();
        if (!this.peopleNumbers) {
            this.peopleNumbers = DEFAULT_PEOPLE_NUMBERS;
        }
        this.form = this.formBuilder.group(DEFAULT_DATA);
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    writeValue(value: any) {
        if (value) {
            this.form.patchValue(value, {emitEvent: false});
        }
    }

    registerOnChange(fn: Function) {
    }

    registerOnTouched(fn: Function) {
    }

    onChange() {
        this.changes.emit({
            peopleNumber: (this.form.value.peopleNumber !== 'any') ? this.form.value.peopleNumber : ''
        });
    }

}
