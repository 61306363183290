// @todo: add error handling, replace <any> with corresponding types
import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {DownloadLog, DownloadLogResponse} from '../models/download-log';

/**
 *
 * Service that interacts with downloadlogs api
 */
@Injectable()
export class DownloadLogsService {
    private API_PATH = 'downloadlogs';

    constructor(private http: SodaApiService) {}

    /**
     * Load download logs with pagination
     * @param options
     */
    public getDownloadLogsWithPaging(options: any): Promise<DownloadLogResponse> {
        const params = new HttpParams({ fromObject: options });
        return this.http
            .get(this.API_PATH + '/paginate', {params})
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
