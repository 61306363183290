export function getImageFileUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = error => reject(error);
    });
}

export function getImageDimensions(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const theImage = new Image();
        theImage.src = url;
        theImage.onload = () => resolve({width: theImage.width, height: theImage.height});
        theImage.onerror = error => reject(error);
    });
}

export function compress(file: File, dimensions: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
        let scaleFactor = 0;
        let width = 0;
        let height = 0;
        if (dimensions.width < dimensions.height && dimensions.height > 256) {
            scaleFactor = 256 / dimensions.height;
            height = 256;
            width = dimensions.width * scaleFactor;
        } else if (dimensions.width > dimensions.height && dimensions.width > 256) {
            scaleFactor = 256 / dimensions.width;
            width = 256;
            height = dimensions.height * scaleFactor;
        } else if (dimensions.width === dimensions.height) {
            width = 256;
            height = 256;
        } else {
            width = dimensions.width;
            height = dimensions.height;
        }
        const fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = event => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                // img.width and img.height will contain the original dimensions
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                    let newFile;
                    if (navigator.msSaveBlob) {
                        // workaround for Edge and IE since they are not supported File constructor
                        newFile = blobToFile(
                            new Blob([blob], { type: file.type }),
                            fileName
                        );
                    } else {
                        newFile = new File([blob], fileName, {
                            type: file.type,
                            lastModified: Date.now()
                        });
                    }

                    resolve(newFile);
                }, 'image/png', 1);
            };
            reader.onerror = error => reject(error);
        };
    });

    // to change Blob to File
    function blobToFile(theBlob: Blob, fileName: string): File {
        const b: any = theBlob;
        b.name = fileName;
        b.lastModifiedDate = new Date();
        return b as File;
    }
}
