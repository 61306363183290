import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges, OnDestroy, OnInit,
    Output, PLATFORM_ID,
    SimpleChanges, ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import {DragulaService} from 'ng2-dragula';
import {Asset} from '../../models/asset';
import {WindowRefService} from '../../services/window-ref.service';
import {LightboxService} from '../../services/lightbox.service';
import {filter, take} from 'rxjs/operators';
import { AssetActionPlugin } from '../../models/asset-action-plugin';
import {Subscription} from 'rxjs';
import {LightboxDataService} from '../../services/lightbox-data.service';
import {DragAndDropService} from '../../services/drag-and-drop.service';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';


/**
 * The component is used to display a list of assets that is given via the assets input in either grid or list form.
 * The layout can be toggled using the layout input.
 *
 * Grid Layouts
 * For the asset list there are three different layout modes. The masonry grid layout, grid layout and list layout.
 * The layout can be specified when using the component.
 *
 * Masonry Grid
 * type: masonry-grid
 * The masonry grid is the default grid. It calculates how many items can be rendered in on row and resizes each asset to the same size.
 * So the whole grid ...
 *
 * Grid
 * type: grid
 * The grid layout renders all assets with a fixed height and a target width. The specified gridTargetItemWidth is used for calculating the
 * maximum number of assets that can be displayed in a single row.
 *
 * List
 * type: list
 * The list layout renders one asset and the available meta information per row
 */
@Component({
    selector: 'st-asset-list',
    templateUrl: './asset-list.component.html',
    styleUrls: ['./asset-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AssetListComponent
    implements OnInit, DoCheck, AfterViewInit, OnChanges, OnDestroy {


    @Input('lightboxId')
    set setLightboxId(id: number) {
        this.dragulaId = 'lb_bag';
        this.lightboxId = id;
        this.initDragulaForLightbox();
    }

    /**
     * Is the list sortable
     */
    @Input()
    sortable = false;

    @Input()
    assetActionPlugins: AssetActionPlugin[] = [];

    @Input() hidePreviewLink: boolean;

    @Output()
    orderChange = new EventEmitter<any>();

    @Output() selectionChange = new EventEmitter<any>();

    @Output() loadMoreAssets: EventEmitter<void> = new EventEmitter();

    @Output() assetAction = new EventEmitter<{id: string; action: string}>();

    /**
     * Array of assets passed to build the list
     */
    @Input('assets')
    set setInputAssets(assets: Asset[]) {
        this.setAssets(assets);
    }

    /**
     * Layout for the list
     */
    @Input()
    layout = 'masonry-grid';

    /**
     * Grid item width
     */
    @Input()
    gridItemTargetWidth = 200;

    /**
     * The default asset height
     */
    @Input()
    defaultAssetHeight = 150;

    /**
     * Should toggle similarity search
     */
    @Output()
    toggleSimilaritySearch = new EventEmitter<Asset>();

    @Output() previewLinkClicked: EventEmitter<Asset> = new EventEmitter<Asset>();

    @Input() urlGenerator: Function;

    @Input() scrollToDetail = true;

    @Input() displayAssetName = false;

    /**
     * List of assets
     */
    public assets: any = [];

    /**
     * Id of the current lightbox
     */
    public lightboxId: number = null;

    private previousAssetPositions = {};

    /**
     * Gird item width for the grid layout
     */
    gridItemWidth: number;

    /**
     * Flag that indicates whether the layout was changed or not
     */
    layoutChanged: boolean;

    private assetsTmp: any = [];
    showDetailId: number;
    showDetailAsset: any;
    private detailLoaded: number = null;
    private defaultMarginWidth = 10;
    private currentHostWidth = 0;
    public dragulaId: string;

    selectedAssets = {};
    lastSelectedAsset: number = null;

    dataSubscription: Subscription;

    /**
     * The max asset height
     */
    maxAssetHeight;

    forcePush = false;

    @ViewChild('mainContainer') dragulaContainerRef: ElementRef;

    public dragulaOptions = {
        direction: 'horizontal'
    };

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkHostSizeChange();
    }

    constructor(
        private hostElement: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: Object,
        private window: WindowRefService,
        private dragulaService: DragulaService,
        private lightboxDataService: LightboxDataService,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions
    ) {
        if ( this.sdkOptions.layout && this.sdkOptions.layout.masonryGrid ) {
            this.maxAssetHeight = this.sdkOptions.layout.masonryGrid.maxAssetHeight;
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        /*setTimeout(() => {
            this.checkHostSizeChange();
            this.dragAndDropService.isLightboxBarDragAndDropInititalized().pipe(filter((Boolean), take(1))).subscribe(() => {
                this.dragulaId = 'assets';
                //this.changeDetectorRef.detectChanges();
            });
        }, 1);*/
    }

    public reset() {
        this.showDetailId = null;
        this.showDetailAsset = null;
        this.detailLoaded = null;
        this.assets = [];
        this.assetsTmp = [];
    }

    private getPositionList(assetList: any[]) {
        const list = {};
        let i = 0;
        for (const asset of assetList) {
            list[asset.id] = i;
            i++;
        }

        return list;
    }

    public setAssets(assets) {
        if (!Array.isArray(assets)) {
            assets = [];
        }

        /*        if (JSON.stringify(assets) === JSON.stringify(this.assets)) {
                    console.log("RETURN ME");
                    return;
                }*/

        this.assets = assets;
        this.previousAssetPositions = this.getPositionList(assets);

        this.refreshSize();
    }

    /**
     * Add assets to current assets list
     * @param assets
     */
    public addAssets(assets) {
        this.setAssets(this.assets.concat(assets));
    }

    public getBoundingClientRect(): ClientRect {
        if (!this.hostElement.nativeElement.getBoundingClientRect) {
            const rect = {
                bottom: 0,
                height: 0,
                left: 0,
                right: 0,
                top: 0,
                width: 0
            };

            return rect as ClientRect;
        }
        return this.hostElement.nativeElement.getBoundingClientRect();
    }

    /**
     * Refreshes layout size
     */
    public refreshSize() {
        if (this.layout === 'masonry-grid') {
            if (!this.assets || !this.assets.length) {
                return;
            }

            let combinedWidth = 0;
            let assetRow = [];
            this.assetsTmp = [];

            for (const asset of this.assets) {
                this.forcePush = false;

                if (!asset.sizeFactor) {
                    asset.sizeFactor = asset.width / asset.height;
                }

                const newWidth = this.defaultAssetHeight * asset.sizeFactor;

                if (
                    combinedWidth + newWidth + this.defaultMarginWidth >
                    this.currentHostWidth && !this.forcePush
                ) {
                    this.addAssetsTmp(assetRow, combinedWidth);

                    if ( !this.forcePush ) {
                        combinedWidth = 0;
                        assetRow = [];
                    }
                }

                combinedWidth += newWidth;
                assetRow.push(asset);
            }

            if (assetRow.length > 0) {
                for (const asset2 of assetRow) {
                    asset2.displayHeight = this.defaultAssetHeight + 'px';
                    asset2.displayWidth =
                        ((this.defaultAssetHeight * asset2.sizeFactor) /
                            this.currentHostWidth) *
                        100 +
                        '%';
                    this.assetsTmp.push(asset2);
                }
            }

            this.assets = this.assetsTmp;
        } else if (this.layout === 'grid') {
            if (
                this.assets.length * this.gridItemTargetWidth >
                this.currentHostWidth
            ) {
                this.gridItemWidth =
                    this.currentHostWidth /
                    Math.round(
                        this.currentHostWidth / this.gridItemTargetWidth
                    );
            } else {
                this.gridItemWidth = this.gridItemTargetWidth;
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    private addAssetsTmp(assetRow: any, combinedWidth: number): void {
        const newHeight =
            this.defaultAssetHeight * (this.currentHostWidth / combinedWidth);

        if ( this.maxAssetHeight && newHeight > this.maxAssetHeight ) {
            this.forcePush = true;
            return;
        }

        for (const asset2 of assetRow) {
            asset2.displayHeight = Math.floor(newHeight) + 'px';
            asset2.displayWidth =
                ((newHeight * asset2.sizeFactor) / this.currentHostWidth) *
                100 +
                '%';
            asset2.marginWidth = this.defaultMarginWidth + 'px';
            this.assetsTmp.push(asset2);
        }
    }

    /**
     * Shows passed asset details
     * @param event
     */
    showAssetDetail(event: any): void {
        if (event.event && (event.event.ctrlKey || event.event.shiftKey || event.event.metaKey )) {
            this.setThumbSelection(event);
            return;
        } else {
            this.selectedAssets = {};
            this.selectionChange.emit([]);
        }
        this.showDetailId = event.id;
        this.showDetailAsset = event.asset;
    }

    /**
     * Closes current asset details
     */
    closeAssetDetail(event: any): void {
        this.showDetailId = null;
    }

    ngDoCheck() {
        this.checkHostSizeChange();
    }

    checkHostSizeChange() {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const style = getComputedStyle(this.hostElement.nativeElement);
        const paddingX =
            parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);

        const rect = this.getBoundingClientRect();
        const newWidth = rect.width - paddingX;

        // if the width is still the same and the layout has not changed
        if (this.currentHostWidth === newWidth && !this.layoutChanged) {
            return;
        }

        this.currentHostWidth = newWidth;

        this.refreshSize();
        this.layoutChanged = false;
    }

    /**
     * Go to previous asset
     * @param event
     */
    previousAsset(event: any): void {
        let previousAsset = null;
        for (const asset of this.assets) {
            if (asset.id === this.showDetailId) {
                if (!previousAsset) {
                    return;
                }

                this.showDetailAsset = previousAsset;
                this.showDetailId = previousAsset.id;

                return;
            }

            previousAsset = asset;
        }
    }

    /**
     * Go to previous asset
     * @param event
     */
    nextAsset(event: any): void {
        let assetMatched = false;
        for (const asset of this.assets) {
            if (assetMatched) {
                this.showDetailAsset = asset;
                this.showDetailId = asset.id;

                return;
            } else if (asset.id === this.showDetailId) {
                assetMatched = true;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if the layout has changed the change dection has to run agaiin to calculate the changes
        if (changes['layout'] && this.layout) {
            this.layoutChanged = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    initDragulaForLightbox() {
        this.lightboxDataService.attachDragAndDropForLightbox(this.dragulaId);
        const assetGroup = this.dragulaService.find(this.dragulaId);
        if (!assetGroup) {
            this.dragulaService.createGroup(this.dragulaId, {
                direction: 'horizontal',
                accepts: (element, target, source, sibling) => {
                    if (target !== source) {
                        const assetId = element['dataset']['assetid'];
                        const lightBox = +target['dataset']['parentLightboxId'];
                        const assets = this.lightboxDataService.getLightbox(lightBox).assets;
                        const entry = (assets) ? assets.find(asset => {
                            return asset.id === assetId;
                        }) : false;
                        return !entry;
                    }
                    return true;
                },
                moves: (el) => {
                    return el.nodeName !== 'ST-ASSET-DETAIL';
                },
            });
        }

        this.dataSubscription = this.dragulaService.drop(this.dragulaId).subscribe((value: any) => {
            if (value['name'] !== this.dragulaId) {
                return;
            }
            let newPos = null;
            const element = value['el'];
            const target = value['target'];
            const source = value['source'];
            const assetId = element.dataset['assetid'];
            const sourceLightboxId = source.dataset['parentLightboxId'];

            if (this.dragulaContainerRef.nativeElement === target ) {
                if (this.dragulaContainerRef.nativeElement === source) {
                    if (!element) {
                        newPos = target.children.length - 1;
                    } else {
                        for (let i = 0; i < target.children.length; i++) {
                            if (
                                target.children[i].dataset['assetid'] ===
                                element.dataset['assetid']
                            ) {
                                newPos = i;
                                break;
                            }
                        }
                    }

                    const oldPos = this.previousAssetPositions[assetId];
                    this.previousAssetPositions = this.getPositionList(this.assets);

                    this.orderChange.emit({
                        assetId: assetId,
                        from: oldPos,
                        to: newPos
                    });
                } else {
                    if (!element) {
                        newPos = target.children.length - 1;
                    } else {
                        for (let i = 0; i < target.children.length; i++) {
                            if (
                                target.children[i].dataset['assetid'] ===
                                element.dataset['assetid']
                            ) {
                                newPos = i;
                                break;
                            }
                        }
                    }

                    this.orderChange.emit({
                        assetId: assetId,
                        oldLightboxId: sourceLightboxId,
                        to: newPos
                    });
                }
            }
        });
    }

    onLoadMoreAssets() {
        this.loadMoreAssets.next();
    }

    ngOnDestroy(): void {
        this.lightboxDataService.detachDragAndDropForLightbox(this.dragulaId)
        if (this.dataSubscription) this.dataSubscription.unsubscribe();
    }

    onToggleSimilaritySearch(asset: Asset) {
        this.toggleSimilaritySearch.emit(asset);
    }

    setThumbSelection( event: any ): void {
        if( event.event.ctrlKey || event.event.metaKey ) {
            this.selectedAssets[ event.id ] = !this.selectedAssets[ event.id ];

            this.lastSelectedAsset = event.id;
        }
        else if( event.event.shiftKey ) {
            this.selectedAssets = {};

            let setSelection = false;
            for( let asset of this.assets ) {
                if( asset.id == event.id || asset.id == this.lastSelectedAsset ) {
                    setSelection = !setSelection;
                    this.selectedAssets[ asset.id ] = true;
                }
                else if(setSelection) {
                    this.selectedAssets[ asset.id ] = true;
                }
            }
        }


        let selected = [];
        for( let asset of this.assets ) {
            if( this.selectedAssets[ asset.id ] ) {
                selected.push(asset.id);
            }
        }
        this.selectionChange.emit(selected);

    }

    onAssetAction($event) {
        this.assetAction.emit($event);
    }

    trackByFn(index, item) {
        if (!item) return null;
        return item.id;
    }

    /**
     * Called when the preview link was clicked
     * @param asset
     */
    onPreviewLinkCLicked(asset: Asset) {
        this.previewLinkClicked.next(asset);
    }
}
