import {Pipe, PipeTransform} from '@angular/core';

/**
 * The pipe transforms the given value into the same value but with the first letter capitalized
 */
@Pipe({
  name: 'imageType'
})
export class ImageTypePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      const splittedValues = value.split('/');
      return splittedValues[splittedValues.length - 1];
    }

    return value;
  }

}
