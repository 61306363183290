import { Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { skip, startWith, takeUntil } from 'rxjs/operators';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';

export const DEFAULT_DATA = {
    license: ''
};

@Component({
    selector: 'st-asset-search-license-filter',
    templateUrl: './asset-search-license-filter.component.html',
    styleUrls: ['./asset-search-license-filter.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AssetSearchLicenseFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    constructor(private formBuilder: FormBuilder) {
        super();
        this.form = this.formBuilder.group(DEFAULT_DATA);
    }

    @Input() set filter(filter) {
        if (filter) {
            this.form.setValue({
                license: filter.license || 'any'
            }, { emitEvent: false });

        }
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    onChange() {
        this.changes.emit({
            license: (this.form.value.license !== 'any') ? this.form.value.license : ''
        });
    }
}
