import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {Observable} from 'rxjs';

@Injectable()
export class ContactFormService {
    private API_PATH = 'forms';


    constructor(private http: SodaApiService) {}

    sendContactForm(data: ContactFormData) {
        const url = `${this.API_PATH}/contact`;
        return this.http.post(url, {data: data}).toPromise().catch(this.handleError);

    }

    /**
     * Custom error handler
     * @param error catched error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}

export interface ContactFormData {
    userName: string;
    subject: string;
    phoneCall: boolean;
    phoneCallTime: string;
    phoneNumber: string;
    email: string;
    message: string;
    replyViaEmail: boolean;
}
