import {Injectable} from '@angular/core';
import {OrderService} from './order.service';
import {CartDataService} from './cart-data.service';
import {UserDataService} from './user-data.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {Order} from '../models/order';

/**
 * Service that interacts with orders api using OrderService
 * Stores users orders
 */
@Injectable()
export class OrderDataService {
    private _order: BehaviorSubject<Order> = new BehaviorSubject(null);
    public order: Observable<Order> = this._order
        .asObservable()
        .pipe(distinctUntilChanged(null, x => JSON.stringify(x)));

    constructor(
        private orderService: OrderService,
        private cartDataService: CartDataService,
        private userDataService: UserDataService
    ) {}

    /**
     * Load users orders
     * @returns
     */
    public loadOrder() {
        if (!this.userDataService.isLoaded()) {
            this.setOrder(null);
            return;
        }

        this.orderService
            .getOrders({
                type: 'proposal',
                paymentStatus: 'requested'
            })
            .subscribe(orders => {
                if (!Array.isArray(orders) || !orders.length) {
                    this.setOrder(null);
                    return;
                }

                this.setOrder(orders.shift());
            });
    }

    /**
     * Gets _order BehaviourSubject value
     * @returns
     */
    getOrder(): Order {
        return this._order.getValue();
    }

    /**
     * Sets user order
     * Emits order
     * @param order
     */
    private setOrder(order: Order) {
        this._order.next(order);
    }

    /**
     * Moves given order to cart
     * @param orderId
     * @returns
     */
    changeOrderToCart(orderId?: number) {
        if (!orderId) {
            const order = this.getOrder();
            if (!order || !order.id) {
                return;
            }

            orderId = order.id;
        }

        return this.orderService.changeOrderToCart(orderId).then(data => {
            this.loadOrder();
            this.cartDataService.loadCart();
        });
    }

    /**
     * Moves orders article to cart
     * @param orderId order id
     * @param articleId article id
     * @param cartId cart id
     * @returns
     */
    moveOrderArticleToCart(orderId: number, articleId: number, cartId: number) {
        return this.orderService
            .moveToCart(orderId, articleId, cartId)
            .then(response => {
                if (response) {
                    this.loadOrder();
                    this.cartDataService.loadCart();
                }

                return response;
            });
    }
}
