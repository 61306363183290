import { Component, OnInit, Input } from '@angular/core';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';

@Component({
    selector: 'st-layout-switch',
    templateUrl: './layout-switch.component.html',
    styleUrls: ['./layout-switch.component.scss']
})
export class LayoutSwitchComponent extends AssetFilterPartialComponent
    implements OnInit {
    @Input() layout = 'masonry-grid';

    constructor() {
        super();
    }

    ngOnInit() {}

    setLayout(layout: string) {
        this.layout = layout;
        this.changes.emit(this.layout);
    }
}
