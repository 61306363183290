import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Collection } from '../../models/collection';
import { CollectionDataService } from '../../services/collection-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'st-asset-collections-selector',
    templateUrl: './asset-collections-selector.component.html',
    styleUrls: ['./asset-collections-selector.component.scss']
})
export class AssetCollectionsSelectorComponent implements OnInit, OnDestroy {
    form: FormGroup;
    collections: Partial<Collection>[];
    groupedCollectionList: { [key: string]: Partial<Collection>[] };
    @Output() changes = new EventEmitter();
    subs = new Subscription();

    constructor(
        private fb: FormBuilder,
        private collectionService: CollectionDataService
    ) {
    }

    async ngOnInit() {
        await this.initForm();
        this.subs.add(this.collectionService.selectedCollections$.subscribe(collIds => {
            this.updateFormValue();
        }));
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

    async initForm() {
        this.collections = await this.collectionService.getCopyrightCollections();
        this.groupedCollectionList = this.collections
             .sort((c1, c2) => c1.name.localeCompare(c2.name))
             .reduce((acc, curr) => {
                 const char = curr.name.charAt(0).toLocaleUpperCase();
                 acc[char] = [...(acc[char] || []), curr];

                 return acc;
             }, {} as { [key: string]: Partial<Collection>[] });
        this.form = this.fb.group(this.getControls());
    }

    async updateFormValue() {
        this.collections = await this.collectionService.getCopyrightCollections();
        this.form.patchValue(this.getControls());
    }

    getControls() {
        return this.collections.reduce(
            (controls, collection) => ({
                ...controls,
                [collection.id]: collection.isSelected
            }),
            {}
        );
    }

    onSubmit() {
        const collections = Object.keys(this.form.value)
            .filter(key => !!this.form.value[key]);
        this.changes.emit(collections);
    }

    selectAll() {
        const controls = Object.keys(this.form.getRawValue());

        this.form.setValue(controls.reduce((list, item) => {
            list[item] = true;
            return list;
        }, {}));
    }
}
