import {Injectable} from '@angular/core';

import {Supplier} from '../models/supplier';
import {SodaApiService} from './soda-api.service';

/**
 * Service that interacts with supplier api
 */
@Injectable()
export class SupplierService {
    private API_PATH = 'suppliers';

    constructor(private http: SodaApiService) {}

    /**
     * Get suppliers
     * @returns
     */
    public getSuppliers(): Promise<Supplier[]> {
        return this.http
            .get<Supplier[]>(this.API_PATH)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
