import {Injectable, InjectionToken, Injector} from '@angular/core';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {Overlay, OverlayConfig} from '@angular/cdk/overlay';
import {AssetPreviewOverlayComponent} from '../components/asset-preview-overlay/asset-preview-overlay.component';
import {AssetPreviewOverlayRef} from '../helpers/asset-preview-overlay-ref';

@Injectable()
export class AssetPreviewService {

    dialogRef: any;

    // Inject overlay service
    constructor(private overlay: Overlay, private injector: Injector) { }


    open(assetId: string, assetName: string, assetCaption: string) {
        const overlayRef = this.createOverlay({
            hasBackdrop: true,
            backdropClass: 'dark-backdrop',
            panelClass: 'tm-file-preview-dialog-panel'
        });

        // Instantiate remote control
        const dialogRef = new AssetPreviewOverlayRef(overlayRef);

        // Create ComponentPortal that can be attached to a PortalHost
        const filePreviewPortal = new ComponentPortal(AssetPreviewOverlayComponent, null, this.createInjector({
            assetId,
            assetName,
            assetCaption
        }));

        // Attach ComponentPortal to PortalHost
        overlayRef.attach(filePreviewPortal);

        overlayRef.backdropClick().subscribe(_ => {
            dialogRef.close();
            this.dialogRef = null;
        });

        this.dialogRef = dialogRef;
    }

    close() {
        if (this.dialogRef) {
            this.dialogRef.close();
            setTimeout(() => {
                this.dialogRef = null;
            }, 500);
        }
    }

    createInjector(dataToPass): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(CONTAINER_DATA, dataToPass);
        return new PortalInjector(this.injector, injectorTokens);
    }

    private createOverlay(config: FilePreviewDialogConfig) {
        const overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    }

    private getOverlayConfig(config: any): OverlayConfig {
        const positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();

        return new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });
    }

    isPreviewDisplayed(): boolean {
        return !!this.dialogRef;
    }
}

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

export interface AssetPreviewData {
    assetId: string;
    assetName: string;
    assetCaption: string;
}

export interface FilePreviewDialogConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
}
