import {
    AfterViewInit,
    Component,
    EventEmitter, Input,
    OnInit,
    Output,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import {AuthenticationService} from '../../../services/authentication.service';

/**
 * Component that displays the login form
 */
@Component({
    selector: 'st-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, AfterViewInit {
    @Output()
    loginSuccessful: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    register: EventEmitter<void> = new EventEmitter<void>();

    @Input() disableEmailValidation: boolean;

    @ViewChildren('username')
    username;

    public form: FormGroup;

    public loginSuccessMessage = '';

    public formErrors = [];

    private validationMessages = {
        username: {
            email: 'The email is invalid',
            required: 'Please enter a email'
        },
        password: {
            required: 'Please enter a password'
        }
    };

    constructor(
        private user: UserService,
        private router: Router,
        private fb: FormBuilder,
        private authenticationService:AuthenticationService,
        private route: ActivatedRoute
    ) {
        //this.createForm();
    }

    ngAfterViewInit(): void {
        this.username.first.nativeElement.focus();
    }

    ngOnInit() {this.createForm();

        const params = this.route.snapshot.queryParams;
        if (params['lid'] && params['mds'] && params['uid']) {
            this.doDirectLogin(params['lid'], params['mds'], params['uid']);
        }
    }

    createForm() {
        this.form = this.fb.group({
            password: ['', [Validators.required]],
            username: (this.disableEmailValidation) ? ['', [Validators.required]] : ['', [Validators.required, Validators.email]]
        });
    }

    /**
     * Logins the user in the api with gven form credentials
     */
    doLogin(): void {
        this.setFormErrors(false);
        if (!this.form.valid) {
            return;
        }

        this.loginSuccessMessage = '';

        const formData = this.form.getRawValue();
        this.user
            .login(formData.username, formData.password)
            .then(
                () => {
                    this.loginSuccessMessage = 'Sign In Successful';
                    this.authenticationService.setUserLoginStatus(true);
                    this.loginSuccessful.next();
                },
                error => {
                    this.formErrors.push(error.message);
                }
            )
            .catch(error => {
                // Do nothing
            });
    }

    /**
     * A variation of login. Uses user service under the hood
     * @param lid
     * @param mds
     * @param uid
     */
    doDirectLogin(lid: number, mds: string, uid: number): void {
        this.loginSuccessMessage = '';

        this.user
            .directLogin(lid, mds, uid)
            .then(
                () => {
                    this.loginSuccessMessage = 'Sign In Successful';
                    this.authenticationService.setUserLoginStatus(true);
                    this.loginSuccessful.next();
                },
                error => {
                    this.formErrors.push(error.message);
                }
            )
            .catch(error => {
                // Do nothing
            });
    }

    /**
     * Usefull for displaying form errors
     */
    setFormErrors(ignorePristine): void {
        if (!this.form) {
            return;
        }
        const form = this.form;
        this.formErrors = [];

        for (const field in this.validationMessages) {
            const control = form.get(field);
            if (
                control &&
                (!ignorePristine || control.dirty) &&
                !control.valid
            ) {
                for (const key in control.errors) {
                    let message = 'Some error happened';
                    if (
                        this.validationMessages[field] &&
                        this.validationMessages[field][key]
                    ) {
                        message = this.validationMessages[field][key];
                    } else if (key.substr(0, 10) === 'serverside') {
                        message = control.errors[key];
                    }

                    this.formErrors.push(message);
                }
            }
        }
    }

    /**
     * Resends verification email to given email
     */
    resendVerificationEmail() {
        const email = this.form.getRawValue().username;
        if (!email) {
            return;
        }

        this.user.resendVerificationEmail(email).then(
            success => {
                if (success) {
                    this.formErrors = [];
                    this.loginSuccessMessage =
                        'Successfully sent verification email';
                } else {
                    this.loginSuccessMessage = '';
                    this.formErrors = ['Could not send verification email'];
                }
            },
            () => {
                this.loginSuccessMessage = '';
                this.formErrors = ['Could not send verification email'];
            }
        );
    }

    /**
     * Fires register event
     */
    doRegister() {
        this.register.next();
    }
}
