import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input,
    LOCALE_ID,
    Inject
} from '@angular/core';
import {
    FormBuilder,
    FormGroup
} from '@angular/forms';
import { Subject } from 'rxjs';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { DateAdapter } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';
import { debounceTime, takeUntil } from 'rxjs/operators';

export const DEFAULT_DATA = {
    uploadMonthsAgo: 'any'
};

export const UPLOAD_TIME_FIELDS = [
    'uploadMonthsAgo'
];

@Component({
    selector: 'st-asset-search-upload-time-filter',
    templateUrl: './asset-search-upload-time-filter.component.html',
    styleUrls: ['./asset-search-upload-time-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AssetSearchUploadTimeFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    private latestValue = DEFAULT_DATA;

    private datesFilter = {};

    @Input() set filter(filter) {
        if (filter && filter.datesFilter) {
            this.datesFilter = { ...filter.datesFilter };

            const dates = Object.keys(this.datesFilter)
                                .filter(key => UPLOAD_TIME_FIELDS.indexOf(key) !== -1)
                                .reduce((acc, curr) => {
                                    if (!!this.datesFilter[curr]) {
                                        acc[curr] = this.datesFilter[curr];
                                    }
                                    return acc;
                                }, {});
            const newValue = {
                ...DEFAULT_DATA,
                ...dates
            };

            this.latestValue = newValue;
            this.form.setValue(newValue, { emitEvent: false });
        }
    }

    @Input() displayOlder: boolean = false;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<Date>
    ) {
        super();
        this.form = this.formBuilder.group(DEFAULT_DATA);
    }

    ngOnInit() {
        this.translateService.onLangChange.pipe(takeUntil(this.componentDestroyed$)).subscribe(({ lang }) => {
            this.dateAdapter.setLocale(lang);
        });
        this.form.valueChanges.pipe(debounceTime(200), takeUntil(this.componentDestroyed$)).subscribe(value => {
            const oldValue = { ...this.latestValue };
            this.latestValue = { ...value };
            let newValue = {
                ...this.latestValue
            };

            if (!oldValue.uploadMonthsAgo && value.uploadMonthsAgo) {
                newValue = {
                    ...newValue,
                    uploadMonthsAgo: value.uploadMonthsAgo
                };
            }

            this.latestValue = { ...newValue };
            if (!_.isEqual(oldValue, newValue)) {
                this.onChange(newValue);
            }
        });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    onChange(value) {
        this.changes.emit({
            datesFilter: {
                ...Object.keys({...this.datesFilter, ...value}).reduce((obj, key) => {
                    if (!!value[key]) {
                        return (value[key] !== 'any')
                            ? {
                                ...obj,
                                [key]: value[key]
                            }
                            : obj;
                    }
                    return {
                        ...obj,
                        [key]: this.datesFilter[key]
                    };
                }, {}),
            }
        });
    }
}
