import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import {CmsBlockContent} from '../cms-block/cms-block-content';
import { SafeStyle } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'lib-cms-rich-text',
    templateUrl: './cms-rich-text.component.html',
    styleUrls: ['./cms-rich-text.component.css']
})
export class CmsRichTextComponent extends CmsBlockContent implements OnInit {

    classes: string;

    fontFamily: SafeStyle | any;

    fontUrlLinkRef: HTMLLinkElement;

    constructor(el: ElementRef, @Inject(DOCUMENT) private document: any) {
        super(el);
    }

    ngOnInit() {
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }

        if ( this.data.fontFamily ) {
            this.fontFamily = this.data.fontFamily;
        }

        if (this.data.fontUrl
            && Object.keys(this.data.fontUrl).length > 0
            && !!this.data.fontUrl.url
            && this.document.querySelectorAll('link[href^="' + this.data.fontUrl.url + '"]').length === 0) {
            this.fontUrlLinkRef = this.document.createElement('link');
            this.fontUrlLinkRef.href = this.data.fontUrl.url;
            this.fontUrlLinkRef.rel = 'stylesheet';
            this.document.head.appendChild(this.fontUrlLinkRef);
        }
    }
}
