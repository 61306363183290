import { Component, Inject, Injector, Input, OnChanges, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'st-cookie-bar',
    templateUrl: './cookie-bar.component.html',
    styleUrls: ['./cookie-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CookieBarComponent implements OnChanges {
    @Input() cookieBarConfig: cookieBarConfig;
    ccService: NgcCookieConsentService;

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private readonly injector: Injector
    ) {}

    ngOnChanges() {
        this.changeCookiesBarContent();
    }

    changeCookiesBarContent() {
        if(!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.ccService = this.injector.get(NgcCookieConsentService);

        const hasConsented = this.ccService.hasConsented();

        if (!hasConsented && this.cookieBarConfig) {
            this.ccService.getConfig().content = this.ccService.getConfig().content || {};
            this.ccService.getConfig().content.message = '<h3>' + this.cookieBarConfig.title + '</h3>' + this.cookieBarConfig.content;
            this.ccService.getConfig().content.dismiss = this.cookieBarConfig.action;
            this.ccService.getConfig().content.allow = this.cookieBarConfig.action;
            this.ccService.getConfig().content.deny = this.cookieBarConfig.decline;
            this.ccService.destroy();
            this.ccService.init(this.ccService.getConfig());
        }
    }
}

export type cookieBarConfig = {
    title: string,
    content: string,
    action: string,
    decline: string
};

