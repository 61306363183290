import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {UserLoginService} from './user-login.service';
import {HttpClient, HttpHandler, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {retryWhen, switchMap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import { SDK_OPTIONS, SdkOptions } from '../models/sdk-options';
import { TranslateService } from '@ngx-translate/core';
import { SSR_REQUEST_CONTEXT } from '../models/ssr_request_context';

/**
 * Service that extends Angular HttpClient
 * Handles api requests
 */
@Injectable()
export class SodaApiService extends HttpClient {

    constructor(
        _handler: HttpHandler,
        private userLogin: UserLoginService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
        @Optional() @Inject(SSR_REQUEST_CONTEXT) private ssrRequestContext?: any
    ) {
        super(_handler);
    }

    /**
     * Perform a request
     * @param request
     * @param url
     * @param options
     * @returns
     */
    request(
        request: string | HttpRequest<any>,
        url?: string,
        options?: any
    ): Observable<any> {
        // Set URL
        const headers = this.getDefaultHeaders(request, options);

        if (typeof request === 'string') {
            url = this.getRequestUrl(url);
            options = {
                ...options,
                headers
            };
        } else {
            url = this.getRequestUrl(request.url);
            request = request.clone({
                url: this.getRequestUrl(request.url),
                headers
            });
        }

        const response: Observable<any> =
            typeof request === 'string'
                ? super.request(request, url, options)
                : super.request(request);
        return response.pipe(
            retryWhen(errors =>
                errors.pipe(
                    switchMap(sourceErr => {
                        if (
                            url !== 'user/login' &&
                            (sourceErr.status === 403 ||
                                sourceErr.status === 401)
                        ) {
                            this.authenticationService.setUserLoginStatus(
                                false
                            );
                        }
                        return throwError(sourceErr);
                    })
                )
            )
        );
    }

    /**
     * Download a file
     * @param apiUrl
     */
    public download(apiUrl) {
        window.open(this.getRequestUrl(apiUrl), '_blank');
    }

    /**
     *
     * Returns default Request Headers
     * @param  request
     * @param options
     * @returns
     */
    private getDefaultHeaders(
        request: string | HttpRequest<any>,
        options?: any
    ): HttpHeaders {
        let headers: HttpHeaders;
        let token: string;
        if (typeof request === 'string') {
            if (!options.headers) {
                options = {
                    ...options,
                    headers: new HttpHeaders()
                };
            }
            headers = options.headers;
        } else {
            headers = request.headers;
        }

        if ( this.ssrRequestContext) {
            if ( this.ssrRequestContext.viewerCountry ) {
                headers = headers.set('CloudFront-Viewer-Country', this.ssrRequestContext.viewerCountry);
            } else {
                headers = headers.delete('CloudFront-Viewer-Country');
            }

            token = this.ssrRequestContext.usertoken || this.userLogin.getToken();
        } else {
            token = this.userLogin.getToken();
        }

        if (token && this.userLogin.isLoggedIn()) {
            headers = headers.set('Authorization', 'Bearer ' + token);
        } else if (headers.has('Authorization')) {
            headers = headers.delete('Authorization');
        }

        if (!headers.has('Accept')) {
            headers = headers.set('Accept', 'application/json');
        }

        if (!headers.has('SodaType') && this.sdkOptions.useDevApi) {
            headers = headers.set('SodaType', 'dev');
        }

        if (!headers.has('Accept-Language')) {
            headers = headers.set(
                'Accept-Language',
                this.sdkOptions.availableLanguages.indexOf(this.translateService.currentLang) !== -1
                    ? this.translateService.currentLang
                    : this.sdkOptions.primaryLanguage
            );
        }

        return headers;
    }

    /**
     * Returns request url
     * @param path
     * @returns
     */
    private getRequestUrl(path: string): string {
        if (path.substr(0, 1) === '/') {
            path = path.substr(1);
        }

        return this.sdkOptions.apiPath + path;
    }
}
