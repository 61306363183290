import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LightboxDataService} from '../../services/lightbox-data.service';
import {LightboxService} from '../../services/lightbox.service';
import {PreferencesService} from '../../services/preferences.service';
import {UserLoginService} from '../../services/user-login.service';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {DragAndDropService} from '../../services/drag-and-drop.service';
import {base64ImageToBlob} from '../../helpers/base64.helper';
import {ImageSimilarityService} from '../../services/image-similarity.service';
import {EventTrackerEvents, EventTrackerService} from '../../services/event-tracker.service';

@Component({
    selector: 'st-image-similarity-overlay',
    templateUrl: './image-similarity-overlay.component.html',
    styleUrls: ['./image-similarity-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageSimilarityOverlayComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() showAssetList = false;

    @Output() showAssetDetails: EventEmitter<string> = new EventEmitter<string>();

    @Output() drag: EventEmitter<void> = new EventEmitter();

    @Output() drop: EventEmitter<void> = new EventEmitter();

    @Output() cancel: EventEmitter<void> = new EventEmitter();

    @Output() uploadedFile: EventEmitter<Blob> = new EventEmitter<Blob>();

    @Output() similarityUrl: EventEmitter<string> = new EventEmitter<string>();

    subs = new Subscription();

    @ViewChild('dragulaContainer') dragulaContainerRef: ElementRef;

    dataSubscription: Subscription;

    hasFileOver: boolean;

    constructor(
        private lightboxDataService: LightboxDataService,
        private lightboxService: LightboxService,
        private preferenceService: PreferencesService,
        private fb: FormBuilder,
        public userLogin: UserLoginService,
        private dragAndDropService: DragAndDropService,
        private imageSimilarityService: ImageSimilarityService,
        private translate: TranslateService,
        private eventTrackerService: EventTrackerService
    ) {
        this.dragAndDropService.droppedInImageSimilarityOverlay().pipe(filter(Boolean)).subscribe((value) => {
            const url = value.el['dataset']['assetUrl'];
            this.similarityUrl.next(url);
        });
    }

    async onFilesSelectedForUpload(files: any) {
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.AI_PLUS_ASSET_SEARCH);
        this.handleNewFile(files);
    }

    async handleNewFile(files: any) {
        const file: File = files[0];
        const fileSizeMB = file.size / 1000 / 1000;
        const fileName = file.name ? file.name.toLowerCase() : file.type ? file.type : '';
        const rightFileType = fileName.endsWith('.jpg')
            || fileName.endsWith('.jpeg')
            || fileName.endsWith('.png')
            || fileName.endsWith('image/jpeg')
            || fileName.endsWith('image/jpg')
            || fileName.endsWith('image/png');

        if (rightFileType && fileSizeMB <= 6) {
            this.uploadedFile.next(files[0]);
        } else {
            this.uploadedFile.next(null);
            const translation = await this.translate.get('sodatechSdk.aiPlus.invalidFile').pipe(take(1)).toPromise();
            alert(translation);
        }
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();
        if (this.subs) this.subs.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dragAndDropService.setImageSimilarityOverlayContainerRef(this.dragulaContainerRef);
    }

    @HostListener('dragover', ['$event'])
    onDragOver(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.hasFileOver = true;
    }

    @HostListener('drop', ['$event'])
    onDrop(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();

        const html = e.dataTransfer.getData('text/html');
        // console.log("GET", e.dataTransfer.getData('text'));
        // const html = JSON.parse(e.dataTransfer.getData('text')).html;
        const doc: Document = new DOMParser().parseFromString(html, 'text/html');

        if (containsFiles(e)) {
            this.eventTrackerService.pushNewEvent(EventTrackerEvents.AI_PLUS_ASSET_SEARCH);
            this.handleNewFile(e.dataTransfer.files);
        } else if (doc.getElementsByTagName('img').item(0) && doc.getElementsByTagName('img').item(0).src) {
            const str = doc.getElementsByTagName('img').item(0).src;
            try {
                this.handleNewFile([base64ImageToBlob(str)]);
                this.eventTrackerService.pushNewEvent(EventTrackerEvents.AI_PLUS_ASSET_SEARCH);
            } catch (e) {
                if (str) {
                    this.eventTrackerService.pushNewEvent(EventTrackerEvents.AI_PLUS_ASSET_URL_SEARCH);
                    this.similarityUrl.next(str);
                }
            }
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (containsFiles(e)) {
            this.hasFileOver = false;
        }
    }

}

export function containsFiles(e: DragEvent) {
    if (e.dataTransfer.types) {
        for (let i = 0; i < e.dataTransfer.types.length; i++) {
            if (e.dataTransfer.types[i] === 'Files') {
                /**
                 * This is an extra check for EDGE and IE, since they add
                 * some unexpected file to the dataTransfer
                 */
                const file: File = e.dataTransfer.files[0];
                if ( file && file.name.toLowerCase().endsWith('.url') && file.type === '' ) {
                    return false;
                }

                return true;
            }
        }
    }
    return false;
}
