import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';

/**
 * Component for resetting the password
 */
@Component({
  selector: 'st-reset-password-component',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    @Input() token: string = null;
    @Input() email: string = null;

    @Output() resetSuccessful: EventEmitter<void> = new EventEmitter();

    public form: FormGroup = null;
    public formErrors: any = {};
    public success = false;

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private fb: FormBuilder
    ) {
        this.form = fb.group({
            password: new FormControl(''),
            passwordConfirm: new FormControl('')
        });
    }

    ngOnInit() {

    }

    /**
     * Called when the user clicked the reset button
     */
    public resetPassword() {
        const password = this.form.getRawValue().password;
        const passwordConfirm = this.form.getRawValue().passwordConfirm;

        if (!this.arePasswordsValid(password, passwordConfirm)) {
            return;
        }

        this.userService.resetPasswordWithToken(this.email, this.token, password).then(
            (data) => {
                this.resetSuccessful.next();
                this.success = true;
            },
            (data) => {
                data = data.error;
                if (data.violations) {
                    this.formErrors = data.violations;
                }
            }
        );
    }

    /**
     * Check if both passwords are valid
     * @param password
     * @param passwordConfirm
     */
    private arePasswordsValid(password: string, passwordConfirm: string) {
        this.formErrors = {};

        if (!password) {
            this.formErrors['password'] = ['sodatechSdk.formErrors.enterPassword'];
            return false;
        }

        if (password !== passwordConfirm) {
            this.formErrors['passwordConfirm'] = ['sodatechSdk.formErrors.passwordsNoMatch'];
            return false;
        }

        return true;
    }

}
