import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'st-background-color-switcher',
    templateUrl: './background-color-switcher.component.html',
    styleUrls: ['./background-color-switcher.component.css']
})
export class BackgroundColorSwitcherComponent {

    @Input() currentColor: string;

    @Input() disableGrey = false;

    @Output() colorChange = new EventEmitter<string>();

    emitColorChangeEvent(colorName: string) {
        this.colorChange.emit(colorName);
    }

}
