import {
    Component,
    OnDestroy,
    ViewEncapsulation,
    Input,
    OnInit, Inject
} from '@angular/core';
import {
    FormBuilder,
    FormGroup
} from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { startWith, takeUntil, skip, take, map } from 'rxjs/operators';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { AssetService } from '../../services/asset.service';
import { CategoriesService } from '../../services/categories.service';
import * as _ from 'lodash';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';

@Component({
    selector: 'st-asset-search-categories-filter',
    templateUrl: './asset-search-categories-filter.component.html',
    styleUrls: ['./asset-search-categories-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AssetSearchCategoriesFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    categories$: Observable<Array<{ [key: string]: string }>>;

    defaultFormData: { [key: string]: boolean } = {};

    currentCategories: string[] = [];

    isOccurrencesEnabled$: Observable<boolean>;

    @Input() set filter(filter) {
        if (filter && filter.category) {
            this.currentCategories = filter.category;
            this.categories$.pipe(
                take(1)
            ).subscribe(categories => this.updateFormValue(categories));

        } else {
            this.form.setValue(
                { ...this.defaultFormData },
                { emitEvent: false }
            );
        }
    }

    constructor(private formBuilder: FormBuilder,
                private assetService: AssetService,
                private categoriesService: CategoriesService,
                @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {
        super();
        this.categories$ = combineLatest([
            this.assetService.getLoadedCategoriesTotal(),
            this.categoriesService.getLoadedCategories()
        ]).pipe(
            map(([assetCategories, allCategories]: [any, any]) => {
                return assetCategories.map(category => {
                    const categoryData = _.find(allCategories,
                        (_category) => Number(_category.id) === Number(category.id)
                    );
                    category['name'] = categoryData ? categoryData.name : category.id;
                    return category;
                });
            })
        );
        this.isOccurrencesEnabled$ = of(this.sdkOptions.assetSearchFilter && this.sdkOptions.assetSearchFilter.isOccurrencesEnabled);
        this.categories$.subscribe(categories => {
            this.defaultFormData = categories.reduce((obj, category) => ({
                ...obj,
                [category.id]: false
            }), {});
            this.setupForm();
            this.updateFormValue(categories);
        });
    }

    ngOnInit(): void {
        this.setupForm();
    }

    setupForm() {
        this.form = this.formBuilder.group(this.defaultFormData);
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    updateFormValue(categories) {
        const formValue = this.currentCategories
                              .filter(category => categories.findIndex(_category => _category.id === category) !== -1)
                              .map(category => ({
                                  [category]: true
                              }))
                              .reduce((value, curr) => ({
                                  ...curr,
                                  ...value
                              }), {});
        this.form.setValue(
            { ...this.defaultFormData, ...formValue },
            { emitEvent: false }
        );
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    onChange() {
        const selectedFormValues = Object.keys(this.form.value).reduce((_category, key) => {
            if (!!this.form.value[key]) {
                _category = [..._category, key];
            }
            return _category;
        }, []);
        this.categories$.pipe(
            take(1)
        ).subscribe(categories => {
            this.changes.emit({
                category: _.union(this.currentCategories, categories.map(category => category.id))
                           .filter(
                               key => categories.findIndex(_category => _category.id === key) === -1 || selectedFormValues.indexOf(
                                   key) !== -1)
            });
        });
    }
}
