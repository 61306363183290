import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'st-asset-search-pagination',
    templateUrl: './asset-search-pagination.component.html',
    styleUrls: ['./asset-search-pagination.component.scss']
})
export class AssetSearchPaginationComponent implements OnInit {

    @Input() pager: any;

    @Output() pageClicked: EventEmitter<number> = new EventEmitter();

    enteredPage: number;

    constructor() {
    }

    ngOnInit() {
    }

    onKeyPressed(event: KeyboardEvent) {
        if (event.keyCode === 13 && this.enteredPage) {
            this.pageClicked.next(this.enteredPage);
        }
    }

    onNextPageClicked() {
        if (this.enteredPage) {
            this.pageClicked.next(this.enteredPage);
        }
    }
}

export function createPager(totalItems: number, currentPage: number, pageSize: number) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;

    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 1 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {

            if((totalPages - (currentPage - 2)) == 5) {
                startPage = currentPage - 1;
                endPage = currentPage+3;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage+2;
            }
        }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}
