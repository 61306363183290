import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { CartDataService } from '../../services/cart-data.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserDataService } from '../../services/user-data.service';

/**
 * Component that displays an indicator with the number of items in the basket
 */
@Component({
    selector: 'st-cart-indicator',
    templateUrl: './cart-indicator.component.html',
    styleUrls: ['./cart-indicator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CartIndicatorComponent {

    basketCount = 0;
    @Output() cartLinkClicked = new EventEmitter();

    @HostBinding('class.basket-indicator-root') hasRootClass = true;

    @Input() iconOnly = false;

    constructor(private cartDataService: CartDataService, public user: UserDataService) {
        this.cartDataService.cart.pipe(
            map(cart => {
                let count = 0;

                if (cart && cart.articles && Array.isArray(cart.articles)) {
                    count = cart.articles.length;
                }

                return count;
            }),
            distinctUntilChanged()
            )
            .subscribe(basketCount => {
                this.basketCount = basketCount;
            });
    }

    onCartLinkClicked(e: Event) {
        e.preventDefault();
        this.cartLinkClicked.emit();
    }
}
