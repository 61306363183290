import {Component, OnInit, Input, Output, EventEmitter, Inject, OnChanges, SimpleChanges} from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

import { Webseries } from '../../models/webseries';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';
import {WebseriesTitleFormatter} from '../../models/webseries-title-formatter';


@Component({
    selector: 'st-webseries-grid',
    templateUrl: './webseries-grid.component.html',
    styleUrls: ['./webseries-grid.component.scss']
})
export class WebseriesGridComponent implements OnInit, OnChanges {
    @Input() webseries: Webseries[];
    @Input() hoverText: string;
    @Input() titleFormatter: WebseriesTitleFormatter;
    @Input() webseriesUrlGenerator: Function;

    @Output() webserieSelect = new EventEmitter<Webseries>();

    activeWebserieId: number;

    seoUrls: {
        [id: number]: string;
    } = {};

    constructor(private sanitizer: DomSanitizer, @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {}

    async ngOnInit() {}

    getWebserieBackgroundUrl(webserie: Webseries): SafeUrl {
        return this.sanitizer.bypassSecurityTrustStyle(
            `url(${this.getAssetBackgroundUrl(webserie.assets[0].id, webserie.id)})`
        );
    }

    onWebseriesGridItemClicked(event: Event, webserie: Webseries) {
        event.preventDefault();
        event.stopPropagation();
        this.activeWebserieId = webserie.id;
        this.webserieSelect.emit(webserie);
    }

    getAssetBackgroundUrl(assetId, webseriesId): string {
        const baseUrl = this.sdkOptions.apiPath;
        return `${baseUrl}webseries/${webseriesId}/assets/${assetId}?size=800`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes['webseries'] || changes['webseriesUrlGenerator']) && this.webseries && this.webseriesUrlGenerator) {
            this.seoUrls = this.webseries.reduce((list, webseries) => {
                list[webseries.id] = this.webseriesUrlGenerator(webseries);
                return list;
            }, {});
        }
    }
}
