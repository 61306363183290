import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    OnDestroy,
    ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray } from '@angular/forms';
import { Collection } from '../../models/collection';
import { PreferencesService } from '../../services/preferences.service';
import { CollectionDataService } from '../../services/collection-data.service';
import {
    debounceTime,
    distinctUntilChanged,
    takeWhile,
    skip
} from 'rxjs/operators';

export const DUMMY_SODATECH_DEMO_COLLECTION: Collection = {
    id: 'p',
    name: 'Sodatech Demo',
    license: 'fr',
    assetType: 'image',
    usageType: 'advertising',
    exclusive: '',
    copyright: 'Sodatech Demo'
};

/**
 * Component that displays the filter for the asset search
 */
@Component({
    selector: 'st-asset-search-filter',
    templateUrl: './asset-search-filter.component.html',
    styleUrls: ['./asset-search-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AssetSearchFilterComponent implements OnInit, OnDestroy {
    private _filters: any;
    private alive = true;
    form: FormGroup;
    showSearchFilters = false;
    collections: Collection[] = [];

    @Input()
    set filters(filters: { [key: string]: any }) {
        this._filters = filters;
        const { coll, ...rest } = filters;

        this.form.patchValue({ ...rest });
    }

    @Output()
    filterChanged: EventEmitter<any> = new EventEmitter<any>();

    currentFilter: any;

    constructor(
        private fb: FormBuilder,
        private collectionDataService: CollectionDataService,
        private preferencesService: PreferencesService
    ) {
        this.createForm();
        this.collectionDataService.getCollections().then(collections => {
            this.collections = [DUMMY_SODATECH_DEMO_COLLECTION, ...collections];
            this.form.setControl(
                'coll',
                this.fb.array(
                    this.collections.map(collection =>
                        this.fb.control(
                            this._filters.coll.includes(collection.id)
                        )
                    )
                )
            );
        });

        this.preferencesService
            .observe('showSearchFilters')
            .subscribe(status => {
                this.showSearchFilters = status;
            });
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.alive = false;
    }

    get formCollections() {
        return this.form.get('coll') as FormArray;
    }

    createForm() {
        const group = {
            coll: this.fb.array([]),
            color: '',
            size: '',
            sizeCustom: '',
            format: '',
            q: '',
            datesFilter: null,
            farbe: '',
            farbeName: ''
        };

        this.form = this.fb.group(group);

        this.form.valueChanges
            .pipe(
                takeWhile(() => this.alive),
                skip(1),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
                debounceTime(600)
            )
            .subscribe(() => {
                const collections = this.form.value.coll
                    .map(
                        (value, index) =>
                            !!value ? this.collections[index].id : null
                    )
                    .filter(Boolean);

                const { coll, ...rest } = this.form.value;

                const filter = Object.keys(rest)
                    .reduce((acc, curr) => {
                        if (!!rest[curr]) {
                            acc[curr] = rest[curr];
                        }
                        return acc;
                    }, {});

                this.filterChanged.emit({
                    ...filter,
                    ...(collections.length > 0 ? { coll: collections } : null)
                });
            });
    }
}

export function hasFilterValue(data: any, name: string, value) {
    if (!data[name]) {
        return false;
    }

    if (!data[name].length) {
        return data[name] === value;
    }

    for (const val of data[name]) {
        if (val === value) {
            return true;
        }
    }

    return false;
}
