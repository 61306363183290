import {merge as _merge} from 'lodash';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


export const SDK_DEFAULT_TRANSLATIONS = {
    de: {
        sodatechSdk: {
            userIndicator: {
                signIn: 'LogIn / Registrieren'
            },
            assetSearch: {
                emptyState: 'Keine Bilder gefunden',
                searchingAdditionalAssets: 'Weitere Bilder werden gesucht',
                loadMore: 'Mehr laden',
                assetsLoadedCounter: '<strong>{{assets}}</strong> von <strong>{{total}}</strong> Bildern geladen'
            },
            assetDetail: {
                loading: 'lade...',
                addToCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
                downloadLowRes: 'Download LowRes-Datei',
                downloadHighRes: 'Download HighRes-Datei',
                addToGallery: 'In den Leuchttisch',
                deleteFromGallery: 'Aus dem Leuchttisch entfernen',
                share: 'Teilen...',
                copyLink: 'Link kopieren',
                shareOnFacebook: 'Auf Facebook teilen',
                shareOnTwitter: 'Auf Twitter teilen',
                shareOnPinterest: 'Auf Pinterest teilen',
                question: 'Hilfe',
                support: 'Brauchen Sie Unterstützung bei der Suche nach dem richtigen Bild?',
                rfVersusRm: 'RF oder RM',
                photographer: 'Fotograf',
                moreFromPhotographer: 'Alle Bilder des Fotografen',
                moreFromCollection: 'Alle Bilder der Kollektion',
                description: 'Beschreibung',
                size: 'Dateigröße',
                largestAvailableFile: 'Größte verfügbare Datei',
                availableUpon1: 'Auf',
                availableUpon2: 'Anfrage',
                availableUpon3: 'gegen eine zusätzliche Gebühr verfügbar.',
                releases: 'Releases',
                modelReleaseAvailable: 'Model Release vorhanden.',
                propertyReleaseAvailable: 'Property Release vorhanden.',
                keywords: 'Stichwörter',
                showMoreKeywords: 'Weitere Stichwörter & Ähnlichkeitssuche',
                showFewerKeywords: 'Weniger Keywords anzeigen',
                similarPictures: 'Weitere Bilder dieser Serie',
                similarPicturesFromAI: 'Weitere Bilder via Ähnlichkeitssuche',
                highResDownload: {
                    title: 'Verwendungsart und -größe',
                    placeholder: 'Verwendungsart und -größe',
                    action: 'Herunterladen',
                    hint: 'Bitte geben Sie die Verwendungsgröße in DIN für das Bild an, z.B. DIN A 5.',
                    subscriptionsDropDown: 'Art der Subscription'
                },
                imageName: 'Bildnummer',
                license: 'Lizenz',
                licenseType: 'Lizenztyp',
                dateCreated: 'Aufnahmedatum',
                credit: 'Credit',
                creditName: 'Sodaware',
                alternateName: 'Referenzenummer',
                modelReleased: 'Model Release',
                propertyReleased: 'Property Release',
                fileSize: 'Dateigröße',
                optionalFileSize: 'Optionale Dateigröße',
                resolution: 'Auflösung',
                caption: 'Beschreibung',
                modelReleasedTypes: {
                    notAvailable: 'Keine Freigabe erforderlich',
                    exists: 'Vorhanden',
                    existsForPhotographer: 'Vorhanden (für Fotograf)',
                    notPossible: 'Nicht vorhanden',
                    uploaded: 'Hochgeladen',
                    physicallyAvailable: 'Physisch vorhanden',
                    notSet: 'Nicht gesetzt',
                    indifferent: 'egal'
                },
                propertyReleasedTypes: {
                    notAvailable: 'Keine Freigabe erforderlich',
                    exists: 'Vorhanden',
                    existsForPhotographer: 'Vorhanden (für Fotograf)',
                    notPossible: 'Nicht vorhanden',
                    uploaded: 'Hochgeladen',
                    physicallyAvailable: 'Physisch vorhanden',
                    notSet: 'Nicht gesetzt',
                    indifferent: 'egal'
                },
                uncompressed: 'unkomprimiert',
                specialInstructions: 'Hinweis',
                popupBlockerInfo: 'Bitte stellen Sie sicher, dass ihr Pop-up-Blocker deaktiviert ist, um Downloads zuzulassen.',
                hasRestriction: 'Is has restriction'
            },
            assetCalculator: {
                chooseLicense: 'Wählen Sie die Lizenz ,die Sie brauchen',
                chooseSize: 'Wählen Sie die Größe, die Sie brauchen',
                moreOptions: 'Weitere Möglichkeiten',
                customLicense: 'Stellen Sie sich Ihre eigene für Ihre Bedürfnisse zugeschnittene Lizenz zusammen. Bei Fragen ' +
                    'kontaktieren Sie uns. Wir freuen uns auf Ihre Anfrage.',
                commercialOrEditorial: 'Commercial oder Editorial?',
                callUs: 'Für diesen Verwendungszweck nehmen Sie bitte Kontakt mit uns. Gerne erstellen wir für Sie ein Angebot.',
                inTheCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
                toAdjust: 'Übernehmen',
                purchaseImage: 'Rufen Sie uns an für den Kauf dieses Bildes.',
                addedToCart: 'Das Bild wurde in den Warenkorb gelegt.',
                notAddedToCart: 'Das Bild konnte nicht in den Warenkorb gelegt werden. Bitte versuchen Sie es erneut.',
                calculatedPrice: 'Berechnete Nutzungsgebühr'
            },
            assetSearchBreadcrumbs: {
                removeAll: 'Alle entfernen',
                filterName: {
                    q: 'Keyword',
                    coll: 'Kollektion',
                    pgid: 'Fotograf',
                    cop: 'Copyright',
                    // orgid: 'Organisation',
                    license: 'Lizenz',
                    orientation: 'Ausrichtung',
                    format: 'Format',
                    color: 'Farbe',
                    size: 'Größe',
                    peopleCategory: 'Altersgruppe',
                    creationDateFrom: 'Aufnahmedatum (von)',
                    creationDateTo: 'Aufnahmedatum (bis)',
                    creationMonthsAgo: 'Aufnahmedatum (vor)',
                    uploadDateFrom: 'Online seit (von)',
                    uploadDateTo: 'Online seit (bis)',
                    uploadMonthsAgo: 'Online seit (vor)',
                    release: 'Release',
                    exclusive: 'Exklusiv',
                    keywords: 'Keywords',
                    mediaType: 'Medientyp',
                    peopleNumber: 'Anzahl der Personen',
                    aiplus: 'AI\u207A Ähnlichkeitssuche',
                    restrictions: 'Restrictions',
                    category: 'Category'
                },
                filterValue: {
                    license: {
                        rm: 'RM',
                        rf: 'RF'
                    },
                    orientation: {
                        h: 'Querformat',
                        v: 'Hochformat',
                        q: 'Quadratisch',
                        p: 'Panorama',
                        pv: 'Skyscraper'
                    },
                    color: {
                        'ff0000': 'Rot',
                        'f0ff00': 'Gelb',
                        '0fff00': 'Grün',
                        '0046ce': 'Blau',
                        '9900c9': 'Lila',
                        bw: 'Schwarz-Weiß',
                        all: 'Alle Farben'
                    },
                    size: {
                        geA5: '≥A5 (4,3 MP)',
                        geA4: '≥A4 (8,7 MP)',
                        geA3: '≥A3 (17,4 MP)',
                        geA2: '≥A2 (34,8 MP)'
                    },
                    release: {
                        mr: 'Model Release',
                        pr: 'Property Release'
                    },
                    peopleCategory: {
                        baby: 'Baby',
                        children: 'Kinder',
                        youth: 'Jugendliche',
                        adult: 'Erwachsene',
                        senior: 'Senioren',
                        mixedAgeGroups: 'Gemischt'
                    },
                    mediaType: {
                        photography: 'Bilder',
                        illustration: 'Illustrationen'
                    },
                    peopleNumber: {
                        0: 'Keine Personen',
                        1: '1 Person',
                        2: '2 Personen',
                        3: '3 Personen',
                        4: '4 Personen',
                        '5+': '5+ Personen'
                    },
                    datesFilter: {
                        1: '1 Monat',
                        3: '3 Monate',
                        6: '6 Monate',
                        12: '12 Monate',
                        'older': 'Älter'
                    },
                    restrictions: {
                        none: 'No restrictions'
                    }
                }
            },
            assetSearchFilter: {
                collections: 'Kollektionen',
                licenseType: 'Lizenztyp-Filter',
                allLicenses: 'Alle Lizenzen',
                royaltyFree: 'Lizenzfrei',
                rightsManaged: 'Lizenzpflichtig',
                orientation: 'Format-Filter',
                allOrientations: 'Alle Ausrichtungen',
                horizontal: 'Querformat',
                vertical: 'Hochformat',
                square: 'Quadratisch',
                panoramic: 'Panorama',
                skyscraper: 'Skyscraper',
                colorFilter: 'Farbfilter',
                allColors: 'Alle Farben',
                color: 'Farbig',
                monochrome: 'Einfarbig',
                blackAndWhite: 'Schwarz & Weiss',
                keyword: 'Keyword-Filter',
                categories: 'Categories',
                restrictions: 'Restrictions filter'
            },
            assetSearchDateFilter: {
                creationDate: 'Aufnahmedatums-Filter',
                uploadDate: 'Archivierungs-Filter',
                uploadTime: 'Upload-datum-Filter',
                from: 'von',
                to: 'bis',
                placeholder: 'TT.MM.JJJJ',
                uploadedMonthsAgo: {
                    oneMonth: 'Letzter Monat',
                    threeMonth: 'Letzte 3 Monate',
                    sixMonth: 'Letzte 6 Monate',
                    twelveMonth: 'Letzte 12 Monate',
                    older: 'Älter',
                    all: 'Alle'
                },
                creationMonthsAgo: {
                    oneMonth: 'Letzter Monat',
                    threeMonth: 'Letzte 3 Monate',
                    sixMonth: 'Letzte 6 Monate',
                    twelveMonth: 'Letzte 12 Monate',
                }
            },
            assetSearchColorFilter: {
                title: 'Farbfilter',
                color: {
                    'e6261f': 'Rot',
                    'eb7532': 'Orange',
                    'f7d038': 'Gelb',
                    'a3e048': 'Limette',
                    '49da9a': 'Minze',
                    '34bbe6': 'Türkis',
                    '4355db': 'Blau',
                    'd23be7': 'Lila',
                    '000000': 'Schwarz',
                    'ffffff': 'Weiß',
                    bw: 'Schwarzweiß'
                }
            },
            assetSearchSizeFilter: {
                any: 'Alle Größen',
                title: 'Größen-Filter',
                hint: 'Bitte beachten Sie, dass Panorama- oder Skyscraper-Formate von den angegebenen DIN-Größen abweichen können.',
                greaterEqualA5: '≥A5 (4,3 MP)',
                greaterEqualA4: '≥A4 (8,7 MP)',
                greaterEqualA3: '≥A3 (17,4 MP)',
                greaterEqualA2: '≥A2 (34,8 MP)'
            },
            assetSearchReleaseFilter: {
                title: 'Release-Filter',
                modelReleased: 'Model Release',
                propertyReleased: 'Property Release'
            },
            assetSearchMediaTypeFilter: {
                title: 'Medientyp-Filter',
                images: 'Bilder',
                illustrations: 'Illustrationen'
            },
            assetSearchExclusiveFilter: {
                title: 'Exklusiv-Filter'
            },
            assetSearchRestrictionsFilter: {
                all: 'All',
                notRestricted: 'No restrictions',
            },
            assetSearchInput: {
                search: 'Suche'
            },
            assetSearchResultCount: {
                foundMedia: 'Gefundene Medien:'
            },
            assetKeywords: {
                keywords: 'Keywords'
            },
            similaritySearch: {
                toggleSimilarSearch: 'Ähnliche Bilder suchen'
            },
            login: {
                logIn: 'Anmeldung',
                resendVerificationEmail: 'Erneutes versenden der Verifizierungs-Email',
                email: 'E-Mail',
                username: 'Benutzername',
                password: 'Passwort',
                signIn: 'Einloggen',
                errors: {
                    notVerified: 'E-Mail nicht verifiziert',
                    wrongCredentials: 'Falscher Benutzername und / oder Passwort',
                    generalError: 'Etwas ist schief gelaufen...',
                    newPasswordRequestNeeded: 'Passwort muss zurückgesetzt werden'
                },
                register: 'Registrieren'
            },
            requestPasswordReset: {
                forgotPassword: 'Passwort vergessen',
                resetLink: 'Sie erhalten demnächst eine automatisch generierte E-Mail mit einem Reset-Link.',
                email: 'E-Mail',
                username: 'Benutzername',
                resetPassword: 'Passwort zurücksetzen',
                generalError: 'Eine E-Mail konnte nicht versendet werden',
                emailNotAllowed: 'E-Mail Adresse nicht erlaubt. Bitte geben Sie einen Benutzernamen ein.'
            },
            resetPasswordComponent: {
                forgotPassword: 'Passwort vergessen',
                passwordChanged: 'Ihr Passwort wurde zurückgesetzt',
                password: 'Passwort',
                confirmPassword: 'Passwort bestätigen',
                resetPassword: 'Passwort zurücksetzen'
            },
            signup: {
                register: 'Registrieren',
                registrationSuccess: 'Die Registrierung war erfolgreich.<br>Sie erhalten demnächst eine automatisch generierte E-Mail' +
                    ' mit einem Aktivierungslink',
                email: 'E-Mail *',
                username: 'Benutzername *',
                password: 'Passwort *',
                confirmPassword: 'Passwort bestätigen *',
                familyName: 'Nachname *',
                givenName: 'Vorname *',
                company: 'Firma',
                streetAddress: 'Adresse *',
                streetAddress2: 'Adresszusatz',
                city: 'Stadt *',
                state: 'Bundesland',
                postalCode: 'PLZ *',
                country: 'Land *',
                taxNumber: 'USt-ID-Nr.',
                phoneNumber: 'Telefonnummer *',
                business: 'Branche ',
                newsletterSubscribed: 'Newsletter erhalten'
            },
            signupVerification: {
                success: 'Ihr Konto wurde erfolgreich erstellt. Sie können sich jetzt einloggen.',
                fail: 'Leider sind die eingegeben Daten nicht gültig.'
            },
            cart: {
                paymentProcessWarning1: 'Ihre Bestellung wird zur Zahlung vorbereitet.',
                paymentProcessWarning2: 'Bitte klicken Sie',
                paymentProcessWarning3: 'hier',
                paymentProcessWarning4: ', wenn Sie den Kaufprozess abbrechen und die Bilder zurück in den Warenkorb legen wollen.',
                selection: 'Auswahl:',
                sortByDateAdded: 'Sortieren nach Datum des Warenkorbes',
                reverseSort: 'Sortierrichtung umkehren',
                remove: 'Entfernen',
                modify: 'Ändern',
                duplicate: 'Duplizieren',
                pictureId: 'Bild-ID',
                licenseType: 'Lizenztyp',
                terms: 'Lizenzbestimmungen',
                mediaArt: 'Medientyp',
                image: 'Bild',
                shares: 'Freigaben',
                modelReleaseAvailable: 'Model Release vorhanden.',
                propertyReleaseAvailable: 'Property Release vorhanden.',
                size: 'Dateigröße',
                license: 'Lizenz',
                callUs: 'Bitte kontaktieren Sie uns für ein Angebot.',
                of: 'von',
                articlesSelected: 'Artikel sind für den Einkauf ausgewählt.',
                attentionItems: 'Ausgewählte Artikel benötigen zusätzliche Informationen.',
                acceptTerms: 'Ich akzeptiere die AGB und Datenschutzrichtlinien.',
                checkout: 'Bestellung abschließen',
                delete: 'Löschen',
                selectAll: 'Wählen Sie Alle',
                deselectAll: 'Alle abwählen',
                emptyState: 'Derzeit sind keine Bilder im Warenkorb',
                promotionCode: 'Gutschein einlösen'

            },
            cartCheckout: {
                checkout: 'Bestellung abschließen',
                creditCard: 'Kreditkarte',
                creditCardSubtitle: 'Weiter zur Zahlung per Kreditkarte. Danach können Sie Ihren Einkauf abschließen.',
                paypalSubtitle: 'Weiter zur Zahlung per PayPal. Danach können Sie Ihren Einkauf abschließen.',
                onAccount: 'Auf Rechnung',
                onAccountSubtitle: 'Weiter zur Zahlung per Rechnung. Danach können Sie Ihren Einkauf abschließen.',
                invoiceSent: 'Eine Rechnung mit Ihren Einkaufsdetails wird an Ihre E-Mail-Adresse gesendet.',
                totalPurchase: 'Total:',
                continue: 'Weiter',
                note: 'Hinweis',
                optional: 'Wahlweise',
                commentNoted: 'Optionaler Zusatztext für Ihre Rechnung.',
                promotionCode: 'Gutschein'
            },
            promoCode: {
                promoCode: 'Gutschein',
                discount: 'Rabatt',
                codeAddedInfo: 'Ihr Gutschein-Code wurde erfolgreich eingelöst',
                codeNotAddedError: 'Der eingegebene Gutschein-Code konnte nicht gefunden werden oder ist nicht mehr gültig',
                addCode: 'Gutschein einlösen'
            },
            cartIndicator: {
                shoppingCart: 'Warenkorb'
            },
            galleryIndicator: {
                gallery: 'Gallerie'
            },
            checkoutSuccess: {
                imageId: 'Bild-ID',
                image: 'Bild',
                licenseType: 'Lizenztyp',
                mediaArt: 'Medien Typ',
                shares: 'Freigaben',
                modelReleaseAvailable: 'Model Release vorhanden.',
                propertyReleaseAvailable: 'Property Release vorhanden.',
                heading: 'Überschrift',
                size: 'Dateigrösse',
                license: 'Lizenz',
                price: 'Preis',
                download: 'Herunterladen',
                receipt: 'Rechnung herunterladen',
                batchDownload: 'Batch ZIP-Datei herunterladen'
            },
            galleriesOverview: {
                lightTables: 'Leuchttisch-Übersicht',
                selection: 'Auswahl:',
                sortByModificationDate: 'Sortieren nach Änderungsdatum',
                sortByCreationDate: 'Sortieren nach Erstelldatum',
                sortByName: 'Sortieren nach Namen',
                sortBySize: 'Sortieren nach Grösse',
                showAll: 'Zeige alle Leuchttische',
                hideAll: 'Verberge alle Leuchttische',
                reverseSort: 'Sortierrichtung umkehren',
                hideShowGallery: 'Galerie ausblenden / anzeigen',
                create: 'Neuen Leuchttisch anlegen',
                actions: 'Aktionen...',
                edit: 'Editieren',
                delete: 'Löschen',
                deleteSelections: 'Auswahl löschen',
                rename: 'Umbenennen',
                email: 'Email',
                duplicate: 'Duplizieren',
                move: 'Verschieben',
                copy: 'Kopieren',
                moveTo: 'Verschieben nach',
                copyTo: 'Kopieren nach',
                inTheCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
                download: 'Herunterladen',
                numberOfPictures: 'Anzahl Bilder:',
                createdBefore: 'Erstellt vor:',
                editedBefore: 'Bearbeitet vor:',
                comment: 'Kommentar:',
                optionalComment: 'Kommentar (wahlweise)',
                abort: 'Abbrechen',
                save: 'Speichern',
                commentLimit: 'Der Kommentar ist limitiert auf 1000 Zeichen.',
                deleteGallery: 'Sind Sie sicher, dass Sie diesen Leuchttisch definitiv löschen wollen?',
                deleteLightTable: 'Leuchttisch löschen',
                deleteWarning: 'Diese Aktion kann nicht rückgängig gemacht werden.',
                name: 'Name',
                renameLightTable: 'Leuchttisch umbenennen',
                recipientEmailAddress: 'Email Adresse des Empfängers',
                sendLink: 'Sende Link',
                emailWarning: 'Der Empfänger erhält einen Link zu diesem Leuchttisch. Bei mehreren Empfängern werden die Adressen mit ' +
                    'einem Komma getrennt.',
                highResDownload: 'Hochauflösend herunterladen',
                downloadPDF: 'Download als PDF Datei',
                downloadHighRes: 'Download HighRes (.ZIP)',
                downloadLowRes: 'Download LowRes (.ZIP)',
                createLightboxTitle: 'Neuer Leuchttisch',
                emptyState: 'Keine Bilder im Leuchttisch',
                cartNote: 'Bitte beachten Sie, dass Sie nur Bilder kaufen müssen, für die Sie keine Downloadberechtigung haben',
                highResDownloadPlaceholder: 'Geben Sie hier bitte einen Verwendungszweck für den Leuchttisch ein',
                lightboxHasRestriction: '',
                createdOn: 'Erstellt am',
                showMore: 'Mehr anzeigen',
                showLess: 'Weniger anzeigen',
                lightboxSearch: {
                    placeholder: 'Suche in Bildern',
                    notFound: 'Keine Bilder gefunden'
                }
            },
            gridAssetThumb: {
                addToLightbox: 'In den Leuchttisch',
                deleteFromLightBox: 'Aus dem Leuchttisch entfernen',
                addToCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
            },
            lightboxBar: {
                lightTables: 'Leuchttische:',
                sortMenuBy: 'Menü sortieren nach...',
                name: 'Name',
                creationDate: 'Erstelldatum',
                modificationDate: 'Änderungsdatum',
                actions: 'Aktionen...',
                edit: 'Editieren',
                create: 'Erstellen',
                downloadPDF: 'Download als PDF Datei',
                downloadHighRes: 'Download HighRes (.ZIP)',
                downloadLowRes: 'Download LowRes (.ZIP)',
                delete: 'Löschen',
                rename: 'Umbenennen',
                email: 'Leuchtisch-Link per E-Mail versenden',
                duplicate: 'Duplizieren',
                inTheCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
                download: 'Herunterladen',
                images: 'Bilder:',
                created: 'Erstellt:',
                changed: 'Verändert:',
                optionalComment: 'Kommentar (wahlweise)',
                abort: 'Abbrechen',
                createLightTable: 'Leuchttisch erstellen',
                deleteAlert: 'Sind Sie sicher, dass Sie den Leuchttisch definitiv löschen wollen?',
                deleteLightTable: 'Leuchttisch löschen',
                deleteWarning: 'Dieser Vorgang kann nicht rückgängig gemacht werden.',
                renameLightTable: 'Leuchttisch neu benennen',
                recipientEmailAddress: 'Email Adresse des Empfängers',
                emailLightTableLink: 'Email Leuchttisch Link',
                emailWarning: 'Empfänger erhält einen Link zu diesem Leuchttisch. Für mehrere Email-Emfänger bitte mit Komma trennen.'
            },
            listAssetThumb: {
                addToLightbox: 'In den Leuchttisch',
                deleteFromLightBox: 'Aus dem Leuchttisch entfernen',
                addToCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
                name: 'Bildnummer',
                caption: 'Beschreibung',
                copyright: 'Urheberrechte ©:',
                license: 'Lizenz',
                modified: 'Geändert',
                created: 'Aufnahmedatum'
            },
            orderList: {
                total: 'Gesamt:',
                shoppingNumber: 'Einkaufsnummer:',
                offerNumber: 'Angebotsnummer:',
                purchased: 'Eingekauft:',
                downloaded: 'Download am:',
                viewBill: 'Ansicht Rechnung',
                viewOffer: 'Ansicht Angebot',
                inTheCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
                download: 'Herunterladen',
                licenseRenewal: 'Erneuerung der Lizenz',
                delete: 'Löschen',
                imageId: 'Bild-ID',
                photographer: 'Fotograf',
                moreFromPhotographer: 'Mehr von diesem Fotografen',
                moreFromCollection: 'Mehr von dieser Sammlung',
                licenseType: 'Lizenztyp',
                subscriptionName: 'Abonement',
                terms: 'Lizenzbestimmungen',
                mediaArt: 'Medien Typ',
                releases: 'Releases',
                modelReleaseAvailable: 'Model Release vorhanden.',
                propertyReleaseAvailable: 'Property Release vorhanden.',
                heading: 'Bildbeschreibung',
                size: 'Dateigröße',
                license: 'Lizenz',
                price: 'Preis',
                expired: 'Abgelaufen',
                expires: 'Läuft ab',
                emptyState: 'Nicht verfügbar',
                assetNotAvailableAnymore: 'Dieses Bild darf aus rechtlichen Gründen nicht mehr zur Verfügung gestellt werden',
                downloadType: 'Download',
                downloadAsZIP: 'ZIP Download',
                searchPlaceholder: {
                    final: 'Suche in Rechnungen',
                    proposal: 'Suche in Angeboten'
                }
            },
            profile: {
                profileSaved: 'Ihr Profil wurde gespeichert.',
                email: 'E-Mail',
                newPassword: 'Neues Passwort vergeben',
                confirmNewPassword: 'Neues Passwort bestätigen',
                familyName: 'Nachname *',
                givenName: 'Vorname *',
                company: 'Firma *',
                streetAddress: 'Adresse *',
                streetAddress2: 'Adresszusatz',
                city: 'Stadt *',
                state: 'Bundesland',
                postalCode: 'PLZ *',
                country: 'Land *',
                save: 'Speichern',
                username: 'Benutzername',
                taxNumber: 'USt-ID-Nr.',
                phoneNumber: 'Telefonnummer *',
                business: 'Branche ',
            },
            assetSearchPeopleFilter: {
                heading: 'Altersgruppen-Filter',
                baby: 'Baby',
                children: 'Kinder',
                youth: 'Jugendliche',
                adult: 'Erwachsene',
                senior: 'Senioren',
                mixedAgeGroups: 'Gemischt'
            },
            assetCollectionsFilter: {
                collectionsTitle: 'Kollektionen-Filter',
                addCollection: 'Kollektionen auswählen',
                noCollections: 'Keine Kollektionen ausgewählt'
            },
            assetCollectionsSelector: {
                submit: 'Übernehmen',
                all: 'Alle Kollektionen'
            },
            backgroundColorSwitcher: {
                background: 'Hintergrund'
            },
            webseriesFilter: {
                all: 'Alle'
            },
            previewSwitcher: {
                title: 'Vorschau'
            },
            lightboxStickyFooter: {
                itemsInLightbox: '{{value}} bilder in der Lightbox',
                viewLightbox: 'Lightbox ansehen',
                toggleFooter: 'Footer',
                showImprint: 'Impressum'
            },
            business: {
                pleaseSelect: 'Bitte wählen Sie eine aus',
                corporateCustomers: 'Corporate Customers ',
                corporatePublishing: 'Corporate Publishing',
                bookPublishing: 'Buchverlage ',
                calendarPublishing: 'Kalenderverlag',
                merchandising: 'Merchandising',
                advertisingPRCorporatePublishing: 'Werbeagentur/Marketing/Werbung/Werbemittelhersteller ',
                magazinePublisher: 'Zeitschriftenverlag redaktionell  ',
                tvAndRadio: 'Fernseh- und Radiosender',
                miscellaneous: 'Sonstiges',
                insurance: 'Versicherung',
                touristicTravelAgencies: 'Touristik/Reise/Reiseveranstalter',
                finance: 'Bank/Börse/Finanzdienstleister',
                education: 'Bildung',
                industry: 'Wirtschaft/Industrie'
            },
            formErrors: {
                emailNotValid: 'Keine gültige E-Mail Adresse',
                usernameTaken: 'Benutzername bereits vergeben',
                fieldRequired: 'Dieses Feld wird benötigt',
                validatePasswordConfirmation: 'Bitte das Passwort zurücksetzen',
                enterPassword: 'Bitte geben Sie ein Passwort ein',
                passwordsNoMatch: 'Passwörter stimmen nicht überein'
            },
            masonryGridAssetThumb: {
                addToLightbox: 'In den Leuchttisch',
                removeFromLightbox: 'Aus dem Leuchttisch entfernen',
                addToCart: 'In den Warenkorb',
                removeFromCart: 'Aus dem Warenkorb entfernen',
                searchForSimilarImages: 'Ähnliche Bilder suchen'
            },
            durationPipe: {
                seconds: 'Sekunden',
                minutes: 'Minuten',
                hours: 'Stunden',
                days: 'Tage',
                years: 'Jahre'
            },
            searchBarPopover: {
                text: 'Bitte setzen Sie mehrteilige Suchbegriffe in Anführungszeichen, z.B. &ldquo;New York&rdquo;.'
            },
            lightboxAddView: {
                sharedLightbox: 'Freigegebener Leuchttisch',
                assetsInTheLightbox: 'Bilder im Leuchttisch',
                addToLightboxes: 'Zu meinen Leuchttischen hinzufügen',
                infoText: 'Dieser Leuchttisch wurde mit Ihnen geteilt. Um diesen Ihren Leuchttischen hinzuzufügen, klicken Sie' +
                    ' auf "Zu meinen Leuchttischen hinzufügen".',
                notFound: 'Leuchttisch nicht gefunden. Bitte überprüfen Sie den Link und versuchen Sie es erneut.'
            },
            assetSearchPeopleNumberFilter: {
                title: 'Personen-Filter',
                any: 'Alle',
                peopleNumber: {
                    peopleNumberZero: 'Keine Personen',
                    peopleNumberOne: '1 Person',
                    peopleNumberTwo: '2 Personen',
                    peopleNumberThree: '3 Personen',
                    peopleNumberFour: '4 Personen',
                    peopleNumberFivePlus: '5+ Personen'
                }
            },
            pagination: {
                first: 'Anfang',
                previous: 'Zurück',
                next: 'Weiter',
                last: 'Ende',
                page: 'Seite'
            },
            download: {
                placeholder: 'sodatech'
            },
            aiPlus: {
                invalidFile: 'Diese Datei wird nicht unterstützt oder ist zu groß. Es werden nur JPEG- und PNG-Bilder mit einer' +
                    ' Dateigröße von max. 6 MB untestützt.',
                new: 'NEU',
                referencePicture: 'Referenzbild',
                resolution: 'Auflösung:',
                type: 'Typ:',
                url: 'Url'
            },
            contentTeaser: {
                readMore: "WEITERLESEN",
                showLess: "WENIGER ANZEIGEN"
            }
        },
    },
    en: {
        sodatechSdk: {
            userIndicator: {
                signIn: 'Sign In / Register'
            },
            assetDetail: {
                loading: 'Loading...',
                addToCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
                downloadLowRes: 'Download lowres file',
                downloadHighRes: 'Download highres file',
                addToGallery: 'Add to lightbox',
                deleteFromGallery: 'Remove from lightbox',
                share: 'Share...',
                copyLink: 'Copy Link',
                shareOnFacebook: 'Share on Facebook',
                shareOnTwitter: 'Share on Twitter',
                shareOnPinterest: 'Share on Pinterest',
                question: 'Support',
                support: 'Need help finding the right image?',
                rfVersusRm: 'RF versus RM',
                photographer: 'Photographer',
                moreFromPhotographer: 'See more from this photographer',
                moreFromCollection: 'See more from this collection',
                description: 'Description',
                size: 'Size',
                largestAvailableFile: 'Largest available file',
                availableUpon1: 'Available upon',
                availableUpon2: 'request',
                availableUpon3: 'for an additional fee.',
                releases: 'Releases',
                modelReleaseAvailable: 'Model Release Available.',
                propertyReleaseAvailable: 'Property Release Available.',
                keywords: 'Keywords',
                showMoreKeywords: 'More Keywords & Similarity Search',
                showFewerKeywords: 'Show fewer keywords',
                similarPictures: 'Similar images from series',
                similarPicturesFromAI: 'Similary images from ai search',
                highResDownload: {
                    title: 'Usage Type and Size',
                    placeholder: 'Usage Type and Size',
                    action: 'Download',
                    hint: 'Please provide the needed size in DIN for the asset, e.g. DIN A 5',
                    subscriptionsDropDown: 'Price Package Type'
                },
                imageName: 'Image Number',
                license: 'License',
                licenseType: 'License Typ',
                dateCreated: 'Recording Date',
                credit: 'Credit',
                creditName: 'Sodaware',
                alternateName: 'Reference Number',
                modelReleased: 'Model Release',
                propertyReleased: 'Property Release',
                fileSize: 'File size',
                optionalFileSize: 'Optional file size',
                resolution: 'Resolution',
                caption: 'Caption',
                modelReleasedTypes: {
                    notAvailable: 'No permission needed',
                    exists: 'Existing',
                    existsForPhotographer: 'Existing (for photographer)',
                    notPossible: 'Not existing',
                    uploaded: 'Uploaded',
                    physicallyAvailable: 'Physically available',
                    notSet: 'Not set',
                    indifferent: 'Indifferent'
                },
                propertyReleasedTypes: {
                    notAvailable: 'No permission needed',
                    exists: 'Existing',
                    existsForPhotographer: 'Existing (for photographer)',
                    notPossible: 'Not existing',
                    uploaded: 'Uploaded',
                    physicallyAvailable: 'Physically available',
                    notSet: 'Not set',
                    indifferent: 'Indifferent'
                },
                uncompressed: 'uncompressed',
                specialInstructions: 'Special Instructions',
                popupBlockerInfo: 'Please ensure your pop-up blocker has been deactivated in order to allow downloads.',
                hasRestriction: 'Is has restriction'
            },
            assetCalculator: {
                chooseLicense: 'Choose the license you need.',
                chooseSize: 'Choose the size you need.',
                moreOptions: 'More options',
                customLicense: 'Create your own custom license. If you have any questions, please contact us. We look forward to ' +
                    'your inquiry.',
                commercialOrEditorial: 'Commercial or editorial?',
                callUs: 'For this purpose, please contact us. We will gladly make an offer for you.',
                inTheCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
                toAdjust: 'Apply',
                purchaseImage: 'Call us for the purchase of this image.',
                addedToCart: 'The image was added to the cart.',
                notAddedToCart: 'The image could not be added to the cart. Please try again.',
                calculatedPrice: 'Calculated usage fee',

            },
            assetSearch: {
                emptyState: 'No assets found',
                searchingAdditionalAssets: 'Image search in progress',
                loadMore: 'Load more',
                assetsLoadedCounter: '<strong>{{assets}}</strong> of <strong>{{total}}</strong> images loaded'
            },
            assetSearchBreadcrumbs: {
                removeAll: 'Remove all',
                filterName: {
                    q: 'Keyword',
                    coll: 'Collection',
                    pgid: 'Photographer',
                    cop: 'Copyright',
                    license: 'License',
                    orientation: 'Orientation',
                    format: 'Format',
                    color: 'Color',
                    size: 'Size',
                    peopleCategory: 'Age Group',
                    creationDateFrom: 'Creation Date (from)',
                    creationDateTo: 'Creation Date (to)',
                    creationMonthsAgo: 'Creation Date (from)',
                    uploadDateFrom: 'Upload Date (from)',
                    uploadDateTo: 'Upload Date (to)',
                    uploadMonthsAgo: 'Upload Date (from)',
                    release: 'Release',
                    exclusive: 'Exclusive',
                    keywords: 'Keywords',
                    mediaType: 'Media type',
                    peopleNumber: 'People Number',
                    aiplus: 'AI\u207A Similarity Search',
                    restrictions: 'Restrictions',
                    category: 'Category'
                },
                filterValue: {
                    license: {
                        rm: 'RM',
                        rf: 'RF'
                    },
                    orientation: {
                        h: 'Horizontal',
                        v: 'Vertical',
                        q: 'Square',
                        p: 'Panoramic',
                        pv: 'Skyscraper'
                    },
                    color: {
                        'ff0000': 'Red',
                        'f0ff00': 'Yellow',
                        '0fff00': 'Green',
                        '0046ce': 'Blue',
                        '9900c9': 'Purple',
                        bw: 'Black & White',
                        all: 'All Colours'
                    },
                    size: {
                        geA5: '≥A5 (4.3 MP)',
                        geA4: '≥A4 (8.7 MP)',
                        geA3: '≥A3 (17.4 MP)',
                        geA2: '≥A2 (34.8 MP)'
                    },
                    release: {
                        mr: 'Model Release',
                        pr: 'Property Release'
                    },
                    peopleCategory: {
                        baby: 'Baby',
                        children: 'Children',
                        youth: 'Teenagers',
                        adult: 'Adults',
                        senior: 'Seniors',
                        mixedAgeGroups: 'Mixed'
                    },
                    mediaType: {
                        photography: 'Images',
                        illustration: 'Illustrations',
                    },
                    peopleNumber: {
                        0: 'No people',
                        1: '1 Person',
                        2: '2 People',
                        3: '3 People',
                        4: '4 People',
                        '5+': '5+ People'
                    },
                    datesFilter: {
                        1: '1 month',
                        3: '3 months',
                        6: '6 months',
                        12: '12 months',
                        'older': 'Older',
                    },
                    restrictions: {
                        none: 'No restrictions'
                    }
                }
            },
            assetSearchFilter: {
                collections: 'Collections',
                licenseType: 'License Filter',
                allLicenses: 'All licenses',
                royaltyFree: 'Royalty-Free',
                rightsManaged: 'Rights Managed',
                orientation: 'Format Filter',
                allOrientations: 'All orientations',
                horizontal: 'Horizontal',
                vertical: 'Vertical',
                square: 'Square',
                panoramic: 'Panoramic',
                skyscraper: 'Skyscraper',
                colorFilter: 'Color filter',
                allColors: 'All colors',
                color: 'Color',
                monochrome: 'Monochrome',
                blackAndWhite: 'Black-and-white',
                keyword: 'Keyword filter',
                categories: 'Categories',
                restrictions: 'Restrictions filter'
            },
            assetSearchDateFilter: {
                creationDate: 'Record Date Filter',
                uploadDate: 'Archive Filter',
                uploadTime: 'Upload Time Filter',
                from: 'from',
                to: 'to',
                placeholder: 'DD/MM/YYYY',
                uploadedMonthsAgo: {
                    oneMonth: 'Last month',
                    threeMonth: 'Last 3 months',
                    sixMonth: 'Last 6 months',
                    twelveMonth: 'Last 12 months',
                    older: 'Older',
                    all: 'All'
                },
                creationMonthsAgo: {
                    oneMonth: 'Last month',
                    threeMonth: 'Last 3 months',
                    sixMonth: 'Last 6 months',
                    twelveMonth: 'Last 12 months',
                }
            },
            assetSearchColorFilter: {
                title: 'Colour Filter'
            },
            assetSearchSizeFilter: {
                any: 'All Sizes',
                title: 'Size Filter',
                hint: 'Please note that panorama or skyscraper formats may differ from the specified DIN sizes.',
                greaterEqualA5: '≥A5 (4.3 MP)',
                greaterEqualA4: '≥A4 (8.7 MP)',
                greaterEqualA3: '≥A3 (17.4 MP)',
                greaterEqualA2: '≥A2 (34.8 MP)'
            },
            assetSearchReleaseFilter: {
                title: 'Release Filter',
                modelReleased: 'Model Release',
                propertyReleased: ' Property Release'
            },
            assetSearchMediaTypeFilter: {
                title: 'Media Type Filter',
                images: 'Images',
                illustrations: 'Illustrations'
            },
            assetSearchExclusiveFilter: {
                title: 'Exclusive Filter'
            },
            assetSearchRestrictionsFilter: {
                all: 'All',
                notRestricted: 'No restrictions',
            },
            assetSearchInput: {
                search: 'Search'
            },
            assetSearchResultCount: {
                foundMedia: 'Found media:'
            },
            assetKeywords: {
                keywords: 'Keywords'
            },
            similaritySearch: {
                toggleSimilarSearch: 'Search similar images'
            },
            login: {
                logIn: 'LogIn',
                resendVerificationEmail: 'Resend the verification email',
                email: 'Email',
                password: 'Password',
                username: 'Username',
                signIn: 'Sign In',
                errors: {
                    notVerified: 'Email not Verified',
                    wrongCredentials: 'Wrong username and/or password',
                    generalError: 'Something went wrong...',
                    newPasswordRequestNeeded: 'New password request needed'
                },
                register: 'Register'
            },
            requestPasswordReset: {
                forgotPassword: 'Forgot Password',
                resetLink: 'You will soon receive an automatically generated e-mail with a reset link.',
                email: 'E-mail',
                username: 'Username',
                resetPassword: 'Reset password',
                generalError: 'Email could not be sent',
                emailNotAllowed: 'Email is not allowed here. Please enter a username.'
            },
            resetPasswordComponent: {
                forgotPassword: 'Forgot Password',
                passwordChanged: 'Your password has been changed',
                password: 'Password',
                confirmPassword: 'Confirm Password',
                resetPassword: 'Reset password'
            },
            signup: {
                register: 'Register',
                registrationSuccess: 'The registration was successful. <br> You will soon receive an automatically generated email with ' +
                    'an activation link.',
                email: 'E-Mail *',
                username: 'Username *',
                password: 'Password *',
                confirmPassword: 'Confirm Password *',
                familyName: 'Surname*',
                givenName: 'First Name *',
                company: 'Company',
                streetAddress: 'Address *',
                streetAddress2: 'Additional Address',
                city: 'City *',
                state: 'State',
                postalCode: 'Postal Code *',
                country: 'Country *',
                taxNumber: 'VAT-ID Number',
                business: 'Sector',
                phoneNumber: 'Phone Number *',
                newsletterSubscribed: 'Receive newsletter'
            },
            signupVerification: {
                success: 'Your account was created successfully. You can log in now.',
                fail: 'The input was not valid.'
            },
            cart: {
                paymentProcessWarning1: 'Your order will be prepared for payment.',
                paymentProcessWarning2: 'Please click',
                paymentProcessWarning3: 'here',
                paymentProcessWarning4: 'if you want to cancel the purchase process and want to return the pictures to the shopping ' +
                    'cart.',
                selection: 'Selection:',
                sortByDateAdded: 'Sort by date added to shopping cart',
                reverseSort: 'Reverse Sort Direction',
                remove: 'Remove',
                modify: 'Modify',
                duplicate: 'Duplicate',
                pictureId: 'Picture ID',
                licenseType: 'License Type',
                terms: 'Terms',
                mediaArt: 'Media type',
                image: 'Image',
                shares: 'Shares',
                modelReleaseAvailable: 'Model Release Available.',
                propertyReleaseAvailable: 'Property Release Available.',
                size: 'Size',
                license: 'License',
                callUs: 'Please contact us at for an offer.',
                of: 'of',
                articlesSelected: 'Items are selected for purchase.',
                attentionItems: 'Selected articles require additional information.',
                acceptTerms: 'I accept the Terms and Conditions and Privacy Policy.',
                checkout: 'Checkout',
                delete: 'Delete',
                selectAll: 'Select All',
                deselectAll: 'Deselect All',
                emptyState: 'No assets in the cart',
                promotionCode: 'Redeem Promotion Code'
            },
            cartCheckout: {
                checkout: 'Checkout',
                creditCard: 'Credit Card',
                creditCardSubtitle: 'Continue to pay with credit card. Then you can complete your purchase.',
                continueToPay: 'Continue to pay with PayPal. Then you can complete your purchase.',
                paypalSubtitle: 'Continue to pay with PayPal. Then you can complete your purchase.',
                onAccount: 'Invoice',
                onAccountSubtitle: 'Continue to buy it on your account (invoice). Then you can complete your purchase.',
                invoiceSent: 'An invoice with your purchase details will be sent to your email address.',
                totalPurchase: 'Total purchase:',
                continue: 'Continue',
                note: 'Note',
                optional: 'Optional',
                commentNoted: 'Optional additional text for your invoice.',
                promotionCode: 'Promotion Code'
            },
            cartIndicator: {
                shoppingCart: 'Shopping cart'
            },
            galleryIndicator: {
                gallery: 'Gallery'
            },
            promoCode: {
                promoCode: 'Promotion Code',
                discount: 'Discount',
                codeAddedInfo: 'Your promotion code was added successfully',
                codeNotAddedError: 'The entered promotion code could not be found or is not valid anymore',
                addCode: 'Redeem Promotion Code'
            },
            checkoutSuccess: {
                imageId: 'Image ID',
                image: 'Image',
                licenseType: 'License Type',
                mediaArt: 'Media type',
                shares: 'Shares',
                modelReleaseAvailable: 'Model Release Available.',
                propertyReleaseAvailable: 'Property Release Available.',
                heading: 'Heading',
                size: 'Size',
                license: 'License',
                price: 'Price',
                download: 'Download',
                receipt: 'Download Invoice',
                batchDownload: 'Batch Download ZIP File'
            },
            galleriesOverview: {
                lightTables: 'Lightbox Overview',
                selection: 'Selection:',
                sortByModificationDate: 'Sort by change date',
                sortByCreationDate: 'Sort by creation date',
                sortByName: 'Sort by name',
                sortBySize: 'Sort by size',
                showAll: 'Show all lightboxes',
                hideAll: 'Hide all lightboxes',
                reverseSort: 'Reverse Sort Direction',
                hideShowGallery: 'Hide/Show Gallery',
                create: 'Create new lightbox',
                actions: 'Actions...',
                edit: 'Edit',
                delete: 'Delete',
                deleteSelections: 'Delete Selection',
                rename: 'Rename',
                email: 'E-mail',
                duplicate: 'Duplicate',
                move: 'Move',
                copy: 'Copy',
                moveTo: 'Move to',
                copyTo: 'Copy to',
                inTheCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
                download: 'Download',
                numberOfPictures: 'Number of pictures:',
                createdBefore: 'Created before:',
                editedBefore: 'Edited before:',
                comment: 'Comment:',
                optionalComment: 'Comment (optional)',
                abort: 'Abort',
                save: 'Save',
                commentLimit: 'The comment is limited to 1000 characters.',
                deleteGallery: 'Are you sure you want to delete this lightbox?',
                deleteLightTable: 'Delete lightbox',
                deleteWarning: 'This action can not be undone.',
                name: 'Name',
                renameLightTable: 'Rename lightbox',
                recipientEmailAddress: 'Recipient\'s e-mail address',
                sendLink: 'Send link',
                emailWarning: 'The receiver receives a link to this lightbox. With several receivers the addresses are separated with ' +
                    'a comma.',
                highResDownload: 'Download High Resolution',
                createLightboxTitle: 'New Lightbox',
                downloadPDF: 'Download as PDF File',
                downloadHighRes: 'Download highres  (.ZIP)',
                downloadLowRes: 'Download lowres  (.ZIP)',
                emptyState: 'No assets in the lightbox yet',
                cartNote: 'Please keep in mind that you only have to buy images where you don\'t have a download subscription.',
                highResDownloadPlaceholder: 'Please enter the intended use for the lightbox here',
                createdOn: 'Created on',
                showMore: 'Show more',
                showLess: 'Show less',
                lightboxSearch: {
                    placeholder: 'Search in assets',
                    notFound: 'No assets found'
                }
            },
            gridAssetThumb: {
                addToLightbox: 'Add to lightbox',
                deleteFromLightBox: 'Remove from lightbox',
                addToCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
            },
            lightboxBar: {
                lightTables: 'Lightboxes:',
                sortMenuBy: 'Sort Menu By...',
                name: 'Name',
                creationDate: 'Creation date',
                modificationDate: 'Modification Date',
                actions: 'Actions...',
                edit: 'Edit',
                create: 'Create',
                delete: 'Delete',
                rename: 'Rename',
                email: 'Send lightbox link via e-mail',
                duplicate: 'Duplicate',
                inTheCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
                download: 'Download',
                downloadPDF: 'Download as PDF file',
                downloadHighRes: 'Download highres (.ZIP)',
                downloadLowRes: 'Download lowres (.ZIP)',
                images: 'Images:',
                created: 'Created:',
                changed: 'Changed:',
                optionalComment: 'Comment (optional)',
                abort: 'Abort',
                createLightTable: 'Create lightbox',
                deleteAlert: 'Are you sure you want to delete the lightbox definitely?',
                deleteLightTable: 'Delete lightbox',
                deleteWarning: 'This action can not be undone.',
                renameLightTable: 'Rename lightbox',
                recipientEmailAddress: 'Recipient\'s e-mail address',
                emailLightTableLink: 'E-mail Lightbox Link',
                emailWarning: 'Receiver receives a link to this lightbox. For several email recipients please separate with a comma.'
            },
            listAssetThumb: {
                addToLightbox: 'Add to lightbox',
                deleteFromLightBox: 'Remove from lightbox',
                addToCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
                name: 'Image Number:',
                caption: 'Description',
                copyright: 'Copyright:',
                license: 'License:',
                modified: 'Modified:',
                created: 'Recording Date'
            },
            orderList: {
                total: 'Total:',
                shoppingNumber: 'Invoice number:',
                offerNumber: 'Proposal number:',
                purchased: 'Purchased:',
                downloaded: 'Downloaded on:',
                viewBill: 'View invoice',
                viewOffer: 'View proposal',
                inTheCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
                download: 'Download',
                licenseRenewal: 'Renewal of the license',
                delete: 'Delete',
                imageId: 'Image ID',
                photographer: 'Photographer',
                moreFromPhotographer: 'All images of the photographer',
                moreFromCollection: 'All images of the collection',
                licenseType: 'License type',
                subscriptionName: 'Subscription Name',
                terms: 'Terms',
                mediaArt: 'Media type',
                releases: 'Releases',
                modelReleaseAvailable: 'Model Release Available.',
                propertyReleaseAvailable: 'Property Release Available.',
                heading: 'Image Description',
                size: 'Size',
                license: 'License',
                price: 'Price',
                expired: 'Expired',
                expires: 'Expires',
                emptyState: 'Not available',
                assetNotAvailableAnymore: 'This image may no longer be provided for legal reasons',
                downloadType: 'Download',
                downloadAsZIP: 'ZIP Download',
                searchPlaceholder: {
                    final: 'Search in invoices',
                    proposal: 'Search in quotes'
                }
            },
            profile: {
                profileSaved: 'Your profile has been saved.',
                email: 'Email',
                newPassword: 'Enter New Password',
                confirmNewPassword: 'Confirm New Password',
                familyName: 'Family Name *',
                givenName: 'Given Name *',
                company: 'Company *',
                streetAddress: 'Street Address *',
                streetAddress2: 'Additional Street Address',
                city: 'City *',
                state: 'State',
                postalCode: 'Postal Code *',
                country: 'Country *',
                save: 'Save',
                username: 'Username',
                taxNumber: 'VAT-ID Number',
                business: 'Sector',
                phoneNumber: 'Phone Number *',
            },
            assetSearchPeopleFilter: {
                heading: 'Age Group Filter',
                baby: 'Baby',
                children: 'Children',
                youth: 'Teenagers/Youngsters',
                adult: 'Adults',
                senior: 'Seniors',
                mixedAgeGroups: 'Mixed Age Groups'
            },
            assetCollectionsFilter: {
                collectionsTitle: 'Collections Filter',
                addCollection: 'Select collections',
                noCollections: 'No collections selected'
            },
            assetCollectionsSelector: {
                submit: 'Apply',
                all: 'All Collections'
            },
            backgroundColorSwitcher: {
                background: 'Background'
            },
            webseriesFilter: {
                all: 'All'
            },
            previewSwitcher:
                {
                    title: 'Preview'
                },
            lightboxStickyFooter: {
                itemsInLightbox: '{{value}} items in Lightbox',
                viewLightbox: 'View Lightbox',
                toggleFooter: 'Footer',
                showImprint: 'Imprint'
            },
            business: {
                pleaseSelect: 'Please select one',
                corporateCustomers: 'Corporate Customers ',
                corporatePublishing: 'Corporate Publishing',
                bookPublishing: 'Book publishing',
                calendarPublishing: 'Calendar publishing',
                merchandising: 'Merchandising',
                advertisingPRCorporatePublishing: 'Advertising / PR / Corporate publishing',
                magazinePublisher: 'Magazin publisher redaktionell  ',
                tvAndRadio: 'TV and radio channels',
                miscellaneous: 'Miscellaneous',
                insurance: 'Insurance',
                touristicTravelAgencies: 'Touristic / TravelAgencies',
                finance: 'Bank / Stock market / Financial service provider',
                education: 'Education',
                industry: 'Economy / Industry'
            },
            formErrors: {
                emailNotValid: 'This is not a valid email address',
                usernameTaken: 'Username already taken',
                fieldRequired: 'This field is required',
                validatePasswordConfirmation: 'The passwords do not match',
                enterPassword: 'Please enter a password',
                passwordsNoMatch: 'The passwords do not match'
            },
            masonryGridAssetThumb: {
                addToLightbox: 'Add to lightbox',
                removeFromLightbox: 'Remove from lightbox',
                addToCart: 'Add to cart',
                removeFromCart: 'Remove from cart',
                searchForSimilarImages: 'Search for similar images'
            },
            durationPipe: {
                seconds: 'seconds',
                minutes: 'minutes',
                hours: 'hours',
                days: 'days',
                years: 'years'
            },
            searchBarPopover: {
                text: 'Please use quotation marks for multi-part proper names, e.g. &ldquo;New York&rdquo;.'
            },
            lightboxAddView: {
                sharedLightbox: 'Shared lightbox',
                assetsInTheLightbox: 'assets in the lightbox',
                addToLightboxes: 'Add to Lightboxes',
                infoText: 'This lightbox was shared with you. If you would like to add it to your lightboxes click the button ' +
                    '"Add to Lightboxes".',
                notFound: 'Lightbox not found. Please check the link and try again.'
            },
            assetSearchPeopleNumberFilter: {
                title: 'People Filter',
                any: 'All',
                peopleNumber: {
                    peopleNumberZero: 'No people',
                    peopleNumberOne: '1 Person',
                    peopleNumberTwo: '2 People',
                    peopleNumberThree: '3 People',
                    peopleNumberFour: '4 People',
                    peopleNumberFivePlus: '5+ People'
                }
            },
            pagination: {
                first: 'First',
                previous: 'Previous',
                next: 'Next',
                last: 'Last',
                page: 'Page'
            },
            download: {
                placeholder: 'sodatech'
            },
            aiPlus: {
                invalidFile: 'This file is not supported or too large. Only JPEG or PNG images with a maximum file size of 6MB ' +
                    'will be accepted.',
                new: 'NEW',
                referencePicture: 'Reference Picture',
                resolution: 'Resolution:',
                type: 'Type:',
                url: 'Url:'
            },
            contentTeaser: {
                readMore: "READ MORE",
                showLess: "SHOW LESS"
            }
        }
    },
    nl: {
        sodatechSdk: {
            userIndicator: {
                signIn: 'Log in / Registreer',
            },
            assetDetail: {
                loading: 'Laden',
                addToCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelwagen verwijderen',
                downloadLowRes: 'Download lowres-bestand',
                downloadHighRes: 'Download highres-bestand',
                addToGallery: 'Aan lightbox toevoegen',
                deleteFromGallery: 'Uit lightbox verwijderen',
                share: 'Delen',
                copyLink: 'Kopier link',
                shareOnFacebook: 'Deel op Facebook',
                shareOnTwitter: 'Deel op Twitter',
                shareOnPinterest: 'Deel op Pinterest',
                question: 'Support',
                support: 'Hulp nodig bij het vinden van het juiste beeld?',
                rfVersusRm: 'RF versus RM',
                photographer: 'Fotograaf',
                moreFromPhotographer: 'Meer van deze fotograaf',
                moreFromCollection: 'Meer van deze collectie',
                description: 'Omschrijving',
                size: 'Grootte',
                largestAvailableFile: 'Grootste beschikbare bestand',
                availableUpon1: 'Beschikbaar op',
                availableUpon2: 'aanvraag',
                availableUpon3: 'voor een aanvullende vergoeding',
                releases: 'Releases',
                modelReleaseAvailable: 'Model Release Available.',
                propertyReleaseAvailable: 'Property Release Available.',
                keywords: 'Keywords',
                showMoreKeywords: 'More Keywords & Similarity Search',
                showFewerKeywords: 'Show fewer keywords',
                similarPictures: 'Similar images from series',
                similarPicturesFromAI: 'Similar images from AI search',
                highResDownload: {
                    title: 'GGebruik en Beeldformaat',
                    placeholder: 'Gebruik en afbeeldingsgrootte',
                    action: 'Download',
                    hint: 'Geef de benodigde grootte in DIN op voor het item, bijv. DIN A 5',
                    subscriptionsDropDown: 'Type Price Package'
                },
                imageName: 'beeldnummer',
                license: 'Licentie',
                licenseType: 'Type Licentie',
                dateCreated: 'Datum opname',
                credit: 'Credit',
                creditName: 'Sodaware',
                alternateName: 'Referentienummer',
                modelReleased: 'Model Release',
                propertyReleased: 'Property Release',
                fileSize: 'Bestandsgrootte',
                optionalFileSize: 'Optionele bestandsgrootte',
                resolution: 'Resolutie',
                caption: 'Bijschrift',
                modelReleasedTypes: {
                    notAvailable: 'Geen toestemming nodig',
                    exists: 'Beschikbaar',
                    existsForPhotographer: 'Beschikbaar (voor fotograaf)',
                    notPossible: 'Niet beschikbaar',
                    uploaded: 'Uploaded',
                    physicallyAvailable: 'Aanwezig',
                    notSet: 'Niet ingesteld',
                    indifferent: 'Indifferent'
                },
                propertyReleasedTypes: {
                    notAvailable: 'Geen toestemming nodig',
                    exists: 'Beschikbaar',
                    existsForPhotographer: 'Beschikbaar (voor fotograaf)',
                    notPossible: 'Niet beschikbaar',
                    uploaded: 'Uploaded',
                    physicallyAvailable: 'Aanwezig',
                    notSet: 'Niet ingesteld',
                    indifferent: 'Indifferent'
                },
                uncompressed: 'Ongecomprimeerd',
                specialInstructions: 'Specale instructies',
                popupBlockerInfo: 'Zorg ervoor dat je pop-up blokkering is gedeactiveerd om downloads toe te staan.',
                hasRestriction: 'Heeft restrictie'
            },
            assetCalculator: {
                chooseLicense: 'Kies licentie',
                chooseSize: 'Kies bestandsgrootte',
                moreOptions: 'Meer opties',
                customLicense: 'Maak uw eigen aangepaste licentie. Neem bij vragen contact met ons op. Wij helpen u graag.',
                commercialOrEditorial: 'Commercieel of redactioneel',
                callUs: 'Neem hiervoor contact met ons op. Wij maken graag een offerte voor u.',
                inTheCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelwagen verwijderen',
                toAdjust: ' Apply',
                purchaseImage: 'Bel ons voor de aankoop van deze afbeelding',
                addedToCart: 'Het beeld is toegevoegd aan de winkelwagen',
                notAddedToCart: 'De afbeelding kon niet aan de winkelwagen worden toegevoegd. Probeer het opnieuw.',
                calculatedPrice: 'Berekende gebruikskosten'
            },
            assetSearch: {
                emptyState: 'Geen beelden gevonden',
                searchingAdditionalAssets: 'Bezig met zoeken naar afbeeldingen',
                loadMore: 'Laad meer',
                assetsLoadedCounter: '<strong>{{assets}}</strong> of <strong>{{total}}</strong> afbeeldingen geladen',
            },
            assetSearchBreadcrumbs: {
                removeAll: 'Verwijder alle',
                filterName: {
                    q: 'Trefwoord',
                    coll: 'Collectie',
                    pgid: 'Fotograaf',
                    cop: 'Copyright',
                    license: 'Licentie',
                    orientation: 'Orientatie',
                    format: 'Formaat',
                    color: 'Kleur',
                    size: 'Grootte',
                    peopleCategory: 'Leeftijd',
                    creationDateFrom: 'Datum opname (van)',
                    creationDateTo: 'Datum opname (tot)',
                    creationMonthsAgo: 'Datum opname (van)',
                    uploadDateFrom: 'Upload datum (van)',
                    uploadDateTo: 'Upload datum (tot)',
                    uploadMonthsAgo: 'Upload datum (van)',
                    release: 'Release',
                    exclusive: 'Exclusief',
                    keywords: 'Trefwoorden',
                    mediaType: 'Mediatype',
                    peopleNumber: 'Aantal mensen',
                    aiplus: 'Zoeken naar overeenkomstige beelden',
                    restrictions: 'Restricties',
                    category: 'Categorie',
                },
                filterValue: {
                    license: {
                        rm: 'RM',
                        rf: 'RF'
                    },
                    orientation: {
                        h: 'Horizontaal',
                        v: 'Verticaal',
                        q: 'Vierkant',
                        p: 'Panoramisch',
                        pv: 'Skyscraper',
                    },
                    color: {
                        'ff0000': 'Rood',
                        'f0ff00': 'Geel',
                        '0fff00': 'Groen',
                        '0046ce': 'Blauw',
                        '9900c9': 'Paars',
                        bw: 'Zwart wit',
                        all: 'Alle kleuren',
                    },
                    size: {
                        geA5: '≥A5 (4.3 MP)',
                        geA4: '≥A4 (8.7 MP)',
                        geA3: '≥A3 (17.4 MP)',
                        geA2: '≥A2 (34.8 MP)'
                    },
                    release: {
                        mr: 'Model Release',
                        pr: 'Property Release'
                    },
                    peopleCategory: {
                        baby: 'Baby',
                        children: 'Kinderen',
                        youth: 'Tieners',
                        adult: 'Volwassenen',
                        senior: 'Senioren',
                        mixedAgeGroups: 'Gemengd',
                    },
                    mediaType: {
                        photography: 'Beelden',
                        illustration: 'Illustraties'
                    },
                    peopleNumber: {
                        0: 'Geen mensen',
                        1: '1 persoon',
                        2: '2 personen',
                        3: '3 personen',
                        4: '4 personen',
                        '5+': '5+ personen'
                    },
                    datesFilter: {
                        1: '1 maand',
                        3: '3 maanden',
                        6: '6 maanden',
                        12: '12 maanden',
                        'older': 'ouder'
                    },
                    restrictions: {
                        none: 'Geen restricties',
                    }
                }
            },
            assetSearchFilter: {
                collections: 'Collecties',
                licenseType: 'Licentiefilter',
                allLicenses: 'Alle licenties',
                royaltyFree: 'Royalty-Free',
                rightsManaged: 'Rights Managed',
                orientation: 'Formaatfilter',
                allOrientations: 'Alle orientaties',
                horizontal: 'Horizontaal',
                vertical: 'Verticaal',
                square: 'Vierkant',
                panoramic: 'Panoramisch',
                skyscraper: 'Skyscraper',
                colorFilter: 'Kleurenfilter',
                allColors: 'Alle kleuren',
                color: 'Kleur',
                monochrome: 'Monochroom',
                blackAndWhite: 'Zwart wit',
                keyword: 'Trefwoordenfilter',
                categories: 'Categorieen',
                restrictions: 'Restrictiefilter',
            },
            assetSearchDateFilter: {
                creationDate: 'Datum opname filter',
                uploadDate: 'Archieffilter',
                uploadTime: 'Upload datum filter',
                from: 'van',
                to: 'tot',
                placeholder: 'DD/MM/YYYY',
                uploadedMonthsAgo: {
                    oneMonth: 'Laatste maand',
                    threeMonth: 'Laatste 3 maanden',
                    sixMonth: 'Laatste 6 maanden',
                    twelveMonth: 'Laatste 12 maanden',
                    older: 'Ouder',
                    all: 'Alles'
                },
                creationMonthsAgo: {
                    oneMonth: 'Laatste maand',
                    threeMonth: 'Laatste 3 maanden',
                    sixMonth: 'Laatste 6 maanden',
                    twelveMonth: 'Laatste 12 maanden'
                }
            },
            assetSearchColorFilter: {
                title: 'Kleurenfilter'
            },
            assetSearchSizeFilter: {
                any: 'Alle afmetingen',
                title: 'Afmetingfilter',
                hint: 'Houd er rekening mee dat panorama- of skyscraper formaten kunnen verschillen van de opgegeven DIN-formaten',
                greaterEqualA5: '≥A5 (4.3 MP)',
                greaterEqualA4: '≥A4 (8.7 MP)',
                greaterEqualA3: '≥A3 (17.4 MP)',
                greaterEqualA2: '≥A2 (34.8 MP)'
            },
            assetSearchReleaseFilter: {
                title: 'Release filter',
                modelReleased: 'Model Release',
                propertyReleased: ' Property Release'
            },
            assetSearchMediaTypeFilter: {
                title: 'Mediatypefilter',
                images: 'Beelden',
                illustrations: 'Illustraties',
            },
            assetSearchExclusiveFilter: {
                title: 'Exclusiviteitsfilter'
            },
            assetSearchRestrictionsFilter: {
                all: 'Alles',
                notRestricted: 'Geen restricties'
            },
            assetSearchInput: {
                search: 'Zoeken'
            },
            assetSearchResultCount: {
                foundMedia: 'Gevonden media',
            },
            assetKeywords: {
                keywords: 'Trefwoorden',
            },
            similaritySearch: {
                toggleSimilarSearch: 'Zoeken naar overeenkomstige beelden',
            },
            login: {
                logIn: 'Inloggen',
                resendVerificationEmail: 'Verzend de verificatie email opnieuw',
                email: 'Email',
                password: ' Wachtwoord',
                username: 'Gebruikersnaam',
                signIn: 'Inloggen',
                errors: {
                    notVerified: 'Email niet geverifieerd',
                    wrongCredentials: 'Verkeerde gebruikersnaam en / of wachtwoord',
                    generalError: 'Er is iets fout gegaan...',
                    newPasswordRequestNeeded: 'Nieuw wachtwoordverzoek nodig',
                },
                register: 'Registreer'
            },
            requestPasswordReset: {
                forgotPassword: 'Wachtwoord vergeten',
                resetLink: 'U ontvangt binnenkort een automatisch gegenereerde email met een resetlink.',
                email: 'Email',
                username: 'Gebruikersnaam',
                resetPassword: 'Reset wachtwoord',
                generalError: 'Email kon niet verzonden worden',
                emailNotAllowed: 'Email is hier niet toegestaan. Voer een gebruikersnaam in.'
            },
            resetPasswordComponent: {
                forgotPassword: 'Wachtwoord vergeten',
                passwordChanged: 'Uw wachtwoord is gewijzigd',
                password: 'Wachtwoord',
                confirmPassword: 'Wachtwoord bevestigen',
                resetPassword: 'Reset wachtwoord'
            },
            signup: {
                register: 'Registreer',
                registrationSuccess: 'De registratie was succesvol. <br>U ontvangt binnenkort een automatisch gegenereerde email met' +
                    ' een activeringslink',
                email: 'Email *',
                username: 'Gebruikersnaam *',
                password: 'Wachtwoord *',
                confirmPassword: 'Wachtwoord bevestigen *',
                familyName: 'Achternaam *',
                givenName: 'Voornaam *',
                company: 'Bedrijf',
                streetAddress: 'Adres *',
                streetAddress2: 'Adres aanvulling',
                city: 'Stad *',
                state: '-',
                postalCode: 'Postcode *',
                country: 'Land *',
                taxNumber: 'BTW-nummer',
                business: 'Branche',
                phoneNumber: 'Telefoonnummer *',
                newsletterSubscribed: 'Nieuwsbrief ontvangen'
            },
            signupVerification: {
                success: 'Uw account is succesvol aangemaakt. U kunt nu inloggen.',
                fail: 'De ingevoerde gegevens zijn niet geldig'
            },
            cart: {
                paymentProcessWarning1: 'Uw bestelling wordt voorbereid.',
                paymentProcessWarning2: 'Klik',
                paymentProcessWarning3: 'hier',
                paymentProcessWarning4: 'als u het aankoopproces wilt annuleren en de beelden naar de winkelwagen wilt retourneren.',
                selection: 'Selectie',
                sortByDateAdded: 'Sorteer op datum toegevoegd aan winkelwagen',
                reverseSort: 'Sorteerrichting omkeren',
                remove: 'Verwijderen',
                modify: 'Aanpassen',
                duplicate: 'Dupliceren',
                pictureId: 'Picture ID',
                licenseType: 'Type licentie',
                terms: 'Voorwaarden',
                mediaArt: 'Mediatype',
                image: 'beeld',
                shares: 'Shares',
                modelReleaseAvailable: 'Model Release beschikbaar',
                propertyReleaseAvailable: 'Property Release beschikbaar',
                size: 'Grootte',
                license: 'Licentie',
                callUs: 'Neem contact met ons op voor een offerte',
                of: 'van',
                articlesSelected: 'Items zijn geselecteerd voor aankoop',
                attentionItems: 'Voor de geselecteerde items is aanvullende informatie nodig',
                acceptTerms: 'Ik accepteer de Algemene voorwaarden en Privacybeleid',
                checkout: 'Afrekenen',
                delete: 'Verwijderen',
                selectAll: 'Alles selecteren',
                deselectAll: 'Alles deselecteren',
                emptyState: 'Geen beelden in winkelwagen',
                promotionCode: 'Promotiecode inwisselen',
            },
            cartCheckout: {
                checkout: 'Afrekenen',
                creditCard: 'Credit Card',
                creditCardSubtitle: 'Klik op Doorgaan om te betalen met credit card. Deze functie vind je in de PayPal site maar je hebt geen PayPal account nodig.',
                paypalSubtitle: 'Klik op Doorgaan om te betalen met PayPal.',
                onAccount: 'Factuur',
                onAccountSubtitle: 'Klik op Doorgaan voor het ontvangen van een factuur.',
                invoiceSent: 'Een factuur met de aankoopgegevens wordt naar uw emailadres verzonden',
                totalPurchase: 'Totale aankoop',
                continue: 'Doorgaan',
                note: 'Opmerking',
                optional: 'Optioneel',
                commentNoted: 'Optionele aanvullende tekst voor uw factuur',
                promotionCode: 'Promotiecode'
            },
            cartIndicator: {
                shoppingCart: 'Winkelwagen',
            },
            galleryIndicator: {
                gallery: 'Galerij'
            },
            promoCode: {
                promoCode: '',
                discount: '',
                codeAddedInfo: 'Your promotion code was added successfully',
                codeNotAddedError: 'The entered promotion code could not be found or is not valid anymore',
                addCode: 'Redeem Promotion Code'
            },
            checkoutSuccess: {
                imageId: 'Beeld ID',
                image: 'Beeld',
                licenseType: 'Type licentie',
                mediaArt: 'Mediatype',
                shares: 'Shares',
                modelReleaseAvailable: 'Model Release beschikbaar',
                propertyReleaseAvailable: 'Property Release beschikbaar',
                heading: 'Heading',
                size: 'Grootte',
                license: 'Licentie',
                price: 'Prijs',
                download: 'Download',
                receipt: 'Download factuur',
                batchDownload: 'Batch Download ZIP bestand'
            },
            galleriesOverview: {
                lightTables: 'Lightbox overzicht',
                selection: 'Selectie',
                sortByModificationDate: 'Sorteer op wijzigingsdatum',
                sortByCreationDate: 'Sorteer op aanmaakdatum',
                sortByName: 'Sorteer op naam',
                sortBySize: 'Sorteer op grootte',
                showAll: 'Geef alle lightboxen weer',
                hideAll: 'Verberg alle lightboxen',
                reverseSort: 'Sorteerrichting omkeren',
                hideShowGallery: 'Galerij verbergen / tonen',
                create: 'Nieuwe lightbox aanmaken',
                actions: 'Acties...',
                edit: 'Bewerken',
                delete: 'Verwijderen',
                deleteSelections: 'Selectie verwijderen',
                rename: 'Naam wijzigen',
                email: 'Email',
                duplicate: 'Dupliceren',
                move: 'Verplaatsen',
                copy: 'Kopieren',
                moveTo: 'Verplaatsen naar',
                copyTo: 'Kopieren naar',
                inTheCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelwagen verwijderen',
                download: 'Download',
                numberOfPictures: 'Aantal beelden',
                createdBefore: 'aangemaakt vóór',
                editedBefore: 'bewerkt',
                comment: 'Opmerking',
                optionalComment: 'Opmerking (optioneel)',
                abort: 'Afbreken',
                save: 'Opslaan',
                commentLimit: 'De opmerking is beperkt tot 1000 tekens',
                deleteGallery: 'Weet je zeker dat je deze lightbox wilt verwijderen?',
                deleteWarning: 'Deze actie kan niet ongedaan gemaakt worden',
                name: 'Name',
                renameLightTable: 'Rename lightbox',
                recipientEmailAddress: 'Emailadres ontvanger',
                sendLink: 'Link versturen',
                emailWarning: 'De ontvanger ontvangt een link naar deze lightbox. Bij meerdere ontvangers worden de adressen gescheiden' +
                    ' door een komma.',
                highResDownload: 'Download hoge resolutie',
                createLightboxTitle: 'Nieuwe Lightbox',
                downloadPDF: 'Download als PDF bestand',
                downloadHighRes: 'Download highres  (.ZIP)',
                downloadLowRes: 'Download lowres  (.ZIP)',
                emptyState: 'Nog geen beelden in lightbox',
                cartNote: 'Houd er rekening mee dat u alleen afbeeldingen hoeft te kopen waarvoor u geen downloadabonnement hebt.',
                highResDownloadPlaceholder: 'Voer hier het gebruik voor de lightbox in.',
                createdOn: 'Gemaakt op',
                showMore: 'Laat meer zien',
                showLess: 'Laat minder zien',
                lightboxSearch: {
                    placeholder: 'Zoek in afbeeldingen',
                    notFound: 'Geen afbeeldingen gevonden'
                }
            },
            gridAssetThumb: {
                addToLightbox: 'Aan lightbox toevoegen',
                deleteFromLightBox: 'Uit lightbox verwijderen',
                addToCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelwagen verwijderen'
            },
            lightboxBar: {
                lightTables: 'Lightboxen',
                sortMenuBy: 'Menu sorteren op',
                name: 'Naam',
                creationDate: 'Aanmaakdatum',
                modificationDate: 'Wijziging datum',
                actions: 'Acties...',
                edit: 'Bewerken',
                create: 'Creeer',
                delete: 'Verwijderen',
                rename: 'naam wijzigen',
                email: 'Verzend lightbox-link via email',
                duplicate: 'Dupliceren',
                inTheCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelwagen verwijderen',
                download: 'Download',
                downloadPDF: 'Download als PDF bestand',
                downloadHighRes: 'Download highres (.ZIP)',
                downloadLowRes: 'Download lowres (.ZIP)',
                images: 'Beelden',
                created: 'Gecreeerd',
                changed: 'Gewijzigd',
                optionalComment: 'Opmerking (optioneel)',
                abort: 'Afbreken',
                createLightTable: 'Nieuwe lightbox aanmaken',
                deleteAlert: 'Weet je zeker dat je de lightbox wilt verwijderen?',
                deleteLightTable: 'Lightbox verwijderen',
                deleteWarning: 'Deze actie kan niet ongedaan gemaakt worden',
                renameLightTable: 'Lightbox naam wijzigen',
                recipientEmailAddress: 'Het emailadres van de ontvanger',
                emailLightTableLink: 'Email Lightbox Link',
                emailWarning: 'Ontvanger ontvangt een link naar deze lightbox. Gebruik een komma tussen emailadressen bij' +
                    ' meerdere ontvangers.'
            },
            listAssetThumb: {
                addToLightbox: 'Aan lightbox toevoegen',
                deleteFromLightBox: 'Uit lightbox verwijderen ',
                addToCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelwagen verwijderen',
                name: 'Beeldnummer',
                caption: 'Omschrijving',
                copyright: 'Copyright:',
                license: 'Licentie',
                modified: 'Gewijzigd',
                created: 'Aanmaakdatum'
            },
            orderList: {
                total: 'Totaal',
                shoppingNumber: 'Factuur nummer:',
                offerNumber: 'Offerte nummer:',
                purchased: 'Aangekocht:',
                downloaded: 'Gedownload op',
                viewBill: 'Bekijk factuur',
                viewOffer: 'Bekijk offerte',
                inTheCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelwagen verwijderen',
                download: 'Download',
                licenseRenewal: 'Verlenging van de licentie',
                delete: 'Verwijderen',
                imageId: 'Beeld ID',
                photographer: 'Fotograaf',
                moreFromPhotographer: 'Alle beelden van de fotograaf',
                moreFromCollection: 'Alle beelden van de collectie',
                licenseType: 'Type licentie',
                subscriptionName: 'Naam van abonnement',
                terms: 'Voorwaarden',
                mediaArt: 'Mediatype',
                releases: 'Releases',
                modelReleaseAvailable: 'Model Release Beschikbaar',
                propertyReleaseAvailable: 'Property Release Beschikbaar',
                heading: 'Omschrijving',
                size: 'Grootte',
                license: 'Licentie',
                price: 'Prijs',
                expired: 'Verlopen',
                expires: 'Verloopt',
                emptyState: 'Niet beschikbaar',
                assetNotAvailableAnymore: 'Om juridische redenen wordt deze afbeelding niet langer verstrekt ',
                downloadType: 'Download',
                downloadAsZIP: 'ZIP Download',
                searchPlaceholder: {
                    final: 'Zoeken in facturen',
                    proposal: 'Zoeken in offertes'
                }
            },
            profile: {
                profileSaved: 'Je profiel is opgeslagen',
                email: 'Email',
                newPassword: 'Voer nieuw wachtwoord in',
                confirmNewPassword: 'Bevestig nieuw wachtwoord',
                familyName: 'Achternaam *',
                givenName: 'Voornaam *',
                company: 'Bedrijf *',
                streetAddress: 'Straat *',
                streetAddress2: 'Aanvulling straat',
                city: 'Stad *',
                state: 'State',
                postalCode: 'Postcode *',
                country: 'Land *',
                save: 'Opslaan',
                username: 'Gebruikersnaam',
                taxNumber: 'BTW-nummer',
                business: 'Branche',
                phoneNumber: 'Telefoonnummer *',
            },
            assetSearchPeopleFilter: {
                heading: 'Leeftijdfilter',
                baby: 'Baby',
                children: 'Kinderen',
                youth: 'Tieners',
                adult: 'Volwassenen',
                senior: 'Senioren ',
                mixedAgeGroups: 'Gemengd',
            },
            assetCollectionsFilter: {
                collectionsTitle: 'Collectiefilter',
                addCollection: 'Selecteer collecties',
                noCollections: 'Geen collecties geselecteerd',
            },
            assetCollectionsSelector: {
                submit: 'Pas toe',
                all: 'Alle collecties'
            },
            backgroundColorSwitcher: {
                background: 'Achtergrond'
            },
            webseriesFilter: {
                all: 'Alle'
            },
            previewSwitcher:
                {
                    title: 'Voorvertoning'
                },
            lightboxStickyFooter: {
                itemsInLightbox: '{{value}} items in Lightbox',
                viewLightbox: 'Bekijk lightbox',
                toggleFooter: 'Footer',
                showImprint: 'Imprint',
            },
            business: {
                pleaseSelect: 'Selecteer',
                corporateCustomers: 'Corporate Customers',
                corporatePublishing: 'Corporate Publishing',
                bookPublishing: 'Uitgever',
                calendarPublishing: 'Uitgever',
                merchandising: 'Merchandising',
                advertisingPRCorporatePublishing: 'Advertising / PR / Corporate publishing',
                magazinePublisher: 'Magazine uitgever redactioneel',
                tvAndRadio: 'TV en Radio zenders',
                miscellaneous: 'Divers',
                insurance: 'Verzekering',
                touristicTravelAgencies: 'Toeristisch/Reisbureau',
                finance: 'Bank / Beurs / Financiële dienstverlener',
                education: 'Onderwijs',
                industry: 'Economie / Industrie'
            },
            formErrors: {
                emailNotValid: 'Dit is geen geldig emailadres',
                usernameTaken: 'Gebruikersnaam al in gebruik',
                fieldRequired: 'Dit veld is verplicht',
                validatePasswordConfirmation: 'De wachtwoorden komen niet overeen',
                enterPassword: 'Voer wachtwoord in',
                passwordsNoMatch: 'De wachtwoorden komen niet overeen'
            },
            masonryGridAssetThumb: {
                addToLightbox: 'Aan lightbox toevoegen',
                removeFromLightbox: 'Uit lightbox verwijderen',
                addToCart: 'Aan winkelwagen toevoegen',
                removeFromCart: 'Uit winkelagen verwijderen',
                searchForSimilarImages: 'Zoeken naar overeenkomstige beelden',
            },
            durationPipe: {
                seconds: 'seconden',
                minutes: 'minuten',
                hours: 'uren',
                days: 'dagen',
                years: 'jaren'
            },
            searchBarPopover: {
                text: 'Gebruik aanhalingstekens voor meervoudige namen, bijv. &ldquo;New York&rdquo',
            },
            lightboxAddView: {
                sharedLightbox: 'Gedeelde lightbox',
                assetsInTheLightbox: 'Beelden in lightbox',
                addToLightboxes: 'Aan lightboxen toevoegen',
                infoText: 'Deze lightbox is met u gedeeld. Als u deze aan uw lightboxen wilt toevoegen, klikt u op de knop ' +
                    '"Toevoegen aan lightboxen"',
                notFound: 'Lightbox niet gevonden. Controleer de link en probeer het opnieuw'
            },
            assetSearchPeopleNumberFilter: {
                title: 'Personenfilter',
                any: 'Alle',
                peopleNumber: {
                    peopleNumberZero: 'Geen personen',
                    peopleNumberOne: '1 persoon',
                    peopleNumberTwo: '2 personen',
                    peopleNumberThree: '3 personen',
                    peopleNumberFour: '4 personen',
                    peopleNumberFivePlus: '5+ personen'
                }
            },
            pagination: {
                first: 'Eerste',
                previous: 'Vorige',
                next: 'Volgende',
                last: 'Laatste',
                page: 'Pagina'
            },
            download: {
                placeholder: 'sodatech'
            },
            aiPlus: {
                invalidFile: 'Dit bestand wordt niet ondersteund of is te groot. Alleen JPEG- of PNG-afbeeldingen met een maximale' +
                    ' bestandsgrootte van 6 MB worden geaccepteerd',
                new: 'NIEUW',
                referencePicture: 'Reference Picture',
                resolution: 'Resolution:',
                type: 'Type:',
                url: 'Url:'
            },
            contentTeaser: {
                readMore: "READ MORE",
                showLess: "SHOW LESS"
            }
        }
    },
};

export class SodatechTranslateLoaderWrapper implements TranslateLoader {

    constructor(private loader: TranslateLoader) {
    }

    getTranslation(lang: string): Observable<any> {
        return this.loader.getTranslation(lang).pipe(
            map(translations => {
                return _merge(SDK_DEFAULT_TRANSLATIONS[lang] || {}, translations);
            })
        );
    }

}
