import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    ViewChild,
    OnChanges,
    AfterViewInit, HostBinding, Inject, EventEmitter, Output, OnDestroy, HostListener, PLATFORM_ID
} from '@angular/core';
import {SwiperConfigInterface, SwiperComponent} from 'ngx-swiper-wrapper';

import {DomSanitizer, SafeStyle, SafeUrl} from '@angular/platform-browser';

import {WebseriesService} from '../../services/webseries.service';
import {Webseries} from '../../models/webseries';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {isPlatformServer} from '@angular/common';
import {CMSService} from '../../services/cms.service';
import {BreakpointService} from '../../services/breakpoint.service';
import { Breakpoint } from '../../models/breakpoint.enum';

export interface WebSeriesSliderConfig {
    sliderTitle: string;
    sliderDescription: string;
    webSeriesId: number;
    showWebseriesOverlay?: boolean;
    showTitle: boolean;
    showDescription: boolean;
    titleSource?: 'series' | 'asset';
    descriptionSource?: 'series' | 'asset';
    thumbnails: boolean;
    loop?: boolean;
    sliderHeight: string;
    breakPointHeights?: {
        mobile: string;
        tablet: string;
        desktop: string;
    };
    thumbsSliderHeight?: string;
    thumbCount?: number;
    slidesLength?: number;
    effect?: 'slide' | 'fade';
    duration?: number;
    transition?: number;
    showAutoplayControls?: boolean;
    slideContentControl?: boolean;
}

export interface WebSeriesSlide {
    title?: string;
    description?: string;
    slideBackgroundUrl: string;
    thumbBackgroundUrl: string;
}

@Component({
    selector: 'st-web-series-slider',
    templateUrl: './webseries-slider.component.html',
    styleUrls: ['./webseries-slider.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WebseriesSliderComponent
    implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    public slides: WebSeriesSlide[] = [];

    @ViewChild('slider') slider?: SwiperComponent;
    @ViewChild('thumbsSlider') thumbsSlider?: SwiperComponent;

    @Input() config: WebSeriesSliderConfig;

    @Input() webseries: Webseries;

    @Input() skipLangChange = false;

    @Input() overlayPositioning: WebseriesOverlayPositioning;

    public sliderConfig: SwiperConfigInterface;

    thumbsConfig: SwiperConfigInterface;

    loading: boolean;

    _webseries: Webseries;

    isAutoplaying = true;

    needToRestartAutoplay = false;

    lastAutoplayRestartTimeout: any = null;

    firstImageLoaded = false;

    @HostBinding('style.minHeight')
    get sliderMinHeight() {
        return this.config.sliderHeight;
    }

    @Output() slideClick: EventEmitter<void> = new EventEmitter();

    dataSubscription: Subscription;

    breakpointSubscription: Subscription;

    showSlideContent = false;

    constructor(
        private sanitizer: DomSanitizer,
        private webseriesService: WebseriesService,
        private translateService: TranslateService,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
        @Inject(PLATFORM_ID) private platformId: Object,
        private breakpointService: BreakpointService
    ) {
    }

    ngOnInit() {
        // this.initialize();
        this.dataSubscription = this.translateService.onLangChange.subscribe(() => {
            if (!this.skipLangChange) {
                this.initialize();
            }
        });
    }

    ngOnChanges() {
        this.firstImageLoaded = false;
        this.initialize();
    }

    ngAfterViewInit() {
    }

    initBreakpointSubscription() {
        if (this.breakpointSubscription) this.breakpointSubscription.unsubscribe();
        this.breakpointSubscription = this.breakpointService.getCurrentBreakpoint().subscribe(data => {
            if (this.config.breakPointHeights) {
                if (data === Breakpoint.Mobile) {
                    this.config.sliderHeight = this.config.breakPointHeights.mobile;
                    if (this.thumbsConfig) {
                        this.thumbsConfig.slidesPerView = 3;
                    }
                } else if (data === Breakpoint.Tablet) {
                    this.config.sliderHeight = this.config.breakPointHeights.tablet;
                    if (this.thumbsConfig) {
                        this.thumbsConfig.slidesPerView = 5;
                    }
                } else if (data === Breakpoint.Desktop) {
                    this.config.sliderHeight = this.config.breakPointHeights.desktop;
                    if (this.thumbsConfig) {
                        this.thumbsConfig.slidesPerView = 11;
                    }
                }
            }
        });
    }

    async initialize() {
        const {
            sliderTitle,
            sliderDescription,
            webSeriesId,
            showTitle,
            showDescription,
            titleSource,
            descriptionSource
        } = this.config;
        this.loading = true;
        if (!this.webseries) {
            this._webseries = {...await this.webseriesService.getWebserie(webSeriesId)};
        } else {
            this._webseries = {...this.webseries};
        }

        if (sliderTitle) {
            this._webseries.title = sliderTitle;
        }

        if (sliderDescription) {
            this._webseries.description = sliderDescription;
        }

        this.loading = false;
        // configuring the slides
        const slides = this._webseries.assets.filter(asset => !!asset.asset).map(({asset, id}) => {
            const thumbMedia = asset.associatedMedia
                .find(media => media.additionalType === 'thumb');
            const thumbUrl = (thumbMedia) ? thumbMedia.contentUrl : '';
            const map = {
                title: {
                    asset: asset.bylineTitle,
                    series: this._webseries.title
                },
                description: {
                    asset: asset.caption,
                    series: this._webseries.description
                }
            };
            const title = !!showTitle ? map.title[titleSource] : null;
            const description = !!showDescription
                ? map.description[descriptionSource]
                : null;
            return {
                title,
                description,
                slideBackgroundUrl: this.getAssetBackgroundUrl(id),
                thumbBackgroundUrl: thumbUrl
            };
        });

        this.slides = this.config.slidesLength
            ? slides.slice(0, this.config.slidesLength)
            : slides;

        this.sliderConfig = {
            a11y: true,
            direction: 'horizontal',
            slidesPerView: 1,
            pagination: false,
            observer: true,
            navigation: true,
            centeredSlides: true,
            loop: true,
            loopedSlides: this.slides.length,
            init: false,
            autoplay: {
                delay: this.config.duration || 3000,
                disableOnInteraction: false
            },
            lazy: {
                loadPrevNext: true,
                loadOnTransitionStart: true,
                loadPrevNextAmount: 3
            },
            preloadImages: false,
            effect: this.config.effect || 'slide',
            speed: this.config.transition || 300,
            touchEventsTarget: 'wrapper',
            preventInteractionOnTransition: false
        };

        if (this.config.thumbnails) {
            // Setup thumbnails
            this.thumbsConfig = {
                slidesPerView: this.config.thumbCount || 'auto',
                spaceBetween: 5,
                pagination: false,
                observer: true,
                //slideToClickedSlide: true,
                centeredSlides: true,
                loop: true,
                loopedSlides: this.slides.length,
                init: false,
                speed: this.config.transition || 300,
                preventInteractionOnTransition: false
            };

            setTimeout(() => {
                if (this.slider) this.slider.directiveRef.swiper().controller.control = this.thumbsSlider.directiveRef.swiper();
                if (this.thumbsSlider) this.thumbsSlider.directiveRef.swiper().controller.control = this.slider.directiveRef.swiper();
                if (this.slider) this.slider.directiveRef.init();
                if (this.thumbsSlider) this.thumbsSlider.directiveRef.init();
                if (!this.isAutoplaying) {
                    this.stopAutoPlay();
                }

                if (this.thumbsSlider) {
                    this.thumbsSlider.directiveRef.swiper().loopDestroy();
                    this.thumbsSlider.directiveRef.swiper().loopCreate();
                }
            }, 1000);
        } else {
            setTimeout(() => {
                if (this.slider) this.slider.directiveRef.init();

                if (!this.isAutoplaying) {
                    this.stopAutoPlay();
                }
            }, 1000);
        }
        this.initBreakpointSubscription();
    }

    getAssetBackgroundUrl(assetId): string {
        const baseUrl = this.sdkOptions.imgPath;
        return `${baseUrl}webseries/${this.config.webSeriesId}/assets/${assetId}/1920`;
    }

    getAssetBackgroundStyle(url: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
    }

    onClick(e: Event) {
        e.preventDefault();
        this.slideClick.next();
    }

    startAutoPlay() {
        this.slider.directiveRef.startAutoplay();
        this.isAutoplaying = true;
    }

    stopAutoPlay() {
        this.slider.directiveRef.stopAutoplay();
        this.isAutoplaying = false;
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();
    }

    onThumbClick(e: Event) {
        e.preventDefault();
        this.needToRestartAutoplay = this.isAutoplaying || this.needToRestartAutoplay;
        this.stopAutoPlay();
        const clickedSlide = this.thumbsSlider.directiveRef.swiper().clickedIndex;
        this.thumbsSlider.directiveRef.swiper().slideTo(clickedSlide, 500);
        if (this.needToRestartAutoplay) {
            if (this.lastAutoplayRestartTimeout) {
                clearTimeout(this.lastAutoplayRestartTimeout);
            }
            this.lastAutoplayRestartTimeout = setTimeout(() => {
                this.startAutoPlay();
                this.needToRestartAutoplay = false;
            }, this.config.transition || 300);
        }
    }

    onThumbMove(e: Event) {
        this.needToRestartAutoplay = this.isAutoplaying || this.needToRestartAutoplay;
        this.stopAutoPlay();
    }

    onThumbMoveEnd(e: Event) {
        if (this.needToRestartAutoplay) {
            if (this.lastAutoplayRestartTimeout) {
                clearTimeout(this.lastAutoplayRestartTimeout);
            }
            this.lastAutoplayRestartTimeout = setTimeout(() => {
                this.startAutoPlay();
                this.needToRestartAutoplay = false;
            }, this.config.transition || 300);
        }
    }

    onLazyImageLoaded([el]: HTMLElement[]) {
        const currentSlide = this.slider.directiveRef.swiper().realIndex;
        const loadedIndex = parseInt(el.dataset['swiperSlideIndex'], 10);
        this.firstImageLoaded = (currentSlide === loadedIndex) || this.firstImageLoaded;
    }

    hideSlideContent() {
        this.showSlideContent = !this.showSlideContent;
    }
}

export interface WebseriesOverlayPositioning {
    horizontal: string;
    vertical: string;
}
