import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Lightbox } from '../../models/lightbox';
import { LightboxDataService } from '../../services/lightbox-data.service';
import { PreferencesService } from '../../services/preferences.service';
import { LightboxService } from '../../services/lightbox.service';
import { UserLoginService } from '../../services/user-login.service';
import {Subscription} from 'rxjs';
import {DragulaService} from 'ng2-dragula';

/**
 * Component that displays the lightbox bar
 */
@Component({
    selector: 'st-lightbox-bar',
    templateUrl: './lightbox-bar.component.html',
    styleUrls: ['./lightbox-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LightboxBarComponent implements OnInit {

    @Input() lightboxId: number = null;

    @Output() showAssetDetails: EventEmitter<string> = new EventEmitter<string>();

    public lightboxes: Lightbox[] = [];
    public lightbox: Lightbox;

    public action = '';
    public errorMessage: string = null;

    public sortColumn: string;
    public visible = false;
    public showLightboxBar = true;
    public tabOpen = true;

    subs = new Subscription();

    constructor(
        private lightboxDataService: LightboxDataService,
        private lightboxService: LightboxService,
        private preferenceService: PreferencesService,
        public userLogin: UserLoginService,
        private dragulaService: DragulaService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.preferenceService.observe('lb_visible').subscribe(isVisible => {
            this.visible = !!isVisible;
        });

        this.lightboxDataService.getSelectedLightboxObservable().subscribe(changedLightbox => {
            this.lightbox = changedLightbox;
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showLightboxBar = (this.router.url.substr(0, 8) !== '/gallery');
            }
        });
    }


    changeVisibility() {
        this.visible = !this.visible;
        this.preferenceService.set('lb_visible', this.visible);

        if (!this.visible) {
            this.tabOpen = false;
            window.setTimeout(() => this.tabOpen = true, 3000);
        }
    }

    isVisible() {
        return this.visible;
    }

    showAssetDetail(assetId: any): void {
        this.showAssetDetails.next(assetId);
    }
}
