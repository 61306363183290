import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {Webseries} from '../models/webseries';
import {Marketing} from '../models/marketing';

/**
 * Services for loading the webseries and specific webserie
 */
@Injectable()
export class MarketingService {
    private path = 'marketing';

    constructor(private http: SodaApiService) {}

    /**
     * Get the webserie from the api with the specified webserie id
     * @param id
     */
    public getMarketing(): Promise<Marketing[]> {
        const url = `${this.path}`;
        return this.http
            .get(url)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);

        return Promise.reject(error.message || error);
    }
}
