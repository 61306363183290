import {LightboxAsset} from './lightbox_asset';

/**
 * The lightbox application model
 */
export class Lightbox {
    id: number;
    name: string;
    comment: string;
    creationDate: Date;
    modificationDate: Date;
    assets: LightboxAsset[];
    isActive?: boolean;
}
