import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CollectionService } from './collection.service';
import { Collection } from '../models/collection';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs/internal/observable/from';
import { concatMap, filter, map, pluck, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Service that interacts with the collection api using CollectionService
 * Caches the collections once and returns them instead of calling the api again
 */
@Injectable()
export class CollectionDataService {
    private collections: Promise<Collection[]> = null;
    selectedCollections$ = new BehaviorSubject<string[]>([]);
    collectionsChanged$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    isSelectedCollectionsChanged = false;

    constructor(private route: ActivatedRoute, private collectionService: CollectionService) {
        this.route.queryParams
            .pipe(
                pluck('coll'),
                tap(coll => {
                    if (!coll && !!this.selectedCollections$.value) {
                        this.isSelectedCollectionsChanged = true;
                        this.selectedCollections$.next([]);
                    }
                }),
                filter(Boolean),
                map(coll => Array.isArray(coll) ? coll : [coll]),
                map(coll => coll.reduce((collections, collectionId) => {
                    return [...collections, ...collectionId.split(';')];
                }, []))
            ).subscribe(selectedCollections => {
                this.isSelectedCollectionsChanged = true;
                this.selectedCollections$.next(selectedCollections);
            });
    }

    /**
     * Get collections from api
     * @returns
     */
    public getCollections(forceUpdate = false): Promise<Collection[]> {
        const selectedCollections = this.selectedCollections$.getValue();
        if (this.collections === null || forceUpdate) {
            this.collections = this.collectionService.getCollections()
                .then(collections => {
                    this.collectionsChanged$.next(null);
                    return collections.map(collection => {
                        return {
                            ...collection,
                            isSelected: !!selectedCollections.find(selectedCollection => selectedCollection === collection.id)
                        };
                    });
                });
        }

        if ( this.isSelectedCollectionsChanged ) {
            this.isSelectedCollectionsChanged = false;
            this.collections = this.collections.then(collections => collections.map(collection => ({
                ...collection,
                isSelected: !!selectedCollections.find(selectedCollection => selectedCollection === collection.id)
            })));
        }

        return this.collections;
    }

    /**
     * Get collections grouped by their copyright
     */
    public async getCollectionsGroupedByCopyright(): Promise<{ [copyright: string]: Collection[] }> {
        const collections = await this.getCollections();
        return collections.reduce((copyrights, collection) => {
            copyrights[collection.copyright] = copyrights[collection.copyright] || [];
            copyrights[collection.copyright].push(collection);
            return copyrights;
        }, {});
    }

    public async getCopyrightCollections(): Promise<Partial<Collection>[]> {
        const collections = await this.getCollectionsGroupedByCopyright();
        return Object.keys(collections)
            .map(copyright => {
                const copyrightCollections = collections[copyright];
                const collectionIds = copyrightCollections.map(coll => coll.id);
                const isSelected = copyrightCollections.reduce((selected, collection) => {
                    return selected || collection.isSelected;
                }, false);
                return {
                    id: collectionIds.join(';'),
                    name: copyright,
                    isSelected
                } as Partial<Collection>;
            });
    }

}
