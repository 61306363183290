import {
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnInit
} from '@angular/core';
import { CMSService } from '../../services/cms.service';
import { CMSBlockContentComponent, CmsMenu } from '../../models/cms.model';

@Component({
    selector: 'st-cms-menu',
    templateUrl: './cms-menu.component.html',
    styleUrls: ['./cms-menu.component.css']
})
export class CmsMenuComponent
    implements OnInit, OnChanges, CMSBlockContentComponent {
    @Input() slug: string;
    @Input() lang: string;
    @Input() isDraftView: boolean;
    @Input() data: CmsMenu;
    @Input() children: any[];

    config: any;

    @HostBinding('class.cms-menu') rootClassName = true;

    constructor(private cmsService: CMSService) {}

    private async loadConfig() {
/*        if (this.slug && this.lang) {
            const loadFunction = this.isDraftView
                ? this.cmsService.loadDraftConfig.bind(this.cmsService)
                : this.cmsService.loadConfig.bind(this.cmsService);
            this.config = await loadFunction(this.slug, this.lang);
            console.log(this.config);
        }*/
    }

    ngOnInit(): void {}

    ngOnChanges() {
        // this.loadConfig();
    }
}
