import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../../services/user.service';

/**
 * Component for request password reset
 */
@Component({
  selector: 'st-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements OnInit {

    public form: FormGroup = null;
    public formErrors: any = {};
    public success = false;

    @Input() noEmailAllowed: boolean;

    @Output() requestPasswordResetSuccessful: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private userService: UserService,
        private fb: FormBuilder
    ) {
        this.form = fb.group({
            email: new FormControl('')
        });
    }

    ngOnInit() {
    }

    /**
     * Called when the user clicks the reset password button
     */
    public sendToken() {
        const email = this.form.getRawValue().email;
        if (!email) {
            return;
        }
        if (this.noEmailAllowed) {
            if (email.indexOf('@') !== -1) {
                this.formErrors.email = ['sodatechSdk.requestPasswordReset.emailNotAllowed'];
            } else {
                this.sendResetpasswordRequest(email);
            }
        } else {
            this.sendResetpasswordRequest(email);
        }
    }

    sendResetpasswordRequest(email: string) {
        this.userService.requestPasswordResetToken(email).then(
            (data) => {
                this.success = true;
                this.requestPasswordResetSuccessful.next();
            },
            (data) => {
                data = data.error;
                if (data.violations) {
                    this.formErrors = data.violations;
                } else if (data.message === 'Could not send mail') {
                    this.formErrors.email = ['sodatechSdk.requestPasswordReset.generalError'];
                }
            }
        );
    }

}
