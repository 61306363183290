import {Category} from './category';
import {Supplier} from './supplier';
import {Collection} from './collection';
import {ContentLocation} from './content_location';
import {AssociatedMedia} from './associated_media';

/**
 * The asset application model
 */
export class Asset {
    id: string;
    fileFormat: string;
    supplier: Supplier;
    originalType: string;
    alternateName: string;
    width: number;
    height: number;
    collection: Collection;
    onlyEditorialUsage: boolean;
    uploadDate: Date;
    activationTime: Date;
    name: string;
    dateModified: Date;
    color: boolean;
    orientation: string;
    exclusive: boolean;
    exclusivePrice: number;
    photographerCode: string;
    copyright: string;
    license: string;
    specificSupplierProvisionPercentageState: string;
    specificSupplierProvisionPercentage: string;
    modelReleased: number;
    propertyReleased: number;
    aggregateRating: number;
    categories: Category[];
    byline: string;
    bylineTitle: string;
    contentLocation: ContentLocation[];
    transmissionreference: string;
    copyrightHolder: string;
    caption: string;
    keywords: string[];
    dateCreated: Date;
    objectName: string;
    headline: string;
    source: string;
    creditLine: string;
    associatedMedia: AssociatedMedia[];
    optionalFilesize: number;
    restrictions?: AssetRestriction[];
    subscriptions?: SubscriptionItem[];
    author: any;
    specialInstructions: string;
    invisible?: boolean;
}

export class AssetRestriction {
    branchIds: number[];
    countryCodes: string[];
    endDate: string;
    exclusivity: number;
    startDate: string;
    type: 'block_internaly' | 'block' | 'reservation';
}

export class SubscriptionItem {
    currency: string;
    price: string;
    subscriptionId: number;
    title: string;
}
