import {Injectable} from '@angular/core';
import {HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LangInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

        const acceptLanguageHeader = req.headers.get('Accept-Language');

        if (!acceptLanguageHeader) {
            return next.handle(req);
        }

        const request = req.clone({
            params: req.params.append('lang', acceptLanguageHeader)
        });

        return next.handle(request);
    }
}
