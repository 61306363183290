import {Component, ElementRef, HostBinding, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { Params, Router } from '@angular/router';
import { CmsLinkTypes } from '../../models/cms.model';
import { CmsBlockContent } from '../cms-block/cms-block-content';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'st-cms-button',
    templateUrl: './cms-button.component.html'
})
export class CmsButtonComponent extends CmsBlockContent implements OnInit {

    @HostBinding('class.cms-button') rootClassName = true;

    @HostBinding('class.text-left') btnLeft = false;

    @HostBinding('class.text-center') btnCenter = false;

    @HostBinding('class.text-right') btnRight = false;

    classes: string;

    queryParamsObj: Params = {};

    hovered: boolean;

    linkTypes = CmsLinkTypes;

    constructor(el: ElementRef, private router: Router, @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
                @Inject(DOCUMENT) private document: any, @Inject(PLATFORM_ID) private platformId: Object) {
        super(el);
    }

    ngOnInit() {
        if (this.data.queryParams) {
            this.queryParamsObj = JSON.parse(this.data.queryParams);
        }
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }

        if (this.data.position) {
            switch (this.data.position) {
                case 'left':
                    this.btnLeft = true;
                    break;
                case 'center':
                    this.btnCenter = true;
                    break;
                case 'right':
                    this.btnRight = true;
                    break;
            }
        }
    }

    scrollToAnchor() {
        if (isPlatformBrowser(this.platformId) && this.data.anchor) {
            const yOffset = -this.sdkOptions.ui.scrollToAnchorOffset;
            const element = this.document.querySelector(`#${this.data.anchor}`);

            if ( element ) {
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        }
    }
}
