import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset } from '../../models/asset';
import { Article } from '../../models/article';

/**
 * Modal component that renders the specified asset / article.
 */
@Component({
    selector: 'st-asset-modal-detail',
    templateUrl: './asset-modal-detail.component.html',
    styleUrls: ['./asset-modal-detail.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AssetModalDetailComponent implements OnInit {
    @Input()
    asset: Asset;
    @Input()
    article: Article;
    @Output()
    close = new EventEmitter<any>();

    public assetId = null;

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        if (!this.asset && !this.article) {
            this.route.params.subscribe(params => {
                this.assetId = params['assetId'];
            });
        }
    }

    /**
     * Closes asset modal detail
     */
    closeDetail() {
        if (!this.asset && !this.article) {
            this.router.navigate([{ outlets: { modal: null } }], {
                queryParamsHandling: 'merge'
            });
        } else {
            const assetId =
                this.asset && this.asset.id
                    ? this.asset.id
                    : this.article.asset.id;

            this.close.emit({
                id: assetId
            });
        }
    }
}
