import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Webseries } from '../../models/webseries';
import { ImageUtilsService } from '../../services/image-utils.service';

/**
 * Component that displays a thumb of the webseries
 */
@Component({
    selector: 'st-webseries-thumb',
    templateUrl: './webseries-thumb.component.html',
    styleUrls: ['./webseries-thumb.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WebseriesThumbComponent implements OnChanges {
    @Input()
    webseries: Webseries = null;
    @Input()
    width: any = 'auto';
    @Input()
    height: any = '300px';
    @Input()
    marginWidth: any = '0px';

    @HostBinding('style.width')
    styleWidth: any = '300px';
    @HostBinding('style.height')
    styleHeight: any = '300px';

    private imageWidth = 0;
    private imageHeight = 0;

    private loading = false;
    private newSize = null;

    public backgroundImage = '';

    public thumbWidth: number;
    public thumbHeight: number;

    constructor(
        private hostElement: ElementRef,
        private sanitizer: DomSanitizer,
        private imageUtils: ImageUtilsService
    ) {}

    ngOnChanges(changes: any) {
        if (changes.webseries && changes.webseries.currentValue) {
            const webseries = changes.webseries.currentValue;

            this.loading = true;
            this.imageUtils.getSizeByUrl(webseries.coverUrl).then(data => {
                this.loading = false;

                if (data.success) {
                    this.backgroundImage = 'url(' + webseries.coverUrl + ')';
                    this.imageWidth = data.width;
                    this.imageHeight = data.height;
                }

                if (this.newSize) {
                    this.setNewSize(this.newSize.width, this.newSize.height);
                    this.newSize = null;
                }
            });
        }

        if (
            (changes.width && changes.width.currentValue) ||
            (changes.height && changes.height.currentValue)
        ) {
            const newWidth = changes.width
                ? changes.width.currentValue
                : this.styleWidth;
            const newHeight = changes.height
                ? changes.height.currentValue
                : this.styleHeight;

            this.setNewSize(newWidth, newHeight);
        }
    }

    /**
     * Sets a new size for the thumb
     * @param width
     * @param height
     */
    setNewSize(width: any, height: any): void {
        if (this.loading) {
            this.newSize = {
                width: width,
                height: height
            };
            return;
        }

        if (width === 'auto') {
            const factor = this.imageWidth / this.imageHeight;
            width = factor * parseInt(height, 10);
            width += 'px';
        } else if (height === 'auto') {
            const factor = this.imageHeight / this.imageWidth;
            height = factor * parseInt(width, 10);
            height += 'px';
        }

        const marginWidth =
            parseInt(this.marginWidth ? this.marginWidth : '0', 10) + 'px';

        this.styleWidth = this.sanitizer.bypassSecurityTrustStyle(
            'calc(' + width + ' - ' + marginWidth + ')'
        );
        this.styleHeight = this.sanitizer.bypassSecurityTrustStyle(height);

        this.setThumbWidthInfo();
    }

    setThumbWidthInfo() {
        window.setTimeout(() => {
            if (
                this.hostElement.nativeElement &&
                this.hostElement.nativeElement.offsetWidth
            ) {
                this.thumbWidth = Number(
                    this.hostElement.nativeElement.offsetWidth
                );
                this.thumbHeight = Number(
                    this.hostElement.nativeElement.offsetHeight
                );
            }
        }, 50);
    }
}
