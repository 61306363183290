import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DownloadLogResponse} from '../models/download-log';
import {
    AI_PLUS_FILE,
    AI_PLUS_FILE_URL,
    AI_PLUS_IDS,
    AI_PLUS_IMAGE_META_INFORMATION,
    readFromLocalStorage, removeFromLocalStorage,
    writeToLocalStorage
} from '../helpers/local-storage.helper';
import {base64ImageToBlob, convertToBase64} from '../helpers/base64.helper';
import {compress, getImageDimensions, getImageFileUrl} from '../helpers/compress-image.helper';
import {EventTrackerEvents, EventTrackerService} from './event-tracker.service';
import {isPlatformBrowser} from '@angular/common';
import {SDK_OPTIONS, SdkOptions} from '../models/sdk-options';
import * as FormData from 'form-data';

/**
 *
 * Service that interacts with downloadlogs api
 */
@Injectable()
export class ImageSimilarityService {

    private API_PATH = this.sdkOptions.similaritySearch.api;

    isUploadingImage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    similarityImageIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

    imageFile: BehaviorSubject<Blob> = new BehaviorSubject<File>(null);
    imageMetaInformation: BehaviorSubject<ImageMetaInformation> = new BehaviorSubject<ImageMetaInformation>(null);
    imageUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    similarImages: string[];
    similarityDataReadFromLocaleStorage: boolean;

    imageSimilarityProcessingError: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private compressedFile: Blob;

    constructor(private http: HttpClient, private eventTrackerService: EventTrackerService, @Inject(PLATFORM_ID) private platformId: Object,  @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {
        if (isPlatformBrowser(this.platformId)) {
            const ids = readFromLocalStorage<string[]>(AI_PLUS_IDS);
            this.similarityDataReadFromLocaleStorage = ids && !!ids.length;
            if (this.similarityDataReadFromLocaleStorage) {
                const file = readFromLocalStorage<string>(AI_PLUS_FILE);
                const imageInformation = readFromLocalStorage<ImageMetaInformation>(AI_PLUS_IMAGE_META_INFORMATION);
                const assetUrl = readFromLocalStorage<string>(AI_PLUS_FILE_URL);
                if (file) {
                    this.imageFile.next(base64ImageToBlob(file));
                }
                if (imageInformation) {
                    this.imageMetaInformation.next(imageInformation);
                }
                if (assetUrl) {
                    this.imageUrl.next(assetUrl);
                    this.extractImageData(null, assetUrl);
                }
                this.similarityImageIds$.next(ids);
                this.similarImages = ids;
            }
        }
    }

    getSimilaryImageFile(): Observable<Blob> {
        return this.imageFile.asObservable();
    }

    getSimilaryImageUrl(): Observable<string> {
        return this.imageUrl.asObservable();
    }

    getImageMetaInformation(): Observable<ImageMetaInformation> {
        return this.imageMetaInformation.asObservable();
    }

    getSimilarImagesAsObservable(): Observable<string[]> {
        return this.similarityImageIds$.asObservable();
    }

    getSimilarImages(): string[] {
        return this.similarImages;
    }

    clearSimilarityResults() {
        if (isPlatformBrowser(this.platformId)) {
            removeFromLocalStorage(AI_PLUS_IDS);
            removeFromLocalStorage(AI_PLUS_FILE);
            removeFromLocalStorage(AI_PLUS_IMAGE_META_INFORMATION);
            removeFromLocalStorage(AI_PLUS_FILE_URL);
            this.similarImages = null;
            this.similarityImageIds$.next(null);
            this.imageMetaInformation.next(null);
            this.imageFile.next(null);
            this.imageUrl.next(null);
        }
    }

    /**
     * Get similarity for url
     * @param url
     */
    public getSimilarityForImageUrl(url: string, similarItemsQtty = 100) {
        this.imageSimilarityProcessingError.next(null);
        this.isUploadingImage$.next(true);

        const formData = new FormData();
        formData.append('image_url', url);
        formData.append( 'similar_items', String(similarItemsQtty));
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.AI_PLUS_SEARCH);

        return this.getSimilarityImages(formData).toPromise().then((data: any) => {
            this.clearSimilarityResults();
            this.isUploadingImage$.next(false);
            const ids = data.data.map(item => item[2]);
            if (isPlatformBrowser(this.platformId)) {
                writeToLocalStorage(AI_PLUS_IDS, ids);
                writeToLocalStorage(AI_PLUS_FILE_URL, decodeURIComponent(url));
            }
            this.similarityDataReadFromLocaleStorage = false;
            this.extractImageData(null, url);
            this.similarityImageIds$.next(ids);
            this.similarImages = ids;
        }).catch(error => {
            this.handleError(error);
        });

    }

    getSimilarityImages(formData: FormData) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.http.post(`${this.API_PATH}/image_search`, formData, {
            headers
        });
    }

    /**
     * Get similariytForImage
     * @param file
     */
    public async getSimilarityForImage(file: File) {
        this.imageSimilarityProcessingError.next(null);
        this.isUploadingImage$.next(true);
        //await this.extractImageData(await fixFileRotation(file), null);
        await this.extractImageData(file, null);

        const formData = new FormData();
        formData.append('image_file', this.compressedFile);
        formData.append( 'similar_items', '100');
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.AI_PLUS_SEARCH);

        return this.getSimilarityImages(formData).toPromise().then((data: any) => {
            this.isUploadingImage$.next(false);
            const ids = data.data.map(item => item[2]);
            if (isPlatformBrowser(this.platformId)) {
                removeFromLocalStorage(AI_PLUS_FILE_URL);
                writeToLocalStorage(AI_PLUS_IDS, ids);
            }
            this.similarityDataReadFromLocaleStorage = false;
            this.similarityImageIds$.next(ids);
            this.similarImages = ids;
        }).catch(error => {
            this.handleError(error);
        });
    }

    async extractImageData(file: File, url: string): Promise<any> {
        const fileUrl = file ? await getImageFileUrl(file) : url;
        const dimensions = await getImageDimensions(fileUrl);
        const metaInformation = {
            height: dimensions.height,
            width: dimensions.width,
            type: file ? file.type : null,
            url: url ? decodeURIComponent(fileUrl) : null
        };
        this.imageMetaInformation.next(metaInformation);
        if (isPlatformBrowser(this.platformId)) {
            writeToLocalStorage(AI_PLUS_IMAGE_META_INFORMATION, metaInformation);
        }
        if (file) {
            this.compressedFile = await compress(file, dimensions);
            this.imageFile.next(this.compressedFile);
            this.storeImageFile(this.compressedFile);
        } else if (url) {
            this.imageUrl.next(url);
        }
    }

    isUploadingImage(): Observable<boolean> {
        return this.isUploadingImage$.asObservable();
    }

    getImageSimilarityProcessingError(): Observable<string> {
        return this.imageSimilarityProcessingError.asObservable();
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        this.imageSimilarityProcessingError.next('API_ERROR');
        return Promise.reject(error.message || error);
    }

    private async storeImageFile(blob: Blob) {
        const data = await convertToBase64(blob);
        if (isPlatformBrowser(this.platformId)) {
            writeToLocalStorage(AI_PLUS_FILE, data);
        }
    }

    isSimilarityDataReadFromLocalStorage(): boolean {
        return this.similarityDataReadFromLocaleStorage;
    }

    publishNewTrackingEvent(event: any) {
        this.eventTrackerService.pushNewEvent(event);
    }
}

export interface ImageSmilarityEntry {
    name: string;
    id: string;
}

export interface ImageMetaInformation {
    width: string;
    height: string;
    type: string;
    url: string;
}
