import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';

@Component({
    selector: 'st-preview-switcher',
    templateUrl: './preview-switch.component.html',
    styleUrls: ['./preview-switch.component.scss']
})
export class PreviewSwitchComponent extends AssetFilterPartialComponent
    implements OnInit {

    @Input() showPreview;

    constructor() {
        super();
    }

    ngOnInit() {}

    onPreviewChange($event) {
        this.changes.emit(this.showPreview);
    }
}
