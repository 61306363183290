import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding, HostListener, Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Lightbox} from '../../models/lightbox';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LightboxDataService} from '../../services/lightbox-data.service';
import {LightboxService} from '../../services/lightbox.service';
import {PreferencesService} from '../../services/preferences.service';
import {UserLoginService} from '../../services/user-login.service';
import {NavigationEnd, Router} from '@angular/router';
import {DragulaService} from 'ng2-dragula';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {LightboxAsset} from '../../models/lightbox_asset';
import {TranslateService} from '@ngx-translate/core';
import {DragAndDropService} from '../../services/drag-and-drop.service';
import {base64ImageToBlob} from '../../helpers/base64.helper';
import {containsFiles} from '../image-similarity-overlay/image-similarity-overlay.component';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';

@Component({
    selector: 'st-lightbox-galleries',
    templateUrl: './lightbox-galleries.component.html',
    styleUrls: ['./lightbox-galleries.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LightboxGalleriesComponent implements OnInit, OnDestroy {

    @HostBinding('class.lightbox-galleries-root') mainClass = true;

    @Input() lightboxId: number = null;

    hasFileOver: boolean;

    @Input() set enableDragAndDrop(enable: boolean) {
        if (enable) {
            this.dragAndDropService.initAssetListDragAndDrop();
        }
    }

    @Input() set showAssetList(state: true) {
        this._showAssetList = state;
        setTimeout(() => {
            this.dragAndDropService.setLightboxBarContainerRef(this.dragulaContainerRef);
        }, 300);
    }

    @Output() showAssetDetails: EventEmitter<string> = new EventEmitter<string>();

    @Output() drag: EventEmitter<void> = new EventEmitter();

    @Output() drop: EventEmitter<void> = new EventEmitter();

    @Output() cancel: EventEmitter<void> = new EventEmitter();

    _showAssetList: boolean;

    public lightboxes: Lightbox[] = [];
    public lightbox: Lightbox;

    public form: FormGroup;
    public formCreate: FormGroup;
    public formSend: FormGroup;
    public formRename: FormGroup;

    public action = '';
    public errorMessage: string = null;

    public sortColumn: string;
    public visible = false;
    public showLightboxBar = true;
    public tabOpen = true;

    subs = new Subscription();

    @ViewChild('dragulaContainer') dragulaContainerRef: ElementRef;

    selectedAssets = {};
    lastSelectedAsset: string = null;

    showDetailId: number;
    showDetailAsset: any;

    public lightboxSelections = {};

    currentlySelectedAssets: any[] = [];

    dataSubscription: Subscription;

    loadingLighbox: boolean = false;

    constructor(
        private lightboxDataService: LightboxDataService,
        private lightboxService: LightboxService,
        private preferenceService: PreferencesService,
        private fb: FormBuilder,
        public userLogin: UserLoginService,
        private dragulaService: DragulaService,
        private router: Router,
        private translate: TranslateService,
        private dragAndDropService: DragAndDropService,
        @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions
    ) {
        this.createForm();
        this.subs.add(this.dragAndDropService.droppedInLightboxBar().pipe(filter(Boolean))
            .subscribe((value) => {
                this.drop.next();
                value.target.removeChild(value.el);
                const assetId = value.el['dataset']['assetid'];
                if (!this.lightboxDataService.isAssetInLightbox(this.lightboxDataService.getSelectedLightboxId(), assetId)) {
                    this.lightboxDataService.addAsset(assetId, '');
                }
            })
        );

        this.subs.add(this.dragulaService.drag('assets').subscribe(() => {
            this.drag.next();
        }));

        this.subs.add(this.dragulaService.cancel('assets').subscribe(() => {
            this.cancel.next();
        }));

    }

    @HostListener('dragover', ['$event'])
    onDragOver(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.hasFileOver = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.hasFileOver = false;
    }

    @HostListener('drop', ['$event'])
    onDrop(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();

        const assetId = JSON.parse(e.dataTransfer.getData('text')).assetId;
        if (assetId) {
            if (!this.lightboxDataService.isAssetInLightbox(this.lightboxDataService.getSelectedLightboxId(), assetId)) {
                this.lightboxDataService.addAsset(assetId, '');
            }
        }
        this.hasFileOver = false;
    }

    ngOnInit() {
        this.subs.add(this.lightboxDataService.getLightboxListObservable().subscribe(lightboxList => {
            this.lightboxes = this.sortLightboxes(lightboxList, this.getSortColumn());
            if (this.lightbox && this.lightbox.id > 0) {
                this.setSelectedLightbox(this.lightbox.id);
            }
        }));

        this.subs.add(this.preferenceService.observe('lb_visible').subscribe(isVisible => {
            this.visible = !!isVisible;
        }));

        this.subs.add(this.lightboxDataService.getSelectedLightboxObservable().subscribe(changedLightbox => {
            if (changedLightbox && !this.lightbox || (this.lightbox && changedLightbox && this.lightbox.id !== changedLightbox.id)) {
                this.loadLightboxDetails(changedLightbox.id);
            }
            this.lightbox = changedLightbox;
            const lightboxId = (changedLightbox && changedLightbox['id'] > 0) ? changedLightbox['id'] : '';
            this.setSelectedLightbox(lightboxId);
        }));

        this.subs.add(this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showLightboxBar = (this.router.url.substr(0, 8) !== '/gallery');
            }
        }));
    }

    setSelectedLightbox(lightboxId) {
        this.form.patchValue({lightboxSelection: lightboxId}, {emitEvent: false});
    }

    createForm() {
        this.form = this.fb.group({
            lightboxSelection: '',
            action: '',
            lightboxes: ''
        });

        this.dataSubscription = this.form.valueChanges
            .subscribe(data => {
                if (!this.lightbox || data.lightboxSelection !== this.lightbox.id) {
                    if (typeof data.lightboxSelection === 'string' && data.lightboxSelection.substr(0, 5) === 'sort_') {
                        this.setSortColumn(data.lightboxSelection.substr(5));

                        if (this.lightbox && this.lightbox.id) {
                            this.form.patchValue({lightboxSelection: this.lightbox.id});
                        }

                    } else {
                        this.lightboxDataService.setSelectedLightboxId(data.lightboxSelection);
                        data.action = '';
                        this.resetAction();
                    }
                }

                this.resetErrorMessage();

                if (data.action === 'create') {
                    this.action = 'edit';
                    this.formCreate.setValue({
                        'name': '',
                        'comment': ''
                    });

                } else if (data.action === 'rename') {
                    this.formRename.setValue({
                        'name': this.lightbox.name
                    });
                    this.action = 'rename';

                } else if (data.action === 'edit') {
                    this.router.navigate([`/${this.translate.currentLang}`, 'gallery', this.lightbox.id], { queryParams: {
                        edit: true
                    } });
                    this.resetAction();

                } else if (data.action === 'delete') {
                    this.action = 'delete';

                } else if (data.action === 'deleteSelections') {
                    this.action = 'deleteSelections';
                    this.removeSelections(this.lightbox.id);

                } else if (data.action === 'pdf') {
                    this.action = 'pdf';
                    this.downloadPdf();

                } else if (data.action === 'zip') {
                    this.action = 'zip';
                    this.downloadZip();

                } else if (data.action == 'highResZip') {
                    this.action = 'highResZip';
                    this.downloadHighResZip();

                } else if (data.action === 'duplicate') {
                    this.duplicate();

                } else if (data.action === 'cart') {
                    this.copyToCart();

                } else if (data.action === 'send') {
                    this.action = 'send';
                    this.formSend.setValue({
                        'email': '',
                        'comment': ''
                    });

                } else {
                    this.action = '';
                }

            });


        this.formCreate = this.fb.group({
            name: '',
            comment: ''
        });

        this.formSend = this.fb.group({
            email: '',
            comment: ''
        });

        this.formRename = this.fb.group({
            name: ''
        });


    }

    resetAction() {
        this.action = '';
        this.form.patchValue({action: ''}, {emitEvent: false});
    }

    sortLightboxes(lightboxes: Lightbox[], sortBy) {

        lightboxes.sort(function (a, b) {
            let valA = a[sortBy];
            let valB = b[sortBy];

            if (valA && valA.getTime) {
                valA = valA.getTime();
                valB = valB.getTime();

                return valB - valA;
            }

            valA = valA.toUpperCase();
            valB = valB.toUpperCase();

            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }

            return 0;
        });

        return lightboxes;
    }

    isValidSort(sort) {
        return ['name', 'modificationDate', 'creationDate', 'assets'].indexOf(sort) > -1;
    }

    getSortColumn(): string {
        if (this.sdkOptions.lightbox && this.sdkOptions.lightbox.defaultSorting) {
            return this.sdkOptions.lightbox.defaultSorting;
        } else {
            const sort = this.preferenceService.get('lightbox_bar_sort');

            return this.isValidSort(sort) ? sort : 'name';
        }
    }

    setSortColumn(column: string) {
        if (!this.isValidSort(column)) {
            return;
        }

        this.preferenceService.set('lightbox_bar_sort', column);
        this.lightboxes = this.sortLightboxes(this.lightboxes, this.getSortColumn());
        this.sortColumn = column;
    }

    deleteLightbox() {
        if (!this.lightbox || !(this.lightbox.id > 0)) {
            return;
        }

        const lightboxId = this.lightbox.id;

        this.lightboxDataService.deleteLightbox(this.lightbox.id).then(() => {
            this.resetAction();
        });
    }

    downloadPdf() {
        this.lightboxService.downloadPdf(this.lightbox.id).then(() => {
            this.resetAction();
        });
    }

    downloadZip() {
        this.lightboxService.downloadZip(this.lightbox.id).then(() => {
            this.resetAction();
        });
    }

    downloadHighResZip() {
        this.lightboxService.downloadHighResZip(this.lightbox.id).then(() => {
            this.resetAction();
        });
    }

    sendMail() {
        const email = this.formSend.get('email').value;
        const comment = this.formSend.get('comment').value;

        this.lightboxService.sendMail(this.lightbox.id, email, comment).then(() => {
            this.resetAction();
        });
    }

    duplicate() {
        this.lightboxService.getLightbox(this.lightbox.id).subscribe(data => {
            const lightbox = this.cleanLightboxForDuplicate(data);

            this.lightboxDataService.createLightbox(lightbox).then(() => {
                this.resetAction();
            });
        });
    }

    copyToCart() {
        this.lightboxService.copyToCart(this.lightbox.id).then(() => {
            this.resetAction();
        });
    }

    createLightbox() {
        const formData = this.formCreate.getRawValue();
        this.lightboxDataService.createLightbox(formData).then(
            () => {
                this.resetAction();
            },
            data => {
                if (data.status === 409) {
                    const body = data.error;
                    if (body.message === 'Name is empty') {
                        this.setErrorMessage('Please enter a name');
                    } else {
                        this.setErrorMessage('A gallery with that name already exists.');
                    }
                }
            }
        );
    }

    renameLightbox() {
        const formData = this.formRename.getRawValue();
        formData['id'] = this.lightbox.id;
        formData['comment'] = this.lightbox.comment;

        this.lightboxDataService.updateLightbox(formData).then(
            () => {
                this.resetAction();
            },
            data => {
                if (data.status === 409) {
                    this.setErrorMessage('A gallery with that name already exists.');
                }
            }
        );
    }

    setErrorMessage(msg) {
        this.errorMessage = msg;
    }

    resetErrorMessage() {
        this.errorMessage = null;
    }


    cleanLightboxForDuplicate(lightbox: Lightbox): Lightbox {
        lightbox.id = null;
        lightbox.creationDate = null;
        lightbox.modificationDate = null;
        lightbox.name = lightbox.name + ' - Duplicate';

        if (lightbox.assets && lightbox.assets.length > 0) {
            const newAssets = [];
            for (const asset of lightbox.assets) {
                asset.asset = null;
                asset.creationTime = null;
                asset.modificationTime = null;
                newAssets.push(asset);
            }
            lightbox.assets = newAssets;
        }

        return lightbox;
    }

    removeSelections(lightboxId) {
        const selections = Object.keys(this.selectedAssets).filter(key => !!this.selectedAssets[key]);
        for (let i = 0; i < selections.length; i++) {
            this.lightboxDataService.deleteAsset(lightboxId, selections[i]);
        }
        this.action = '';
        this.form.patchValue({
            action: ''
        });
    }


/*
    showAssetDetail(event: any): void {
        this.showAssetDetails.next(event.id);
    }
*/

    /**
     * Shows passed asset details
     * @param event
     */
    showAssetDetail(event: any): void {
        if (event.event.ctrlKey || event.event.shiftKey || event.event.metaKey ) {
            this.setThumbSelection(event);
            return;
        }

        this.showDetailId = event.id;
        this.showDetailAsset = event.asset;
    }

    setThumbSelection( event: any ): void {
        if (event.event.ctrlKey || event.event.metaKey ) {
            this.selectedAssets[ event.id ] = !this.selectedAssets[ event.id ];
            this.lastSelectedAsset = event.id;
        } else if ( event.event.shiftKey ) {
            this.selectedAssets = {};

            let setSelection = false;
            for (const asset of this.lightbox.assets) {
                if (asset.id == event.id || asset.id == this.lastSelectedAsset ) {
                    setSelection = !setSelection;
                    this.selectedAssets[ asset.id ] = true;
                } else if(setSelection) {
                    this.selectedAssets[ asset.id ] = true;
                }
            }
        }


        const selected = [];
        for (const asset of this.lightbox.assets) {
            if (this.selectedAssets[ asset.id ] ) {
                selected.push(asset.id);
            }
        }
        this.currentlySelectedAssets = selected;
    }

    isEmpty() {
        return (!this.lightbox || !this.lightbox.assets || !this.lightbox.assets.length);
    }

    isAssetSelectionEmpty(): boolean {
        let isEmpty = true;
        const values = Object.keys(this.selectedAssets).map(key => !!this.selectedAssets[key]);
        for (const entry of values) {
            if (entry) {
                isEmpty = false;
                break;
            }
        }
        return isEmpty;
    }

    moveLightboxes(lightboxId: number, copy: boolean): void {

        copy = !!copy;

        if ( !this.lightboxSelections[ lightboxId ] || !this.lightboxSelections[ lightboxId ].length ) {
            return;
        }

        for ( let lightbox of this.lightboxes ) {
            /*if ( !this.lightboxForms[lightboxId].forms.action.controls['lb_'+ lightbox.id].value ) {
                continue;
            }*/

            for ( let assetId of this.lightboxSelections[ lightboxId ] ) {
                let asset = this.getLightboxAsset(lightboxId, assetId);
                if ( !asset ) {
                    continue;
                }

                this.lightboxDataService.addAsset(assetId, asset.comment, lightbox.id).then( () => {
                    if( !copy ) {
                        this.lightboxDataService.deleteAsset(lightboxId, assetId);
                    }
                });
            }
        }

        this.cancelAction();
    }

    getLightboxAsset(lightboxId: number, assetId: string): LightboxAsset {
        for( let lightbox of this.lightboxes ) {
            if( lightbox.id != lightboxId ) {
                continue;
            }

            for( let asset of lightbox.assets ) {
                if( asset.id == assetId ) {
                    return asset;
                }
            }
        }

        return null;
    }

    cancelAction() {
        this.form.patchValue({ action: '' });
    }

    hasSubscriptionInLightbox(): boolean {
        if (this.lightbox && this.lightbox.assets) {
            return !!this.lightbox.assets.filter(asset => !!asset.asset).find(asset => asset.asset.subscriptions && asset.asset.subscriptions.length > 0);
        } else {
            return false;
        }
    }

    hasNoSubscriptionInLightbox(): boolean {
        if (this.lightbox && this.lightbox.assets) {
            return !!this.lightbox.assets.filter(asset => !!asset.asset).find(asset => asset.asset.subscriptions && asset.asset.subscriptions.length === 0);
        } else {
            return false;
        }
    }

    loadLightboxDetails(lightboxId: number) {
        this.loadingLighbox = true;
        this.lightboxService.getLightbox(lightboxId).toPromise().then(lightbox => {
            this.loadingLighbox = false;
            this.lightboxDataService.setLightbox(lightbox);
        }).catch(() => {
            this.loadingLighbox = false;
        });

    }

    ngOnDestroy(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();
        if (this.subs) this.subs.unsubscribe();
    }
}
