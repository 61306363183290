import {Injectable} from '@angular/core';
import {distinctUntilChanged, skip, take, takeWhile} from 'rxjs/operators';
import {Article} from '../models/article';
import {Cart} from '../models/cart';
import {Order} from '../models/order';
import {PreferencesService} from './preferences.service';
import {CartService} from './cart.service';
import {UserDataService} from './user-data.service';
import {UserLoginService} from './user-login.service';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Service that interacts with the cart api using CartService
 * Stores users cart and emits changes
 */
@Injectable()
export class CartDataService {
    private _cart: BehaviorSubject<Cart> = new BehaviorSubject(null);
    public cart: Observable<Cart> = this._cart
        .asObservable()
        .pipe(distinctUntilChanged(null, x => JSON.stringify(x)));

    promotionCodeActive: boolean;
    private selectedArticles: number[];

    constructor(
        private preferenceService: PreferencesService,
        private cartService: CartService,
        private userDataService: UserDataService,
        private userLogin: UserLoginService
    ) {
        this.preferenceService
            .observe('cart_id')
            .pipe(distinctUntilChanged())
            .subscribe(cartId => {
                if (cartId > 0) {
                    this.cartService.getCart(cartId).subscribe(
                        cart => {
                            this.setCart(cart);
                        },
                        () => {
                            this.loadCart();
                        }
                    );
                } else if (this.userDataService.isLoaded()) {
                    this.loadCart();
                } else {
                    this.setCart(null);
                }
            });
    }

    setPromitionCodeActive(status: boolean) {
        this.promotionCodeActive = status;
    }

    isPromotionCodeActive() {
        return this.promotionCodeActive;
    }

    /**
     * Loads carts from the api for the user or create a new one
     * @returns
     */
    public loadCart() {
        if (!this.userDataService.isLoaded()) {
            this.setCart(null);
            return;
        }

        this.cartService.getCarts().subscribe(carts => {
            if (Array.isArray(carts) && carts.length) {
                this.setCart(carts.shift());
                return;
            }

            // Create new cart
            this.cartService.addCart({}).subscribe(cart => {
                this.setCart(cart);
            });
        });
    }

    /**
     * Loads a single cart by id
     * @returns
     */
    private loadSingleCart() {
        let cartId = Number(this.preferenceService.get('cart_id'));

        if (!(cartId > 0)) {
            cartId = this.getLoadedCartId();
        }

        if (!(cartId > 0)) {
            return this.loadCart();
        }

        this.cartService.getCart(cartId).subscribe(loadedCart => {
            this.setCart(loadedCart);
        });
    }

    /**
     *
     * Sets the passed cart
     * @param cart to be setted and passed by _cart BehaviourSubject
     */
    private setCart(cart: Cart) {
        const cartId = cart && cart.id ? cart.id : 0;

        if (cart && !Array.isArray(cart.articles)) {
            cart.articles = [];
        }

        this._cart.next(cart);
        this.setCartId(cartId);
    }

    /**
     * Sets users cartId
     * @param cartId
     * @memberof CartDataService
     */
    private setCartId(cartId: number) {
        cartId = cartId > 0 ? cartId : 0;

        this.preferenceService.set('cart_id', cartId);
    }

    /**
     * Gets  loaded cart id
     * @returns
     */
    private getLoadedCartId() {
        const cart = this.getCart();
        if (!cart || !cart.id) {
            return null;
        }

        return cart.id;
    }

    /**
     * Gets _cart BehaviourSubject value
     * @returns
     */
    getCart(): Cart {
        return this._cart.getValue();
    }

    /**
     * Adds an article to the cart
     * @param assetId asset id
     * @param calculatorOptions
     * @returns
     */
    addArticle(assetId: string, calculatorOptions?) {
        if (!this.userDataService.isLoaded()) {
            this.userLogin
                .login()
                .pipe(
                    skip(1),
                    take(1)
                )
                .subscribe(data => {
                    if (data !== 'login') {
                        return;
                    }

                    this.cart
                        .pipe(
                            takeWhile(cart => {
                                if (!cart || !(cart.id > 0)) {
                                    return true;
                                }

                                this.addArticle(assetId, calculatorOptions);

                                return false;
                            })
                        )
                        .subscribe();
                });

            return;
        }

        const cartId = this.getLoadedCartId();

        return this.cartService
            .addArticle(cartId, assetId, calculatorOptions)
            .toPromise()
            .then(article => {
                this.loadSingleCart();
            });
    }

    /**
     * Adds multiple articles to the selected cart
     * @param articles to be added
     */
    addArticles(articles: Article[]): Promise<any> {
        const cartId = this.getLoadedCartId();

        return this.cartService
            .getCart(cartId)
            .toPromise()
            .then(cart => {
                cart.articles.push(...articles);

                return this.cartService
                    .updateCart(cartId, cart)
                    .toPromise()
                    .then(updatedCart => {
                        this.setCart(updatedCart);
                    });
            });
    }

    /**
     *
     * Updates current cart with articles
     * @param articles to be added to the cart
     */
    setArticles(articles: Article[]): Promise<any> {
        const cartId = this.getLoadedCartId();

        return this.cartService
            .getCart(cartId)
            .toPromise()
            .then(cart => {
                cart.articles = articles;

                return this.cartService
                    .updateCart(cartId, cart)
                    .toPromise()
                    .then(updatedCart => {
                        this.setCart(updatedCart);
                    });
            });
    }

    /**
     *
     * Updates an article in the cart
     * @param article to be updated
     * @returns
     */
    updateArticle(article: Article) {
        const cartId = this.getLoadedCartId();

        return this.cartService
            .updateArticle(cartId, article.id, article)
            .toPromise()
            .then(newArticle => {
                this.loadSingleCart();
            });
    }

    /**
     * Remove an article from the cart
     * @param articleId article id
     * @returns
     */
    removeArticle(articleId: number) {
        const cartId = this.getLoadedCartId();

        return this.cartService
            .deleteArticle(cartId, articleId)
            .toPromise()
            .then(article => {
                this.loadSingleCart();
            });
    }

    /**
     * Checks if the asset exists in cart
     * @param assetId
     * @returns
     */
    isAssetInCart(assetId: string) {
        const cart = this.getCart();
        if (!cart || !cart.articles || !cart.articles.length) {
            return false;
        }

        for (const article of cart.articles) {
            if (article.assetId === assetId) {
                return true;
            }
        }

        return false;
    }

    /**
     * Checks out cart
     * @param articleIdList
     * @returns
     */
    checkout(articleIdList: number[]): Promise<Order> {
        const cartId = this.getLoadedCartId();

        return this.cartService
            .checkoutCart(cartId, articleIdList)
            .toPromise()
            .then(data => {
                this.loadCart();

                return data;
            });
    }
}
