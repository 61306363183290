/**
 * The collection application model
 */
export class Collection {
    id: number | any;
    name: string;
    exclusive: string;
    license: string;
    assetType: string;
    usageType: string;
    copyright: string;
    visibleInSearch?: boolean;
    isSelected?: boolean;
}
