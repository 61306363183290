import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { UserAddress } from '../../../models/user-address';
import { CountryService } from '../../../services/country.service';
import { User } from '../../../models/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { take, takeUntil } from 'rxjs/operators';
import {MarketingService} from '../../../services/marketing.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import {SDK_OPTIONS, SdkOptions} from '../../../models/sdk-options';

/**
 * Component that includes the signup form and the signup logic
 */
@Component({
    selector: 'st-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public countries;
    public businessTypes;

    public formErrors = {
        email: [],
        username: [],
        password: [],
        passwordConfirm: [],
        familyName: [],
        givenName: [],
        company: [],
        postalCode: [],
        streetAddress: [],
        streetAddress2: [],
        addressLocality: [],
        addressRegion: [],
        addressCountry: [],
        taxNumber:[],
        business:[],
        telephone:[]
    };

    public success = false;
    public errorMessages: string[] = [];

    private validationMessages = {
        email: {
            email: 'sodatechSdk.formErrors.emailNotValid'
        },
        general: {
            required: 'sodatechSdk.formErrors.fieldRequired',
            email: 'sodatechSdk.formErrors.emailNotValid',
            usernameTaken: 'sodatechSdk.formErrors.usernameTaken',
            validatePasswordConfirmation: 'sodatechSdk.formErrors.validatePasswordConfirmation'
        }
    };

    componentDestroyed$: Subject<void> = new Subject();

    constructor(
        private countryService: CountryService,
        private userService: UserService,
        private fb: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private marketingService: MarketingService,
        private translateService: TranslateService,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions
    ) {
        this.businessTypes = this.sdkOptions.businessTypes;
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group(
            {
                email: ['', [Validators.required, Validators.email]],
                username: ['', Validators.required],
                password: ['', [Validators.required]],
                passwordConfirm: ['', [Validators.required]],
                familyName: ['', [Validators.required]],
                givenName: ['', [Validators.required]],
                company: '',
                postalCode: ['', [Validators.required]],
                streetAddress: ['', [Validators.required]],
                streetAddress2: [''],
                addressLocality: ['', [Validators.required]],
                addressRegion: '',
                addressCountry: [this.sdkOptions.primaryCountry, [Validators.required]],
                taxNumber: [''],
                business: [''],
                telephone: ['', [Validators.required]],
                newsletterSubscribed: ['']

            },
            {
                validator: this.validatePasswordConfirmation
            }
        );

        this.form.valueChanges.subscribe(data => this.onValueChanged(data));
        this.onValueChanged(); // (re)set validation messages now

        this.translateService.onLangChange.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            this.getCountries();
        })
    }

    async ngOnInit() {
        this.getCountries();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
    }

    getCountries(): void {
        this.countryService
            .getSortedCountries()
            .pipe(take(1))
            .subscribe(countries => (this.countries = countries));
    }

    /**
     * Submits the form and registers the user with given info in the api
     */
    onSubmit() {
        this.setFormErrors(false);
        this.errorMessages = [];
        this.success = false;
        this.form['submitted'] = true;
        if (!this.form.valid) {
            this.form['submitted'] = false;
            return;
        }
        const user = this.prepareSaveUser();

        this.userService.register(user).then(
            successData => {
                this.success = true;
                this.authenticationService.setSignupSuccessfulStatus(true);
                // web will only consume the information once
                this.authenticationService.setSignupSuccessfulStatus(false);
            },
            errorData => {
                this.form['submitted'] = false;
                const errorContent = errorData.error;

                if (errorContent.violations && errorContent.violations) {
                    for (const propertyPath in errorContent.violations) {
                        this.form
                            .get(propertyPath)
                            .setErrors(errorContent.violations[propertyPath]);
                    }

                    this.setFormErrors(false);
                } else if (errorContent.code === 409) {
                    this.form
                        .get('username')
                        .setErrors(['sodatechSdk.formErrors.usernameTaken']);
                    this.setFormErrors(false);
                }
            }
        );
    }

    /**
     * Prepares the correct payload to be sent to the api
     */
    prepareSaveUser(): User {
        const formModel = this.form.value;

        const user = new User();
        user.email = formModel.email;
        user.username = formModel.username;
        user.password = formModel.password;
        user.familyName = formModel.familyName;
        user.givenName = formModel.givenName;
        user.company = formModel.company;
        user.taxNumber = formModel.taxNumber;
        user.userFunction = [+formModel.business];
        user.newsletterSubscribed = formModel.newsletterSubscribed;

        const address = new UserAddress();
        address.contactType = 'main';
        address.familyName = formModel.familyName;
        address.givenName = formModel.givenName;
        address.postalCode = formModel.postalCode;
        address.streetAddress = formModel.streetAddress;
        address.streetAddress2 = formModel.streetAddress2;
        address.addressLocality = formModel.addressLocality;
        address.addressRegion = formModel.addressRegion;
        address.addressCountry = formModel.addressCountry;
        address.telephone = formModel.telephone;

        user.address = [address];

        return user;
    }

    onValueChanged(data?: any) {
        this.setFormErrors(true);
    }

    setFormErrors(ignorePristine): void {
        if (!this.form) {
            return;
        }
        const form = this.form;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = [];

            const control = form.get(field);

            if (
                control &&
                (!ignorePristine || control.dirty) &&
                !control.valid
            ) {
                for (const key in control.errors) {
                    let message = 'Some error happened';
                    if (
                        this.validationMessages[field] &&
                        this.validationMessages[field][key]
                    ) {
                        message = this.validationMessages[field][key];
                    } else if (
                        this.validationMessages['general'] &&
                        this.validationMessages['general'][key]
                    ) {
                        message = this.validationMessages['general'][key];
                    } else {
                        message = control.errors[key];
                    }

                    this.formErrors[field].push(message);
                }
            }
        }
    }

    /**
     * Validates that passwords match
     * @param group
     */
    validatePasswordConfirmation(group: FormGroup) {
        const pw = group.controls['password'];
        const pw2 = group.controls['passwordConfirm'];

        if (pw.value !== pw2.value) {
            pw2.setErrors({ validatePasswordConfirmation: true });
        } else if (pw2.hasError('validatePasswordConfirmation')) {
            pw2.setErrors(null);
        }

        return null;
    }
}

export const BUSINESS_TYPES = {
    corporateCustomers: 56,
    corporatePublishing: 9,
    bookPublishing: 8,
    calendarPublishing: 23,
    merchandising: 57,
    advertisingPRCorporatePublishing: 55,
    magazinePublisher: 53,
    tvAndRadio: 15,
    insurance: 50,
    miscellaneous: 58,
    touristicTravelAgencies: 48,
    finance: 3,
    education: 7,
    industry: 51
};
