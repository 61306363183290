import { InjectionToken } from '@angular/core';
import {Collection} from './collection';
import { Breakpoint } from './breakpoint.enum';

export const SDK_OPTIONS: InjectionToken<SdkOptions> = new InjectionToken<SdkOptions>('SDK_OPTIONS');

export interface SdkOptions {

    baseUrl: string;

    company: {
        name: string;
        socialMedia: {
            twitter: string;
        }
    };

    apiPath: string;

    imgPath: string;

    useDevApi: boolean;

    icons?: {
        [iconName: string]: string;
    };

    domain: string;

    storyBlok?: {
        storiesEndpoint: string;
        spacesEndpoint: string,
        token: string;
        draftToken: string;
        version: string;
    };

    searchGridData: {
        assetUrlSuffix: string;
        assetNamePrefix: string;
        assetNameSuffix: string;
    };

    similaritySearch?: {
        api: string;
    };

    availableLanguages: string[];

    defaultCountries: string[];

    primaryCountry: string;

    primaryLanguage: string;

    assetDetails: {
        preview: boolean;
        alwaysDisplayCalculator: boolean;
        displaySupportOption: boolean;
        hideSubscriptionText: boolean;
        displayRFHint: boolean;
        displayCreditor: boolean;
        credit: {
            alwaysUseCollectionData: boolean
        },
        seo: {
            descriptionSuffix: string;
            rfLink: string;
            rmLink: string;
        },
        useHeadlineForDescription?: boolean;
        hidePreviewKeywords?: boolean;
    };
    layout?: {
        grid?: {
            displayAssetCaption: boolean;
        },
        displayAsset?: {
            useNativeImage?: boolean
            useLazySizes?: boolean
        },
        cmsImage?: {
            useLazySizes?: boolean
        }
        masonryGrid?: {
            maxAssetHeight?: number;
        },
        lightbox?: {
            displayShowMoreButton?: boolean
        }
    };

    assetSearchFilter?: {
        isOccurrencesEnabled: boolean;
        peopleFilter: {
            [key: number]: {
                [language: string]: string
            }
        }
    };

    ui?: {
        materialSelectItemHeight?:  number;
        scrollToAnchorOffset?: number;
    };

    orderList: {
        proposal: {
            displayArticleActions: boolean;
            hideAddToCartAction: boolean;
        }
        hideTaxesPerArticle: boolean;
    };

    businessTypes: {
        [type: string]: number
    };

    assetCalculator: {
        hideCommercialOrEditorial: boolean;
    };

    additionalCollections?: Collection[];
    userFunctions?: {
        showCart: number;
        directHighResDownload: number;
    };

    termsLink: string;

    rendering?: {
        breakpointSizesMap: {[key: string]: string};
    };

    lightbox?: {
        showCreationDate?: boolean;
        useSearch?: boolean;
        hideSortingOption: boolean;
        defaultSorting: string;
    };

    cmsComponents?: {
        contentTeaser?: {
            maxHeight?: number;
        }
    };

    cart?: {
        checkout?: {
            paypalLogo?: string;
            creditCardLogo?: string;
        }
    };
}
