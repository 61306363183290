import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {User} from '../models/user';
import {UserLoginService} from './user-login.service';
import {Router} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service that interacts with user api
 */
@Injectable()
export class UserService {
    userHasLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private path = 'user';

    constructor(
        private http: SodaApiService,
        private userLogin: UserLoginService,
        private router: Router,
        private translate: TranslateService
    ) {}

    /**
     * Login with given credentials
     * @param username
     * @param password
     * @returns
     */
    public login(username: string, password: string): Promise<any> {
        const url = this.path + '/login';

        const postBody = new URLSearchParams();
        postBody.set('username', username);
        postBody.set('password', password);

        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        );

        return this.http
            .post<any>(url, postBody.toString(), { headers: headers })
            .toPromise()
            .then(
                successResponse => {
                    if (!successResponse.token) {
                        throw new Error('No token in login response');
                    }
                    this.userLogin.setToken(successResponse.token);
                    this.userHasLoggedIn$.next(true);
                },
                errorResponse => {
                    if (errorResponse.status === 401) {
                        if (
                            errorResponse.error.message.status === '401 Disabled'
                        ) {
                            throw new Error('sodatechSdk.login.errors.notVerified');
                        } else {
                            throw new Error('sodatechSdk.login.errors.wrongCredentials');
                        }
                    } else if (errorResponse.status === 409) {
                        throw new Error('sodatechSdk.login.errors.newPasswordRequestNeeded');
                    } else {
                        throw new Error('sodatechSdk.login.errors.generalError');
                    }
                }
            );
    }

    /**
     * Perform a direct login
     * @param lid
     * @param mds
     * @param uid
     * @returns
     */
    public directLogin(lid: number, mds: string, uid: number): Promise<any> {
        const url =
            this.path +
            '/direct-login/' +
            encodeURIComponent(String(lid)) +
            '/' +
            encodeURIComponent(String(uid)) +
            '/' +
            encodeURIComponent(mds);

        return this.http
            .post<any>(url, '')
            .toPromise()
            .then(
                successResponse => {
                    if (!successResponse.token) {
                        throw new Error('No token in login response');
                    }

                    this.userLogin.setToken(successResponse.token);
                    this.router.navigate([`/${this.translate.currentLang}`]);
                    this.userHasLoggedIn$.next(true);
                },
                errorResponse => {
                    if (errorResponse.status === 401) {
                        if (
                            errorResponse.error.message.status === '401 Disabled'
                        ) {
                            throw new Error('sodatechSdk.login.errors.notVerified');
                        } else {
                            throw new Error('sodatechSdk.login.errors.wrongCredentials');
                        }
                    } else {
                        throw new Error('sodatechSdk.login.errors.generalError');
                    }
                }
            );
    }

    /**
     * Logout current user
     */
    public logout() {
        this.userLogin.deleteToken();
    }

    /**
     * Register a new user
     * @param user
     * @returns
     */
    public register(user: User) {
        const url = this.path;

        return this.http
            .post(url, user)
            .toPromise()
            .then(
                successResponse => {
                    return successResponse;
                },
                errorResponse => {
                    if (
                        errorResponse.headers
                            .get('Content-Type')
                            .indexOf('application/problem+json') !== -1
                    ) {
                        throw errorResponse.error;
                    }

                    throw errorResponse;
                }
            );
    }

    /**
     * Verifies if users token is valid
     * @param userId
     * @param token
     * @returns
     */
    public verify(userId: number, token: string) {
        const url =
            this.path +
            '/' +
            userId +
            '/verify?token=' +
            encodeURIComponent(token);

        return this.http.post(url, {}).toPromise();
    }

    /**
     * Returns user data
     * @returns
     */
    public getUser(): Promise<User> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const url = this.path;

        return this.http.get<any>(url, { headers: headers }).toPromise();
    }

    /**
     * Resends a verification email to given email address
     * @param email
     * @returns
     */
    public resendVerificationEmail(email: string) {
        const url =
            this.path +
            '/resend-verification?email=' +
            encodeURIComponent(email);

        return this.http
            .post(url, '')
            .toPromise()
            .then(
                successResponse => {
                    return true;
                },
                errorResponse => {
                    return false;
                }
            );
    }

    /**
     * Updates users info
     * @param user data
     * @returns
     */
    public update(user: User) {
        const url = this.path;

        return this.http
            .put(url, user)
            .toPromise()
            .then(
                successResponse => successResponse,
                errorResponse => {
                    if (
                        errorResponse.headers
                            .get('Content-Type')
                            .indexOf('application/problem+json') !== -1
                    ) {
                        throw errorResponse.error;
                    }

                    throw errorResponse;
                }
            );
    }

    /**
     * Sends an email for resetting password
     * @param  email
     * @returns
     */
    public requestPasswordResetToken(email: string) {
        const url = this.path + '/request-reset-token';

        return this.http
            .post(url, { email: email })
            .toPromise()
            .then(successResponse => {
                return true;
            });
    }

    /**
     * Resets user password with the given token
     * @param email
     * @param token
     * @param newPassword
     * @returns
     */
    public resetPasswordWithToken(
        email: string,
        token: string,
        newPassword: string
    ) {
        const url = this.path + '/reset-password';

        return this.http
            .post(url, {
                email: email,
                token: token,
                password: newPassword
            })
            .toPromise()
            .then(successResponse => {
                return true;
            });
    }
}
