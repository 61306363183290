import {
    AfterViewInit,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { LightboxDataService } from '../../services/lightbox-data.service';
import { CartDataService } from '../../services/cart-data.service';
import { ImageUtilsService } from '../../services/image-utils.service';
import { Asset } from '../../models/asset';
import { PreferencesService } from '../../services/preferences.service';
import { AssetActionPlugin } from '../../models/asset-action-plugin';
import { AssetAction } from '../../models/asset-action';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {AssetService} from '../../services/asset.service';
import { isPlatformServer } from '@angular/common';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import {RemoveFileExtensionPipe} from '../../pipes/remove-file-extension.pipe';
import {EventTrackerEvents, EventTrackerService} from '../../services/event-tracker.service';
import {UserDataService} from '../../services/user-data.service';
import {AssetType} from '../asset-detail/asset-detail.component';
import {AssociatedMedia} from '../../models/associated_media';
import {transliterate as tr} from 'transliteration';
import * as _ from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';

/**
 * Component that displays the asset in the masonry grid
 */
@Component({
    selector: 'st-masonry-grid-asset-thumb',
    templateUrl: './masonry-grid-asset-thumb.component.html',
    styleUrls: ['./masonry-grid-asset-thumb.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [RemoveFileExtensionPipe]
})
export class MasonryGridAssetThumbComponent implements OnInit, OnChanges, AfterViewInit, DoCheck, OnDestroy {

    /**
     * Asset type enum
     */
    assetType= AssetType;

    /**
     * Asset
     */
    @Input() asset: any = {};

    /**
     * Lightbox id the asset belongs to
     */
    @Input() lightboxId: number = null;

    /**
     * Flag that describes whether the asset is selected or not
     */
    @Input() selected: boolean = false;

    /**
     * Triggered when the asset details should be opened for the asset
     */
    @Output() detail = new EventEmitter<any>();

    /**
     * Toggles the similarity search
     */
    @Output() toggleSimilaritySearch = new EventEmitter<Asset>();

    /**
     * Width of the grid item
     */
    @Input() gridItemWidth: number;

    /**
     * Asset action plugin that includes additional non sdk actions
     */
    @Input() assetActionPlugins: AssetActionPlugin[] = [];

    /**
     * Seo url generator
     */
    @Input() urlGenerator: Function;

    /**
     * Flag that describes whether the asset name should be displayed or not
     */
    @Input() displayAssetName = false;

    /**
     * Current asset file
     */
    assetFile: AssociatedMedia;

    /**
     * Flag that describes whether the preview is shown or not
     */
    showPreview: boolean;

    /**
     * Actions for the asset
     */
    assetActions$: Observable<AssetAction[]>;

    /**
     * Defines the item width
     */
    @Input() width: any = 'auto';

    /**
     * Defines the item height
     */
    @Input() height: any = '100px';

    /**
     * Defines the margin around the item
     */
    @Input() marginWidth: any = '0px';

    /**
     * Flag that describes if the overlay is enabled or not
     */
    @Input() overlay = true;

    /**
     * seo url
     */
    seoUrl: string = null;

    /**
     * Background image of the current asset
     */
    public backgroundImage: SafeResourceUrl;

    /**
     * Default settings
     */
    public thumbWidth = 0;
    public thumbHeight = 0;
    public imgWidth: number = null;
    public imgHeight: number = null;


    @HostBinding('style.width') styleWidth: any = '100px';
    @HostBinding('style.height') styleHeight: any = '100px';
    @HostBinding('class.st-masonry-grid-asset-thumb') className = true;
    @HostBinding('class.is-first') firstClassName = false;
    @HostBinding('class.is-last') lastClassName = false;
    @HostBinding('class.is-top') topClassName = false;
    @HostBinding('class.is-preview-mode') isPreviewModeClassName = false;
    @HostBinding('attr.data-asset-url') assetUrlAttribute = 'www.google.com';
    private currentImgHeight = 0;

    @ViewChild('thumbImageView') thumbImageView: ElementRef;

    /**
     * Flag that describes if the cart is available or not
     */
    isCartAvailable: boolean;


    constructor(
        private hostElement: ElementRef,
        private sanitizer: DomSanitizer,
        private lightboxDataService: LightboxDataService,
        private cartDataService: CartDataService,
        private imageUtilsService: ImageUtilsService,
        private preferencesService: PreferencesService,
        private assetService: AssetService,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
        private removeFileExtensionPipe: RemoveFileExtensionPipe,
        private eventTrackerService: EventTrackerService,
        private userDataService: UserDataService
    ) {}

    ngOnInit() {
        this.preferencesService.observe('showpreview').pipe(
            untilDestroyed(this)
        ).subscribe((showPreview) => {
            this.showPreview = showPreview;
        });
        this.updateHostClassNames();
    }

    ngOnDestroy(): void {
    }

    onDragStart(e: DragEvent) {
        e.dataTransfer.setData('text', JSON.stringify({
            html: this.thumbImageView.nativeElement.outerHTML,
            assetId: this.asset.id
        }));
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.updateHostClassNames();
    }

    @HostListener('mouseover')
    onMouseOver() {
        this.isPreviewModeClassName = this.showPreview && true;
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.isPreviewModeClassName = this.showPreview && false;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.updateHostClassNames();
        });
    }

    ngDoCheck(): void {
        this.updateHostClassNames();
    }

    ngOnChanges(changes: any) {
        this.updateHostClassNames();
        if (changes.asset && changes.asset.currentValue) {
            const asset = changes.asset.currentValue;

            if (asset.width && asset.height) {
                this.imgWidth = parseInt(asset.width, 10);
                this.imgHeight = parseInt(asset.height, 10);
            } else {
                // In some cases the assets from the API don't have WIDTH and HEIGHT set in a response. In this case,
                // take it from the preview image
                const requiredHeight = parseInt(this.height, 10);
                const assetFile = this.getFittingAssetFile(
                    asset,
                    requiredHeight
                );

                this.assetUrlAttribute = assetFile.contentUrl;
                this.imageUtilsService
                    .getSizeByUrl(assetFile.contentUrl)
                    .then(imgData => {
                        this.imgWidth = imgData.width;
                        this.imgHeight = imgData.height;

                        if (this.sdkOptions.searchGridData.assetUrlSuffix) {
                            const newPath = imgData.path + this.sdkOptions.searchGridData.assetUrlSuffix + this.getImageUrl();
                            this.backgroundImage = this.sanitizer.bypassSecurityTrustUrl(newPath);
                        } else {
                            this.backgroundImage = this.sanitizer.bypassSecurityTrustUrl(imgData.path);
                        }

                        const newWidth = changes.width
                            ? changes.width.currentValue
                            : this.styleWidth;
                        const newHeight = changes.height
                            ? changes.height.currentValue
                            : this.styleHeight;

                        this.setNewSize(newWidth, newHeight);
                    });
            }

            this.updateActions();


            // is cart available
            if (this.sdkOptions.assetDetails.alwaysDisplayCalculator) {
                this.isCartAvailable = true;
            } else if (this.asset && this.asset.subscriptions && this.asset.subscriptions.length) {
                const user = this.userDataService.getUser();
                this.isCartAvailable = !!(user && user.userFunction && user.userFunction.length && user.userFunction.find(id => id === 59));
            } else {
                this.isCartAvailable = true;
            }
        }

        if (
            (changes.width && changes.width.currentValue) ||
            (changes.height && changes.height.currentValue)
        ) {
            const newWidth = changes.width
                ? changes.width.currentValue
                : this.styleWidth;
            const newHeight = changes.height
                ? changes.height.currentValue
                : this.styleHeight;

            this.setNewSize(newWidth, newHeight);
        }
        if ((changes['asset'] || changes['urlGenerator']) && this.asset && this.urlGenerator) {
            this.seoUrl = this.urlGenerator(this.asset);
        }
    }

    getImageUrl() {
        const prefix = tr(`${this.sdkOptions.searchGridData.assetNamePrefix}${this.removeFileExtensionPipe.transform(this.asset.name)} - `);
        let caption = tr(this.asset.caption).trim().replace(/[\/\\:*?"<>|]/g, '-');
        const suffix = tr('.jpg');
        const maxLength = 240 - prefix.length - suffix.length;
        const captionLength = caption.length;
        const totalLength = captionLength + prefix.length + suffix.length;
        if (totalLength > 240) {
            caption = tr(_.truncate(caption, {
                'length': maxLength
            })).replace(/\./g, '');
        }

        return encodeURIComponent(tr(`${prefix}${caption}${this.sdkOptions.searchGridData.assetNameSuffix}`));
    }


    /**
     * Defines a new size for the thumb
     * @param width
     * @param height
     */
    setNewSize(width: any, height: any): void {
        if (width === 'auto') {
            const factor = this.imgWidth / this.imgHeight;
            width = factor * parseInt(height, 10);
            width += 'px';
        } else if (height === 'auto') {
            const factor = this.imgHeight / this.imgWidth;
            height = factor * parseInt(width, 10);
            height += 'px';
        }

        const marginWidth = this.marginWidth ? this.marginWidth : '0px';

        this.styleWidth = this.sanitizer.bypassSecurityTrustStyle(
            'calc( ' + width + ' - ' + marginWidth + ' )'
        );
        this.styleHeight = this.sanitizer.bypassSecurityTrustStyle(height);

        const assetFile = this.getFittingAssetFile(
            this.asset,
            parseInt(height, 10)
        );

        if (assetFile.height > this.currentImgHeight) {
            this.currentImgHeight = assetFile.height;
            this.assetUrlAttribute = assetFile.contentUrl;

            if (this.sdkOptions.searchGridData.assetUrlSuffix) {
                const newPath = assetFile.contentUrl + this.sdkOptions.searchGridData.assetUrlSuffix + this.getImageUrl();
                this.backgroundImage = this.sanitizer.bypassSecurityTrustUrl(newPath);
            } else {
                this.backgroundImage = this.sanitizer.bypassSecurityTrustUrl(assetFile.contentUrl);
            }
        }

        this.setThumbWidthInfo();
    }

    /**
     * Returns the fitting in the thumb asset file
     * @param asset
     * @param requiredHeight
     */
    getFittingAssetFile(asset: any, requiredHeight: number): any {
        let assetFile = null;
        for (const tmpFile of asset.associatedMedia) {
            if (
                tmpFile.fileFormat !== 'image/jpeg' ||
                tmpFile.additionalType.indexOf('watermarked') >= 0 ||
                tmpFile.additionalType === 'original'
            ) {
                continue;
            }

            if (
                !assetFile ||
                (tmpFile.height >= requiredHeight &&
                    assetFile.height > tmpFile.height) ||
                (assetFile.height < requiredHeight &&
                    assetFile.height < tmpFile.height)
            ) {
                assetFile = tmpFile;
            }
        }

        if (!assetFile) {
            for (const tmpFile of asset.associatedMedia) {
                if (tmpFile.additionalType === 'preview') {
                    assetFile = tmpFile;
                    break;
                }
            }
        }

        if (!assetFile.width || !assetFile.height) {
            assetFile.width = requiredHeight;
            assetFile.height = requiredHeight;
        }

        return assetFile;
    }

    /**
     * Emits to asset-detail component to show given asset
     */
    showImageDetail(event: any): void {
        this.detail.emit({
            id: this.asset.id,
            element: this.hostElement,
            asset: this.asset,
            event: event
        });
    }


    /**
     * Returns assets copyright
     */
    getCopyright(): string {
        if (this.asset.supplier && this.asset.supplier.copyright) {
            return this.asset.supplier.copyright;
        }

        return 'Sodatech Demo';
    }

    /**
     * Adds current asset to the lightbox
     */
    addToLightbox(event) {
        event.stopPropagation(event);
        event.preventDefault();

        this.lightboxDataService.addAsset(this.asset.id, '');
    }

    /**
     * Adds current asset to cart
     */
    addToCart(event) {
        event.stopPropagation();
        event.preventDefault();
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.ADD_TO_CART);
        this.cartDataService.addArticle(this.asset.id);
    }

    /**
     * Checks if current asset is in lightbox
     */
    isInLightbox(): boolean {
        const lightboxId = this.getLightboxId();
        return this.lightboxDataService.isAssetInLightbox(
            lightboxId,
            this.asset.id
        );
    }

    /**
     * Checks if current asset is in cart
     */
    isInCart(): boolean {
        return this.cartDataService.isAssetInCart(this.asset.id);
    }

    /**
     * Deletes the asset from the lightbox
     * @param event
     */
    deleteFromLightbox(event) {
        event.stopPropagation();
        event.preventDefault();

        const lightboxId = this.getLightboxId();
        this.lightboxDataService.deleteAsset(lightboxId, this.asset.id);
    }

    /**
     * Returns Lightbox id
     * @param event
     */
    getLightboxId(): number {
        return this.lightboxId > 0
            ? this.lightboxId
            : this.lightboxDataService.getSelectedLightboxId();
    }

    /**
     * Sets the thumb width
     */
    setThumbWidthInfo() {
        window.setTimeout(() => {
            if (
                this.hostElement.nativeElement &&
                this.hostElement.nativeElement.offsetWidth
            ) {
                this.thumbWidth = Number(
                    this.hostElement.nativeElement.offsetWidth
                );
                this.thumbHeight = Number(
                    this.hostElement.nativeElement.offsetHeight
                );
            }
        }, 50);
    }

    onToggleSimilaritySearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.toggleSimilaritySearch.emit(this.asset);
    }

    private updateHostClassNames() {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const parentRect = this.hostElement.nativeElement.parentNode ? this.hostElement.nativeElement.parentNode.getBoundingClientRect() : null;
        const elementRect = this.hostElement.nativeElement.getBoundingClientRect();
        if (parentRect && elementRect) {
            this.topClassName = Math.ceil(parentRect.top + 20) >= Math.ceil(elementRect.top);
            this.firstClassName = Math.ceil(parentRect.left + 20) >= Math.ceil(elementRect.left);
            this.lastClassName = Math.ceil(parentRect.right - 20) <= Math.ceil(elementRect.right);
        }

    }

    private updateActions() {
        this.assetActions$ = (this.assetActionPlugins.length > 0)
            ? combineLatest(...this.assetActionPlugins.map(plugin => plugin.getActions(this.asset))).pipe(
                map((pluginActions: AssetAction[][]) => {
                    return pluginActions.reduce((actions, a) => {
                        return [...actions, ...a];
                    }, [] as AssetAction[]);
                })
            )
            : of([]);
    }

    downloadPreview(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.DOWNLOAD_PREVIEW);
        this.assetService.download(this.asset.id, 'layout');
    }

    /**
     * Return if the asset has restrictions
     */
    hasRestriction() {
        if (this.asset && this.asset.restrictions && this.asset.restrictions.length) {
            const user = this.userDataService.getUser();
            return !!this.asset.restrictions.find(restriction => restriction.type === 'block' || restriction.type === 'reservation' || restriction.type === 'block_internaly');
        } else {
            return false;
        }
    }

}
