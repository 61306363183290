import {Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AssetFilterPartialComponent} from '../asset-filter-partial-component/asset-filter-partial.component';
import {skip, startWith, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

export const DEFAULT_DATA = {
    baby: false,
    children: false,
    youth: false,
    adult: false,
    senior: false,
    mixedAgeGroups: false
};

export const CATEGORY_MAPPING =  {
    83: 'baby',
    84: 'children',
    85: 'youth',
    86: 'adult',
    87: 'senior',
    88: 'mixedAgeGroups'
}

@Component({
    selector: 'st-asset-search-people-filter',
    templateUrl: './asset-search-people-filter.component.html',
    styleUrls: ['./asset-search-people-filter.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(
                () => AssetSearchPeopleFilterComponent
            ),
            multi: true
        }
    ]
})
export class AssetSearchPeopleFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy, ControlValueAccessor {

    form: FormGroup;

    @Input() set filter(filter) {
        if (filter && filter.peopleCategory) {
            const peopleCategory = {};
            filter.peopleCategory.map(value => peopleCategory[CATEGORY_MAPPING[value]] = true);


            this.form.setValue({
                ...DEFAULT_DATA,
                ...peopleCategory
            }, { emitEvent: false });

        }
    }

    componentDestroyed$ = new Subject<boolean>();
    onTouch: Function;

    @Input() showHeading = true;

    constructor(private fb: FormBuilder) {
        super();
        this.form = this.fb.group({
            baby: [],
            children: [],
            youth: [],
            adult: [],
            senior: [],
            mixedAgeGroups: []
        });
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    writeValue(value: any) {
        if (value) {
            this.form.patchValue(value, { emitEvent: false });
        }
    }

    registerOnChange(fn: Function) {
    }

    registerOnTouched(fn: Function) {
    }

    onChange() {
        const returnValues = [];
        if (this.form.value.baby) {
            returnValues.push(83);
        }
        if (this.form.value.children) {
            returnValues.push(84);
        }
        if (this.form.value.youth) {
            returnValues.push(85);
        }
        if (this.form.value.adult) {
            returnValues.push(86);
        }
        if (this.form.value.senior) {
            returnValues.push(87);
        }
        if (this.form.value.mixedAgeGroups) {
            returnValues.push(88);
        }
        this.changes.emit({ peopleCategory: returnValues });
    }

}
