import {Injectable} from '@angular/core';

/**
 * Service that returns native window object ref
 */
@Injectable()
export class WindowRefService {
    constructor() {}

    /**
     * Returns window object
     */
    get nativeWindow(): any {
        if (typeof window !== 'undefined' && window.document) {
            return window;
        }

        return {
            scrollTo: function() {}
        };
    }

    get hasVerticalScrollbar() {
        const rootElem = document.documentElement || document.body;

        return rootElem.scrollHeight > rootElem.clientHeight;
    }
}
