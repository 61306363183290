import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventTrackerService {

    eventTracker: BehaviorSubject<EventTrackerPayload> = new BehaviorSubject<EventTrackerPayload>(null);

    constructor() {
    }

    pushNewEvent(event: any, data?: any) {
        this.eventTracker.next({
            type: event,
            payload: data
        });
    }

    getEvent(): Observable<EventTrackerPayload> {
        return this.eventTracker.asObservable();
    }
}

export interface EventTrackerPayload {
    type: EventTrackerEvents;
    payload: any;
}

export enum EventTrackerEvents {
    AI_PLUS_ASSET_SEARCH = 'AI_PLUS_EXTERNAL_ASSET_SEARCH',
    AI_PLUS_ASSET_URL_SEARCH = 'AI_PLUS_EXTERNAL_ASSET_URL_SEARCH',
    ADD_TO_CART = 'ADD_TO_CART',
    DOWNLOAD_PREVIEW = 'DOWNLOAD_PREVIEW',
    DOWNLOAD_HIGH_RES = 'DOWNLOAD_HIGH_RES',
    BUY_ASSETS = 'BUY_ASSETS',
    AI_PLUS_SEARCH = 'AI_PLUS_SEARCH'
}
