import { SodaApiService } from './soda-api.service';
import { UserLoginService } from './user-login.service';
import { HttpHandler } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { SDK_OPTIONS, SdkOptions } from '../models/sdk-options';
import { FactoryProvider, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SSR_REQUEST_CONTEXT } from '../models/ssr_request_context';

export function SodaApiServiceFactory(_handler: HttpHandler, userLogin: UserLoginService,
                                      authenticationService: AuthenticationService, translateService: TranslateService,
                                      sdkOptions: SdkOptions,  ssrRequestContext?: any) {
    return new SodaApiService(_handler, userLogin, authenticationService, translateService, sdkOptions, ssrRequestContext);
}

export const SodaApiServiceProvider: FactoryProvider = {
    provide: SodaApiService,
    useFactory: SodaApiServiceFactory,
    deps: [HttpHandler, UserLoginService, AuthenticationService, TranslateService, SDK_OPTIONS, [new Optional(), SSR_REQUEST_CONTEXT]]
};
