import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { SDK_OPTIONS, SdkOptions } from '../models/sdk-options';

@Pipe({
    name: 'storyblokRendering'
})
export class StoryblokRenderingPipe implements PipeTransform {
    domains: string[] = [
        's3.amazonaws.com/a.storyblok.com',
        'a.storyblok.com',
    ];
    constructor(@Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
                @Inject(PLATFORM_ID) private readonly platformId: Object) {
    }

    transform(image: any, width: string, disable = false): any {
        const matchedDomain = this.domains.filter(domain => image.indexOf(domain) > -1)[0];
        if (!disable && image && width && matchedDomain) {
            if ( isPlatformServer(this.platformId) ) {
                width = '320';
            }
            const imageService = '//img2.storyblok.com/';
            const path = image
                .replace(`//${matchedDomain}`, '')
                .replace('https:', '');
            const option = width + 'x0';
            return imageService + option + path;
        } else {
            return image;
        }
    }
}
