import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BreakpointService } from '../services/breakpoint.service';
import { SDK_OPTIONS, SdkOptions } from '../models/sdk-options';

@Pipe({
    name: 'breakpointMap'
})
export class BreakpointMapPipe implements PipeTransform {
    constructor(@Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
                private breakpointService: BreakpointService) {}

    transform(breakpointsMap: { [K: string]: any } = this.sdkOptions.rendering.breakpointSizesMap): Observable<string> {
        return this.breakpointService.getCurrentBreakpoint().pipe(
            filter(Boolean),
            map(breakpoint => breakpointsMap[breakpoint])
        );
    }
}
