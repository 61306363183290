import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../models/user';
import {CountryService} from '../../services/country.service';
import {UserService} from '../../services/user.service';
import {UserDataService} from '../../services/user-data.service';
import {UserAddress} from '../../models/user-address';
import { take, takeUntil } from 'rxjs/operators';
import {BusinessType} from '../../models/user';
import {BUSINESS_TYPES} from '../auth/signup/signup.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';

/**
 * Component that displays the users profile where he can change his personal details
 */
@Component({
    selector: 'st-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public countries;

    public user: User = null;

    public formErrors = {
        password: [],
        passwordConfirm: [],
        familyName: [],
        givenName: [],
        company: [],
        postalCode: [],
        streetAddress: [],
        streetAddress2: [],
        addressLocality: [],
        addressRegion: [],
        addressCountry: [],
        taxNumber: [],
        business: [],
        telephone: []
    };

    public success = false;
    public errorMessages: string[] = [];
    public businessTypesKeys;
    public businessTypes = this.sdkOptions.businessTypes;

    private validationMessages = {
        en: {
            general: {
                required: 'This field is required.',
                validatePasswordConfirmation: 'The passwords do not match.'
            }
        },
        de: {
            general: {
                required: 'Dieses Feld kann nicht leer sein.',
                validatePasswordConfirmation: 'Die Passwörter stimmen nicht überein.'
            }
        }
    };

    componentDestroyed$: Subject<void> = new Subject();

    constructor(
        private countryService: CountryService,
        private userService: UserService,
        private fb: FormBuilder,
        private userDataService: UserDataService,
        private translateService: TranslateService,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions
    ) {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group(
            {
                password: '',
                passwordConfirm: '',
                familyName: ['', [Validators.required]],
                givenName: ['', [Validators.required]],
                company: '',
                postalCode: ['', [Validators.required]],
                streetAddress: ['', [Validators.required]],
                streetAddress2: [''],
                addressLocality: ['', [Validators.required]],
                addressRegion: '',
                addressCountry: [this.sdkOptions.primaryCountry, [Validators.required]],
                taxNumber: [''],
                business: [''],
                telephone: ['', [Validators.required]],
                newsletterSubscribed: ['']
            },
            {
                validator: this.validatePasswordConfirmation
            }
        );

        this.form.valueChanges.subscribe(data => this.onValueChanged(data));
        this.onValueChanged(); // (re)set validation messages now
    }

    ngOnInit() {
        this.getCountries();
        this.businessTypesKeys = Object.keys(this.businessTypes).sort();

        this.userService.getUser().then(user => {
            this.user = user;
            const address = user.address.find(
                data => data.contactType === 'main'
            );

            if (!address) {
                return;
            }

            this.form.patchValue({
                familyName: user.familyName,
                givenName: user.givenName,
                company: user.company,
                postalCode: address.postalCode,
                streetAddress: address.streetAddress,
                streetAddress2: address.streetAddress2,
                addressLocality: address.addressLocality,
                addressRegion: address.addressRegion,
                addressCountry: address.addressCountry,
                taxNumber: user.taxNumber,
                business: (user.userFunction && user.userFunction.length) ? user.userFunction[0] : null,
                telephone: address.telephone,
                newsletterSubscribed: user.newsletterSubscribed
            });
        });

        this.translateService.onLangChange.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            this.getCountries();
        });
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
    }

    /**
     * Returns a list of available countries
     */
    getCountries(): void {
        this.countryService
            .getSortedCountries()
            .pipe(take(1))
            .subscribe(countries =>
                this.countries = countries
            );
    }

    /**
     * Validates that passwords match
     * @param group
     */
    validatePasswordConfirmation(group: FormGroup) {
        const pw = group.controls['password'];
        const pw2 = group.controls['passwordConfirm'];

        if (pw.value !== pw2.value) {
            pw2.setErrors({validatePasswordConfirmation: true});
        } else if (pw2.hasError('validatePasswordConfirmation')) {
            pw2.setErrors(null);
        }

        return null;
    }

    onValueChanged(data?: any) {
        this.setFormErrors(true);
    }

    /**
     * Sets form errors
     * @param ignorePristine
     */
    setFormErrors(ignorePristine): void {
        const lang = this.translateService.currentLang;
        const validationMessages = this.validationMessages[lang];
        if (!this.form) {
            return;
        }
        const form = this.form;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = [];

            const control = form.get(field);

            if (
                control &&
                (!ignorePristine || control.dirty) &&
                !control.valid
            ) {
                for (const key in control.errors) {
                    let message = 'Some error happened';
                    if (
                        validationMessages[field] &&
                        validationMessages[field][key]
                    ) {
                        message = validationMessages[field][key];
                    } else if (
                        validationMessages['general'] &&
                        validationMessages['general'][key]
                    ) {
                        message = validationMessages['general'][key];
                    } else {
                        message = control.errors[key];
                    }

                    this.formErrors[field].push(message);
                }
            }
        }
    }

    /**
     * Updates user details through the api
     */
    save() {
        this.setFormErrors(false);
        this.errorMessages = [];
        this.success = false;

        if (!this.form.valid) {
            return;
        }
        const user = this.prepareSaveUser();

        this.userService.update(user).then(
            successData => {
                this.userDataService.reload();
                this.success = true;
            },
            errorData => {
                const errorContent = errorData.error;

                if (errorContent.violations && errorContent.violations) {
                    for (const propertyPath in errorContent.violations) {
                        this.form
                            .get(propertyPath)
                            .setErrors(errorContent.violations[propertyPath]);
                    }

                    this.setFormErrors(false);
                }
            }
        );
    }

    /**
     * Creates the payload to be saved through the api
     */
    prepareSaveUser(): User {
        const formModel = this.form.value;

        const user = this.user;

        user.password = formModel.password;
        user.familyName = formModel.familyName;
        user.givenName = formModel.givenName;
        user.company = formModel.company;
        user.taxNumber = formModel.taxNumber;
        user.userFunction = [+formModel.business];
        user.newsletterSubscribed = formModel.newsletterSubscribed;

        let address = null;

        for (const tmp of user.address) {
            if (tmp.contactType === 'main') {
                address = tmp;
                break;
            }
        }

        let newAddress = false;
        if (!address) {
            address = new UserAddress();
            newAddress = true;
        }

        address.contactType = 'main';
        address.familyName = formModel.familyName;
        address.givenName = formModel.givenName;
        address.postalCode = formModel.postalCode;
        address.streetAddress = formModel.streetAddress;
        address.streetAddress2 = formModel.streetAddress2;
        address.addressLocality = formModel.addressLocality;
        address.addressRegion = formModel.addressRegion;
        address.addressCountry = formModel.addressCountry;
        address.telephone = formModel.telephone;

        if (newAddress) {
            user.address = [address];
        }

        return user;
    }
}
