import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Services that stores the information about the user authentification
 */
@Injectable()
export class AuthenticationService {
    /**
     * BehaviourSubject that indicates whether the signup was successful or not
     */
    signupSuccessful$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    /**
     * BehaviourSubject that indicates whether the signup verfication was successful or not
     */
    signupVerificationSuccessful$: BehaviorSubject<
        boolean
    > = new BehaviorSubject<boolean>(false);

    /**
     * BehaviourSubject that indicates whether the user is logged in or not
     */
    isUserLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    /**
     * BehaviourSubject that indicates whether the user is logged out or not
     */
    isUserLoggedOut$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    constructor() {}

    /**
     * Returns the signup successful status
     */
    getSignupSuccessfulStatus(): Observable<boolean> {
        return this.signupSuccessful$.asObservable();
    }

    /**
     * Changes the signup succesful status
     * @param status
     */
    setSignupSuccessfulStatus(status: boolean) {
        this.signupSuccessful$.next(status);
    }

    /**
     * Returns the signup verification successful status
     */
    getSignupVerificationSuccessfulStatus(): Observable<boolean> {
        return this.signupVerificationSuccessful$.asObservable();
    }

    /**
     * Changes the signup succesful status
     * @param status
     */
    setSignupVerificationSuccessfulStatus(status: boolean) {
        this.signupVerificationSuccessful$.next(status);
    }

    /**
     * Returns the user logged in status
     */
    isUserLoggedIn(): Observable<boolean> {
        return this.isUserLoggedIn$.asObservable();
    }

    /**
     * Changes the logged in status
     * @param status
     */
    setUserLoginStatus(status: boolean) {
        this.isUserLoggedIn$.next(status);
    }

    /**
     * Returns the user logged out status
     */
    isUserLoggedOut(): Observable<boolean> {
        return this.isUserLoggedOut$.asObservable();
    }

    /**
     * Changes the logged in status
     */
    setUserLoggedOutStatus(status: boolean) {
        this.isUserLoggedOut$.next(status);
    }
}
