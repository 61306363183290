import {Injectable} from '@angular/core';
import {PreferencesService} from './preferences.service';
import {LightboxService} from './lightbox.service';
import {Lightbox} from '../models/lightbox';
import {LightboxAsset} from '../models/lightbox_asset';
import {UserDataService} from './user-data.service';
import {UserLoginService} from './user-login.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, skip, switchMap, take, takeWhile} from 'rxjs/operators';
import {DragulaService} from 'ng2-dragula';

/**
 * Service that interacts with the lightbox api using LightboxService
 * Stores lightbox data
 */
@Injectable()
export class LightboxDataService {
    private lightboxesSubject: BehaviorSubject<{}> = new BehaviorSubject(
        this.getEmptyLightboxes()
    );
    private lightboxes: Observable<{}> = this.lightboxesSubject.asObservable();

    private defaultInitInProgress = false;

    dragulaStates: {
        [dragulaId: string]: number
    } = {};

    constructor(
        private preferenceService: PreferencesService,
        private lightboxService: LightboxService,
        private userDataService: UserDataService,
        private userLogin: UserLoginService,
        private dragulaService: DragulaService
    ) {
        this.userDataService.user.subscribe(user => {
            if (user && user.id) {
                this.load();
            } else {
                this.setLightboxes(this.getEmptyLightboxes());
            }
        });
    }

    attachDragAndDropForLightbox(dragulaId) {
        this.dragulaStates = {
            ...this.dragulaStates,
            [dragulaId]: (this.dragulaStates[dragulaId]) ? this.dragulaStates[dragulaId] + 1 : 1
        };
    }

    detachDragAndDropForLightbox(dragulaId: string) {
        this.dragulaStates = {
            ...this.dragulaStates,
            [dragulaId]: (this.dragulaStates[dragulaId]) ? this.dragulaStates[dragulaId] - 1 : 0
        };

        if (this.dragulaStates[dragulaId] === 0) {
            this.dragulaService.destroy(dragulaId);
        }
    }

    /**
     * Returns a new empty lightboxes object
     * @returns
     */
    getEmptyLightboxes() {
        return {
            list: [],
            data: {},
            selectedId: null
        };
    }

    /**
     * Returns selected lightbox as an Observable
     * @returns
     */
    getSelectedLightboxObservable(): Observable<Lightbox> {
        return this.lightboxes.pipe(
            switchMap(lightboxes => {
                const lightboxId = this.getSelectedLightboxIdFromData(lightboxes);
                if (!(lightboxId > 0)) {
                    Observable.create();
                }

                if (lightboxes['selectedId'] !== lightboxId) {
                    this.setSelectedLightboxId(lightboxId);
                }

                return this.getLightboxObservable(lightboxId);
            }),
            distinctUntilChanged(null, x => JSON.stringify(x))
        );
    }

    /**
     * Returns lighbox as Observable
     * @param lightboxId lightbox id
     * @returns
     */
    getLightboxObservable(lightboxId: number): Observable<Lightbox> {
        return this.lightboxes.pipe(
            map(lightboxes => {
                const lightbox = lightboxes['data'][lightboxId];
                if (!lightbox) {
                    return null;
                }

                return lightbox;
            }),
            distinctUntilChanged(null, x => JSON.stringify(x))
        );
    }

    /**
     * Returns lightboxes list as an Observable
     * @returns
     */
    getLightboxListObservable(): Observable<Lightbox[]> {
        return this.lightboxes.pipe(
            map(lightboxes => {
                const lightboxList = [];

                for (const lightboxId of lightboxes['list']) {
                    if (lightboxes['data'][lightboxId]) {
                        lightboxList.push(lightboxes['data'][lightboxId]);
                    }
                }

                return lightboxList;
            }),
            distinctUntilChanged(null, x => JSON.stringify(x))
        );
    }

    /**
     * Load lightboxes from api
     */
    load() {
        this.lightboxService.getLightboxes({
            itemsPerPage: 150
        }).subscribe(
            result => {
                const newLightboxes = this.getEmptyLightboxes();

                if (result.result && Array.isArray(result.result)) {
                    for (const lightbox of result.result) {
                        newLightboxes.data[Number(lightbox.id)] = lightbox;
                        newLightboxes.list.push(lightbox.id);
                    }
                }

                newLightboxes.selectedId = this.getSelectedLightboxIdFromData(
                    newLightboxes
                );

                this.setLightboxes(newLightboxes);
            },
            () => {
                console.error('Could not load lightboxes');
            }
        );
    }

    /**
     * Sets lightboxes
     * @param lightboxes to be setted and emitted by lightboxesSubject
     * @returns
     */
    private setLightboxes(lightboxes) {
        if (
            this.userLogin.isLoggedIn() &&
            (!lightboxes || !lightboxes.list || !lightboxes.list.length)
        ) {
            if (!this.defaultInitInProgress) {
                this.defaultInitInProgress = true;

                const lightbox = new Lightbox();
                lightbox.name = 'My First Gallery';
                this.createLightbox(lightbox);
            }

            return;
        }

        this.lightboxesSubject.next(lightboxes);
    }

    /**
     * Get lightboxes subject observable value
     * @returns
     */
    private getLightboxes() {
        return this.lightboxesSubject.value;
    }

    /**
     * Sets a lightbox
     * @param lightbox
     * @returns
     */
    setLightbox(lightbox: Lightbox) {
        if (!(lightbox.id > 0)) {
            return;
        }

        const lightboxId = Number(lightbox.id);
        const lightboxes = this.getLightboxes();
        lightboxes['data'][lightboxId] = lightbox;

        if (lightboxes['list'].indexOf(lightboxId) === -1) {
            lightboxes['list'].unshift(lightboxId);
        }

        this.setLightboxes(lightboxes);
    }

    /**
     * Removes a lightbox
     * @param lightboxId
     * @returns
     */
    removeLightbox(lightboxId: number) {
        if (!(lightboxId > 0)) {
            return;
        }

        const lightboxes = this.getLightboxes();
        lightboxes[lightboxId] = null;

        const pos = lightboxes['list'].indexOf(lightboxId);
        if (pos !== -1) {
            lightboxes['list'].splice(pos, 1);
        }

        if (this.getSelectedLightboxId() === lightboxId) {
            this.setSelectedLightboxId(lightboxes['list'][0]);
        }

        this.setLightboxes(lightboxes);
    }

    /**
     * Gets a lightbox by id
     * @param lightboxId lightbox id
     * @returns
     */
    getLightbox(lightboxId: number): Lightbox {
        return this.getLightboxes()['data'][lightboxId];
    }

    /**
     * Gets assets in a lightbox
     * @param lightboxId lightbox id
     * @returns
     */
    getAssets(lightboxId: number): LightboxAsset[] {
        const lightbox = this.getLightbox(lightboxId);
        if (!lightbox) {
            return [];
        }

        let assets = lightbox.assets;

        if (!(assets instanceof Array)) {
            assets = [];
        }

        return assets;
    }

    /**
     * Sets assets in a lightbox
     * @param lightboxId lightbox id
     * @param assets
     * @memberof LightboxDataService
     */
    setAssets(lightboxId: number, assets: LightboxAsset[]) {
        if (!assets) {
            assets = [];
        }

        const lightbox = this.getLightbox(lightboxId);
        lightbox.assets = assets;
        lightbox.modificationDate = new Date();

        this.setLightbox(lightbox);
    }

    /**
     *
     * Adds an asset to a lightbox
     * @param assetId asset id
     * @param comment
     * @returns
     */
    addAsset(assetId, comment, lightboxId?: number, position?: number) {
        if (!this.userLogin.isLoggedIn()) {
            this.userLogin
                .login()
                .pipe(
                    skip(1),
                    take(1)
                )
                .subscribe(data => {
                    if (data !== 'login') {
                        return;
                    }

                    this.userDataService.user
                        .pipe(
                            takeWhile(user => {
                                if (!user || !(user.id > 0)) {
                                    return true;
                                }

                                this.addAsset(assetId, comment);

                                return false;
                            })
                        )
                        .subscribe();
                });

            return;
        }

        if (!(lightboxId > 0)) {
            lightboxId = this.getSelectedLightboxId();
            if (!lightboxId) {
                return null;
            }
        }

        return this.lightboxService
            .addAsset(lightboxId, assetId, '', position)
            .pipe(
                map(data => {
                    const assets = this.getAssets(lightboxId);
                    assets.splice(position, 0, data);
                    this.setAssets(lightboxId, assets);
                    return data;
                })
            )
            .toPromise();
    }

    /**
     * Deletes an asset in lightbox
     * @param lightboxId
     * @param assetId
     * @returns
     */
    deleteAsset(lightboxId: number, assetId) {
        if (!(lightboxId > 0)) {
            lightboxId = this.getSelectedLightboxId();
            if (!lightboxId) {
                return null;
            }
        }

        return this.lightboxService
            .deleteAsset(lightboxId, assetId)
            .toPromise()
            .then(() => {
                const assets = this.getAssets(lightboxId);

                const newAssets = [];
                for (const asset of assets) {
                    if (asset.id !== assetId) {
                        newAssets.push(asset);
                    }
                }

                this.setAssets(lightboxId, newAssets);
            });
    }

    /**
     * Updates an asset in a lightbox
     * @param lightboxId lightbox id
     * @param assetId asset id
     * @param comment
     * @param position
     * @returns
     */
    updateAsset(
        lightboxId: number,
        assetId: string,
        comment?: string,
        position?: number
    ): Promise<any> {
        return this.lightboxService
            .updateAsset(lightboxId, assetId, comment, position)
            .then(data => {
                const assets = this.getAssets(lightboxId);

                const newAssets = [];
                let updateAsset = null;
                for (const asset of assets) {
                    if (asset.id === assetId) {
                        updateAsset = asset;
                    } else {
                        newAssets.push(asset);
                    }
                }

                if (updateAsset) {
                    newAssets.splice(position - 1, 0, updateAsset);
                }

                this.setAssets(lightboxId, newAssets);

                return data;
            });
    }

    /**
     * Sets the selected lighbox id
     * @param lightboxId lightbox id
     * @returns
     */
    setSelectedLightboxId(lightboxId: number) {
        const currentLightboxId = this.getSelectedLightboxId();
        if (currentLightboxId === lightboxId) {
            return true;
        }

        const lightboxes = this.getLightboxes();
        if (!lightboxes['data'][lightboxId]) {
            return false;
        }

        lightboxes['selectedId'] = lightboxId;
        this.preferenceService.set('lightbox_id', lightboxId);
        this.setLightboxes(lightboxes);

        return true;
    }

    /**
     * Returns the stored lighbox id
     * @returns
     */
    getSelectedLightboxId(): number {
        const lightboxes = this.getLightboxes();
        return this.getSelectedLightboxIdFromData(lightboxes);
    }

    /**
     * Gets the selected lightbox id from passed lightboxes
     * @param lightboxes
     * @returns
     */
    private getSelectedLightboxIdFromData(lightboxes): number {
        let lightboxId = Number(lightboxes['selectedId']);
        if (lightboxId > 0) {
            return lightboxId;
        }

        lightboxId = Number(this.preferenceService.get('lightbox_id'));
        if (lightboxId > 0) {
            return lightboxId;
        }

        if (
            lightboxes['list'] &&
            Array.isArray(lightboxes['list']) &&
            lightboxes['list'].length > 0
        ) {
            lightboxId = Number(lightboxes['list'][0]);
            if (lightboxes['data'] && lightboxes['data'][lightboxId]) {
                return lightboxId;
            }
        }

        return null;
    }

    /**
     * Updates a lightbox
     * @param  lightbox lightbox data
     * @returns
     */
    updateLightbox(lightbox: Lightbox) {
        lightbox['creationDate'] = null;
        lightbox['modificationDate'] = null;

        return this.lightboxService
            .updateLightbox(lightbox.id, lightbox)
            .pipe(
                map(data => {
                    this.setLightbox(data);
                    return data;
                })
            )
            .toPromise();
    }

    /**
     * Creates a new lightbox
     * @param lightbox
     * @returns
     */
    createLightbox(lightbox: Lightbox) {
        if (!this.userDataService.isLoaded()) {
            return this.userLogin
                .login()
                .pipe(
                    skip(1),
                    take(1)
                )
                .toPromise().then(data => {
                    if (data !== 'login') {
                        return;
                    }

                    return this.lightboxService
                        .createLightbox(lightbox)
                        .pipe(
                            map(lb => {
                                this.setLightbox(lb);
                                this.setSelectedLightboxId(lb.id);
                                this.defaultInitInProgress = false;
                                return lb;
                            })
                        ).toPromise();
                });
        }
        return this.lightboxService
            .createLightbox(lightbox)
            .pipe(
                map(data => {
                    this.setLightbox(data);
                    this.setSelectedLightboxId(data.id);
                    this.defaultInitInProgress = false;
                    return data;
                })
            )
            .toPromise();
    }

    /**
     * Deletes a lightbox
     * @param lightboxId lightbox id
     * @returns
     */
    deleteLightbox(lightboxId: number) {
        return this.lightboxService
            .deleteLightbox(lightboxId)
            .toPromise()
            .then(() => {
                this.removeLightbox(lightboxId);
            });
    }

    /**
     * Checks if the given asset exists in the given lightbox
     * @param lightboxId
     * @param assetId
     * @returns
     */
    isAssetInLightbox(lightboxId: number, assetId): boolean {
        const assets = this.getAssets(lightboxId);
        for (const asset of assets) {
            if (asset.id === assetId) {
                return true;
            }
        }

        return false;
    }
}
