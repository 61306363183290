import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';

import { Collection } from '../../models/collection';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { CollectionDataService } from '../../services/collection-data.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'st-asset-collections-filter',
    templateUrl: './asset-collections-filter.component.html',
    styleUrls: ['./asset-collections-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AssetCollectionsFilterComponent extends AssetFilterPartialComponent implements OnInit, OnChanges {

    @Output() addCollection = new EventEmitter();

    @Input() showHeading = true;

    filter$: BehaviorSubject<any> = new BehaviorSubject({});

    availableCollections$: BehaviorSubject<Partial<Collection>[]> = new BehaviorSubject([]);

    selectedCollections$: Observable<Partial<Collection>[]>;

    constructor(private collectionService: CollectionDataService) {
        super();
        this.selectedCollections$ = combineLatest(this.filter$, this.availableCollections$).pipe(map(([filter, collections]) => {

            if (!filter || !filter['coll'] || filter['coll'].length === 0 || !collections) {
                return [];
            }
            return collections.filter(coll => filter['coll'].indexOf(coll.id) > -1);
        }));
    }

    async ngOnInit() {
        this.availableCollections$.next(await this.collectionService.getCopyrightCollections());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['filter']) {
            this.filter$.next(this.filter);
        }
    }

    onAddCollectionClicked(e: Event) {
        e.preventDefault();
        this.addCollection.emit();
    }

    removeSelectedCollection(collection: Collection) {
        this.changes.emit({
            coll: this.filter['coll'].filter(coll => coll !== collection.id)
        });
    }
}
