import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    Input,
    ElementRef
} from '@angular/core';
import { Router, Params } from '@angular/router';

import {
    CMSBlockContentComponent,
    CmsMenuItem,
    CmsMenuItemLinkTypes
} from '../../models/cms.model';
import { CmsBlockContent } from '../cms-block/cms-block-content';
import {UserService} from '../../services/user.service';
import {UserDataService} from '../../services/user-data.service';

@Component({
    selector: 'st-cms-menu-item',
    templateUrl: './cms-menu-item.component.html',
    styleUrls: ['./cms-menu-item.component.css']
})
export class CmsMenuItemComponent extends CmsBlockContent
    implements OnInit, OnChanges, CMSBlockContentComponent {
    @Input() data: CmsMenuItem;
    queryParamsObj: Params = {};

    get isInternalLink() {
        return this.data.linkType === CmsMenuItemLinkTypes.InternalUrl;
    }

    constructor(el: ElementRef, private router: Router, public userDataService: UserDataService) {
        super(el);
    }

    ngOnInit(): void {
        this.data.queryParams =
            this.data.queryParams &&
            this.data.queryParams !== '{}' &&
            this.data.queryParams.length > 0
                ? this.data.queryParams
                : null;

        if (this.data.queryParams) {
            this.queryParamsObj = JSON.parse(this.data.queryParams);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {}


}
