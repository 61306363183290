import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Directive for an outside click event
 */
@Directive({
    selector: '[outsideClick]' // tslint:disable-line:directive-selector
})
export class OutsideClickDirective {

    constructor(private _elementRef: ElementRef) { }

    @Output()
    public outsideClick: EventEmitter<void> = new EventEmitter<void>();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.outsideClick.emit();
        }
    }
}
