import {
    Component,
    Input,
    HostBinding,
    SimpleChanges,
    Inject,
    OnChanges,
    AfterViewInit,
    ViewChild,
    ElementRef,
    Renderer2,
    PLATFORM_ID
} from '@angular/core';

import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

import {AssetService, AssetSize} from '../../services/asset.service';
import {Asset} from '../../models/asset';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';
import { LazysizesService } from '../../services/lazysizes.service';
import { isPlatformBrowser } from '@angular/common';
import { filter, take } from 'rxjs/operators';

const objectFitImages = require('object-fit-images');

@Component({
    selector: 'st-display-asset',
    templateUrl: './display-asset.component.html',
    styleUrls: ['./display-asset.component.scss'],
})
export class DisplayAssetComponent implements AfterViewInit, OnChanges {

    @Input() assetId: string;
    @Input() webseriesId: number;
    @Input() assetSize = 1024;
    @Input() height: string | number = 'auto';
    @Input() backgroundUrl: string;

    @HostBinding('style.backgroundImage') backgroundImage;

    image: string;

    @HostBinding('style.paddingTop')
    get assetHeight() {
        if (this.height === 'auto') {
            const percent = (this.assetAspectRatio * 100);
            return `${percent}%`;
        } else if (this.height === 'none') {
            return null;
        } else {
            return `${this.height}px`;
        }
    }

    assetAspectRatio: number;

    useNativeImage = false;
    useLazySizes = false;
    useStoryblokRendering = false;

    /**
     * Native image reference
     */
    @ViewChild('imageRef') imageRef: ElementRef;

    /**
     * Breakpoints sizes map
     */
    imageSizesMap: { [key: string]: string };

    constructor(
        private sanitizer: DomSanitizer,
        private assetService: AssetService,
        private lazysizesService: LazysizesService,
        private renderer: Renderer2,
        private elRef: ElementRef,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if ( this.sdkOptions.layout && this.sdkOptions.layout.displayAsset ) {
            this.useNativeImage = this.sdkOptions.layout.displayAsset.useNativeImage;
            this.useLazySizes = this.sdkOptions.layout.displayAsset.useLazySizes;
        }
        this.imageSizesMap = this.sdkOptions.rendering ? this.sdkOptions.rendering.breakpointSizesMap : {};
    }

    ngAfterViewInit(): void {
        if ( this.useNativeImage && this.imageRef) {
            objectFitImages(this.imageRef.nativeElement);
        }
        if ( this.useLazySizes && this.imageRef && isPlatformBrowser(this.platformId)) {
            this.renderer.setAttribute(this.imageRef.nativeElement, 'data-src',
                this.imageRef.nativeElement.getAttribute('data-image-src') || this.image
            );
        }
    }

    async setBackgroundImage() {
        const imageUrl = this.getAssetBackgroundUrl(this.assetId);
        this.image = this.useNativeImage ? imageUrl : null;
        this.useStoryblokRendering = !this.assetId;
        this.backgroundImage = this.useNativeImage ? null : this.getSanitizedStyle(imageUrl);
        const asset: Asset = await this.assetService.getAsset(this.assetId);
        this.assetAspectRatio = (asset) ? asset.height / asset.width : 1;
    }

    async setBackgroundImageUrl() {
        this.image = this.useNativeImage ? this.backgroundUrl : null;
        this.useStoryblokRendering = !!this.backgroundUrl;
        this.backgroundImage =  this.useNativeImage ? null : this.getSanitizedStyle(this.backgroundUrl);
        if ( !this.useLazySizes ) {
            const assetSize: AssetSize = await this.assetService.getAssetSize(this.backgroundUrl).toPromise();
            this.assetAspectRatio = (assetSize) ? assetSize.height / assetSize.width : 1;
        }
    }

    getSanitizedStyle(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['backgroundUrl'] && this.backgroundUrl) {
            this.setBackgroundImageUrl();
        } else if ((changes['assetId'] || changes['webseriesId']) && this.assetId && this.webseriesId) {
            this.setBackgroundImage();
        }
    }

    getAssetBackgroundUrl(assetId): string {
        const baseUrl = this.sdkOptions.imgPath;
        return `${baseUrl}webseries/${this.webseriesId}/assets/${assetId}/${this.assetSize}`;
    }

    setStyle(el, key, value): void {
        this.renderer.setStyle(el, key, value);
    }

    onImageLoaded(img: string) {
        this.assetService.getAssetSize(img).pipe(
            filter(Boolean),
            take(1)
        ).subscribe(assetSize => {
            this.assetAspectRatio = (assetSize) ? assetSize.height / assetSize.width : 1;
        })
    }
}
