import {Component, ElementRef, OnInit} from '@angular/core';
import {CmsBlockContent} from '../cms-block/cms-block-content';

@Component({
    selector: 'lib-cms-html',
    templateUrl: './cms-html.component.html',
    styleUrls: ['./cms-html.component.css']
})
export class CmsHTMLComponent extends CmsBlockContent implements OnInit {

    classes: string;

    constructor(el: ElementRef) {
        super(el);
    }

    ngOnInit() {
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }
    }
}
