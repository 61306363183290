import { InjectionToken } from '@angular/core';
import {ComponentRegistries, registerInjectionTokenForComponentType} from '../helpers/dynamic-component.helper';

/** Component Content **/

export const ROW_COMPONENT = new InjectionToken(`ROW_COMPONENT`);
export const RICH_TEXT_COMPONENT = new InjectionToken(`RICH_TEXT_COMPONENT`);
export const HEADING_COMPONENT = new InjectionToken(`HEADING_COMPONENT`);
export const SLIDER_COMPONENT = new InjectionToken(`SLIDER_COMPONENT`);
export const SECTION_COMPONENT = new InjectionToken(`SECTION_COMPONENT`);
export const BUTTON_COMPONENT = new InjectionToken(`BUTTON_COMPONENT`);
export const VIDEO_COMPONENT = new InjectionToken(`VIDEO_COMPONENT`);
export const ICON_COMPONENT = new InjectionToken(`ICON_COMPONENT`);
export const IMAGE_COMPONENT = new InjectionToken(`IMAGE_COMPONENT`);
export const HTML_COMPONENT = new InjectionToken(`HTML_COMPONENT`);
export const MENU_COMPONENT = new InjectionToken(`MENU_COMPONENT`);
export const MENU_ITEM_COMPONENT = new InjectionToken(`MENU_ITEM_COMPONENT`);
export const MENU_GROUP_COMPONENT = new InjectionToken(`MENU_GROUP_COMPONENT`);
export const FAQ_COMPONENT = new InjectionToken('FAQ_COMPONENT');
export const FAQ_ENTRY_COMPONENT = new InjectionToken('FAQ_ENTRY_COMPONENT');
export const CONTENT_TEASER_COMPONENT = new InjectionToken(`CONTENT_TEASER_COMPONENT`);


export function registerInjectionTokensForDynamicComponents() {
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'row', ROW_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'rich-text', RICH_TEXT_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'heading', HEADING_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'slider', SLIDER_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'section', SECTION_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'button', BUTTON_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'icon', ICON_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'image', IMAGE_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'video', VIDEO_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'html', HTML_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'menu', MENU_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'menu-item', MENU_ITEM_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'menu-group', MENU_GROUP_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'faq', FAQ_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'faq-entry', FAQ_ENTRY_COMPONENT);
    registerInjectionTokenForComponentType(ComponentRegistries.CMS, 'content-teaser', CONTENT_TEASER_COMPONENT);
}
