import {
    Component,
    EventEmitter, Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Cart } from '../../models/cart';
import { Article } from '../../models/article';
import { Order } from '../../models/order';
import { PreferencesService } from '../../services/preferences.service';
import { CartDataService } from '../../services/cart-data.service';
import { CartService } from '../../services/cart.service';
import { UserLoginService } from '../../services/user-login.service';
import { WindowRefService } from '../../services/window-ref.service';
import { OrderService } from '../../services/order.service';
import { OrderDataService } from '../../services/order-data.service';

@Component({
    selector: 'st-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CartComponent implements OnInit {
    public sortForm: FormGroup;
    public articleForm: FormGroup = new FormGroup({});

    public cart: Cart = null;

    public detailArticle: Article = null;
    public paymentOrder: Order = null;

    @Output()
    checkout = new EventEmitter<{
        type: string,
        orderId: number
    }>();

    @Input() hideDuplicateItemAction = false;

    @Input() promotionCodeAllowed = true;

    constructor(
        private fb: FormBuilder,
        private preferenceService: PreferencesService,
        private cartDataService: CartDataService,
        private cartService: CartService,
        private router: Router,
        private userLogin: UserLoginService,
        private windowRefService: WindowRefService,
        private orderService: OrderService,
        private orderDataService: OrderDataService
    ) {
        this.orderDataService.loadOrder();

        this.orderDataService.order.subscribe(order => {
            this.paymentOrder = order;
        });
    }

    ngOnInit() {
        this.articleForm.addControl('acceptAgb', new FormControl(false));

        this.cartDataService.cart.subscribe(cart => {
            for (const controlName in this.articleForm.controls) {
                if (controlName.substr(0, 8) !== 'article_') {
                    continue;
                }

                const articleId = Number(controlName.substr(8));
                if (
                    !cart ||
                    !cart.articles.find(value => value.id === articleId)
                ) {
                    this.articleForm.removeControl(controlName);
                }
            }

            if (cart && cart.articles.length > 0) {
                for (const article of cart.articles) {
                    const controlName = 'article_' + article.id;
                    if (!this.articleForm.contains(controlName)) {
                        this.articleForm.addControl(
                            controlName,
                            new FormControl(true)
                        );
                    }
                }
            }

            this.cart = cart;
        });

        this.sortForm = this.fb.group({
            sort: this.getSortColumn()
        });

        this.sortForm.valueChanges.subscribe(data => {
            this.setSortColumn(data.sort);
        });
    }

    /**
     * Returns current sort field. Defaults to 'dateAdded'
     */
    getSortColumn(): string {
        const sort = this.preferenceService.get('cart_article_sort');

        return this.isValidSort(sort) ? sort : 'dateAdded';
    }

    /**
     * Sets a new sort column
     */
    setSortColumn(column: string) {
        if (!this.isValidSort(column)) {
            return;
        }

        this.preferenceService.set('cart_article_sort', column);
    }

    resetSortField() {
        this.sortForm.patchValue({ sort: this.getSortColumn() });
    }

    /**
     * Checks if given sort is valid
     */
    isValidSort(sort) {
        return ['dateAdded'].indexOf(sort) > -1;
    }

    /**
     * Checks if is currently sorted asc o desc
     */
    getReverseSort(): boolean {
        const sortAsc = this.preferenceService.get('cart_article_sort_asc');

        return sortAsc === null ? true : !!sortAsc;
    }

    /**
     * Sorts given articles asc or desc
     * @param articles
     * @param sortBy
     */
    sortArticles(articles: Article[], sortBy) {
        const sortAsc = this.getReverseSort();

        articles.sort(function(a, b) {
            let valA;
            let valB;

            if (sortAsc) {
                valA = a[sortBy];
                valB = b[sortBy];
            } else {
                valA = b[sortBy];
                valB = a[sortBy];
            }

            if (valA && valA.getTime) {
                valA = valA.getTime();
                valB = valB.getTime();

                return valB - valA;
            } else if (Array.isArray(valA) && Array.isArray(valB)) {
                return valA.length - valB.length;
            }

            valA = valA && valA.toUpperCase ? valA.toUpperCase() : '';
            valB = valB && valB.toUpperCase ? valB.toUpperCase() : '';

            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }

            return 0;
        });

        return articles;
    }

    /**
     * Return thumbnail url (contentUrl)
     */
    getThumbnailUrl(article: Article) {
        if (
            !article ||
            !article.asset ||
            !article.asset.id ||
            !article.asset.associatedMedia ||
            !Array.isArray(article.asset.associatedMedia)
        ) {
            return '';
        }

        for (const media of article.asset.associatedMedia) {
            if (media.additionalType === 'preview') {
                // 'preview'
                return media.contentUrl;
            }
        }

        return '';
    }

    /**
     * Removes and article from cart through the api
     * @param article
     */
    removeArticle(article: Article) {
        this.cartDataService.removeArticle(article.id);
    }

    /**
     * Modifies an article
     * @param article
     */
    modifyArticle(article: Article) {
        if (!article || !article.asset || !article.asset.id) {
            return;
        }

        this.detailArticle = article;
    }

    closeArticleDetail() {
        this.detailArticle = null;
    }

    /**
     * Duplicates an article through the api
     * @param article
     */
    duplicateArticle(article: Article) {
        this.cartDataService.addArticle(
            article.assetId,
            article.calculatorArguments
        );
    }

    switchReverseSort() {
        this.setReverseSort(!this.getReverseSort());
    }

    /**
     * Reverses current sort
     * @param sortAsc
     */
    setReverseSort(sortAsc: boolean) {
        this.preferenceService.set('cart_article_sort_asc', sortAsc);
        // this.cart.articles = this.sortArticles(this.cart.articles, this.getSortColumn());
    }

    calculateSize(article: Article) {
        let width = article.asset.width;
        let height = article.asset.height;

        if (article.maxSize > 0) {
            if (article.maxSizeType === 'megaPixel') {
                const factor = width / height;
                height = Math.sqrt((article.maxSize * 1024 * 1024) / factor);
                width = height * factor;
            } else {
                if (width > height) {
                    const factor = height / width;
                    width = article.maxSize;
                    height = width * factor;
                } else {
                    const factor = width / height;
                    height = article.maxSize;
                    width = height * factor;
                }
            }
        }

        if (width > article.asset.width || height > article.asset.height) {
            width = article.asset.width;
            height = article.asset.height;
        }

        return {
            width: Math.round(width),
            height: Math.round(height)
        };
    }

    calculateRawFileSize(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        const rawSize = imgSize.width * imgSize.height * 3;

        if (rawSize < 1024) {
            return rawSize + ' B';
        } else if (rawSize < 1024 * 1024) {
            return Math.round(rawSize / 1024) + ' KB';
        } else {
            return Math.round((rawSize / 1024 / 1024) * 10) / 10 + ' MB';
        }
    }

    calculateMegapixel(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        return (
            Math.round(
                ((imgSize.width * imgSize.height) / (1024 * 1024)) * 10
            ) /
                10 +
            ' MP'
        );
    }

    calculatePrintSize(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        const dpi = article.dpiSize > 0 ? article.dpiSize : 300;
        return (
            Math.round(imgSize.width * (2.54 / dpi) * 100) / 100 +
            'cm x ' +
            Math.round(imgSize.height * (2.54 / dpi) * 100) / 100 +
            'cm @ ' +
            dpi +
            ' DPI'
        );
    }

    /**
     * Checks if there are items in cart that require attention
     */
    attentionItems() {
        if (!this.cart || !this.cart.articles) {
            return false;
        }

        for (const controlName in this.articleForm.controls) {
            if (
                controlName.substr(0, 8) !== 'article_' ||
                !this.articleForm.controls[controlName].value
            ) {
                continue;
            }

            const articleId = Number(controlName.substr(8));
            const article = this.cart.articles.find(
                value => value.id === articleId
            );
            if (article && !(article.price > 0)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns the number of selected articles
     */
    articlesSelected() {
        const data = this.articleForm.getRawValue();

        let count = 0;
        for (const controlName in data) {
            if (controlName.substr(0, 8) !== 'article_' || !data[controlName]) {
                continue;
            }

            count++;
        }

        return count;
    }

    /**
     * Returns the total of articles
     */
    totalArticles() {
        if (!this.cart || !this.cart.articles) {
            return 0;
        }

        return this.cart.articles.length;
    }

    /**
     * Calculates cart total price
     */
    getTotalPrice() {
        if (!this.cart || !this.cart.articles) {
            return 0;
        }

        let totalPrice = this.cart.totalNettoPrice;

        const data = this.articleForm.getRawValue();

        for (const article of this.cart.articles) {
            const controlName = 'article_' + article.id;
            if (!data[controlName]) {
                totalPrice -= Number(article.price);
            }
        }

        return totalPrice;
    }

    isCheckoutActive() {
        if (this.attentionItems()) {
            return false;
        }

        if (!(this.articlesSelected() > 0)) {
            return false;
        }

        if (!this.articleForm.controls['acceptAgb'].value) {
            return false;
        }

        return true;
    }

    /**
     * Performs a checkout through the api
     */
    doCheckout() {
        if (!this.isCheckoutActive()) {
            return;
        }

        this.cartDataService.checkout(this.getSelectedArticleIds()).then(() => {
            this.checkout.next({
                type: 'payment',
                orderId: null
            });
        });
    }

    /**
     * Cancels payment process through the api
     */
    cancelPaymentProcess() {
        this.orderDataService.changeOrderToCart();
    }

    /**
     * Returns an array of ids of selected articles
     */
    getSelectedArticleIds() {
        const articleIdList = [];

        const data = this.articleForm.getRawValue();
        for (const article of this.cart.articles) {
            if (data['article_' + article.id]) {
                articleIdList.push(article.id);
            }
        }

        return articleIdList;
    }

    /**
     * Checks if the are unselected articles in the form
     */
    isArticleUnselected() {
        const data = this.articleForm.getRawValue();
        for (const article of this.cart.articles) {
            if (!data['article_' + article.id]) {
                return true;
            }
        }
        return false;
    }

    isModelReleaseAvailable(article: Article) {
        return (
            article.asset &&
            [2, 4, 8].indexOf(Number(article.asset.modelReleased)) !== -1
        );
    }

    isPropertyReleaseAvailable(article: Article) {
        return (
            article.asset &&
            [2, 4, 8].indexOf(Number(article.asset.propertyReleased)) !== -1
        );
    }

    showTerms() {
        const window = this.windowRefService.nativeWindow;
        if (window && window.open) {
            window.open(
                '/terms?mode=compact',
                'terms',
                'width=800,height=700,location=no,menubar=no,toolbar=no,status=no,scrollbars=yes'
            );
        }
    }

    /**
     * Checks if the article is restricted
     * @param article
     */
    isArticleRestricted(article: Article) {
        if (!article.asset.categories.length) {
            return false;
        }

        for (const category of article.asset.categories) {
            if (category.id === 1) {
                return true;
            }
        }

        return false;
    }

    /**
     * Selects or deselects all articles
     * @param value
     */
    toggleSelectAllArticles(value: boolean) {
        for (const controlName in this.articleForm.controls) {
            if (controlName.includes('article_')) {
                this.articleForm.get(controlName).setValue(value);
            }
        }
    }

    removeSelectedArticles() {
        const selectedArticlesIds = this.getSelectedArticleIds();

        selectedArticlesIds.forEach(id => {
            this.cartDataService.removeArticle(id);
        });
    }

    doCheckoutWithPromotionCode() {
        if (!this.isCheckoutActive()) {
            return;
        }

        this.cartDataService.checkout(this.getSelectedArticleIds()).then((order) => {
            this.checkout.next({
                type: 'promotion-code',
                orderId: +order.id
            });
        });
    }
}
