export const AI_PLUS_IDS = 'aiPlusIds';
export const AI_PLUS_FILE = 'aiPlusFile';
export const AI_PLUS_IMAGE_META_INFORMATION = 'aiPlusMetaInformation';

export const AI_PLUS_FILE_URL = 'aiPlusFileUrl';

export function writeToLocalStorage(key: string, value: any) {
    if (value) {
        value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
}

export function readFromLocalStorage<T>(key: string): T {
    const value: string = localStorage.getItem(key);

    if (value && value !== 'undefined' && value !== 'null') {
        return <T>JSON.parse(value);
    }

    return null;
}

export function removeFromLocalStorage(key: string) {
    if (key) {
        localStorage.removeItem(key);
    }
}

export function clearLocalStorage() {
    localStorage.clear();
}
