import {Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {CmsBlockContent} from '../cms-block/cms-block-content';
import {CmsFaqEntryComponent} from '../cms-faq-entry/cms-faq-entry.component';

@Component({
    selector: 'lib-cms-faq',
    templateUrl: './cms-faq.component.html',
    styleUrls: ['./cms-faq.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CmsFaqComponent extends CmsBlockContent implements OnInit, OnChanges {
    @HostBinding('class.cms-faq') rootClassName = true;

    @Input() children: CmsFaqEntryComponent;

    config: any;

    constructor(el: ElementRef) {
        super(el);
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

}
