// tslint:disable
// fixing tlsint errors results in a broken component
// @todo: check coercion, strict equality, etc.
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';

import {CartDataService} from '../../services/cart-data.service';
import {CalculatorService} from '../../services/calculator.service';
import {Article} from '../../models/article';
import {Asset} from '../../models/asset';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import * as _ from 'lodash';

/**
 * Component that loads the right calculator and pricelist for the asset and displays the right specific prices where the user can select
 * the right one
 */
@Component({
    selector: 'st-asset-calculator',
    templateUrl: './asset-calculator.component.html',
    styleUrls: ['./asset-calculator.component.scss']
})
export class AssetCalculatorComponent implements OnInit {
    /**
     * Asset of the component passed
     */
    @Input('asset')
    set setAsset(value: Asset) {
        if (!value || value == this.asset) {
            return;
        }

        this.asset = value;
        this.loadCalculator();
    }

    /**
     * Asset of the component to be setted
     */
    public asset: Asset;

    /**
     * Article of the component passed
     */
    @Input('article')
    set setArticle(value: Article) {
        if (!value || value == this.article) {
            return;
        }

        this.article = value;

        if (this.article && this.article.asset && this.article.asset.id) {
            this.asset = this.article.asset;
        }

        this.loadCalculator();
    }

    /**
     * Article of the component to be setted
     */
    public article: Article;

    @Output()
    close = new EventEmitter<any>();

    /**
     * Form used for the calculations
     */
    public form: any = {};

    /**
     * Array of pricelists
     */
    public pricelists: any = [];
    /**
     * Object containing pricelists ordered by id
     */
    public pricelistsById: any = {};

    /**
     * Should the component ignore value changes of the form
     */
    private ignoreValueChanges: boolean = false;

    /**
     * Currently selected pricelist id
     */
    public selectedPricelistId: number = null;

    /**
     * Whether we are currently adding to the cart
     */
    addingToCart = false;

    /**
     * Whether the asset was successfully added to the cart
     */
    addedToCart = false;

    /**
     * Whether the asset was not successfully added to the cart
     */
    notAddedToCart = false;

    /**
     * Whether the calculator is initialized
     */
    calculatorInitialized: boolean = false;

    constructor(
        private calculator: CalculatorService,
        private cartDataService: CartDataService,
        public sanitizer: DomSanitizer,
        @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions
    ) {
    }

    ngOnInit() {
    }

    /**
     * @returns current asset id
     */
    private getAssetId(): string {
        if (this.asset) {
            return this.asset.id;
        } else if (this.article && this.article.assetId) {
            return this.article.assetId;
        } else if (
            this.article &&
            this.article.asset &&
            this.article.asset.id
        ) {
            return this.article.asset.id;
        }

        return null;
    }

    /**
     * Loads calculator data from the api
     */
    private loadCalculator() {
        this.calculator.getCalculator(this.getAssetId()).then(pricelists => {
            pricelists = pricelists.filter(entry => entry.options && entry.options.length);
            this.setCalculator(pricelists);

            if (
                this.article &&
                this.article.calculatorArguments &&
                this.article.calculatorArguments['pricelistId'] > 0
            ) {
                let pricelistId = this.article.calculatorArguments[
                    'pricelistId'
                    ];

                let loadCalculatorData = Object.assign(
                    {},
                    this.article.calculatorArguments
                );
                delete loadCalculatorData['pricelistId'];

                this.loadPricelist(pricelistId, loadCalculatorData);
            }
        });
    }

    /**
     * Loads pricelists data from the api
     */
    private loadPricelist(pricelistId?: number, calculatorArguments?: any) {
        this.calculator
            .getPricelist(this.getAssetId(), pricelistId, calculatorArguments)
            .then(pricelist => {
                this.selectedPricelistId = pricelistId;

                if (
                    this.pricelistsById[pricelistId] &&
                    this.pricelistsById[pricelistId].license != 'rf'
                ) {
                    this.form['custom'].patchValue(
                        {
                            custom: 'custom',
                            selectedCalculator: pricelistId
                        },
                        {emitEvent: false}
                    );
                }
                this.setPricelist(pricelist, null, calculatorArguments);
            });
    }

    /**
     * Sets a calculator
     * @param pricelists priceslist to be setted
     */
    private setCalculator(pricelists): void {
        this.pricelists = [];
        this.pricelistsById = {};

        this.form = {};

        this.selectedPricelistId = null;

        this.form['custom'] = new FormGroup({
            custom: new FormControl(''),
            selectedCalculator: new FormControl('')
        });

        this.form['custom'].valueChanges.subscribe(data => {
            if (this.ignoreValueChanges) {
                return;
            }

            let nonRfPricelists = this.getAllPricelistsExceptWithLicense('rf');
            this.selectedPricelistId =
                nonRfPricelists.length > 1
                    ? data.selectedCalculator
                    : nonRfPricelists[0].id;

            this.resetAllFormsExcept('custom');
        });

        for (let pricelist of pricelists) {
            this.setPricelist(pricelist, pricelists.length);
        }

        if (this.getAllPricelistsExceptWithLicense('rf').length === 1) {
            this.form['custom'].patchValue({
                custom: 'custom'
            });
        }
        this.calculatorInitialized = true;
    }

    /**
     * Sets a pricelist
     * @param pricelist pricelist
     * @param calculatorArguments calculator arguments
     */
    setPricelist(pricelist, priceListCount, calculatorArguments?: {}) {
        if (!calculatorArguments) {
            calculatorArguments = this.form[pricelist.id]
                ? this.form[pricelist.id].getRawValue()
                : {};
        }

        // Create Form Group
        if (!this.form[pricelist.id]) {
            this.form[pricelist.id] = new FormGroup({});
            this.addPricelistFormListener(
                this.form[pricelist.id],
                pricelist.id
            );
        }

        let group: FormGroup = this.form[pricelist.id];

        // Create Form Controls
        this.ignoreValueChanges = true;
        let patchDefaultSize = '';
        for (let option of pricelist.options) {
            if (!group.contains(option.key)) {
                if (option.key == 'size') {
                    let priceIdList = [];
                    for (let price of option.values) {
                        priceIdList.push(price.id);
                    }

                    if (priceIdList.length) {
                        let selectedPos = priceIdList.length - 1;
                        patchDefaultSize = priceIdList[selectedPos].toString();
                    }
                }

                group.addControl(option.key, new FormControl());
            }
        }
        this.ignoreValueChanges = false;

        // Set Form Values
        let tmpPatch = {};
        let currentValues = group.getRawValue();
        for (let option of pricelist.options) {
            let val =
                calculatorArguments && calculatorArguments[option.key]
                    ? calculatorArguments[option.key]
                    : null;

            if (currentValues[option.key] != val) {
                tmpPatch[option.key] = val;
            }
        }

        if (tmpPatch) {
            group.patchValue(tmpPatch, {emitEvent: false});
        }

        // Set pricelist
        this.pricelistsById[pricelist.id] = pricelist;

        let key = this.pricelists.findIndex(
            element => element.id == pricelist.id
        );
        if (key > -1) {
            this.pricelists[key] = pricelist;
        } else {
            this.pricelists.push(pricelist);
        }

        if (patchDefaultSize != '') {
            group.patchValue({size: patchDefaultSize}, {emitEvent: true});
        }

        if (priceListCount === 1 && pricelist.license === 'rm' && pricelist.options.length === 1 && pricelist.options[0].values.length === 1) {
            group.patchValue({
                [pricelist.options[0].key]: pricelist.options[0].values[0].id
            });
        }
    }

    addPricelistFormListener(formGroup: FormGroup, pricelistId: number) {
        formGroup.valueChanges.pipe(distinctUntilChanged(_.isEqual)).subscribe(data => {
            if (this.ignoreValueChanges) {
                return;
            }

            this.resetAllFormsExcept(pricelistId);

            this.loadPricelist(
                pricelistId,
                this.getPricelistOptions(pricelistId)
            );
        });
    }

    /**
     * Returns pricelist options
     * @param pricelistId id of the pricelist
     * @returns
     */
    getPricelistOptions(pricelistId) {
        let options = {};

        if (!this.form[pricelistId] || !this.pricelistsById[pricelistId]) {
            return options;
        }

        let data = this.form[pricelistId].getRawValue();
        for (let key in data) {
            if (!data[key]) {
                continue;
            }

            options[key] = data[key];

            if (options[key] === true) {
                for (let option of this.pricelistsById[pricelistId].options) {
                    if (option.key !== key) {
                        continue;
                    }

                    options[key] = option.id;
                }
            }
        }

        return options;
    }

    /**
     * Resets all form groups except the one that matches the given id
     * @param pricelistId id of the form group
     */
    resetAllFormsExcept(pricelistId) {
        this.ignoreValueChanges = true;

        let resetCustom =
            this.pricelistsById[pricelistId] &&
            this.pricelistsById[pricelistId].license === 'rf';

        Object.keys(this.form).forEach(key => {
            if (!(Number(key) === pricelistId || (!resetCustom && key === 'custom'))) {
                this.form[key].reset();
                if (this.pricelistsById[key]  && key !== 'custom') {
                    const options = this.pricelistsById[key].options;
                    if (options.length > 1) {
                        for (let i = 1; i < options.length; i++) {
                            this.form[key].removeControl(options[i].key);
                        }
                        this.form[key].updateValueAndValidity();

                        /**
                         * as the template uses a mixture of form controls and data from the price list,
                         * we have to make sure that this will not crash the form
                         */
                        setTimeout(() => {
                            const priceList = this.pricelists.find(list => list.id === Number(key));
                            priceList.price = null;
                            priceList.options = [options[0]];
                        }, 100);
                    }
                }
            }
        });

        this.ignoreValueChanges = false;
    }

    loadPriceList(pricelistId) {
        this.calculator
            .getPricelist(this.getAssetId(), pricelistId, null)
            .then(pricelist => {

            });
    }

    /**
     * Calculates size
     * @param size
     */
    calculateSize(size) {
        let _size = {
            width: this.asset.width,
            height: this.asset.height
        };

        if (size.extra.sideLength > 0) {
            if (this.asset.width > this.asset.height) {
                const factor = this.asset.height / this.asset.width;

                _size = {
                    width: size.extra.sideLength,
                    height: Math.round(factor * size.extra.sideLength)
                };
            } else {
                const factor = this.asset.width / this.asset.height;

                _size = {
                    height: size.extra.sideLength,
                    width: Math.round(factor * size.extra.sideLength)
                };
            }
        } else if (size.extra.megaPixel > 0) {
            _size = {
                width: Math.round(
                    Math.sqrt(
                        (size.extra.megaPixel * this.asset.width) /
                        this.asset.height
                    ) * 1024
                ),
                height: Math.round(
                    Math.sqrt(
                        (size.extra.megaPixel * this.asset.height) /
                        this.asset.width
                    ) * 1024
                )
            };
        }

        return _size;
    }

    /**
     * Checks if given size is smaller
     * @param size size to be checked against
     */
    isSizeSmaller(size): boolean {
        let imgSize = this.calculateSize(size);

        return (
            this.asset.width >= imgSize.width &&
            this.asset.height >= imgSize.height
        );
    }

    /**
     * Calculates file size
     * @returns calculated size
     */
    calculateRawFileSize(size): string {
        let imgSize = this.calculateSize(size);

        let rawSize = imgSize.width * imgSize.height * 3;

        if (rawSize < 1024) {
            return `${rawSize} B`;
        } else if (rawSize < 1048576) {
            return `${Math.round(rawSize / 1024)} KB`;
        } else {
            return `${Math.round(rawSize / 1024 / 1024)} MB`;
        }
    }

    /**
     * Calculates size in megapixels
     * @param size
     */
    calculateMegapixel(size): string {
        let imgSize = this.calculateSize(size);
        return `${Math.round(
            ((imgSize.width * imgSize.height) / (1024 * 1024)) * 10
        ) / 10} MP`;
    }

    /**
     * Calculates print size
     * @param size
     */
    calculatePrintSize(size): string {
        // 9.83" x 3.00" @ 72 DPI
        let imgSize = this.calculateSize(size);

        const sizeX = Math.round(imgSize.width * (2.54 / size.extra.dpi) * 100) / 100;
        const sizeY = Math.round(imgSize.height * (2.54 / size.extra.dpi) * 100) / 100;

        return `${sizeX} cm x ${sizeY} cm @ ${size.extra.dpi} dpi`;
    }

    /**
     * Returns option description text
     */
    getOptionDescriptionText(formGroupId, controlId, selectedOption): string {
        if (this.form[formGroupId] && this.form[formGroupId].controls[controlId]) {
            let selectedId = this.form[formGroupId].controls[controlId].value;
            const option = this.pricelistsById[formGroupId].options[
                selectedOption
                ].values.find(option => option.id == selectedId);
            return (option) ? option.description || '' : '';
        } else {
            return '';
        }

    }

    /**
     * Returns pricelist description text
     * @param pricelistId
     */
    getPricelistDescriptionText(pricelistId): string {
        if (!(pricelistId > 0)) {
            return '';
        }

        return this.pricelistsById[pricelistId].description;
    }

    /**
     * Checks if pricelist contains given license
     * @param license
     */
    isLicensePricelistAvailable(license): boolean {
        return this.getPricelistsByLicense(license).length > 0;
    }

    /**
     * Checks if there are other licenses available
     * @param license
     */
    isLicenseAvailableOtherThan(license): boolean {
        return this.getAllPricelistsExceptWithLicense(license).length > 0;
    }

    /**
     * Returns pricelists that match given license
     */
    getPricelistsByLicense(license): any[] {
        return this.pricelists.filter(
            pricelist => pricelist.license == license
        );
    }

    /**
     * Returns pricelists that don't match given license
     */
    getAllPricelistsExceptWithLicense(license) {
        return this.pricelists.filter(
            pricelist => pricelist.license != license
        );
    }

    /**
     * Checks if a price is selected
     * @param pricelistId id
     * @param key form key
     * @param value form value
     */
    isPriceSelected(pricelistId, key, value) {
        return (
            this.form[pricelistId] &&
            this.form[pricelistId].getRawValue()[key] == value
        );
    }

    /**
     * Adds an article to cart
     * @param event event to be stopped
     */
    addToCart(event) {
        event.stopPropagation();
        this.addedToCart = false;
        this.notAddedToCart = false;
        this.addingToCart = true;

        if (!(this.selectedPricelistId > 0)) {
            this.addingToCart = false;
            return;
        }

        let calculatorArguments = {
            pricelistId: this.selectedPricelistId,
            ...this.getPricelistOptions(this.selectedPricelistId)
        };

        if (this.article) {
            let article = Object.assign({}, this.article);
            article.calculatorArguments = calculatorArguments;

            this.cartDataService.updateArticle(article).then(() => {
                this.closeCalculator();
                this.addingToCart = false;
                this.addedToCart = true;
            }).catch(() => {
                this.addingToCart = false;
                this.notAddedToCart = true;
            });
        } else {
            this.cartDataService.addArticle(this.asset.id, calculatorArguments).then(() => {
                this.addingToCart = false;
                this.addedToCart = true;
            }).catch(() => {
                this.addingToCart = false;
                this.notAddedToCart = true;
            });
        }
    }

    /**
     * Shows restricted message via browsers alert api
     * @param event event to be stopped
     */
    showRestrictedMessage(event) {
        event.stopPropagation();

        alert(
            'We’re sorry, this image has usage restrictions. To license this image, please contact us at sales@sodatech.com .'
        );
    }

    /**
     * Emits event for closing the calculator
     */
    closeCalculator() {
        this.close.emit({
            id: this.getAssetId()
        });
    }

    /**
     * Checks if is restricted
     */
    isRestricted() {
        return Boolean(this.asset.categories.find(c => c.id === 1));
    }
}
