import {Inject, Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {SodaApiService} from './soda-api.service';
import {map} from 'rxjs/operators';
import {SDK_OPTIONS, SdkOptions} from '../models/sdk-options';

/**
 * Service that interacts with the collection api
 */
@Injectable()
export class CollectionService {
    private API_PATH = 'collections';


    constructor(private http: SodaApiService, @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions) {
    }

    /**
     * Gets collections
     * @returns
     * @memberof CollectionService
     */
    public getCollections(): Promise<any> {
        const params = new HttpParams({fromObject: {sort: 'name'}});

        return this.http
            .get(this.API_PATH, {params})
            .pipe(map((data: any[]) => {
                    if (this.sdkOptions.additionalCollections) {
                        data.push(...this.sdkOptions.additionalCollections);
                    }
                    return data;
                }
            ))
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
