import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { CartDataService } from '../../services/cart-data.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserDataService } from '../../services/user-data.service';
import {LightboxDataService} from '../../services/lightbox-data.service';

/**
 * Component that displays an indicator with the number of lightboxes
 */
@Component({
    selector: 'st-gallery-indicator',
    templateUrl: './gallery-indicator.component.html',
    styleUrls: ['./gallery-indicator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GalleryIndicatorComponent {

    galleryCount = 0;
    @Output() cartLinkClicked = new EventEmitter();

    @HostBinding('class.gallery-indicator-root') hasRootClass = true;

    @Input() iconOnly = false;

    @Input() displayGalleryCount = true;

    constructor(private lightboxDataService: LightboxDataService, public user: UserDataService) {
        this.lightboxDataService.getLightboxListObservable().pipe(
            map(lightboxList => {
                let count = 0;

                if (lightboxList && lightboxList.length) {
                    count = lightboxList.length;
                }

                return count;
            }),
            distinctUntilChanged()
            )
            .subscribe(galleryCount => {
                this.galleryCount = galleryCount;
            });
    }

    onCartLinkClicked(e: Event) {
        e.preventDefault();
        this.cartLinkClicked.emit();
    }
}
