import { Inject, Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {CookiesService} from '@ngx-utils/cookies';
import { SDK_OPTIONS, SdkOptions } from '../models/sdk-options';

/**
 * Service that perdforms user auth
 */
@Injectable()
export class UserLoginService {
    private tokenStorageName = 'auth_bearer';
    private tokenCookieName = 'usertoken';

    private jwtHelperService = new JwtHelperService();

    private _status: BehaviorSubject<any> = new BehaviorSubject(null);
    public status: Observable<any> = this._status.asObservable();
    private statusToken = null;

    public loggedIn = false;
    private inProgress: boolean = false;
    private redirectUrl: string = null;

    constructor(
        private router: Router,
        private storage: LocalStorageService,
        private authenticationService: AuthenticationService,
        private cookies: CookiesService,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions
    ) {
        const loggedIn = this.isLoggedIn();
        this.authenticationService.setUserLoginStatus(loggedIn);
        this.authenticationService.setUserLoggedOutStatus(!loggedIn);
    }

    /**
     * Logins given user
     * @param redirectUrl
     * @returns
     */
    public login(redirectUrl?: string): Observable<any> {
        if (!this.inProgress) {
            this.inProgress = true;

            if (redirectUrl) {
                this.redirectUrl = redirectUrl;
            }

            this.router.navigate([{ outlets: { modal: 'login' } }], {
                queryParamsHandling: 'merge'
            });
        }

        return this.status;
    }

    /**
     * Logout current given user
     * @returns
     */
    public logout() {
        this.deleteToken();
        // this.router.navigate(['/']);
        this.authenticationService.setUserLoginStatus(false);
        this.authenticationService.setUserLoggedOutStatus(true);
        return this.status;
    }

    /**
     * Delete users stored token
     */
    public deleteToken() {
        this.storage.clear(this.tokenStorageName);
        this.cookies.remove(this.tokenCookieName);
        this.inProgress = false;
        this.redirectUrl = null;
        this.isLoggedIn();
    }

    /**
     * Stores given users token
     * @param token
     */
    public setToken(token: string) {
        this.storage.store(this.tokenStorageName, token);
        this.cookies.put(this.tokenCookieName, token, {
            domain: this.sdkOptions.domain === 'localhost'
                ? this.sdkOptions.domain
                :`.${this.sdkOptions.domain.split(':')[0]}`,
            expires: new Date('31 December 2020')
        });
        this.inProgress = false;
        this.isLoggedIn();

        if (token && this.redirectUrl) {
            this.router.navigate([this.redirectUrl], {
                queryParamsHandling: 'merge'
            });
        }

        this.redirectUrl = null;
    }

    /**
     * Returns users stored token
     * @returns
     */
    public getToken() {
        return this.storage.retrieve(this.tokenStorageName);
    }

    /**
     * Checks if the user is logged in
     * @returns
     */
    public isLoggedIn() {
        const loginStatus = this.checkLoggedIn();

        if (loginStatus !== this.loggedIn) {
            this.loggedIn = loginStatus;
        }

        const status = this.loggedIn ? 'login' : 'logout';
        const token = this.getToken();

        if (this._status.getValue() !== status || this.statusToken !== token) {
            this.statusToken = token;
            this._status.next(status);
        }

        return this.loggedIn;
    }

    /**
     * Checks if token is expired
     * @returns
     */
    private checkLoggedIn() {
        const token = this.getToken();
        if (!token) {
            return false;
        }

        try {
            return !this.jwtHelperService.isTokenExpired(token);
        } catch (e) {}

        return false;
    }
}
