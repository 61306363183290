import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewEncapsulation,
    OnDestroy
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

import {DOCUMENT} from '@angular/common';

import {Meta} from '@angular/platform-browser';
import {switchMap, map, pluck} from 'rxjs/operators';
import {Asset} from '../../models/asset';
import {Article} from '../../models/article';
import {AssetService} from '../../services/asset.service';
import {LightboxDataService} from '../../services/lightbox-data.service';
import {CartDataService} from '../../services/cart-data.service';
import {WindowRefService} from '../../services/window-ref.service';
import {LocationRefService} from '../../services/location-ref.service';
import {AssociatedMedia} from '../../models/associated_media';

import * as _scrollIntoView from 'scroll-into-view';
import {UserDataService} from '../../services/user-data.service';
import {CollectionDataService} from '../../services/collection-data.service';
import {TranslateService} from '@ngx-translate/core';
import {RemoveFileExtensionPipe} from '../../pipes/remove-file-extension.pipe';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import {EventTrackerEvents, EventTrackerService} from '../../services/event-tracker.service';
import {AssetPreviewService} from '../../services/asset-preview.service';
import {transliterate as tr} from 'transliteration';

import * as _ from 'lodash';
import {Observable} from 'rxjs';

const scrollIntoView = _scrollIntoView;

const SDK_ASSET_DETAIL_OPEN_CLASSNAME = 'st-asset-detail-opened';

/**
 * The component loads the assets for the specific asset and renders the asset.
 */
@Component({
    selector: 'st-asset-detail',
    templateUrl: './asset-detail.component.html',
    styleUrls: ['./asset-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [RemoveFileExtensionPipe]
})
export class AssetDetailComponent implements OnInit, OnChanges, OnDestroy {

    assetType = AssetType;

    /**
     * Id of the passed asset
     */
    @Input() assetId: any;

    /**
     * Asset passed
     */
    @Input('asset')
    set setAssetInput(asset: Asset) {
        this.setAsset(asset);
    }

    @Input() hideCloseButton: boolean;

    /**
     * Component asset
     */
    public asset: Asset = null;

    /**
     * Article passed
     */
    @Input('article')
    set setArticle(value: Article) {
        if (!value) {
            return;
        }

        this.article = value;
        this.setAsset(value.asset);
    }

    /**
     * Show previous asset detail
     */
    @Input() showPrevious: any = false;

    /**
     * Show next asset detail
     */
    @Input() showNext: any = false;

    /**
     * Lightbox id passed
     */
    @Input() lightboxId: number = null;

    /**
     * Whether to scroll to the asset details when the asset is set
     */
    @Input() scrollTo = false;

    @Input() urlGenerator: Function;

    @Input() hidePreviewLinkIcon = false;

    @Output() close = new EventEmitter<any>();
    @Output() previous = new EventEmitter<any>();
    @Output() next = new EventEmitter<any>();

    @Output() assetAction = new EventEmitter<{ id: string; action: string }>();

    /**
     * Toogle similarity search when the user clicked the link
     */
    @Output() toggleSimilaritySearch = new EventEmitter<Asset>();

    /**
     * Trigger preview link clicked
     */
    @Output() previewLinkClicked: EventEmitter<Asset> = new EventEmitter<Asset>();

    /**
     * Flag that describes whether the similarity search was used or not
     */
    similaritySearchUsed = false;

    /**
     * Article of the component
     */
    public article: Article;

    /**
     * Array of similar assets
     */
    public similarAssets: any[] = [];

    /**
     * Should show all keywords
     */
    public showAllKeywords = false;

    /**
     * Should show share buttons menu
     */
    public showShareMenu = false;

    /**
     * Should show the support menu
     */
    public showSupportMenu = false;

    /**
     * Flag that describes whether the high res popover is displayed or not
     */
    showHighResPopover: boolean;

    /**
     * High res usage input value
     */
    highResUsage: string;

    /**
     * Supplier query params of the current asset
     */
    supplierQueryParam: any;

    /**
     * Keyword query params of the current asset
     */
    keywordQueryParam: any;

    /**
     * Collection query params of the current asset
     */
    collectionQueryParam: any;

    /**
     * Image schema for JSON LD
     */
    imageSchema: any;

    /**
     * Product schema for JSON LD
     */
    productSchema: any;

    /**
     * Asset video url (set if the asset is a mp4)
     */
    assetVideoUrl: string;

    assetUrl: string;

    userFunctions$: Observable<number[]>;

    /**
     * Is asset of restriction type block
     */
    isRestrictionTypeBlock = false;

    /**
     * Is preview keywords should be hides
     */
    hidePreviewKeywords = false;

    @HostListener('window:keyup', ['$event'])
    windowKeyUp(event) {
        if (event.code === 'ArrowLeft' && !this.assetPreviewService.isPreviewDisplayed()) {
            this.goToPrevious();
        } else if (event.code === 'ArrowRight' && !this.assetPreviewService.isPreviewDisplayed()) {
            this.goToNext();
        } else if (event.code === 'Escape' && !this.assetPreviewService.isPreviewDisplayed()) {
            this.clickClose();
        }
    }

    /**
     * Setups an event on window object and delegates it to the needed method
     * @param event
     */
    @HostListener('window:click', ['$event'])
    windowClick(event) {
        if (!this.showShareMenu && !this.showSupportMenu) {
            return;
        }

        const toggle = {
            support: true,
            share: true
        };

        let obj = event.target;
        while (obj) {
            if (obj.id === 'shareButtonDiv') {
                toggle['share'] = false;
                break;
            } else if (obj.id === 'supportButtonDiv') {
                toggle['support'] = false;
                break;
            }

            obj = obj.parentNode ? obj.parentNode : null;
        }

        if (toggle['share']) {
            this.toggleShareMenu(false);
        }
        if (toggle['support']) {
            this.toggleSupportMenu(false);
        }
    }

    constructor(
        private assetService: AssetService,
        private route: ActivatedRoute,
        public element: ElementRef,
        private router: Router,
        private lightboxDataService: LightboxDataService,
        private cartDataService: CartDataService,
        @Inject(DOCUMENT) private document: any,
        private window: WindowRefService,
        private location: LocationRefService,
        private metaService: Meta,
        private userDataService: UserDataService,
        private collectionDataService: CollectionDataService,
        private translate: TranslateService,
        @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions,
        private removeFileExtensionPipe: RemoveFileExtensionPipe,
        private eventTrackerService: EventTrackerService,
        private assetPreviewService: AssetPreviewService
    ) {
        this.userFunctions$ = this.userDataService.getUserFunctions();
        this.hidePreviewKeywords = this.sdkOptions.assetDetails.hidePreviewKeywords;
    }

    ngOnInit() {
        if (this.assetId || (this.asset && this.asset.id)) {
            const assetId = this.assetId ? this.assetId : this.asset.id;

            this.asset = null;
            this.assetId = null;
            this.assetService.getAsset(assetId).then(asset => {
                this.setAsset(asset);
            });
        } else {
            this.route.params
                .pipe(
                    switchMap((params: Params) =>
                        this.assetService.getAsset(params['id'])
                    )
                )
                .subscribe(asset => this.setAsset(asset));
        }

        document.querySelector('body').classList.add(SDK_ASSET_DETAIL_OPEN_CLASSNAME);
    }

    get currentLang() {
        return this.translate.currentLang;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['scrollTo'] && this.scrollTo) {
            this.scrollToDetail();
        }
        if ((changes['asset'] || changes['urlGenerator']) && this.urlGenerator && this.asset) {
            this.setAsset(this.asset);
        }
    }

    ngOnDestroy() {
        document.querySelector('body').classList.remove(SDK_ASSET_DETAIL_OPEN_CLASSNAME);
    }

    /**
     * Sets an asset in the component
     * @param asset
     */
    setAsset(asset: Asset) {
        this.asset = asset;
        if (asset) {
            this.assetId = asset.id;
        }
        this.isRestrictionTypeBlock = this.asset.restrictions &&
            this.asset.restrictions.length && !!this.asset.restrictions.find(restriction => restriction.type === 'block');
        this.loadSimilarAssets();
        if (this.asset && this.scrollTo) {
            this.scrollToDetail();
        }
        if (this.asset) {
            this.calculateQueryParams();
        }
        if (this.asset && this.asset.fileFormat && this.asset.fileFormat.indexOf(AssetType.VIDEO) > -1) {
            this.assetVideoUrl = this.getImageOfType('mp4_preview').contentUrl;
        }
        this.assetUrl = this.getImageSrc();
    }

    /**
     * Calculate query params for the copyright
     */
    async calculateQueryParams() {

        if (this.sdkOptions.assetDetails.credit.alwaysUseCollectionData && this.asset.collection) {
            this.collectionQueryParam = {coll: [this.asset.collection.id]};
        } else if (!this.asset.collection && this.asset.copyright !== '' && this.asset.byline === '') {
            this.supplierQueryParam = {pgid: this.asset.supplier.id};
        } else if (!this.asset.collection && this.asset.copyright !== '' && this.asset.byline !== '') {
            this.supplierQueryParam = {pgid: this.asset.supplier.id};
            this.keywordQueryParam = {q: `"${this.asset.byline}"`};
        } else if (this.asset.collection) {
            if (this.asset.byline !== '') {
                const collections = await this.collectionDataService.getCopyrightCollections();
                if (this.asset) {
                    if (collections) {
                        const filteredCollections = collections.filter(collection => collection.id.split(';').indexOf(this.asset.collection.id) > -1);
                        const collection = filteredCollections[0];
                        if (collection) {
                            this.collectionQueryParam = {coll: [collection.id]};
                        } else {
                            this.collectionQueryParam = {coll: [this.asset.collection.id]};
                        }
                    }
                    this.keywordQueryParam = {q: `"${this.asset.byline}"`};
                }
            } else {
                const collections = await this.collectionDataService.getCopyrightCollections();
                if (this.asset) {
                    if (collections) {
                        const filteredCollection = collections.filter(collection => collection.id.indexOf(this.asset.collection.id) > -1);
                        const collection = filteredCollection[0];
                        if (collection) {
                            this.collectionQueryParam = {coll: [collection.id]};
                        } else {
                            this.collectionQueryParam = {coll: [this.asset.collection.id]};
                        }
                    }
                }
            }
        } else {
            this.keywordQueryParam = {q: `"${this.asset.supplier.name}"`};
        }
    }


    /**
     * Loads similar assets from the api
     */
    loadSimilarAssets() {
        this.assetService.getSimilarAssets(this.assetId, {}).then(({assets}) => {
            if (assets && assets.length > 0) {
                this.similaritySearchUsed = false;
                this.similarAssets = assets;
            }
        });
    }

    /**
     * Emits a close event with current assets id as payload
     */
    clickClose(): void {
        this.close.emit({
            id: this.asset.id
        });
    }

    /**
     * Adds an article to the cart
     */
    addToCart() {
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.ADD_TO_CART);
        this.cartDataService.addArticle(String(this.asset.id));
    }

    /**
     * Checks if current asset is in cart
     */
    isInCart(): boolean {
        return this.cartDataService.isAssetInCart(String(this.asset.id));
    }

    /**
     * ...
     */
    alert(message: string): void {
        console.log('ALERT: ' + message);
    }

    /**
     * Opens a [mailto] location with given contents
     */
    sendMail(mailToContent: string): void {
        this.location.nativeLocation.href = 'mailto:' + mailToContent;
    }

    /**
     * Returns image src
     * @param type
     */
    getImageSrc(): string {
        const image = this.getImageOfType((this.userDataService.isLoggedIn()) ? 'preview' : 'preview_watermarked');
        if (!image) {
            return null;
        }
        let newPath;
        if (this.sdkOptions.searchGridData.assetUrlSuffix) {
            newPath = image.contentUrl + this.sdkOptions.searchGridData.assetUrlSuffix + this.getImageUrl();
        } else {
            newPath = image.contentUrl;
        }

        return newPath;
    }


    getImageUrl() {
        const prefix = tr(`${this.sdkOptions.searchGridData.assetNamePrefix}${this.removeFileExtensionPipe.transform(this.asset.name)} - `);
        let caption = tr(this.asset.caption.trim().replace(/[\/\\:*?"<>|]/g, '-'));
        const suffix = tr('.jpg');
        const maxLength = 240 - prefix.length - suffix.length;
        const captionLength = caption.length;
        const totalLength = captionLength + prefix.length + suffix.length;
        if (totalLength > 240) {
            caption = tr(_.truncate(caption, {
                'length': maxLength
            })).replace(/\./g, '');
        }

        return encodeURIComponent(tr(`${prefix}${caption}${this.sdkOptions.searchGridData.assetNameSuffix}`));
    }

    /**
     * Returns image by type
     * @param type type of image
     */
    getImageOfType(type: string): AssociatedMedia {
        for (const assetFile of this.asset.associatedMedia) {
            if (assetFile.additionalType === type) {
                return assetFile;
            }
        }

        return null;
    }

    searchKeyword(keyword: string): void {
        this.router.navigate(['/search'], {queryParams: {q: keyword}});
    }

    showSimilarAssetDetail(event: any): void {
        this.assetService.getAsset(event.asset.id).then(asset => {
            this.setAsset(asset);
        }).catch(() => {
            this.setAsset(event.asset);
        });
    }

    /**
     * Triggers asset change to the previous asset
     */
    goToPrevious() {
        this.previous.emit({
            id: this.asset.id
        });
    }

    /**
     * Triggers asset change to the next asset
     */
    goToNext() {
        this.next.emit({
            id: this.asset.id
        });
    }

    /**
     * Called when the user clicked the low res download
     */
    downloadPreview() {
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.DOWNLOAD_PREVIEW);
        this.assetService.download(this.asset.id, 'layout');
    }

    /**
     * Toogle visibility of the high res popover
     * @param e
     */
    toggleHighResPopover(e: Event) {
        e.stopPropagation();
        this.showHighResPopover = !this.showHighResPopover;
    }

    /**
     * Hides the high res popover
     */
    hideHighResPopover() {
        if (this.showHighResPopover) {
            this.showHighResPopover = false;
        }
    }

    downloadHighRes(data: {subscriptionId: number; usage: string}) {
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.DOWNLOAD_HIGH_RES);
        this.assetService.highResDownload(this.asset.id, data.usage, data.subscriptionId);
    }

    /**
     * Returns the currently selected lightbox id
     */
    getLightboxId(): number {
        return this.lightboxId > 0
            ? this.lightboxId
            : this.lightboxDataService.getSelectedLightboxId();
    }

    /**
     * Adds the current asset to the currently selected lightbox
     */
    addToLightbox() {
        this.lightboxDataService.addAsset(this.asset.id, '');
    }

    /**
     * Check if asset is in a lightbox
     */
    isInLightbox(): boolean {
        const lightboxId = this.getLightboxId();
        return this.lightboxDataService.isAssetInLightbox(
            lightboxId,
            this.asset.id
        );
    }

    /**
     * Delete asset from lightbox
     */
    deleteFromLightbox() {
        const lightboxId = this.getLightboxId();
        this.lightboxDataService.deleteAsset(lightboxId, this.asset.id);
    }

    /**
     * Scrolls browser to details
     */
    scrollToDetail() {
        const container = this.element.nativeElement as HTMLElement;
        const target = document.createElement('a');
        target.style.display = 'inline-block';
        target.id = 'assetDetailsTarget';
        container.parentElement.insertBefore(target, container);
        scrollIntoView(target, {
            align: {
                top: 0.2
            }
        } as any, type => {
            target.remove();
        });
    }

    /**
     * Calculate asset size
     */
    calculateSize() {
        return {
            width: this.asset.width,
            height: this.asset.height
        };
    }

    /**
     * calculate raw file size
     */
    calculateRawFileSize(): string {
        const imgSize = this.calculateSize();
        if (!imgSize) {
            return null;
        }

        const rawSize = imgSize.width * imgSize.height * 3;

        if (rawSize < 1024) {
            return rawSize + ' B';
        } else if (rawSize < 1024 * 1024) {
            return Math.round(rawSize / 1024) + ' KB';
        } else {
            return Math.round((rawSize / 1024 / 1024) * 10) / 10 + ' MB';
        }
    }

    /**
     * Calculate mega pixels of the asse
     */
    calculateMegapixel(): string {
        const imgSize = this.calculateSize();
        if (!imgSize) {
            return null;
        }

        return Math.round(
            (imgSize.width * imgSize.height) / 1000 / 1000 * 10) / 10 +
            ' MP';
    }

    /**
     * Calculate print size of the image
     */
    calculatePrintSize(): string {
        const imgSize = this.calculateSize();

        if (!imgSize) {
            return null;
        }

        const dpi = 300;

        // pixelto.net/px-to-cm-converter
        const sizeX = Math.round(imgSize.width * (2.54 / dpi) * 100) / 100;
        const sizeY = Math.round(imgSize.height * (2.54 / dpi) * 100) / 100;

        return `${sizeX} cm x ${sizeY} cm @ ${dpi} dpi`;
    }

    /**
     * Caluclate optional size of the asset
     */
    calculateOptionalSize() {
        if (!this.asset || !this.asset.optionalFilesize) {
            return null;
        }

        const imgSize = this.calculateSize();
        if (!imgSize) {
            return null;
        }

        const rawSize = imgSize.width * imgSize.height;
        if (rawSize * 1.1 < this.asset.optionalFilesize) {
            return null;
        }

        const pixelCount = this.asset.optionalFilesize * 1024 * 1024;

        const hFactor = imgSize.width / imgSize.height;
        const vFactor = imgSize.height / imgSize.width;

        const newWidth = Math.sqrt(pixelCount * hFactor);
        const newHeight = Math.sqrt(pixelCount * vFactor);

        return {
            width: Math.round(newWidth),
            height: Math.round(newHeight)
        };
    }

    /**
     * Returns optional size of the asset
     */
    getOptionalSize() {
        if (!this.asset || !this.asset.optionalFilesize) {
            return 0;
        }

        const imgSize = this.calculateSize();
        if (imgSize) {
            const rawSize = imgSize.width * imgSize.height;
            if (rawSize * 1.1 < this.asset.optionalFilesize) {
                return 0;
            }
        }

        return this.asset.optionalFilesize * 3;
    }

    /**
     * Returns the optional dimension of the asset
     */
    getOptionalDimensions() {
        const size = this.calculateOptionalSize();
        if (!this.asset || !this.asset.optionalFilesize) {
            return '0 x 0';
        }

        return size.width + ' x ' + size.height;
    }

    /**
     * Returns optional mega pixel for the asset
     */
    getOptionalMegapixel(): string {
        if (!this.asset || !this.asset.optionalFilesize) {
            return '0';
        }

        return Math.round(this.asset.optionalFilesize * 10) / 10 + ' MP';
    }

    /**
     * Returns the aspect ratio of the asset
     * @param width
     * @param height
     */
    getAspectRatio(width: number, height: number): string {
        if (width > height) {
            return Math.round((width / height) * 10) / 10 + ':1';
        } else {
            return '1:' + Math.round((height / width) * 10) / 10;
        }
    }

    /**
     * Returns the optional print size of the asset
     */
    getOptionalPrintSize(): string {
        // 9.83" x 3.00" @ 72 DPI
        const imgSize = this.calculateOptionalSize();
        if (!imgSize) {
            return null;
        }

        const dpi = 300;
        return (
            Math.round((imgSize.width / dpi) * 100) / 100 +
            '" x ' +
            Math.round((imgSize.height / dpi) * 100) / 100 +
            '" @ ' +
            dpi +
            ' DPI'
        );
    }

    /**
     *
     */
    requestOptionalFilesize() {
        const address = 'research@sodatech.com';
        const subject = 'Request image size';
        const message =
            'Image Id: ' +
            this.asset.name +
            '\n' +
            'Tell us the size you think you need. We’ll get back to you with a quote. And it always helps to provide the formula you used to' +
            ' determine the file size. That way we can confirm you’re getting exactly what you need. If you want help figuring out the right' +
            ' size, we’ll be glad to assist. Please include your phone number if you’d like us to call you.';
        this.location.nativeLocation.href =
            'mailto:' +
            encodeURIComponent(address) +
            '?subject=' +
            encodeURIComponent(subject) +
            '&body=' +
            encodeURIComponent(message);
    }

    isModelReleaseAvailable() {
        return [2, 4, 8].indexOf(Number(this.asset.modelReleased)) !== -1;
    }

    isPropertyReleaseAvailable() {
        return [2, 4, 8].indexOf(Number(this.asset.propertyReleased)) !== -1;
    }

    /**
     * Removes the extension from the given filename (foo.xyz => foo)
     * @param value
     */
    removeFileExtension(value: string): string {
        const pos = value.lastIndexOf('.');
        if (pos !== -1) {
            value = value.substr(0, pos);
        }
        return value;
    }

    /**
     * Toggles share menu
     * @param status
     */
    toggleShareMenu(status?: boolean) {
        if (typeof status !== 'boolean') {
            status = !this.showShareMenu;
        }

        this.showShareMenu = status;
    }

    /**
     * Toggles support menu
     */
    toggleSupportMenu(status?: boolean) {
        if (typeof status !== 'boolean') {
            status = !this.showSupportMenu;
        }

        this.showSupportMenu = status;
    }

    /**
     * Returns current asset url
     */
    getAssetUrl() {
        return 'https://www.visiopic.de/asset/' + this.asset.id;
    }

    afterCopy() {
        alert('The image\'s URL has been copied to the clipboard.');
        this.toggleShareMenu(false);
    }

    public getBoundingClientRect(): ClientRect {
        if (!this.element.nativeElement.getBoundingClientRect) {
            const rect = {
                bottom: 0,
                height: 0,
                left: 0,
                right: 0,
                top: 0,
                width: 0
            };

            return rect as ClientRect;
        }
        return this.element.nativeElement.getBoundingClientRect();
    }

    onToggleSimilaritySearchClicked(event) {
        event.stopPropagation();
        this.toggleSimilaritySearch.emit(this.asset);
    }

    /**
     *
     */
    onAssetDetailQuestionClicked() {
        this.assetAction.emit({id: this.assetId, action: 'support'});
    }

    /**
     * Called when the preview was clicked
     */
    onPreviewClicked() {
        this.assetPreviewService.open(this.asset.id, this.asset.name, this.asset.caption);
    }

    /**
     * Returns whether the user is logged in or not
     */
    isAssetPreviewEnabled(): boolean {
        return this.userDataService.isLoggedIn();
    }

    /**
     * Returns if the cart is available for the user
     */
    isCartAvailable(): boolean {
        if (this.asset && this.asset.subscriptions && this.asset.subscriptions.length) {
            const user = this.userDataService.getUser();
            return !!(user.userFunction && user.userFunction.length && this.sdkOptions.userFunctions && user.userFunction.find(id => id === this.sdkOptions.userFunctions.showCart));
        } else {
            return true;
        }
    }

    /**
     * Returns if the asset has restrictions
     */
    hasRestriction(): boolean {
        if (this.asset && this.asset.restrictions && this.asset.restrictions.length) {
            const user = this.userDataService.getUser();
            return !!this.asset.restrictions.find(restriction => restriction.type === 'block' || restriction.type === 'reservation' || restriction.type === 'block_internaly');
        } else {
            return false;
        }
    }

    /**
     * Returns if the user is logged
     */
    isUserLoggedIn(): boolean {
        return this.userDataService.isLoggedIn();
    }

    /**
     * Called when the preview link was clicked
     */
    onPreviewLinkClicked() {
        this.previewLinkClicked.next(this.asset);
    }
}

export enum AssetType {
    VIDEO = 'video',
    IMAGE = 'image'
}

export enum SubscriptionType {
    EASY_ACCESS = 'easy-access',
    PREMIUM = 'premium',
    ABO = 'abo'
}
