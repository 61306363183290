import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnChanges,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Params, Router } from '@angular/router';
import { CmsBlockContent } from '../cms-block/cms-block-content';
import { CmsLinkTypes } from '../../models/cms.model';
import { LazysizesService } from '../../services/lazysizes.service';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'st-cms-image',
    templateUrl: './cms-image.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CmsImageComponent extends CmsBlockContent implements OnInit, AfterViewInit, OnChanges {

    classes: string;

    queryParamsObj: Params = {};

    useLazySizes = false;

    @ViewChild('imageView') imageView: ElementRef;

    @ViewChild('imageRef') imageRef: ElementRef;

    constructor(el: ElementRef,
                private router: Router,
                private lazysizesService: LazysizesService,
                private renderer: Renderer2,
                private elRef: ElementRef,
                @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
                @Inject(PLATFORM_ID) private platformId: Object) {
        super(el);
        if ( this.sdkOptions.layout && this.sdkOptions.layout.cmsImage ) {
            this.useLazySizes = this.sdkOptions.layout.cmsImage.useLazySizes;
        }
    }

    ngOnInit() {
        if (this.data.queryParams) {
            this.queryParamsObj = JSON.parse(this.data.queryParams);
        }
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }
    }

    ngAfterViewInit(): void {
        if ( this.useLazySizes && this.imageRef && isPlatformBrowser(this.platformId)) {
            this.renderer.setAttribute(this.imageRef.nativeElement, 'data-src',
                this.imageRef.nativeElement.getAttribute('data-image-src') || this.data.src
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    onDragStart(e: DragEvent) {
        if ( !this.data.draggable ) {
            return false;
        }
        e.dataTransfer.setData('text', JSON.stringify({
            html: this.imageView.nativeElement.outerHTML
        }));
    }

    isLink(): boolean {
        return !!this.data.linkType;
    }

    isInternatLink(): boolean {
        return this.data.linkType === CmsLinkTypes.InternalUrl;
    }
}
