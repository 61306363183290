import { AfterViewInit, Directive, Inject, Input } from '@angular/core';
import { MatSelect } from '@angular/material';

@Directive({
    selector: '[matSelectHeight]'
})
export class MatSelectHeightDirective implements AfterViewInit {
    @Input('matSelectHeight') height: number;
    constructor(@Inject(MatSelect) private select: MatSelect) {
    }

    ngAfterViewInit(): void {
        if ( this.height ) {
            (<any> this.select)._getItemHeight = () => this.height;
        }
    }
}
