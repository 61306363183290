import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy, HostBinding
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AssetService } from '../../services/asset.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'st-asset-keywords-selector',
    templateUrl: './asset-keywords-selector.component.html',
    styleUrls: ['./asset-keywords-selector.component.scss']
})
export class AssetKeywordsSelectorComponent implements OnInit, OnDestroy {
    form: FormGroup;
    keywords: string[];
    @Output() changes = new EventEmitter();
    componentDestroyed$ = new Subject();
    showMoreKeywords = false;

    @HostBinding('class.asset-keywords-selector') hasAssetKeywordsSelectorClass = true;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private assetService: AssetService
    ) {}

    async ngOnInit() {
        const { keywords, contentLocation } = await this.assetService.getAsset(
            this.route.snapshot.params.assetId
        );

        const selectedKeywordsFromQueryParams =
            this.route.snapshot.queryParams.keywords || [];

        if (contentLocation && contentLocation[0] && contentLocation[0].address) {
            if (contentLocation[0].address.addressCountry.length) {
                keywords.push(contentLocation[0].address.addressCountry);
            }
            if (contentLocation[0].address.addressLocality.length) {
                keywords.push(contentLocation[0].address.addressLocality);
            }
            if (contentLocation[0].address.addressRegion.length) {
                keywords.push(contentLocation[0].address.addressRegion);
            }
        }

        this.keywords =
            keywords
                .filter(Boolean)
                .sort((a: string, b: string) => a.localeCompare(b));

        this.form = this.fb.group(
            this.keywords.reduce(
                (controlsConfig, keyword) => ({
                    ...controlsConfig,
                    [keyword]:
                        selectedKeywordsFromQueryParams.indexOf(keyword) > -1
                }),
                {}
            )
        );

        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(changes => {
                const selectedKeywords = Object.keys(changes).filter(
                    keyword => !!changes[keyword]
                );
                this.changes.emit(selectedKeywords);
            });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    toggleKeywordsList() {
        this.showMoreKeywords = !this.showMoreKeywords;
    }
}
