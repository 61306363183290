import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Lightbox} from '../models/lightbox';
import {PagingResult} from '../models/paging_result';
import {Article} from '../models/article';
import {SodaApiService} from './soda-api.service';
import {CartDataService} from './cart-data.service';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';

export interface AssetsResponse {
    downloadUrl: string;
}

/**
 * Service that interacts with the lightbox api
 */
@Injectable()
export class LightboxService {
    private API_PATH = 'lightboxes';

    private lightboxBarDragAndDropInititalized$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: SodaApiService,
        private cartDataService: CartDataService
    ) {}

    /**
     * Sets lightbox creation and modification dates to Date object
     */
    private setCreationAndModificationDates = (item: any) => {
        item.creationDate = new Date(item.creationDate);
        item.modificationDate = new Date(item.modificationDate);
    };

    /**
     * Gets lightboxes from the api
     * @param options options from which request params are constructed
     * @returns
     */
    getLightboxes(options: any = {}): Observable<PagingResult<Lightbox>> {
        const page = options['page'] > 1 ? options['page'] : 1;
        const perPage =
            options['itemsPerPage'] > 0 ? options['itemsPerPage'] : 50;

        let url = this.API_PATH + '?';
        url += 'page=' + page;
        url += '&itemsPerPage=' + perPage;

        return this.http.get<any>(url).pipe(
            map(response => {
                let total = 0;
                // @todo: once we understand the 'why' behind this, it should be refactored
                if (response['@type'] === 'hydra:Collection') {
                    const hydra = response;
                    response = hydra['hydra:member'];
                    total = +hydra['hydra:totalItems'];
                }

                response.forEach(this.setCreationAndModificationDates);

                return new PagingResult<Lightbox>(
                    response,
                    total,
                    page,
                    perPage
                );
            })
        );
    }

    /**
     * Creates a lightbox
     * @param data
     * @returns
     */
    createLightbox(data) {
        return this.http.post<any>(this.API_PATH, data).pipe(
            map(response => {
                this.setCreationAndModificationDates(response);
                return response;
            })
        );
    }

    /**
     * Updates a lightbox
     * @param id lightbox id
     * @param data lightbox data
     * @returns
     */
    updateLightbox(id, data) {
        const url = `${this.API_PATH}/${id}`;

        return this.http.put<any>(url, data).pipe(
            map(response => {
                this.setCreationAndModificationDates(response);
                return response;
            })
        );
    }

    /**
     * Deletes a lightbox
     * @param id lightbox id
     * @returns
     */
    deleteLightbox(id) {
        return this.http.delete(`${this.API_PATH}/${id}`);
    }

    /**
     * Gets a lightbox by id
     * @param lightboxId
     * @returns
     */
    getLightbox(lightboxId): Observable<any> {
        return this.http.get<any>(`${this.API_PATH}/${lightboxId}`).pipe(
            map(response => {
                this.setCreationAndModificationDates(response);
                return response;
            })
        );
    }

    /**
     * Adds an asset to a lightbox
     * @param lightboxId lightbox id
     * @param assetId asset id
     * @param comment
     * @param position
     * @returns
     */
    addAsset(
        lightboxId: number,
        assetId: number,
        comment?: string,
        position?: number
    ): Observable<any> {
        const url = `${this.API_PATH}/${lightboxId}/assets`;
        const body = {
            id: assetId,
            comment,
            position
        };

        return this.http.post(url, body);
    }

    /**
     * Updates an asset in a given lightbox
     * @param lightboxId lightbox id
     * @param assetId asset id
     * @param comment
     * @param position
     * @returns
     */
    updateAsset(
        lightboxId: number,
        assetId: string,
        comment?: string,
        position?: number
    ): Promise<any> {
        const url = `${this.API_PATH}/${lightboxId}/assets/${assetId}`;
        const body = {
            id: assetId,
            comment,
            position
        };

        return this.http.put(url, body).toPromise();
    }

    /**
     * Deletes an asset in a given lightbox
     * @param lightboxId lightbox id
     * @param assetId asset id
     * @returns
     */
    deleteAsset(lightboxId: number, assetId: number): Observable<any> {
        return this.http.delete(
            `${this.API_PATH}/${lightboxId}/assets/${assetId}`
        );
    }

    /**
     * Downloads a lightbox as pdf
     * @param id lightbox
     * @returns
     */
    downloadPdf(id) {
        const params = new HttpParams().set('download', 'true');

        return this.http
            .get<AssetsResponse>(`${this.API_PATH}/${id}/pdf`, { params })
            .toPromise()
            .then(response => {
                this.http.download(response.downloadUrl);
            })
            .catch(this.handleError);
    }

    /**
     * Downloads a lightbox as zip
     * @param id lightbox id
     * @returns
     */
    downloadZip(id) {
        return this.http
            .get<AssetsResponse>(`${this.API_PATH}/${id}/zip`)
            .toPromise()
            .then(response => {
                this.http.download(response.downloadUrl);
            })
            .catch(this.handleError);
    }

    /**
     * Downloads a lightbox as zip
     * @param id lightbox id
     * @returns
     */
    downloadHighResZip(id, formData?) {
        const params = new HttpParams().set('usage', formData['usage']);

        return this.http
            .get<AssetsResponse>(`${this.API_PATH}/${id}/zip/hires`, { params })
            .toPromise()
            .then(response => {
                this.http.download(response.downloadUrl);
            })
            .catch(this.handleError);
    }

    /**
     * Sends an email
     * @param id
     * @param email email address
     * @param comment comment
     * @returns
     */
    sendMail(id, email: string, comment: string) {
        const url = `${this.API_PATH}/${id}/send`;
        const params = new HttpParams({ fromObject: { email, comment } });

        return this.http
            .post(url, '', { params })
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @param  error error to handle
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    /**
     * Copies lightbox to cart
     * @param lightboxId lightbox id
     * @returns
     */
    copyToCart(lightboxId): Promise<boolean> {
        return this.getLightbox(lightboxId)
            .toPromise()
            .then(lightbox => {
                let articles: Article[] = lightbox.assets.filter(asset => !asset.asset.subscriptions.length).map(asset => {
                    // @todo: we can refactor Article class in order to pass options to its constructor directly
                    const article = new Article();
                    article.assetId = String(asset.id);
                    return article;
                });
                return this.cartDataService.addArticles(articles);
            });
    }

    setLightboxBarDragAndDropInititalized(value = true) {
        this.lightboxBarDragAndDropInititalized$.next(value);
    }

    isLightboxBarDragAndDropInititalized(): Observable<boolean> {
        return this.lightboxBarDragAndDropInititalized$.asObservable();
    }

    /**
     * Cleanups a lightbox setting it to 'pristine' state
     * @param lightbox
     */
    cleanLightboxForDuplication(lightbox: Lightbox) {
        const lightboxClone: Lightbox = _.cloneDeep(lightbox);

        lightboxClone.id = null;
        lightboxClone.creationDate = null;
        lightboxClone.modificationDate = null;
        lightboxClone.name = lightboxClone.name + ' - Duplicate';

        if (lightboxClone.assets && lightboxClone.assets.length > 0) {
            const newAssets = [];
            for (const asset of lightboxClone.assets) {
                asset.asset = null;
                asset.creationTime = null;
                asset.modificationTime = null;
                newAssets.push(asset);
            }
            lightboxClone.assets = newAssets;
        }

        return lightboxClone;
    }

}
