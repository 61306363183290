import {Component, ElementRef, HostBinding, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CMSBlockContentComponent, CMSBlockDataHeading, CMSRawContentComponent} from '../../models/cms.model';

export class CmsBlockContent implements OnChanges, CMSBlockContentComponent {

    @Input() data: any;

    @Input() metaData: any;

    @Input() isDraftView: boolean;

    @HostBinding('class.cms-content-item') contentItemClass = true;

    @HostBinding('class.is-draft') isDraftClass = false;

    @HostBinding('class.is-draft-container') isDraftContainerClass = false;

    @HostListener('mouseover', ['$event']) mouseover(event: Event) {
        event.stopPropagation();
        if (this.isDraftView) {
            this.isDraftClass = true;
        }
    }

    @HostListener('mouseout', ['$event']) mouseleave(event: Event) {
        event.stopPropagation();
        if (this.isDraftView) {
            this.isDraftClass = false;
        }
    }

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.metaData) {
            this.el.nativeElement.setAttribute('data-blok-c', JSON.stringify(this.metaData));
            this.el.nativeElement.setAttribute('data-blok-uid', this.metaData.id + '-' + this.metaData.uid);
            if (this.isDraftView) {
                this.isDraftContainerClass = true;
            }
        }
    }
}
