import { Injectable } from '@angular/core';
import { SodaApiService } from './soda-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Breakpoint } from '../models/breakpoint.enum';


@Injectable()
export class BreakpointService {

    currentBreakpoint$: BehaviorSubject<Breakpoint> = new BehaviorSubject<Breakpoint>(null);

    constructor(private http: SodaApiService) {}

    setCurrentBreakpoint(breakpoint: Breakpoint) {
        this.currentBreakpoint$.next(breakpoint);
    }

    getCurrentBreakpoint(): Observable<Breakpoint> {
        return this.currentBreakpoint$.asObservable();
    }
}
