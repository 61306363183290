import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, EMPTY, Observable, of, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {Asset} from '../../models/asset';
import {AssetService} from '../../services/asset.service';
import {concatMap, debounceTime, filter, switchMap, take, tap} from 'rxjs/operators';
import {Collection} from '../../models/collection';
import {CollectionDataService} from '../../services/collection-data.service';
import {AssetActionPlugin} from '../../models/asset-action-plugin';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {createPager} from '../asset-search-pagination/asset-search-pagination.component';
import {ImageMetaInformation, ImageSimilarityService} from '../../services/image-similarity.service';
import { WindowRefService } from '../../services/window-ref.service';

/**
 * The component loads the assets for the specified search text that is given via the searchText input and uses the AssetList component to
 * render the loaded assets. Additionally, the layout mode can be set via an input.
 */
@Component({
    selector: 'st-asset-search',
    templateUrl: './asset-search.component.html',
    styleUrls: ['./asset-search.component.scss'],
    providers: [DatePipe]
})
export class AssetSearchComponent implements OnInit, OnDestroy {

    /**
     * Oberservable of assets loaded from the api
     */
    loadedAssets$: Observable<Asset[]>;

    /**
     * List of displayed assets
     */
    assets: Asset[] = [];

    /**
     * BehaviourSubject of the searchText
     */
    searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    /**
     *
     */
    searchFilter$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _isSearchPending: boolean;
    private _nextPage: number;

    /**
     * Search text input
     */
    @Input() set searchText(searchText: string) {
        this.currentSearchText = searchText;
        this.searchText$.next(searchText);
    }

    /**
     * Search filter input
     */
    @Input() set searchFilter(filter: any) {
        this.filterChanged = true;
        this.searchFilter$.next(filter);
    }

    /**
     * Grid item target width for the grid layout
     */
    @Input() gridItemTargetWidth: number;

    /**
     * The default asset height
     */
    @Input()
    defaultAssetHeight: number;

    /**
     * Grid layout
     */
    @Input() layout: string;

    /**
     * The plugins used to augment the asset actions
     */
    @Input() assetActionPlugins: AssetActionPlugin[] = [];

    @Input() emptySearch: boolean;

    @Input() set showLoadMore(value: boolean) {
        if (value && this.nextPage > 1 && this.pager) {
            this.pager = createPager(this.totalItems, Math.ceil(this.nextPage / 4), 200);
            this.numberOfTimesAssetsLoaded = (this.nextPage % 4);
        }

        this._showLoadMore = value;
    }

    _showLoadMore = true;

    @Input() hidePreviewLink: boolean;

    @Output() totalItemsChanged: EventEmitter<number> = new EventEmitter();

    @Output() previewLinkClicked: EventEmitter<Asset> = new EventEmitter<Asset>();

    /**
     * BehaviourSubject for the next page
     */
    private nextPageChanged$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

    /**
     * Data subscriptions array
     */
    dataSubscriptions: Subscription[] = [];

    /**
     * Flag that indicates whether data is loaded or not
     */
    dataLoading: boolean;

    /**
     * Current search text
     */
    currentSearchText: string;

    /**
     * Current search filter
     */
    currentSearchFilter: any;

    /**
     * Current page
     */
    currentPage: number;

    /**
     * Next page
     */
    nextPage: number;

    @Input() itemsPerPage = 50;

    totalItems: number;

    maxPages: number;

    public collections: Collection[] = [];

    @Output()
    toggleSimilaritySearch = new EventEmitter<Asset>();

    @Output() assetsReady = new EventEmitter();

    @Output() assetAction = new EventEmitter<{ id: string; action: string }>();

    numberOfTimesAssetsLoaded = 0;

    showLoadMoreButton = false;

    filterChanged = false;

    @Input() urlGenerator: Function;

    locale: string;

    paginationMaxSize: number;

    pager: any = {};

    enteredPage: string;

    imageFile: Observable<Blob>;

    imageUrl: Observable<string>;

    imageFileUrl: string;

    imageMetaInformation$: Observable<ImageMetaInformation>;

    isSearchPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    @Input() defaultEmptyStateMessage = true;

    @Input() scrollToDetail: boolean;

    constructor(private datePipe: DatePipe, private assetService: AssetService,
                private collectionDataService: CollectionDataService, private translate: TranslateService, private imageSimilarityService: ImageSimilarityService,
                private window: WindowRefService) {
        this.loadedAssets$ = combineLatest(this.searchFilter$.pipe(filter(Boolean)),
            this.nextPageChanged$, this.isSearchPending
        ).pipe(
            filter(Boolean),
            tap(([searchFilter, _, isSearchPending]) => {
                if (isSearchPending === true || !this._isSearchPending || (this.nextPage !== this._nextPage)) {
                    this.dataLoading = true;
                }
            }),
            debounceTime(200),
            switchMap(([searchFilter, _, isSearchPending]) => {
                if (isSearchPending === false && this._isSearchPending && (this.nextPage === this._nextPage)) {
                    this._isSearchPending = null;
                    return of({noAction: true});
                } else if (searchFilter && this.isFilterEngaged(searchFilter)) {
                    this._nextPage = this.nextPage;
                    this._isSearchPending = isSearchPending;
                    this.currentSearchFilter = searchFilter;
                    if (this.filterChanged) {
                        this.assets = [];
                        this.currentPage = 1;
                        this._nextPage = 1;
                        this.nextPage = 1;
                        setTimeout(() => {
                            this.totalItemsChanged.next(this.totalItems);
                        });
                        this.filterChanged = false;
                        this.numberOfTimesAssetsLoaded = 0;
                        this.showLoadMoreButton = false;
                    }
                    return this.assetService.getAssets(this.assetService.getSearchFilters(searchFilter, this.nextPage, this.itemsPerPage))
                        .pipe(tap(response => this.assetsReady.next(response)));
                } else {
                    return EMPTY;
                }
            })
        );

        this.collectionDataService.getCollections().then(collections => {
            this.collections = collections;
        });

        this.locale = this.translate.currentLang;

        this.imageFile = this.imageSimilarityService.getSimilaryImageFile();
        this.imageUrl = this.imageSimilarityService.getSimilaryImageUrl();
        this.imageMetaInformation$ = this.imageSimilarityService.getImageMetaInformation();

        this.imageFile.subscribe(file => {
            if (file) {
                const reader = new FileReader();

                reader.readAsDataURL(file); // read file as data url

                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.imageFileUrl = event.target.result;
                };
            }
        });
        this.imageUrl.subscribe(url => {
            if (url) {
                this.imageFileUrl = url;
            }
        });
    }

    isFilterEngaged(data: any) {
        if (this.emptySearch) return true;
        let engagedFilters = 0;
        const filters = Object.keys(data);
        filters.map(key => {
            if (data[key] && data[key].length > 0) {
                engagedFilters++;
            }
        });
        return engagedFilters > 0;
    }

    ngOnInit() {
        this.dataSubscriptions.push(this.loadedAssets$.subscribe((assets: any) => {
                if (assets && !assets.noAction) {
                    if (assets.searchPending || (this._isSearchPending && !assets.searchPending)) {
                        setTimeout(() => {
                            this.isSearchPending.next(assets.searchPending);
                        }, 1000);
                    }
                    this.totalItems = assets.total;
                    this.maxPages = Math.ceil(this.totalItems / this.itemsPerPage);
                    this.paginationMaxSize = Math.ceil(this.maxPages / 4);
                    this.pager = createPager(this.totalItems, Math.ceil(this.nextPage / 4), 200);
                    this.dataLoading = false;

                    // make sure that no duplicates are displayed
                    const newAssets = [...this.assets, ...assets.assets];
                    const ordering = [... new Set(newAssets.map(asset => asset.id))];
                    const entities = newAssets.reduce((list, entry) => {
                        list[entry.id] = entry;
                        return list;
                    }, {});
                    this.assets = ordering.map(key => entities[key]);
                    setTimeout(() => {
                        this.totalItemsChanged.next(this.totalItems);
                        // After assets loading if vertical scrollbar doesn't appears then load more assets
                        if ( !this.window.hasVerticalScrollbar ) {
                            this.onLoadMoreAssets();
                        }
                        //  Display pagination once page 4 was loaded.
                        if (this._showLoadMore && (this.nextPage === 4 && (this.numberOfTimesAssetsLoaded === 3) || this.numberOfTimesAssetsLoaded >= 4)) {
                            this.showLoadMoreButton = true;
                        }
                    });
                }
            })
        );
        this.dataSubscriptions.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.locale = event.lang;
        }));
    }

    ngOnDestroy(): void {
        this.dataSubscriptions.map(subscription => subscription.unsubscribe());
    }

    /**
     * Called when more assets should be loaded
     */
    onLoadMoreAssets() {
        if (!this.dataLoading) {
            if (this._showLoadMore && (this.nextPage === 4 && (this.numberOfTimesAssetsLoaded === 3) || this.numberOfTimesAssetsLoaded >= 4)) {
                return;
            }

            if (this.nextPage < this.maxPages) {
                this.nextPage++;
                this.numberOfTimesAssetsLoaded++;
                this.nextPageChanged$.next(null);
            }
        }

    }

    /**
     * Called when load more assets button is clicked
     */
    onLoadMoreAssetsOnPageClicked() {
        this.showLoadMoreButton = false;
        this.assets = [];
        this.onLoadMoreAssets();
    }

    onToggleSimilaritySearch(asset: Asset) {
        this.toggleSimilaritySearch.emit(asset);
    }

    onAssetAction($event) {
        this.assetAction.emit($event);
    }

    setPage(number: number) {
        this.showLoadMoreButton = false;
        this.assets = [];
        this.numberOfTimesAssetsLoaded = 0;
        this.nextPage = (number === 1) ? 1 : (number + ((number - 1) * 3));
        this.numberOfTimesAssetsLoaded++;
        this.nextPageChanged$.next(null);
    }

    onPreviewLinkClicked(asset: Asset) {
        this.previewLinkClicked.next(asset);
    }
}
