import {
    Component,
    ComponentFactoryResolver,
    ComponentRef, HostBinding,
    Injector,
    Input, OnChanges,
    OnInit,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {CMSBlockContentComponent, CMSRawContentComponent} from '../../models/cms.model';
import {ComponentRegistries, getInjectionTokenForComponentType} from '../../helpers/dynamic-component.helper';

@Component({
    selector: 'st-cms-block',
    templateUrl: './cms-block.component.html',
    styleUrls: ['./cms-block.component.scss']
})
export class CmsBlockComponent implements OnInit, OnChanges {

    @Input() block: CMSRawContentComponent<any>;

    @Input() isDraftView: boolean;

    @ViewChild('blockContent', { read: ViewContainerRef }) widgetContentRef;

    private componentRef: ComponentRef<CMSBlockContentComponent>;

    constructor(private injector: Injector,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._updateComponent(changes, true);
    }


    /**
     * Update the injected component
     * @param changes
     * @param recreate
     */
    private _updateComponent(changes: SimpleChanges, recreate = false) {
        let compChanges = {};
        if (!this.componentRef || recreate) {
            if (!this.block.type) return;
            if (this.componentRef && recreate) {
                this.componentRef.destroy();
            }
            const injectionToken = getInjectionTokenForComponentType(ComponentRegistries.CMS, this.block.type);
            if (injectionToken) {
                const type = this.injector.get(injectionToken);
                const factory = this.componentFactoryResolver.resolveComponentFactory(type);
                this.componentRef = this.widgetContentRef.createComponent(factory) as ComponentRef<CMSBlockContentComponent>;
                compChanges = {
                    data: new SimpleChange(null, this.block.data, true),
                    children: new SimpleChange(null, this.block.children, true),
                    metaData: new SimpleChange(null, this.block.metaData, true),
                    isDraftView: new SimpleChange(null, this.isDraftView, true)
                };
            }
        } else {
            if (changes['data']) compChanges['data'] = changes['data'];
            if (changes['children']) compChanges['children'] = changes['children'];
            if (changes['metaData']) compChanges['metaData'] = changes['metaData'];
            if (changes['isDraftView']) compChanges['isDraftView'] = changes['isDraftView'];
        }

        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.data = this.block.data;
            this.componentRef.instance.children = this.block.children;
            this.componentRef.instance.metaData = this.block.metaData;
            this.componentRef.instance.isDraftView = this.isDraftView;
            this.componentRef.instance.ngOnChanges(compChanges);
        }
    }
}
