import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'st-webseries-grid-filter',
    templateUrl: './webseries-grid-filter.component.html',
    styleUrls: ['./webseries-grid-filter.component.scss']
})
export class WebseriesGridFilterComponent implements OnInit {
    @Input() filterItems: string[];
    @Output() filterChange = new EventEmitter<string>();
    activeFilterItem: string = null;

    constructor() {}

    ngOnInit() {}

    onFilterItemClicked(filterItem: string) {
        this.activeFilterItem = filterItem;
        this.filterChange.emit(this.activeFilterItem);
    }

    onResetFilterClicked() {
        this.activeFilterItem = null;
        this.filterChange.emit(this.activeFilterItem);
    }
}
