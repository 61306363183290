import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {Webseries} from '../models/webseries';
import {TranslateService} from '@ngx-translate/core';
import {HttpHeaders} from '@angular/common/http';

/**
 * Services for loading the webseries and specific webserie
 */
@Injectable()
export class WebseriesService {
    private path = 'webseries';

    constructor(private http: SodaApiService) {}

    /**
     * Get the webserie from the api with the specified webserie id
     * @param id
     */
    public getWebserie(id: number): Promise<Webseries> {
        const url = `${this.path}/${id}`;
        return this.http
            .get(url)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Get the webseries from the api
     * @param options
     */
    public getWebseries(options: any): Promise<Webseries[]> {
        let url = this.path + '?';
        url += 'page=' + (options['page'] > 1 ? options['page'] : 1);
        url +=
            '&itemsPerPage=' +
            (options['itemsPerPage'] > 0 ? options['itemsPerPage'] : 50);

        if (options['parent_id']) {
            url += '&parent_id=' + Number(options['parent_id']);
        }

        if (options['subItemsPerPage']) {
            url += '&subItemsPerPage[assets]=' + options['subItemsPerPage'];
        }

        if (options['sort']) {
            url += '&sort=' + options['sort'];
        }

        return this.http
            .get(url)
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @param error error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);

        return Promise.reject(error.message || error);
    }
}
