import {Pipe, PipeTransform} from '@angular/core';

/**
 * The pipe transforms the given value into a dollar or euro sign based on the value
 */
@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value ? value.toLowerCase() : '';
    if (value === 'usd') {
      return '$';
    }

    return '€';
  }

}
