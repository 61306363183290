import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

/**
 * Service that handles image operations
 */
@Injectable()
export class ImageUtilsService {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    /**
     * Sets image sizes from path?
     * @param path to be used as image src
     * @returns
     */
    public getSizeByUrl(path: string): Promise<any> {
        if (isPlatformServer(this.platformId)) {
            return Promise.resolve({
                path: path,
                success: true,
                width: 0,
                height: 0
            });
        }
        return new Promise(resolve => {
            const img = new Image();

            img.onload = () =>
                resolve({
                    path: path,
                    success: true,
                    width: img.width,
                    height: img.height
                });

            img.onerror = () =>
                resolve({
                    path: path,
                    success: false,
                    width: null,
                    height: null
                });

            img.src = path;
        });
    }

    /**
     * Resizes image height according to its new width
     * @param  oldWidth
     * @param  oldHeight
     * @param  newWidth
     * @returns
     */
    public resizeToWidth(oldWidth, oldHeight, newWidth) {
        return {
            width: Math.round(newWidth),
            height: Math.round((oldHeight / oldWidth) * newWidth)
        };
    }

    /**
     * Resizes image width according to its new height
     * @param oldWidth
     * @param oldHeight
     * @param newHeight
     * @returns
     */
    public resizeToHeight(oldWidth, oldHeight, newHeight) {
        return {
            width: Math.round((oldWidth / oldHeight) * newHeight),
            height: Math.round(newHeight)
        };
    }

    /**
     * Resizes image to its longer side length
     * @param oldWidth
     * @param oldHeight
     * @param longerSideLength
     * @returns
     */
    public resizeToLongerSideLength(oldWidth, oldHeight, longerSideLength) {
        if (oldWidth > oldHeight) {
            return this.resizeToWidth(oldWidth, oldHeight, longerSideLength);
        } else {
            return this.resizeToHeight(oldWidth, oldHeight, longerSideLength);
        }
    }
}
