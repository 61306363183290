import {Pipe, PipeTransform} from '@angular/core';

/**
 * The pipe transform the given file name into file name without extension
 */
@Pipe({
  name: 'removeFileExtension'
})
export class RemoveFileExtensionPipe implements PipeTransform {

  transform(value: string): string {
    const pos = value.lastIndexOf('.');
    if ( pos !== -1 ) {
      value = value.substr(0, pos);
    }
    return value;
  }

}
