import { Component, OnChanges, OnInit, Input, ElementRef } from '@angular/core';
import { CmsMenuGroup, CMSBlockContentComponent } from '../../models/cms.model';
import { CmsBlockContent } from '../cms-block/cms-block-content';

@Component({
    selector: 'lib-cms-menu-group',
    templateUrl: './cms-menu-group.component.html',
    styleUrls: ['./cms-menu-group.component.css']
})
export class CmsMenuGroupComponent extends CmsBlockContent
    implements OnInit, OnChanges, CMSBlockContentComponent {
    @Input() data: CmsMenuGroup;
    @Input() children: any[];

    constructor(el: ElementRef) {
        super(el);
    }

    ngOnInit() {}

    ngOnChanges(): void {}
}
