import {ElementRef, Injectable} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 *
 * Service that interacts with downloadlogs api
 */
@Injectable()
export class DragAndDropService {

    lightboxBarContainerRef: ElementRef;
    assetListContainerRef: ElementRef;
    imageSimilarityOverlayContainerRef: ElementRef;


    dragulaStates: {
        [dragulaId: string]: number
    } = {};

    lightboxBarDragAndDropInititalized$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    lightboxBarDrop: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    imageSimilarityOverlayDrop: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    fileOver: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(private dragulaService: DragulaService) {
    }

    attachDragAndDropForLightbox(dragulaId) {
        this.dragulaStates = {
            ...this.dragulaStates,
            [dragulaId]: (this.dragulaStates[dragulaId]) ? this.dragulaStates[dragulaId] + 1 : 1
        };
    }

    detachDragAndDropForLightbox(dragulaId: string) {
        this.dragulaStates = {
            ...this.dragulaStates,
            [dragulaId]: (this.dragulaStates[dragulaId]) ? this.dragulaStates[dragulaId] - 1 : 0
        };

        if (this.dragulaStates[dragulaId] === 0) {
            this.dragulaService.destroy(dragulaId);
        }
    }

    initAssetListDragAndDrop() {
        const assetsGroup = this.dragulaService.find('assets');

        const groupOptions = {
            accepts: (el, target, source, sibling) => {
                return (this.lightboxBarContainerRef && target === this.lightboxBarContainerRef.nativeElement)
            },
            moves: (el, container) => {
                return (!this.lightboxBarContainerRef || container !== this.lightboxBarContainerRef.nativeElement) && el.nodeName !== 'ST-ASSET-DETAIL';
            },
            copy: true
        };
        if (assetsGroup) {
            this.dragulaService.destroy('assets');
        }
        this.dragulaService.createGroup('assets', groupOptions);
        this.setLightboxBarDragAndDropInititalized(true);

        this.dragulaService.drop('assets')
            .subscribe((value) => {
                if (value.target === this.lightboxBarContainerRef.nativeElement) {
                    this.lightboxBarDrop.next(value);
                } else if (value.target === this.imageSimilarityOverlayContainerRef.nativeElement) {
                    this.imageSimilarityOverlayDrop.next(value);
                }
            });
    }

    setFileOver(status: boolean) {
        this.fileOver.next(status);
    }

    hasFileOver() {
        return this.fileOver.asObservable();
    }

    droppedInImageSimilarityOverlay(): Observable<any> {
        return this.imageSimilarityOverlayDrop.asObservable();
    }

    droppedInLightboxBar(): Observable<any> {
        return this.lightboxBarDrop.asObservable();
    }

    setLightboxBarContainerRef(ref: ElementRef) {
        this.lightboxBarContainerRef = ref;
    }

    setImageSimilarityOverlayContainerRef(ref: ElementRef) {
        this.imageSimilarityOverlayContainerRef = ref;
    }

    setLightboxBarDragAndDropInititalized(value = true) {
        this.lightboxBarDragAndDropInititalized$.next(value);
    }

    isLightboxBarDragAndDropInititalized(): Observable<boolean> {
        return this.lightboxBarDragAndDropInititalized$.asObservable();
    }

}
