import {
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { AssetSearchBreadcrumb } from '../../models/asset_search_breadcrumb';
import { Collection } from '../../models/collection';
import { CollectionDataService } from '../../services/collection-data.service';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {debounce, debounceTime, map, skip, take, takeUntil} from 'rxjs/operators';
import { flatten } from 'lodash';

import * as _moment from 'moment';
import { SupplierDataService } from '../../services/supplier-data.service';
import { Supplier } from '../../models/supplier';
import {AI_PLUS_FILE, AI_PLUS_IDS, AI_PLUS_IMAGE_META_INFORMATION, removeFromLocalStorage} from '../../helpers/local-storage.helper';
import {ImageSimilarityService} from '../../services/image-similarity.service';
import { CategoriesService } from '../../services/categories.service';
import * as _ from 'lodash';
import { UserService } from '../../services/user.service';
const moment = _moment;

export interface AssetSearchCollectionBreadcrumb extends AssetSearchBreadcrumb {
    collectionId: number;
}

export const CATEGORY_MAPPING = {
    83: 'baby',
    84: 'children',
    85: 'youth',
    86: 'adult',
    87: 'senior',
    88: 'mixedAgeGroups'
};

/**
 * Component that displays the currently activated filter breadcrumbs. By clicking the remove button on the breadcrumb,
 * the filter option is disabled.
 */
@Component({
    selector: 'st-asset-search-breadcrumbs',
    templateUrl: './asset-search-breadcrumbs.component.html',
    styleUrls: ['./asset-search-breadcrumbs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AssetSearchBreadcrumbsComponent implements OnInit, OnDestroy, OnChanges {

    // TODO: Should use filter interface
    filter$: BehaviorSubject<any> = new BehaviorSubject({});

    collections$: BehaviorSubject<Partial<Collection>[]> = new BehaviorSubject([]);

    breadcrumbs$: Observable<AssetSearchBreadcrumb[]>;

    componentDestroyed$ = new Subject<void>();

    suppliers$: BehaviorSubject<Supplier[]> = new BehaviorSubject([]);

    @Input() hideRemoveBreadcrumbs: string[] = [];

    @Input()
    plugins: AssetSearchBreadcrumbsPlugin[] = [];

    @Input()
    pluginOptions: {excludeCategories?: any[]} = {};

    @Output()
    filterChanged: EventEmitter<any> = new EventEmitter<any>();

    @Input() availableFilters = [
        'q',
        'coll',
        'pgid',
        'license',
        'orientation',
        'color',
        'size',
        'release',
        'datesFilter',
        'peopleCategory',
        'category',
        'exclusive',
        'cop',
        'mediaType',
        'peopleNumber',
        'aiplus',
        'restrictions'
    ];

    @Input() hideFilters: string[] = [];

    protected filterNameIds = [
        'q',
        'coll',
        'pgid',
        'license',
        'orientation',
        'color',
        'size',
        'release',
        'creationDateFrom',
        'creationDateTo',
        'creationMonthsAgo',
        'uploadDateFrom',
        'uploadDateTo',
        'uploadMonthsAgo',
        'modelReleased',
        'propertyReleased',
        'peopleCategory',
        'keywords',
        'mediaType',
        'peopleNumber',
        'aiplus',
        'restrictions',
        'category'
    ];

    protected singleFilter = [
        'exclusive',
        'aiplus'
    ];

    protected dateFilters = [
        'creationDateFrom',
        'creationDateTo',
        'creationMonthsAgo',
        'uploadDateFrom',
        'uploadDateTo',
        'uploadMonthsAgo',
    ];

    protected peopleCategory = [
        83,
        84,
        85,
        86,
        87,
        88
    ];

    protected release = [
        'mr',
        'pr'
    ];

    protected mediaType = [
        'photography',
        'illustration'
    ];

    protected filterValueIds = [
        'license',
        'color',
        'orientation',
        'size',
        'size',
        'peopleCategory',
        'release',
        'exclusive',
        'mediaType',
        'peopleNumber',
        'datesFilter',
        'restrictions'
    ];

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        public collectionDataService: CollectionDataService,
        private supplierDataService: SupplierDataService,
        private imageSimilarityService: ImageSimilarityService,
        public translateService: TranslateService,
        public categoriesService: CategoriesService) {
    }

    @Input()
    set filters(filters) {
        const _filters = { coll: [], ...filters };
        this.filter$.next(_filters);
    }

    ngOnInit() {
        this.collectionDataService.collectionsChanged$.next(null);
        this.collectionDataService.collectionsChanged$.pipe(debounceTime(100), takeUntil(this.componentDestroyed$)).subscribe(() => {
            this.collectionDataService.getCopyrightCollections().then(collections => {
                this.collections$.next(collections);
            });
        });
        this.supplierDataService.getSuppliers().then(suppliers => {
            this.suppliers$.next(suppliers);
        });
        this.translateService.onLangChange.pipe(takeUntil(this.componentDestroyed$)).subscribe(change => {
            this.locale = (change.lang === 'en') ? 'en-GB' : change.lang;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.breadcrumbs$ = this.getBreadcrumbs();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
    }

    getFilterValueTranslationId(filter: string) {
        return `sodatechSdk.assetSearchBreadcrumbs.filterValue.${filter}`;
    }

    getFilterValueItemTranslationId(filter: string, value: string) {
        return `sodatechSdk.assetSearchBreadcrumbs.filterValue.${filter}.${value}`;
    }

    getFilterNameTranslationId(filter: string) {
        return `sodatechSdk.assetSearchBreadcrumbs.filterName.${filter}`;
    }

    getBreadCrumbTranslations(): Observable<any> {
        const filterNameTranslationIds = this.filterNameIds.concat(this.singleFilter).map(
            this.getFilterNameTranslationId);
        const filterValueTranslationIds = this.filterValueIds.reduce((ids, filterKey) => {

            return [...ids, this.getFilterValueTranslationId(filterKey)];
        }, []);
        return combineLatest(
            this.translateService.stream(filterNameTranslationIds),
            this.translateService.stream(filterValueTranslationIds).pipe(
                map(translations => {
                    return Object.keys(translations).reduce((translationMap, translationId) => {
                        const obj = translations[translationId];
                        Object.keys(obj).forEach(value => {
                            translationMap[`${translationId}.${value}`] = obj[value];
                        });
                        return translationMap;
                    }, {});
                })
            )
        ).pipe(
            map(([t1, t2]) => {
                    return {
                        ...t1,
                        ...t2
                    };
                }
            )
        );
    }

    getBreadcrumbs(): Observable<AssetSearchBreadcrumb[]> {
        const translations$ = this.getBreadCrumbTranslations();
        return combineLatest(this.filter$, this.collections$, this.suppliers$, translations$).pipe(
            map(([filter, collections, suppliers, translations]) => {
                const filterKeys = Object.keys(filter).filter(key => {
                    return this.availableFilters.indexOf(key) > -1 && this.hideFilters.indexOf(key) === -1;
                });
                return filterKeys.reduce((breadcrumbs, filterKey) => {
                    return [
                        ...breadcrumbs,
                        ...this.getBreadcrumbsForFilterKey(filterKey, filter, collections, suppliers, translations)
                    ];
                }, []);
            })
        );
    }

    getBreadcrumbsForFilterKey(filterKey: string, filter: any, collections: Partial<Collection>[], suppliers: Supplier[],
                               translations: any): AssetSearchBreadcrumb[] {
        if (filterKey === 'q') {
            return this.getKeywordBreadcrumbs(filter, translations);
        } else if (filterKey === 'coll') {
            return this.getCollectionBreadcrumbs(filter, collections, translations);
        } else if (filterKey === 'pgid') {
            return this.getSupplierBreadcrumbs(filter, suppliers, translations);
        } else if (filterKey === 'datesFilter') {
            return this.getDateBreadcrumbs(filter, translations);
        } else if (filterKey === 'release') {
            return this.getReleaseBreadcrumbs(filter, translations);
        } else if (filterKey === 'mediaType') {
            return this.getMediaTypeBreadcrumbs(filter, translations);
        } else if (filterKey === 'peopleCategory') {
            return this.getPeopleCategoryBreadcrumbs(filter, translations);
        } else if (filterKey === 'orientation') {
            return this.getOrientationBreadcrumbs(filter, translations);
        } else if (this.singleFilter.indexOf(filterKey) !== -1) {
            return this.getSingleBreadcrumbs(filterKey, filter, translations);
        } else if (filterKey === 'category') {
            return this.getCategoryBreadcrumbs(filter, translations);
        } else {
            const pluginBreadcrumbs = this.plugins
                                          .map(plugin => plugin.getBreadcrumbs(filterKey, filter, translations, this.pluginOptions))
                                          .filter(breadcrumbs => breadcrumbs !== null);
            if (pluginBreadcrumbs.length > 0) {
                return flatten(pluginBreadcrumbs);
            }
            return this.getSimpleBreadcrumbs(filterKey, filter, translations);
        }
    }

    getOrientationBreadcrumbs(filter: any, translations: any) {
        if (!filter.orientation) {
            return [];
        }

        return filter.orientation.map(orientationValue => {
            const translationId = this.getFilterNameTranslationId('orientation');
            const translationValue = this.getFilterValueItemTranslationId('orientation', orientationValue);

            return {
                typeLabel: translations[translationId],
                content: translations[translationValue],
                name: 'orientation',
                value: orientationValue,
                type: 'orientation'
            };
        });
    }

    getCollectionBreadcrumbs(filter: any, collections: Partial<Collection>[],
                             translations: any): AssetSearchCollectionBreadcrumb[] {
        return (collections && collections.length > 0) ? filter.coll.map(id => {
            const selectedCollection = collections.find(collection => collection.id === id);
            if ( !selectedCollection ) {
                return false;
            }
            const { name } = selectedCollection;
            const collectionFilterNameId = this.getFilterNameTranslationId('coll');
            return {
                typeLabel: translations[collectionFilterNameId],
                content: name,
                name: `collection_${id}`,
                value: true,
                collectionId: id,
                type: 'collection'
            };
        }) : [];
    }

    getSupplierBreadcrumbs(filter: any, suppliers: Supplier[],
                             translations: any): AssetSearchBreadcrumb[] {
        if (suppliers && suppliers.length > 0) {
            const supplierId = parseInt(filter['pgid'], 10);
            const supplier = suppliers.find(s => s.id === supplierId);
            if (supplier) {
                const collectionFilterNameId = this.getFilterNameTranslationId('pgid');
                return [
                    {
                        typeLabel: translations[collectionFilterNameId],
                        content: supplier.name,
                        name: `pgid`,
                        value: supplierId,
                        type: 'simple'
                    }
                ];
            }
        }
        return [];
    }

    getReleaseBreadcrumbs(filter: any, translations: any): AssetSearchBreadcrumb[] {
        if (!filter.release) {
            return [];
        }
        return this.release.map(releaseValue => {
            const releaseFilterNameId = this.getFilterNameTranslationId('release');
            const filterValueItem = this.getFilterValueItemTranslationId('release', releaseValue);
            const filteredReleaseFilter = filter.release.filter(releaseItem => releaseItem === releaseValue);
            if (filteredReleaseFilter.length) {
                return {
                    typeLabel: translations[releaseFilterNameId],
                    content: translations[filterValueItem],
                    name: 'release',
                    value: releaseValue,
                    type: 'release'
                };
            }
        }).filter(Boolean);
    }

    getMediaTypeBreadcrumbs(filter: any, translations: any): AssetSearchBreadcrumb[] {
        if (!filter.mediaType) {
            return [];
        }
        return this.mediaType.map(mediaTypeValue => {
            const filterNameId = this.getFilterNameTranslationId('mediaType');
            const filterValueItem = this.getFilterValueItemTranslationId('mediaType', mediaTypeValue);
            const filterMediaType = filter.mediaType.filter(mediaTypeItem => mediaTypeItem === mediaTypeValue);
            if (filterMediaType.length) {
                return {
                    typeLabel: translations[filterNameId],
                    content: translations[filterValueItem],
                    name: 'mediaType',
                    value: mediaTypeValue,
                    type: 'mediaType'
                };
            }
        }).filter(Boolean);
    }

    getPeopleCategoryBreadcrumbs(filter: any, translations: any): AssetSearchBreadcrumb[] {
        if (!filter.peopleCategory) {
            return [];
        }
        return filter.peopleCategory.map(value => {
            const releaseFilterNameId = this.getFilterNameTranslationId('peopleCategory');
            const filterValueItem = this.getFilterValueItemTranslationId('peopleCategory', CATEGORY_MAPPING[value]);
            return {
                typeLabel: translations[releaseFilterNameId],
                content: translations[filterValueItem],
                name: 'peopleCategory',
                value: value,
                type: 'peopleCategory'
            };
        }).filter(Boolean);
    }

    getDateBreadcrumbs(filter: any, translations: any): AssetSearchBreadcrumb[] {
        const breadcrumbs = [];
        this.dateFilters.forEach(filterKey => {
            const date = filter.datesFilter[filterKey];
            if (filterKey === 'creationMonthsAgo' && !!date) {
                const filterNameTranslationId = this.getFilterNameTranslationId(filterKey);
                const filterValueItem = this.getFilterValueItemTranslationId('datesFilter', date);
                const crumb = {
                    typeLabel: translations[filterNameTranslationId],
                    content: translations[filterValueItem],
                    value: filter[filterKey],
                    name: filterKey,
                    type: 'date'
                };
                breadcrumbs.push(crumb);
            } else if (filterKey === 'uploadMonthsAgo' && !!date) {
                const filterNameTranslationId = this.getFilterNameTranslationId(filterKey);
                const filterValueItem = this.getFilterValueItemTranslationId('datesFilter', date);

                const crumb = {
                    typeLabel: translations[filterNameTranslationId],
                    content: translations[filterValueItem],
                    value: filter[filterKey],
                    name: filterKey,
                    type: 'date'
                };
                breadcrumbs.push(crumb);
            } else if (!!date) {
                const dateStr = moment(filter.datesFilter[filterKey]).locale(this.locale).format('L');
                if (dateStr) {
                    const filterNameTranslationId = this.getFilterNameTranslationId(filterKey);
                    const crumb = {
                        typeLabel: translations[filterNameTranslationId],
                        content: dateStr,
                        value: filter[filterKey],
                        name: filterKey,
                        type: 'date'
                    };
                    breadcrumbs.push(crumb);
                }
            }
        });
        return breadcrumbs;
    }

    getSimpleBreadcrumbs(filterKey: string, filter: any, translations: any): AssetSearchBreadcrumb[] {
        if (filter[filterKey]) {
            const values = (Array.isArray(filter[filterKey])) ? filter[filterKey] : [filter[filterKey]];
            const filterNameTranslationId = this.getFilterNameTranslationId(filterKey);

            return values.map(value => {
                let formatedValue = value;
                const translationId = this.getFilterValueItemTranslationId(filterKey, filter[filterKey]);
                if (translations[translationId]) {
                    formatedValue = translations[translationId];
                }

                return {
                    typeLabel: translations[filterNameTranslationId],
                    content: formatedValue,
                    name: filterKey,
                    value: filter[filterKey],
                    type: 'simple'
                };
            });
        } else {
            return [];
        }
    }

    getSingleBreadcrumbs(filterKey: string, filter: any, translations: any): AssetSearchBreadcrumb[] {
        if (filter[filterKey]) {
            const filterNameTranslationId = this.getFilterNameTranslationId(filterKey);
            return [
                {
                    content: translations[filterNameTranslationId],
                    name: filterKey,
                    value: filter[filterKey],
                    type: 'simple'
                }
            ];
        } else {
            return [];
        }
    }

    getKeywordBreadcrumbs(filter: any, translations: any): AssetSearchBreadcrumb[] {
        const keywordString = filter['q'] as string;
        if (keywordString) {
            const filterNameTranslationId = this.getFilterNameTranslationId('q');
            const parts = this.splitKeyword(keywordString);
            return (parts) ? parts.map(part => {
                return {
                    typeLabel: translations[filterNameTranslationId],
                    content: part.replace(/"/g, ''),
                    name: 'q',
                    value: part,
                    type: 'keyword'
                };
            }) : [{
                typeLabel: translations[filterNameTranslationId],
                content: keywordString,
                name: 'q',
                value: keywordString,
                type: 'keyword'
            }];
        } else {
            return [];
        }
    }

    getCategoryBreadcrumbs(filter: any, translations: any): AssetSearchBreadcrumb[] {
        if (!filter.category) {
            return [];
        }
        return filter.category.filter(value => (this.pluginOptions.excludeCategories  && this.pluginOptions.excludeCategories
            .indexOf(value) === -1) || !this.pluginOptions.excludeCategories).map(value => {
            const filterNameTranslationId = this.getFilterNameTranslationId('category');
            const categoryData = _.find(this.categoriesService.loadedCategories.value,
                (_category) => Number(_category.id) === Number(value)
            );
            return {
                typeLabel: translations[filterNameTranslationId],
                content: categoryData ? categoryData.name : value,
                name: 'category',
                value: value,
                type: 'category'
            };
        });
    }

    splitKeyword(keyword: string): string[] | null {
        return keyword.match(/(?:[^\s"]+|"[^"]*")+/g);
    }

    /**
     * Removes a breadcrumb from the array
     */
    removeBreadcrumb(e: Event, breadcrumb) {
        e.preventDefault();
        this.onHandleBreadcrumbSideEffects(breadcrumb);
        this.filterChanged.emit(this.calculateFilterWithoutBreadcrumb(this.filter$.getValue(), breadcrumb));
    }

    private calculateFilterWithoutBreadcrumb(filter, breadcrumb) {
        let newFilter = { ... filter };
        if (breadcrumb.type === 'keyword') {
            const keywords = this.splitKeyword(filter.q);
            newFilter.q = keywords.filter(keyword => keyword !== breadcrumb.value).join(' ');
        } else if (breadcrumb.type === 'collection') {
            newFilter.coll = filter.coll.filter(
                cid => cid !== breadcrumb.collectionId
            );
        } else if (breadcrumb.type === 'date') {
            newFilter.datesFilter = {
                ...filter.datesFilter,
                [breadcrumb.name]: ''
            };
        } else if (breadcrumb.type === 'release') {
            newFilter.release = filter.release.filter(
                releaseItem => releaseItem !== breadcrumb.value
            );
        } else if (breadcrumb.type === 'peopleCategory') {
            newFilter.peopleCategory = filter.peopleCategory.filter(
                category => {
                    return category !== breadcrumb.value;
                }
            );
        } else if (breadcrumb.type === 'orientation') {
            newFilter.orientation = filter.orientation.filter(orientation => {
                return  orientation !== breadcrumb.value;
            });
        } else if (breadcrumb.type === 'mediaType') {
            newFilter.mediaType = filter.mediaType.filter(
                mediaType => {
                    return mediaType !== breadcrumb.value;
                }
            );
        } else if (breadcrumb.type === 'category') {
            newFilter.category = filter.category.filter(category => {
                return category !== breadcrumb.value;
            });
        }  else {
            const pluginsResult = this.plugins.reduce((state, plugin) => {
                const result = plugin.removeBreadcrumb(breadcrumb, state.filter);
                return {
                    filter: {
                        ...state.filter,
                        ...result.filter
                    },
                    affected: state.affected || result.affected
                };
            }, { filter, affected: false });
            if (pluginsResult.affected) {
                newFilter = pluginsResult.filter;
            } else {
                newFilter[breadcrumb.name] = '';
            }
        }
        return newFilter;
    }

    onRemoveAllClicked(e: Event) {
        e.preventDefault();
        this.breadcrumbs$.pipe(take(1)).subscribe(breadcrumbs => {
            const newFilter = breadcrumbs.reduce((filter, breadcrumb) => {
                this.onHandleBreadcrumbSideEffects(breadcrumb);
                return this.calculateFilterWithoutBreadcrumb(filter, breadcrumb);
            }, this.filter$.getValue());
            this.filterChanged.emit(newFilter);
        });
    }

    onHandleBreadcrumbSideEffects(breadcrumb: AssetSearchBreadcrumb) {
        if (breadcrumb.name === 'aiplus') {
            this.imageSimilarityService.clearSimilarityResults();
        }
    }
}

export interface AssetSearchBreadcrumbsPlugin {

    getBreadcrumbs(filterKey: string, filter: any, translations: any, options: any): AssetSearchBreadcrumb[] | null;

    removeBreadcrumb(breadcrumb: AssetSearchBreadcrumb, filter: any): {filter: any, affected: boolean};

}
