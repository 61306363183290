import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CartDataService } from '../../services/cart-data.service';
import { OrderService } from '../../services/order.service';
import { OrderDataService } from '../../services/order-data.service';
import { PreferencesService } from '../../services/preferences.service';
import { Order } from '../../models/order';
import {EventTrackerEvents, EventTrackerService} from '../../services/event-tracker.service';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';

/**
 * Component that handles the checkout process
 */
@Component({
    selector: 'st-cart-checkout',
    templateUrl: './cart-checkout.component.html',
    styleUrls: ['./cart-checkout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CartCheckoutComponent implements OnInit {
    public errorMsg = '';
    public order: Order;
    public paymentOptions = [];
    public formData = null;

    private tokenPaymentType = 'payment_type';

    @ViewChild('paymentFormTag')
    paymentFormTag;

    paymentForm: FormGroup = this.fb.group({
        type: new FormControl(''),
        note: new FormControl(''),
        purchaseOrderNum: new FormControl('')
    });

    paypalLogo: string;

    creditCardLogo: string;

    constructor(
        private fb: FormBuilder,
        private cartDataService: CartDataService,
        private orderService: OrderService,
        private preferenceService: PreferencesService,
        private orderDataService: OrderDataService,
        private eventTrackingService: EventTrackerService,
        @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions
    ) {
        this.orderDataService.loadOrder();
        if ( this.sdkOptions.cart && this.sdkOptions.cart.checkout ) {
            this.paypalLogo = this.sdkOptions.cart.checkout.paypalLogo;
            this.creditCardLogo = this.sdkOptions.cart.checkout.creditCardLogo;
        }
    }

    ngOnInit() {
        this.orderDataService.order.subscribe(order => {
            if (!order) {
                this.order = null;
                return;
            }

            this.order = order;
            this.paymentForm.patchValue({ note: order.memo });

            this.orderService.getOptions(this.order.id).subscribe(options => {
                const paymentOptions = [];
                for (const option of options) {
                    paymentOptions.push(option.type);
                }

                this.paymentOptions = paymentOptions;

                this.setDefaultPaymentType();
            });
        });
    }

    /**
     * Sets a payment type by default if there are no others
     */
    setDefaultPaymentType() {
        let type = 'authorizenet';
        const storedType = this.preferenceService.get(this.tokenPaymentType);

        if( storedType && this.isOptionAvailable(storedType) ) {
            type = storedType;
        } else if(this.isOptionAvailable('invoice')) {
            type = 'invoice';
        } else if(this.isOptionAvailable('authorizenet')) {
            type = 'authorizenet';
        } else if(this.isOptionAvailable('payone')) {
            type = 'payone';
        } else if(this.isOptionAvailable('paypal')) {
            type = 'paypal';
        }

        this.preferenceService.set(this.tokenPaymentType, type);
        this.paymentForm.controls['type'].setValue(type);
    }

    continuePayment() {
        this.eventTrackingService.pushNewEvent(EventTrackerEvents.BUY_ASSETS, {totalPrice: this.order.totalPrice, articles: (this.order.articles && this.order.articles.length) ? this.order.articles.length : 'unkown'});
        if (this.paymentForm.getRawValue().note !== this.order.memo) {
            this.saveNote().then(() => {
                this.continuePayment();
            });
            return;
        }
        const type = this.paymentForm.getRawValue().type;
        if (!type || !(this.paymentOptions.indexOf(type) > -1)) {
            return;
        }

        this.preferenceService.set(this.tokenPaymentType, type);

        this.orderService.getFormData(this.order.id, type).subscribe(data => {
            this.formData = data;
            window.setTimeout(() => this.proceedPayment(), 300);
        });
    }

    proceedPayment() {
        this.paymentFormTag.nativeElement.submit();
    }

    isOptionAvailable(type: string) {
        return this.paymentOptions.indexOf(type) > -1;
    }

    saveNote() {
        const noteTxt = this.paymentForm.getRawValue().note;
        const order = this.orderDataService.getOrder();

        order.memo = noteTxt;

        return this.orderService.updateOrder(order);
    }
}
