import {Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SDK_OPTIONS, SdkOptions} from '../models/sdk-options';
import {TranslateService} from '@ngx-translate/core';
import {
    CMSBlockData,
    CMSBlockDataColumn,
    CMSBlockDataHeading,
    CMSBlockDataRichText,
    CMSBlockDataRow,
    CMSHTMLData,
    CmsButton,
    CmsIcon,
    CmsMenu,
    CmsMenuItem,
    CMSRawContentComponent,
    CMSWebseriesSlider,
    CmsMenuGroup, CMSVideoData, CmsImage, CmsContentTeaser
} from '../models/cms.model';
import {CmsSection} from '../models/cms.model';
import {BehaviorSubject, empty, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {isPlatformServer} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';

/**
 * CMSService
 */
@Injectable({
    providedIn: 'root'
})
export class CMSService {

    version: string;

    // Stores that store each story by id and the storie stored the content per language
    storyConfigs: BehaviorSubject<{ [id: string]: { [language: string]: any } }> = new BehaviorSubject({});
    storyConfigError: BehaviorSubject<{ [id: string]: { [language: string]: any } }> = new BehaviorSubject({});
    isListeningForChanges: boolean;

    storyBlokInEditMode: BehaviorSubject<boolean> = new BehaviorSubject(null);

    constructor(private http: HttpClient, @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions, private _sanitizer: DomSanitizer,
                @Inject(PLATFORM_ID) private platformId: Object, private translate: TranslateService, private ngZone: NgZone) {
    }

    isStoryBlokInEditMode(): Observable<boolean> {
        return this.storyBlokInEditMode.asObservable();
    }

    /**
     * Get the story block params inculding version, token and cached version
     */
    getStoryBlokParams(version: string = 'published'): HttpParams {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('version', version);
        if (version === 'draft') {
            httpParams = httpParams.append('token', this.sdkOptions.storyBlok.draftToken);
        } else {
            httpParams = httpParams.append('token', this.sdkOptions.storyBlok.token);

        }
        let cv = null;
        if (window['sb-cv']) {
            cv = window['sb-cv'];
        } else {
            cv = new Date().getTime().toString();
        }
        httpParams = httpParams.append('cv', cv);
        return httpParams;
    }

    hashCode(value: string) {
        let hash = 0;
        if (value.length === 0) {
            return hash;
        }
        for (let i = 0; i < value.length; i++) {
            const char = value.charCodeAt(i);
            // tslint:disable-next-line:no-bitwise
            hash = ((hash << 5) - hash) + char;
            // tslint:disable-next-line:no-bitwise
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    /**
     * Listens on live input from stroy blok
     */
    listenOnLiveInput(lang: string) {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        if (!this.isListeningForChanges) {
            this.isListeningForChanges = true;
            // debounce the events for 300ms, as changing a lot of text or quick changes would lead to a not needed new renderings of the content
            const fn = this.debounce((event) => {
                this.ngZone.run(() => {
                    if (event.action === 'input') {
                        this.storyConfigs.next({
                            ...this.storyConfigs.getValue(),
                            [event.story.slug]: {
                                ...this.storyConfigs.getValue()[event.story.slug],
                                [lang]: this.handleLiveInput(event)
                            }
                        });
                    } else if (event.action === 'input') {
                        this.loadDraftConfig(event.slug, lang);
                    }
                });
            }, 300);
            window.storyblok.on(['input', 'change'], fn);
        }
    }

    /**
     * Loads the config from the story blok endpoint
     * @param id
     * @param lang
     */
    loadConfig(id: string, lang: string) {
        const slug = this.getRealSlug(id);
        const urlLang = (lang === 'en') ? '' : lang + '/';
        const endpoint = `${this.sdkOptions.storyBlok.storiesEndpoint}${urlLang}/${id}`;
        return this.http.get(endpoint, {params: this.getStoryBlokParams()}).toPromise().then((data: any) => {
            const config = this.transformRoot(data, null);
            this.storyConfigs.next({
                ...this.storyConfigs.getValue(),
                [data.story.slug]: {
                    ...this.storyConfigs.getValue()[data.story.slug],
                    [lang]: {
                        story: data.story.slug,
                        children: config,
                        customClass: data.story.content.customClass,
                        metaTitle: data.story.content.metaTitle,
                        metaDescription: data.story.content.metaDescription,
                        fontUrl: data.story.content.fontUrl,
                        fontFamily: data.story.content.fontFamily
                    }
                }
            });
        }).catch((error) => {
            this.storyConfigError.next({
                ...this.storyConfigError.getValue(),
                [slug]: {
                    ...this.storyConfigError.getValue()[slug],
                    [lang]: error
                }
            });
        });
    }

    getRealSlug(id: string): string {
        let slug;
        const idParts = id.split('/');
        if (idParts.length > 1) {
            slug = idParts[idParts.length - 1];
        } else {
            slug = idParts[0];
        }
        return slug;
    }

    /**
     * Loads the config from the story blok endpoint
     * @param id
     * @param lang
     */
    loadDraftConfig(id: string, lang: string): Promise<any> {
        const slug = this.getRealSlug(id);
        const urlLang = (lang === 'en') ? '' : lang + '/';
        const endpoint = `${this.sdkOptions.storyBlok.storiesEndpoint}${urlLang}/${id}`;
        return this.http.get(endpoint, {params: this.getStoryBlokParams('draft')}).toPromise().then((data: any) => {
            const config = this.transformRoot(data, data.story.id, true);
            this.storyConfigs.next({
                ...this.storyConfigs.getValue(),
                [data.story.slug]: {
                    ...this.storyConfigs.getValue()[data.story.slug],
                    [lang]: {
                        story: data.story.slug,
                        children: config,
                        customClass: data.story.content.customClass,
                        metaTitle: data.story.content.metaTitle,
                        metaDescription: data.story.content.metaDescription,
                        fontUrl: data.story.content.fontUrl,
                        fontFamily: data.story.content.fontFamily,
                        metaData: {
                            name: data.story.content.component,
                            id: data.story.id,
                            uid: data.story.content._uid
                        }
                    }
                }
            });
        }).catch((error) => {
            this.storyConfigError.next({
                ...this.storyConfigError.getValue(),
                [slug]: {
                    ...this.storyConfigError.getValue()[slug],
                    [lang]: error
                }
            });
        });
    }

    /**
     * Get the slug content for a given slug and language
     * @param slug
     * @param lang
     */
    getConfigForSlug(slug: string, lang: string): Observable<any> {
        return this.storyConfigs.asObservable().pipe(map(configs => {
            return (configs && configs[slug] && configs[slug][lang]) ? configs[slug][lang] : empty();
        }));
    }

    /**
     * Get the slug error for a given slug and language
     * @param slug
     * @param lang
     */
    getErrorForSlug(slug: string, lang: string): Observable<any> {
        return this.storyConfigError.asObservable().pipe(switchMap(errors => {
            return (errors && errors[slug] && errors[slug][lang]) ? of(errors[slug][lang]) : empty();
        }));
    }

    /**
     * Handles the live input and transform it into the application format
     * @param data
     */
    handleLiveInput(data: any) {
        const config = this.transformRoot(data, data.story.id, true);
        return {
            story: data.story.slug,
            children: config,
            customClass: data.story.content.customClass,
            metaData: {
                name: data.story.content.component,
                id: data.story.id,
                uid: data.story.content._uid
            }
        };
    }

    /**
     * Transforms the story
     * @param data
     * @param storyId
     * @param isDraft
     */
    transformRoot(data: any, storyId: number, isDraft = false): any[] {
        const contentData: any[] = data.story.content.content;
        return (contentData) ? this.transformContentItems(contentData, storyId, isDraft) : [];
    }

    /**
     * Transform content items
     * @param data
     * @param storyId
     * @param isDraft
     */
    transformContentItems(data: any, storyId: number, isDraft = false): any[] {
        return data.map(item => {
            if (item.component === 'row') {
                return this.transformContentItemRow(item, storyId, isDraft);
            } else if (item.component === 'column') {
                return this.transformContentItemColumn(item, storyId, isDraft);
            } else if (item.component === 'rich-text') {
                return this.transformContentItemRichText(item, storyId, isDraft);
            } else if (item.component === 'heading') {
                return this.transformContentItemHeading(item, storyId, isDraft);
            } else if (item.component === 'slider') {
                return this.transformContentItemSlider(item, storyId, isDraft);
            } else if (item.component === 'section') {
                return this.transformContentItemSection(item, storyId, isDraft);
            } else if (item.component === 'button') {
                return this.transformContentItemButton(item, storyId, isDraft);
            } else if (item.component === 'icon') {
                return this.transformContentItemIcon(item, storyId, isDraft);
            } else if (item.component === 'image') {
                return this.transformContentItemImage(item, storyId, isDraft);
            } else if (item.component === 'html') {
                return this.transformContentHtmlComponent(item, storyId, isDraft);
            } else if (item.component === 'video') {
                return this.transformContentVideoComponent(item, storyId, isDraft);
            } else if (item.component === 'menu') {
                return this.transformContentMenu(item, isDraft);
            } else if (item.component === 'menu-item') {
                return this.transformContentMenuItem(item, isDraft);
            } else if (item.component === 'menu-group') {
                return this.transformContentMenuGroup(item, isDraft);
            } else if (item.component === 'faq') {
                return this.transformFaqComponent(item, isDraft);
            } else if (item.component === 'faq-entry') {
                return this.transformFaqEntryComponent(item, isDraft);
            } else if (item.component === 'content-teaser') {
                return this.transformContentItemContentTeaser(item, storyId, isDraft);
            } else {
                return null;
            }
        }).filter(item => !!item);
    }

    /**
     * Transforms the content item row
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemRow(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CMSBlockDataRow> {
        return {
            type: item.component,
            children: (item.content && item.content.length) ? this.transformContentItems(item.content, spaceId, isDraft) : null,
            data: {
                customClass: ((item.fullWidth) ? 'no-gutters ' : '') + item.customClass,
                backgroundColor: (item.backgroundColor && item.backgroundColor.color.length) ? item.backgroundColor.color : null,
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                }
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item column
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemColumn(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CMSBlockDataColumn> {
        return {
            type: item.component,
            children: (item.content && item.content.length) ? this.transformContentItems(item.content, spaceId, isDraft) : null,
            data: {
                customClass: item.customClass,
                width: {
                    'col': (item.col) ? item.col : null,
                    'col-sm': (item.colSm) ? item.colSm : null,
                    'col-md': (item.colMd) ? item.colMd : null,
                    'col-lg': (item.colLg) ? item.colLg : null,
                    'col-xl': (item.colXl) ? item.colXl : null,
                },
                border: item.borderThickness > 0 ? {
                    width: item.borderThickness,
                    color: item.borderColor ? item.borderColor.color : '',
                    radius: item.borderRadius
                } : null,
                backgroundColor: (item.backgroundColor && item.backgroundColor.color.length) ? item.backgroundColor.color : null,
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                },
                contentPositioning: (item.verticalPosition || item.horizontalPosition) ? {
                    vertical: item.verticalPosition || null,
                    horizontal: item.horizontalPosition || null
                } : null,
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item rich text
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemRichText(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CMSBlockDataRichText> {
        return {
            type: item.component,
            children: (item.content && item.content.length) ? this.transformContentItems(item.content, spaceId, isDraft) : null,
            data: {
                customClass: item.customClass,
                text: item.text,
                position: item.position,
                color: (item.color) ? item.color.color : null,
                backgroundColor: item.backgroundColor ? item.backgroundColor.color : null,
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null
                },
                fontUrl: item.fontUrl,
                fontFamily: item.fontFamily
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined,
        };
    }

    /**
     * Transforms the content item heading
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemHeading(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CMSBlockDataHeading> {
        return {
            type: item.component,
            data: {
                customClass: item.customClass,
                text: item.text,
                id: item.id,
                level: +item.level,
                color: item.color ? item.color.color : '',
                position: item.position,
                border: (item.borderThickness > 0 && item.borderWidth > 0) ? {
                    color: item.borderColor ? item.borderColor.color : '',
                    gap: item.borderGap,
                    height: item.borderThickness,
                    width : item.borderWidth,
                    position: item.borderPosition,
                } : null,
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                },
                backgroundColor: (item.backgroundColor && item.backgroundColor.color.length)
                    ? this.convertHex(item.backgroundColor.color, item.backgroundOpacity)
                    : null,
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item slider
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemSlider(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CMSWebseriesSlider> {
        return {
            type: item.component,
            data: {
                customClass: item.customClass,
                linkType: item.linkType,
                linkUrl: item.linkUrl,
                overlayPositioning: {
                    horizontal: item.overlayHorizontalPosition,
                    vertical: item.overlayVerticalPosition
                },
                sliderConfig: {
                    sliderTitle: item.title,
                    sliderDescription: item.description,
                    webSeriesId: item.webseriesId,
                    showWebseriesOverlay: item.showWebseriesOverlay,
                    showTitle: item.showTitle,
                    showDescription: item.showDescription,
                    titleSource: item.titleSource,
                    descriptionSource: item.descriptionSource,
                    thumbnails: item.displayThumbnails,
                    loop: item.loop,
                    sliderHeight: '500px',
                    breakPointHeights: {
                        mobile: item.mobileHeight + 'px',
                        tablet: item.tabletHeight + 'px',
                        desktop: item.desktopHeight + 'px'
                    },
                    thumbsSliderHeight: item.thumbsSliderHeight || '120px',
                    thumbCount: 11,
                    slidesLength: item.slidesLength,
                    effect: item.effect,
                    duration: +item.duration,
                    transition: +item.transition,
                    showAutoplayControls: item.showAutoplayControls,
                    slideContentControl: item.slideContentControl,
                },
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                }
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item section
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemSection(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CmsSection> {
        return {
            type: item.component,
            children: (item.content && item.content.length) ? this.transformContentItems(item.content, spaceId, isDraft) : null,
            data: {
                customClass: (item.isOverlay) ? item.customClass + ' section-overlay overlay' : item.customClass,
                hasOverlay: item.isOverlay,
                overlayOpacity: item.overlayOpacity,
                backgroundType: item.backgroundType,
                backgroundValue: item.backgroundValue,
                height: item.height,
                isDarkSection: item.isDarkSection,
                isBoxed: item.isBoxed,
                webseriesId: item.webseriesId,
                linkType: item.linkType,
                linkUrl: item.linkUrl,
                assetSize: item.assetSize,
                contentPositioning: {
                    vertical: item.verticalPosition,
                    horizontal: item.horizontalPosition
                },
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                }
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined

        };
    }

    /**
     * Transforms the content item button
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemButton(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CmsButton> {
        return {
            type: item.component,
            data: {
                customClass: (item.position) ? `text-${item.position} ` + item.customClass : item.customClass,
                title: item.title,
                linkType: item.linkType,
                linkUrl: item.linkUrl,
                anchor: item.anchor,
                position: item.position,
                queryParams: item.queryParams,
                outlets: item.outlets,
                backgroundColor: (item.backgroundColor && item.backgroundColor.color.length) ? item.backgroundColor.color : null,
                hoverBackgroundColor: (item.hoverBackgroundColor && item.hoverBackgroundColor.color.length) ? item.hoverBackgroundColor.color : null,
                textColor: (item.textColor && item.textColor.color.length) ? item.textColor.color : null,
                hoverTextColor: (item.hoverTextColor && item.hoverTextColor.color.length) ? item.hoverTextColor.color : null,
                borderColor: (item.borderColor && item.borderColor.color.length) ? item.borderColor.color : 'none',
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                }
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item icon
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemIcon(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CmsIcon> {
        return {
            type: item.component,
            data: {
                customClass: item.customClass,
                name: item.name,
                size: item.size,
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                }
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item icon
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemImage(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CmsImage> {
        return {
            type: item.component,
            data: {
                customClass: item.customClass,
                src: item.src,
                width: item.width,
                height: item.height,
                linkType: item.linkType,
                linkUrl: item.linkUrl,
                queryParams: item.queryParams,
                outlets: item.outlets,
                alt: item.altTag,
                draggable: item.draggable,
                spacing: {
                    marginLeft: item.marginLeft || null,
                    marginTop: item.marginTop || null,
                    marginRight: item.marginRight || null,
                    marginBottom: item.marginBottom || null,
                    paddingLeft: item.paddingLeft || null,
                    paddingTop: item.paddingTop || null,
                    paddingRight: item.paddingRight || null,
                    paddingBottom: item.paddingBottom || null,
                }
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item html
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentHtmlComponent(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CMSHTMLData> {
        return {
            type: item.component,
            data: {
                customClass: item.customClass,
                content: item.content
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content item html
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentVideoComponent(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CMSVideoData> {
        return {
            type: item.component,
            data: {
                customClass: item.customClass,
                url: this._sanitizer.bypassSecurityTrustResourceUrl(item.url + '?rel=0'),
                height: item.height
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined
        };
    }

    /**
     * Transforms the content menu
     * @param item
     * @param isDraft
     */
    transformContentMenu(item: any, isDraft: boolean): CMSRawContentComponent<CmsMenu> {
        return {
            type: item.component,
            children: (item.content && item.content.length) ? this.transformContentItems(item.content, null, isDraft) : null,
            data: {
                customClass: item.customClass,
                title: item.title,
            }
        };
    }

    /**
     * Transforms the content item menu
     * @param item
     * @param isDraft
     */
    transformContentMenuItem(item: any, isDraft: boolean): CMSRawContentComponent<CmsMenuItem> {
        return {
            type: item.component,
            data: {
                title: item.title,
                customClass: item.customClass,
                linkType: item.linkType,
                linkUrl: item.linkUrl,
                authorizedLink: item.authorizedLink,
                queryParams: item.queryParams,
                outlets: item.outlets
            }
        };
    }

    /**
     * Transforms the content menu group
     * @param item
     * @param isDraft
     */
    private transformContentMenuGroup(item: any, isDraft: boolean): CMSRawContentComponent<CmsMenuGroup> {
        return {
            type: item.component,
            children: (item.items && item.items.length) ? this.transformContentItems(item.items, null, isDraft) : null,
            data: {
                title: item.title,
                customClass: item.customClass
            }
        };
    }

    /**
     * Transforms the content item content teaser
     * @param item
     * @param spaceId
     * @param isDraft
     */
    transformContentItemContentTeaser(item: any, spaceId: number, isDraft: boolean): CMSRawContentComponent<CmsContentTeaser> {
        return {
            type: item.component,
            children: (item.content && item.content.length) ? this.transformContentItems(item.content, spaceId, isDraft) : null,
            data: {
                showAsTeaser: item.showAsTeaser
            },
            metaData: (isDraft) ? {
                name: item.component,
                id: spaceId,
                uid: item._uid
            } : undefined

        };
    }

    transformFaqComponent(item: any, isDraft) {
        return {
            type: item.component,
            children: (item.content && item.content.length > 0) ? this.transformContentItems(item.content, null, isDraft) : null,
            data: {
                customClass: item.customClass,
            }
        };
    }

    transformFaqEntryComponent(item: any, isDraft) {
        return {
            type: item.component,
            children: (item.content && item.content.length > 0) ? this.transformContentItems(item.content, null, isDraft) : null,
            data: {
                customClass: item.customClass,
                question: item.question,
                chevronIconColor: item.chevronIconColor ? item.chevronIconColor.color : '',
                isOpened: !!item.isOpened
            }
        };
    }

    debounce(fn, delay) {
        let timer = null;
        return function () {
            const self = this,
                args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(self, args);
            }, delay);
        };
    }

    checkForStoryBlokEditMode() {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        window.storyblok.pingEditor(() => {
            this.storyBlokInEditMode.next(window.storyblok.isInEditor());
        });
    }

    convertHex(hex, opacity) {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        return 'rgba(' + r + ',' + g + ',' + b + ',' + (opacity || 100) / 100 + ')';
    }
}
