import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ElementRef,
    HostBinding,
    ViewChild,
    Inject
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {merge, Observable, of, Subject, Subscription} from 'rxjs';
import {SuggestService} from '../../services/suggest.service';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take} from 'rxjs/operators';
import {ImageSimilarityService} from '../../services/image-similarity.service';
import {DragulaService} from 'ng2-dragula';
import {DragAndDropService} from '../../services/drag-and-drop.service';
import {TranslateService} from '@ngx-translate/core';
import {ImageSimilarityOverlayComponent} from '../image-similarity-overlay/image-similarity-overlay.component';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';

/**
 * Component that provides auto complete functionality to search for assets by keyword. When a suggestion is selected, the search text is
 * propagated via the output. Can be used in conjunction with the AssetSearch to search for assets instantly.
 */
@Component({
    selector: 'st-asset-search-input',
    templateUrl: './asset-search-input.component.html',
    styleUrls: ['./asset-search-input.component.scss']
})
export class AssetSearchInputComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Search fomr group
     */
    public form: FormGroup;

    /**
     * Subject of options base
     */
    public optionsBase: Subject<any> = new Subject<any>();

    /**
     * Observable of options
     */
    public options: Observable<any> = null;

    private lastKeywordChange = 0;

    public showPopoverFlag = false;

    @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();

    @Input() value: string;

    @Input() showEmptyOverlay: true;

    @Input() includeImageSimilarityOverlay: boolean;

    @Input() isDesktopView: boolean;

    dataSubscription: Subscription;

    enterKeyPressed: boolean;

    @Input() placeholderText: string;

    @Input() clearOnEnterKeyPressed = true;

    @HostBinding('class.asset-search-input-root') mainClass = true;

    @ViewChild('imageSimilarityOverlayComponent') imageSimilarityOverlayComponent: ImageSimilarityOverlayComponent;

    @ViewChild('upload') upload: any;

    isImageSimilarityOverlayOpen: boolean;

    constructor(@Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
                private router: Router,
                private route: ActivatedRoute,
                private fb: FormBuilder,
                private suggestionService: SuggestService,
                private dragulaService: DragulaService,
                private imageSimilarityService: ImageSimilarityService,
                private translate: TranslateService,
                private dragAndDropService: DragAndDropService) {
        this.form = this.fb.group({
            search: ['']
        });
        if ( this.sdkOptions.similaritySearch && this.sdkOptions.similaritySearch.api.length > 0 ) {
            this.dragulaService.drag('assets').subscribe(() => {
                this.isImageSimilarityOverlayOpen = true;
            });

            this.dragAndDropService.hasFileOver().subscribe(state => {
                this.isImageSimilarityOverlayOpen = state;
            });
        }
    }

    ngOnInit() {
        const stream = this.form.valueChanges.pipe(map(data => {
            this.lastKeywordChange = (new Date('now')).getTime();
            this.enterKeyPressed = false;
            return data;
        }));

        const suggestOptions = stream.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((data: any) => {
                const keyword = String(data.search).trim();
                if (keyword.length < 2) {
                    return of(null);
                }

                if (keyword === '') {
                    return of(null);
                }
                return this.suggestionService
                    .getSuggestions(keyword).pipe(
                        map(result => result.map(str => {
                            str = str.toLowerCase();
                            const isMultiPart = str.split(' ').length > 1;
                            const text = str.replace(keyword, '<strong>' + keyword + '</strong>');

                            return {
                                text: isMultiPart ? `${text}` : text,
                                value: isMultiPart ? `"${str}"` : str
                            };
                        }))).pipe(map(abc => {
                        if (this.enterKeyPressed) {
                            return [];
                        } else {
                            return abc;
                        }

                    }));
            })
        );

        this.options = merge(this.optionsBase, suggestOptions);


        this.dataSubscription = stream.pipe(debounceTime(600),
            distinctUntilChanged()
        ).subscribe(data => {
            const searchValue: string = this.form.get('search').value;
            if (searchValue.match(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig)) {
                this.imageSimilarityService.getSimilarityForImageUrl(searchValue);
                this.hideAutocomplete();
                this.searchTextChanged.next();
            } else {
                if (data.search && data.search.length > 1) {
                    this.searchTextChanged.next(data.search);
                }
            }
        });
    }

    checkSearch(event) {
        if (event.key === 'Enter') {
            this.enterKeyPressed = true;
            if ( this.clearOnEnterKeyPressed ) {
                this.searchTextChanged.next();
            } else {
                const searchValue: string = this.form.get('search').value;
                if (searchValue === '') {
                    this.searchTextChanged.next();
                } else {
                    this.searchTextChanged.next(searchValue);
                }
            }
            this.hideAutocomplete();
        } else if (event.key === 'Escape') {
            this.hideAutocomplete();
        }
        this.showPopover(event);
    }

    hideAutocomplete() {
        this.optionsBase.next(null);
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.form.patchValue({
            search: this.value
        }, {emitEvent: false});
    }

    showPopover(event) {
        this.showPopoverFlag = !event.target.value;
    }

    onBlur(event) {
        this.showPopoverFlag = false;
    }

    onDragAsset(event: Event) {
    }

    onPhotoClicked(event: Event) {
        event.stopPropagation();
        if (!this.isDesktopView) {
            this.upload.nativeElement.click();
        } else {
            this.isImageSimilarityOverlayOpen = !this.isImageSimilarityOverlayOpen;
        }
    }

    uploadedFile(file: File) {
        this.isImageSimilarityOverlayOpen = false;
        if (file) {
            this.imageSimilarityService.getSimilarityForImage(file);
        }
    }

    onOutsideClick() {
        if (this.isImageSimilarityOverlayOpen) {
            this.isImageSimilarityOverlayOpen = false;
        }
    }

    onSimilaryUrlEntered(url: string) {
        this.isImageSimilarityOverlayOpen = false;
        this.imageSimilarityService.getSimilarityForImageUrl(url);
    }

    onFilesSelectedForUpload(files: any) {
        this.handleNewFile(files);
    }

    async handleNewFile(files: any) {
        const file: File = files[0];
        const fileSizeMB = file.size / 1000 / 1000;
        const fileName = file.name ? file.name.toLowerCase() : file.type ? file.type : '';
        const rightFileType = fileName.endsWith('.jpg')
            || fileName.endsWith('.jpeg')
            || fileName.endsWith('.png')
            || fileName.endsWith('image/jpeg')
            || fileName.endsWith('image/jpg')
            || fileName.endsWith('image/png');

        if (rightFileType && fileSizeMB <= 6) {
            this.uploadedFile(files[0]);
        } else {
            this.uploadedFile(null);
            const translation = await this.translate.get('sodatechSdk.aiPlus.invalidFile').pipe(take(1)).toPromise();
            alert(translation);
        }
    }
}
