import {Component} from '@angular/core';


@Component({
    selector: 'st-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  
    
}
