import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';

/**
 * Component for verifying a newly created account
 */
@Component({
  selector: 'st-signup-verification',
  templateUrl: './signup-verification.component.html',
  styleUrls: ['./signup-verification.component.scss']
})
export class SignupVerificationComponent implements OnInit, OnChanges {

    @Input() userId: number;

    @Input() token: string;

    public status = '';

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
    }

    /**
     * Verify the the user with the userId and the token
     */
    verify() {
        this.userService.verify(this.userId, this.token)
            .then(success => {
                    this.status = success ? 'success' : 'fail';
                    this.authenticationService.setSignupVerificationSuccessfulStatus(true);
                },
                error => {
                    this.status = 'fail';
                }
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.userId && this.token) {
            this.verify();
        }
    }

}
