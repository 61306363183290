import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {SubscriptionType} from '../asset-detail/asset-detail.component';

@Component({
    selector: 'st-asset-high-res-popover',
    templateUrl: './asset-high-res-popover.component.html',
    styleUrls: ['./asset-high-res-popover.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AssetHighResPopoverComponent implements OnInit, OnChanges, OnDestroy {

    @Input() subscriptions: any[];

    @Output() highResDownload: EventEmitter<{ subscriptionId: number; usage: string }> = new EventEmitter<{ subscriptionId: number; usage: string }>();

    @Input() isRFLicense: boolean;

    @Input() displayRFHint: boolean;

    @Input() hideSubscriptionText: boolean;

    @Input() userFunctions: number[];

    displayUsageField: boolean;

    showPopover: boolean;

    highResUsage: string;

    form: FormGroup;

    subscription: Subscription;

    currentSubscriptionType: string;

    subscriptionType = SubscriptionType;

    currentSubscription: any;

    constructor(@Inject(SDK_OPTIONS) public sdkOptions: SdkOptions, private fb: FormBuilder) {
        this.form = this.fb.group({
            subscriptionId: [],
            usage: ['', Validators.required]
        });
        this.subscription = this.form.valueChanges.subscribe(changes => {
            if (changes.subscriptionId) {
                this.currentSubscription = this.subscriptions.find(subscription => subscription.subscriptionId === changes.subscriptionId);
                const title = this.currentSubscription.title.toLowerCase();
                if (title.indexOf('easy') > -1) {
                    this.currentSubscriptionType = SubscriptionType.EASY_ACCESS;
                } else if (title.indexOf('premium') > -1) {
                    this.currentSubscriptionType = SubscriptionType.PREMIUM;
                } else if (title.indexOf('abo') > -1) {
                    this.currentSubscriptionType = SubscriptionType.ABO;
                }
            }
        });
    }

    ngOnInit() {
    }

    toggleHighResPopover(event: Event) {
        event.stopPropagation();
        if (this.userFunctions && this.userFunctions.length) {
            const directDownload = this.userFunctions.indexOf(this.sdkOptions.userFunctions.directHighResDownload) > -1;
            if (directDownload) {
                this.form.get('usage').setValidators(null);
                this.form.get('usage').updateValueAndValidity();
                this.downloadHighRes();
                return;
            }
        }
        this.showPopover = !this.showPopover;
    }

    hideHighResPopover() {
        if (this.showPopover) {
            this.showPopover = false;
        }
    }

    downloadHighRes() {
        if (this.form.valid) {
            this.highResDownload.next(this.form.value);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['subscriptions'] && this.subscriptions && this.subscriptions.length) {
            this.form.patchValue({
                subscriptionId: this.subscriptions[0].subscriptionId
            });
        }

    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
