import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { LightboxDataService } from '../../services/lightbox-data.service';
import { CartDataService } from '../../services/cart-data.service';
import { AssociatedMedia } from '../../models/associated_media';
import { Asset } from '../../models/asset';
import { PreferencesService } from '../../services/preferences.service';
import { AssetActionPlugin } from '../../models/asset-action-plugin';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetAction } from '../../models/asset-action';
import {AssetService} from '../../services/asset.service';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import {RemoveFileExtensionPipe} from '../../pipes/remove-file-extension.pipe';
import {EventTrackerEvents, EventTrackerService} from '../../services/event-tracker.service';
import {UserDataService} from '../../services/user-data.service';
import {AssetType} from '../asset-detail/asset-detail.component';
import {transliterate as tr} from 'transliteration';
import * as _ from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';

/**
 * Component for the displaying the asset in the grid
 */
@Component({
    selector: 'st-grid-asset-thumb',
    templateUrl: './grid-asset-thumb.component.html',
    styleUrls: ['./grid-asset-thumb.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [RemoveFileExtensionPipe]
})
export class GridAssetThumbComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {

    /**
     * Asset type enum
     */
    assetType= AssetType;

    /**
     * Asset
     */
    @Input() asset: any = {};

    /**
     * Lightbox id the asset belongs to
     */
    @Input() lightboxId: number = null;

    /**
     * Flag that describes whether the asset is selected or not
     */
    @Input() selected: boolean = false;

    /**
     * Triggered when the asset details should be opened for the asset
     */
    @Output() detail = new EventEmitter<any>();

    /**
     * Toggles the similarity search
     */
    @Output() toggleSimilaritySearch = new EventEmitter<Asset>();

    /**
     * Width of the grid item
     */
    @Input() gridItemWidth: number;

    /**
     * Asset action plugin that includes additional non sdk actions
     */
    @Input() assetActionPlugins: AssetActionPlugin[] = [];

    /**
     * Seo url generator
     */
    @Input() urlGenerator: Function;

    /**
     * Flag that describes whether the asset name should be displayed or not
     */
    @Input() displayAssetName = false;

    /**
     * Current asset file
     */
    assetFile: AssociatedMedia;

    /**
     * Flag that describes whether the preview is shown or not
     */
    showPreview: boolean;

    /**
     * Actions for the asset
     */
    assetActions$: Observable<AssetAction[]>;

    @HostBinding('class.st-grid-asset-thumb') className = true;
    @HostBinding('class.is-first') firstClassName = false;
    @HostBinding('class.is-last') lastClassName = false;
    @HostBinding('class.is-top') topClassName = false;
    @HostBinding('class.is-preview-mode') isPreviewModeClassName = false;

    /**
     * Seo url
     */
    seoUrl: string;

    /**
     * Background image url for the asset
     */
    public backgroundImage: SafeResourceUrl;

    /**
     * Flag tht describes whether the caption should be displayed or not
     */
    public displayAssetCaption$: Observable<boolean>;

    /**
     * Flagt that describes whether the asset name should be displayed or not
     */
    public displayAssetName$: Observable<boolean>;

    @ViewChild('thumbImageView') thumbImageView: ElementRef;

    /**
     * Flag that describes if the cart is available or not
     */
    isCartAvailable: boolean;

    constructor(
        private hostElement: ElementRef,
        private sanitizer: DomSanitizer,
        private lightboxDataService: LightboxDataService,
        private cartDataService: CartDataService,
        private preferencesService: PreferencesService,
        private assetService: AssetService,
        @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions,
        private removeFileExtensionPipe: RemoveFileExtensionPipe,
        private eventTrackerService: EventTrackerService,
        private userDataService: UserDataService
    ) {}

    @HostListener('window:resize')
    onWindowResize() {
        this.updateHostClassNames();
    }

    @HostListener('mouseover')
    onMouseOver() {
        this.isPreviewModeClassName = this.showPreview && true;
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.isPreviewModeClassName = this.showPreview && false;
    }

    ngOnInit() {
        this.preferencesService.observe('showpreview').pipe(
            untilDestroyed(this)
        ).subscribe((showPreview) => {
            this.showPreview = showPreview;
        });

        this.displayAssetCaption$ = of(this.sdkOptions.layout && this.sdkOptions.layout.grid && this.sdkOptions.layout.grid.displayAssetCaption);

        this.displayAssetName$ = of(this.displayAssetName);
    }

    onDragStart(e: DragEvent) {
        e.dataTransfer.setData('text', JSON.stringify({
            html: this.thumbImageView.nativeElement.outerHTML,
            assetId: this.asset.id
        }));
    }

    ngOnChanges(changes: any) {
        this.updateHostClassNames();
        if (changes.asset && changes.asset.currentValue) {
            const asset = changes.asset.currentValue;
            this.assetFile = this.getFittingAssetFile(asset);

            if (this.sdkOptions.searchGridData.assetUrlSuffix) {
                const newPath = this.assetFile.contentUrl + this.sdkOptions.searchGridData.assetUrlSuffix + this.getImageUrl();
                this.backgroundImage = this.sanitizer.bypassSecurityTrustUrl(newPath);
            } else {
                this.backgroundImage = this.sanitizer.bypassSecurityTrustUrl(this.assetFile.contentUrl);
            }

            this.updateActions();

            // is cart available
            if (this.sdkOptions.assetDetails.alwaysDisplayCalculator) {
                this.isCartAvailable = true;
            } else if (this.asset && this.asset.subscriptions && this.asset.subscriptions.length) {
                const user = this.userDataService.getUser();
                this.isCartAvailable = !!(user && user.userFunction && user.userFunction.length && user.userFunction.find(id => id === 59));
            } else {
                this.isCartAvailable = true;
            }
        }
        if ((changes['asset'] || changes['urlGenerator']) && this.asset && this.urlGenerator) {
            this.seoUrl = this.urlGenerator(this.asset);
        }


    }

    getImageUrl() {
        const prefix = tr(`${this.sdkOptions.searchGridData.assetNamePrefix}${this.removeFileExtensionPipe.transform(this.asset.name)} - `);
        let caption = tr(this.asset.caption).trim().replace(/[\/\\:*?"<>|]/g, '-');
        const suffix = tr('.jpg');
        const maxLength = 240 - prefix.length - suffix.length;
        const captionLength = caption.length;
        const totalLength = captionLength + prefix.length + suffix.length;
        if (totalLength > 240) {
            caption = tr(_.truncate(caption, {
                'length': maxLength
            })).replace(/\./g, '');
        }

        return encodeURIComponent(tr(`${prefix}${caption}${this.sdkOptions.searchGridData.assetNameSuffix}`));
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.updateHostClassNames();
        });
    }

    ngAfterViewChecked() {
        this.updateHostClassNames();
    }
    ngOnDestroy(): void {}

    getFittingAssetFile(asset: any): any {
        let assetFile = null;
        for (const tmpFile of asset.associatedMedia) {
            if (
                tmpFile.fileFormat !== 'image/jpeg' ||
                tmpFile.additionalType.indexOf('watermarked') >= 0 ||
                tmpFile.additionalType === 'original'
            ) {
                continue;
            }
            assetFile = tmpFile;
        }

        return assetFile;
    }

    /**
     * Emits to the asset-detail component to show given asset
     */
    showImageDetail(event?: Event): void {
        this.detail.emit({
            id: this.asset.id,
            element: this.hostElement,
            asset: this.asset,
            event: event
        });
    }

    /**
     * Returns current asset copyright
     * Defaults to 'Panoramic Images'
     */
    getCopyright(): string {
        if (this.asset.supplier && this.asset.supplier.copyright) {
            return this.asset.supplier.copyright;
        }

        return 'Panoramic Images';
    }

    /**
     * Adds current asset to the lightbox through the api
     * @param event
     */
    addToLightbox(event) {
        event.stopPropagation();
        event.preventDefault();

        this.lightboxDataService.addAsset(this.asset.id, '');
    }

    /**
     * Adds current asset to the cart through the api
     * @param event
     */
    addToCart(event) {
        event.stopPropagation();
        event.preventDefault();
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.ADD_TO_CART);
        this.cartDataService.addArticle(this.asset.id);
    }

    /**
     * Checks if current asset is in the lightbox
     */
    isInLightbox(): boolean {
        const lightboxId = this.getLightboxId();
        return this.lightboxDataService.isAssetInLightbox(
            lightboxId,
            this.asset.id
        );
    }

    /**
     * Checks if current asset is in the cart through the api
     */
    isInCart(): boolean {
        return this.cartDataService.isAssetInCart(this.asset.id);
    }

    /**
     * Deletes current asset from the lightbox through the api
     */
    deleteFromLightbox(event) {
        event.stopPropagation();
        event.preventDefault();
        const lightboxId = this.getLightboxId();
        this.lightboxDataService.deleteAsset(lightboxId, this.asset.id);
    }

    /**
     * Returns lightbox id
     */
    getLightboxId(): number {
        return this.lightboxId > 0
            ? this.lightboxId
            : this.lightboxDataService.getSelectedLightboxId();
    }

    onToggleSimilaritySearch(event) {
        event.stopPropagation();
        event.preventDefault();
        this.toggleSimilaritySearch.emit(this.asset);
    }

    private updateHostClassNames() {
        const parentRect = this.hostElement.nativeElement.parentNode.getBoundingClientRect();
        const elementRect = this.hostElement.nativeElement.getBoundingClientRect();

        this.topClassName = Math.ceil(parentRect.top) === Math.ceil(elementRect.top);
        this.firstClassName = Math.ceil(parentRect.left) === Math.ceil(elementRect.left);
        this.lastClassName = Math.ceil(parentRect.right) === Math.ceil(elementRect.right);
    }

    private updateActions() {
        this.assetActions$ = (this.assetActionPlugins.length > 0)
            ? combineLatest(...this.assetActionPlugins.map(plugin => plugin.getActions(this.asset))).pipe(
                map((pluginActions: AssetAction[][]) => {
                    return pluginActions.reduce((actions, a) => {
                        return [...actions, ...a];
                    }, [] as AssetAction[]);
                })
            )
            : of([]);
    }

    /**
     * Called when the user clicked download
     * @param event
     */
    downloadPreview(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        this.eventTrackerService.pushNewEvent(EventTrackerEvents.DOWNLOAD_PREVIEW);
        this.assetService.download(this.asset.id, 'layout');
    }

    /**
     * Return if the asset has restrictions
     */
    hasRestriction() {
        if (this.asset && this.asset.restrictions && this.asset.restrictions.length) {
            const user = this.userDataService.getUser();
            return !!this.asset.restrictions.find(restriction => restriction.type === 'block' || restriction.type === 'reservation' || restriction.type === 'block_internaly');
        } else {
            return false;
        }
    }
}
