import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';

/**
 * The pipe transforms the given value into a duration string
 */
@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(value: Date, args?: any): any {

        if (!value || !value.getTime) {
            return null;
        }

        return this.translateService
            .stream('sodatechSdk.durationPipe')
            .pipe(
                map(({seconds, minutes, hours, days, years}) => {
                    const created = Math.round(value.getTime() / 1000);
                    const current = Math.round(Date.now() / 1000);
                    const elapsed = current - created;

                    if (elapsed < 60) {
                        return elapsed + ' ' + seconds;
                    } else if (elapsed < 60 * 60) {
                        return Math.floor(elapsed / 60) + ' ' + minutes;
                    } else if (elapsed < 60 * 60 * 24) {
                        return Math.floor(elapsed / 60 / 60) + ' ' + hours;
                    } else if (elapsed < 60 * 60 * 24 * 365) {
                        return Math.floor(elapsed / 60 / 60 / 24) + ' ' + days;
                    }

                    return Math.floor(elapsed / 60 / 60 / 24 / 365) + ' ' + years;
                })
            );
    }

}
