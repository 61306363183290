// @todo: replace <any> with correct types, remove #toPromise(), use Observables
import {Injectable} from '@angular/core';
import {SodaApiService} from './soda-api.service';
import {HttpParams} from '@angular/common/http';

/**
 * Service that interacts with the calculator api
 */
@Injectable()
export class CalculatorService {
    private API_PATH = 'assets';

    constructor(private http: SodaApiService) {}

    public getCalculator(assetId: string): Promise<any> {
        return this.http
            .get<any>(`${this.API_PATH}/${assetId}/calculator`)
            .toPromise()
            .then(response => {
                return response.pricelists;
            })
            .catch(this.handleError);
    }

    /**
     * Get the pricelist
     * @param assetId asset id
     * @param pricelistId pricelist id
     * @param calculatorArguments object from which HttpRequest params are created
     */
    public getPricelist(
        assetId: string,
        pricelistId: number,
        calculatorArguments?: any
    ): Promise<any> {
        const url = `${this.API_PATH}/${assetId}/calculator/${pricelistId}`;
        const params = new HttpParams({ fromObject: calculatorArguments });

        return this.http
            .get<any>(url, {
                params
            })
            .toPromise()
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @param error error to handle
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
