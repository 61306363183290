import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import {CMSBlockContentComponent, CMSWebseriesSlider, CMSWebseriesSliderLinkType } from '../../models/cms.model';
import { WebSeriesSliderConfig } from '../webseries-slider/webseries-slider.component';
import {CmsBlockContent} from '../cms-block/cms-block-content';
import {CMSService} from '../../services/cms.service';


@Component({
    selector: 'st-cms-slider',
    templateUrl: './cms-slider.component.html'
})
export class CmsSliderComponent extends CmsBlockContent implements OnInit {

    @HostBinding('class.cms-slider') rootClassName = true;

    @Output() slideClicked = new EventEmitter<void>();

    config: WebSeriesSliderConfig;

    constructor(
        private router: Router,
        private location: Location,
        el: ElementRef,
    ) {
        super(el);
    }

    ngOnInit() {
        this.config = this.data.sliderConfig;
    }

    onSlideClicked() {
        if (this.data.linkUrl !== '' && !this.isDraftView) {
            this.data.linkType === CMSWebseriesSliderLinkType.Internal
            ? this.router.navigateByUrl(this.data.linkUrl)
            : window.open(this.data.linkUrl, '_blank');
        }
    }
}
