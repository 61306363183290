import {Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {skip, startWith, takeUntil} from 'rxjs/operators';
import {AssetFilterPartialComponent} from '../asset-filter-partial-component/asset-filter-partial.component';

export const DEFAULT_DATA = {
    photography: false,
    illustration: false
};

@Component({
    selector: 'st-asset-search-media-type-filter',
    templateUrl: './asset-search-media-type-filter.component.html',
    styleUrls: ['./asset-search-media-type-filter.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(
                () => AssetSearchMediaTypeFilterComponent
            ),
            multi: true
        }
    ]
})
export class AssetSearchMediaTypeFilterComponent
    extends AssetFilterPartialComponent
    implements OnInit, OnDestroy, ControlValueAccessor {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    constructor(private formBuilder: FormBuilder) {
        super();
        this.form = this.formBuilder.group({
            photography: [false],
            illustration: [false]
        });
    }

    @Input() set filter(filter) {
        if (filter && filter.mediaType) {
            const mediaType = {};
            filter.mediaType.map(value => {
                mediaType[value] = true;
            });


            this.form.setValue({
                ...DEFAULT_DATA,
                ...mediaType
            }, {emitEvent: false});

        }
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    writeValue(value: any) {

        this.form.patchValue(value, {emitEvent: value});
    }

    registerOnChange(fn: Function) {
    }

    registerOnTouched(fn: Function) {
    }

    onChange() {
        const returnValues = [];
        if (this.form.value.photography) {
            returnValues.push('photography');
        }
        if (this.form.value.illustration) {
            returnValues.push('illustration');
        }
        if (returnValues) {
            this.changes.emit({mediaType: returnValues});
        }
    }

}
