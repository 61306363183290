import { empty as observableEmpty, Observable } from 'rxjs';
import {
    Component,
    EventEmitter, Inject,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Order } from '../../models/order';
import { UserLoginService } from '../../services/user-login.service';
import { OrderService } from '../../services/order.service';
import {DownloadLogsService} from '../../services/download-log.service';
import {DownloadLog} from '../../models/download-log';
import {Asset} from '../../models/asset';
import {Article} from '../../models/article';
import {CollectionDataService} from '../../services/collection-data.service';
import {TranslateService} from '@ngx-translate/core';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';

/**
 * Component that displays the list of downloads
 */
@Component({
    selector: 'st-download-list',
    templateUrl: './download-list.component.html',
    styleUrls: ['./download-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DownloadListComponent implements OnInit {
    public logs: DownloadLog[];

    @Input()
    locale: string;

    @Output()
    onSearch: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    showAssetDetails: EventEmitter<string> = new EventEmitter();

    isLoading: boolean;

    assetQueryParams: {
        [id: string]: AssetCreditQueryParams;
    } = {};

    currentPage = 1;
    itemsPerPage = 50;
    maxPages: number;

    constructor(
        private orderService: OrderService,
        private userLogin: UserLoginService,
        private downloadLogsService: DownloadLogsService,
        private collectionDataService: CollectionDataService,
        private translate: TranslateService,
        @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions,
    ) {}

    ngOnInit() {
        this.userLogin.status
            .pipe(distinctUntilChanged())
            .subscribe(userStatus => {
                if (userStatus === 'login') {
                    this.isLoading = true;
                    this.downloadLogsService.getDownloadLogsWithPaging({page: this.currentPage,
                    itemsPerPage: this.itemsPerPage}).then(response => {
                        this.isLoading = false;
                        this.maxPages = Math.ceil(response.total / this.itemsPerPage);
                        this.logs = response.data;
                        this.calculatessetCreditQueryParams(response.data);
                    }).catch(() => {
                        this.isLoading = false;
                    });
                } else {
                }
            });
    }

    async calculatessetCreditQueryParams(logs: DownloadLog[]) {
        const collections = await this.collectionDataService.getCopyrightCollections();
        logs.map(log => {
            if (!log.asset) {
                return;
            }
            if (this.sdkOptions.assetDetails.credit.alwaysUseCollectionData && log.asset.collection) {
                const collection = collections.find(collection => collection.id.indexOf(log.asset.collection.id) > -1);
                this.assetQueryParams = {
                    ...this.assetQueryParams,
                    [log.asset.id]: {
                        ...this.assetQueryParams[log.asset.id],
                        collectionQueryParam: (collection) ? {coll: [collection.id]} : {},
                    }
                };
            } else if (!log.asset.collection && log.asset.copyright !== '' && log.asset.byline === '') {
                this.assetQueryParams = {
                    ...this.assetQueryParams,
                    [log.asset.id]: {
                        ...this.assetQueryParams[log.asset.id],
                        supplierQueryParam: {pgid: log.asset.supplier.id}
                    }
                };
            } else if (!log.asset.collection && log.asset.copyright !== '' && log.asset.byline !== '') {
                this.assetQueryParams = {
                    ...this.assetQueryParams,
                    [log.asset.id]: {
                        ...this.assetQueryParams[log.asset.id],
                        supplierQueryParam: {pgid: log.asset.supplier.id},
                        keywordQueryParam: {q : `"${log.asset.byline}"`}
                    }
                };
            } else if (log.asset.collection) {
                const collection = collections.find(collection => collection.id.indexOf(log.asset.collection.id) > -1);
                if (log.asset.byline !== '') {
                    this.assetQueryParams = {
                        ...this.assetQueryParams,
                        [log.asset.id]: {
                            ...this.assetQueryParams[log.asset.id],
                            collectionQueryParam: (collection) ? {coll: [collection.id]} : {},
                            keywordQueryParam: {q : `"${log.asset.byline}"`}
                        }
                    };
                } else {
                    this.assetQueryParams = {
                        ...this.assetQueryParams,
                        [log.asset.id]: {
                            ...this.assetQueryParams[log.asset.id],
                            collectionQueryParam: (collection) ? {coll: [collection.id]} : {},
                        }
                    };
                }
            }
        });
    }

    getFittingAssetFile(asset: any, requiredHeight: number): any {
        if (!asset) {
            return '/assets/img/image-no-longer-avail.png';
        }

        let assetFile = null;
        for (const tmpFile of asset.associatedMedia) {
            if (
                tmpFile.fileFormat !== 'image/jpeg' ||
                tmpFile.additionalType.indexOf('watermarked') >= 0 ||
                tmpFile.additionalType === 'original'
            ) {
                continue;
            }
            if (
                !assetFile ||
                (tmpFile.height >= requiredHeight &&
                    assetFile.height > tmpFile.height) ||
                (assetFile.height < requiredHeight &&
                    assetFile.height < tmpFile.height)
            ) {
                assetFile = tmpFile;
            }
        }

        return assetFile.contentUrl;
    }

    /**
     * Emits the photograper id
     */
    searchPhotographer(photographerId: number): void {
        this.onSearch.next({ pgid: photographerId });
    }

    /**
     * Emits the collection id
     */
    searchCollection(collectionId: number): void {
        this.onSearch.next({ coll: collectionId });
    }

    /**
     * Emits the copyright
     */
    searchCopyright(copyright: string): void {
        this.onSearch.next({ copyright: copyright });
    }

    /**
     * Returns given asset media type
     * Defaults to 'Other'
     */
    getMediaType(asset: Asset) {
        if (!asset) {
            return '';
        }

        if (asset.fileFormat.substr(0, 6) === 'image/') {
            return 'Photograph';
        } else if (asset.fileFormat.substr(0, 6) === 'video/') {
            return 'Video';
        } else if (asset.fileFormat.substr(0, 6) === 'audio/') {
            return 'Audio';
        }

        return 'Other';
    }

    isModelReleaseAvailable(article: Article) {
        return (
            article.asset &&
            [2, 4, 8].indexOf(Number(article.asset.modelReleased)) !== -1
        );
    }

    isPropertyReleaseAvailable(article: Article) {
        return (
            article.asset &&
            [2, 4, 8].indexOf(Number(article.asset.propertyReleased)) !== -1
        );
    }

    /**
     * Calculates given article size
     * @param article
     */
    calculateSize(article: Article) {
        let width = 0;
        let height = 0;

        if (article.asset) {
            if (article.maxSize > 0) {
                if (article.maxSizeType === 'sideLength') {
                    if (article.asset.width > article.asset.height) {
                        width = Number(article.maxSize);
                        height =
                            (article.asset.height / article.asset.width) *
                            width;
                    } else {
                        height = Number(article.maxSize);
                        width =
                            (article.asset.width / article.asset.height) *
                            height;
                    }
                } else {
                    const tmpSize = this.getDimensionsFromMegapixel(
                        article,
                        Number(article.maxSize)
                    );
                    width = tmpSize.width;
                    height = tmpSize.height;
                }
            } else if (article.asset.width > 0) {
                width = article.asset.width;
                height = article.asset.height;
            }
        }

        return {
            width: Math.round(width),
            height: Math.round(height)
        };
    }

    get currentLang() {
        return this.translate.currentLang;
    }

    calculateRawFileSize(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        const rawSize = imgSize.width * imgSize.height * 3;

        if (rawSize < 1024) {
            return rawSize + ' B';
        } else if (rawSize < 1024 * 1024) {
            return Math.round(rawSize / 1024) + ' KB';
        } else {
            return Math.round((rawSize / 1024 / 1024) * 10) / 10 + ' MB';
        }
    }

    calculateMegapixel(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        return (
            Math.round(
                ((imgSize.width * imgSize.height) / (1024 * 1024)) * 10
            ) /
            10 +
            ' MP'
        );
    }

    calculatePrintSize(article: Article): string {
        // 9.83" x 3.00" @ 72 DPI
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        let dpi = 300;
        if (article.dpiSize > 0) {
            dpi = article.dpiSize;
        }
        const sizeX = Math.round(imgSize.width * (2.54 / dpi) * 100) / 100;
        const sizeY = Math.round(imgSize.height * (2.54 / dpi) * 100) / 100;

        return `${sizeX} cm x ${sizeY} cm @ ${dpi} dpi`;
    }

    getDimensionsFromMegapixel(article: Article, megapixel: number) {
        let width = 0;
        let height = 0;

        if (article.asset && article.asset.width > 0) {
            const pixelCount = megapixel * 1024 * 1024;

            width = Math.round(
                Math.sqrt(
                    pixelCount * (article.asset.width / article.asset.height)
                )
            );
            height = Math.round(
                Math.sqrt(
                    pixelCount * (article.asset.height / article.asset.width)
                )
            );
        }

        return {
            width: width,
            height: height
        };
    }

    /**
     * Load more download logs
     */
    onLoadMoreDownloadLogs() {
        if (this.currentPage === this.maxPages) {
            return;
        }
        this.isLoading = true;
        this.currentPage++;
        this.downloadLogsService.getDownloadLogsWithPaging({page: this.currentPage,
            itemsPerPage: this.itemsPerPage}).then(response => {
            this.isLoading = false;
            this.logs = [...this.logs, ...response.data];
            this.calculatessetCreditQueryParams(response.data);
        }).catch(() => {
            this.currentPage--;
            this.isLoading = false;
        });
    }
}

export interface AssetCreditQueryParams {
    supplierQueryParam: any;
    keywordQueryParam: any;
    collectionQueryParam: any;
}
