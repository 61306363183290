import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { CmsBlockContent } from '../cms-block/cms-block-content';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';

@Component({
    selector: 'st-cms-content-teaser',
    templateUrl: './cms-content-teaser.component.html',
    styleUrls: ['./cms-content-teaser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CmsContentTeaserComponent extends CmsBlockContent implements OnInit {

    /**
     * Set child components
     */
    @Input() set children(data: any) {
        this.components = data;
    }

    /**
     * Child components
     */
    components: any[] = [];

    /**
     * Container custom classes
     */
    classes: string;

    /**
     * Host class
     */
    @HostBinding('class.cms-content-teaser') hostClass = true;

    /**
     * Content max height for `showAsTeaser` mode
     */
    maxHeight: number;

    /**
     * Content original height
     */
    originalHeight: number;

    /**
     * Content calculated max height
     */
    contentMaxHeight: number;

    /**
     * Should content be with preview and show more/ show less buttons
     */
    showAsTeaser = false;

    /**
     * Show more/ show less state
     */
    useReadMore = false;

    /**
     * Show more button visible
     */
    showReadMoreBtn = true;

    /**
     * Show less button visible
     */
    showLessBtn = false;

    /**
     * Content view reference
     */
    @ViewChild('contentView') contentView: ElementRef;

    /**
     * Handle preview on window resize event
     */
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if ( this.showAsTeaser && this.components.length > 0 ) {
            this.handleMaxHeight();
        }
    }

    constructor(el: ElementRef,
                private cdr: ChangeDetectorRef,
                @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {
        super(el);
        /**
         * Apply sdk options
         */
        if ( this.sdkOptions.cmsComponents && this.sdkOptions.cmsComponents.contentTeaser ) {
            this.maxHeight = this.sdkOptions.cmsComponents.contentTeaser.maxHeight || 100;
        }
    }

    ngOnInit() {
        /**
         * Set custom classes
         */
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }
        /**
         * Set showAsTeaser
         */
        this.showAsTeaser = this.data.showAsTeaser;

        /**
         * If `showAsTeaser` is true, handle preview height
         */
        if ( this.showAsTeaser && this.components.length > 0 ) {
            this.handleMaxHeight();
        }
    }

    /**
     * Handle preview height and `useReadMore` state
     */
    handleMaxHeight() {
        // reset calculated max height
        this.contentMaxHeight = null;
        // rerender view to make content have original height
        this.cdr.detectChanges();
        // get content original height
        this.originalHeight = this.getStylePropertyValue(this.contentView.nativeElement, 'height', true) as number;
        // set calculated max height
        this.contentMaxHeight = this.maxHeight;
        // handle useReadMore state in order to show/hide  buttons
        this.useReadMore = this.originalHeight > this.contentMaxHeight;
    }

    /**
     * Called each time when user clicks to show more button
     */
    onReadMoreClicked() {
        this.showReadMoreBtn = false;
        this.showLessBtn = true;
    }

    /**
     * Called each time when user clicks to show less button
     */
    onLessClicked() {
        this.showReadMoreBtn = true;
        this.showLessBtn = false;
    }

    /**
     * Hepler function to get style property value from HTML element
     *
     * @param el
     * @param property
     * @param isNumber
     */
    private getStylePropertyValue(el: HTMLElement, property: string, isNumber: boolean): string | number {
        const value = getComputedStyle(el).getPropertyValue(property);
        return isNumber ? parseInt(value) : value;
    }

}
