import {Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {CmsBlockContent} from '../cms-block/cms-block-content';

@Component({
    selector: 'st-cms-icon',
    templateUrl: './cms-icon.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CmsIconComponent extends CmsBlockContent implements OnInit, OnChanges {

    @HostBinding('style.height') componentHeight;

    classes: string;

    constructor(el: ElementRef, private router: Router) {
        super(el);
    }

    ngOnInit() {
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        if (this.data) {
            this.componentHeight = this.data.size;
        }
    }
}
