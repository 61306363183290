import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

@Injectable()
export class CustomMomentDateAdapter extends MomentDateAdapter {

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
    }

    parse(value, parseFormat) {
        if (value && typeof value === 'string') {
            return moment(value, parseFormat, this.locale, true);
        }

        return value ? moment(value).locale(this.locale) : null;
    }

    setLocale(locale: string) {
        super.setLocale((locale === 'en') ? 'en-GB' : locale);
    }
}
