import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    forwardRef,
    Input
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    FormGroup
} from '@angular/forms';
import {Subject} from 'rxjs';
import {startWith, takeUntil, skip} from 'rxjs/operators';
import {AssetFilterPartialComponent} from '../asset-filter-partial-component/asset-filter-partial.component';

export const DEFAULT_DATA = {
    size: ''
};

@Component({
    selector: 'st-asset-search-size-filter',
    templateUrl: './asset-search-size-filter.component.html',
    styleUrls: ['./asset-search-size-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(
                () => AssetSearchSizeFilterComponent
            ),
            multi: true
        }
    ]
})
export class AssetSearchSizeFilterComponent
    extends AssetFilterPartialComponent
    implements OnInit, OnDestroy, ControlValueAccessor {
    form: FormGroup;
    componentDestroyed$ = new Subject<boolean>();

    @Input() set filter(filter) {
        if (filter) {
            this.form.setValue({
                size: filter.size || 'any'
            }, {emitEvent: false});

        }
    }

    constructor(private formBuilder: FormBuilder) {
        super();
        this.form = this.formBuilder.group(DEFAULT_DATA);
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    writeValue(value: any) {
        if (value) {
            this.form.patchValue(value, {emitEvent: false});
        }
    }

    registerOnChange(fn: Function) {
    }

    registerOnTouched(fn: Function) {
    }

    onChange() {
        this.changes.emit({
            size: (this.form.value.size !== 'any') ? this.form.value.size : ''
        });
    }
}
