import {UserAddress} from './user-address';

/**
 * The user application model
 */
export class User {
    public id: number;
    public email: string;
    public username: string;
    public password: string;
    public familyName: string;
    public givenName: string;
    public gender: string;
    public jobTitle: string;
    public company: string;
    public address: UserAddress[];
    public url: string;
    public language: string;
    public currency: string;
    public newsletterSubscribed: string;
    public taxNumber: string;
    public salesPerson: SalesPerson;
    public business: BusinessType;
    public userFunction: number[];
    public telephone: string;
}

export class SalesPerson {
    public email: string;
    public familyName: string;
    public givenName: string;
    public id: number;
    public telephone: string;
}

export enum BusinessType {
    CorporateCustomers = 'CORPORATE_CUSTOMERS',
    BookPublishing = 'BOOK_PUBLISHING',
    CalendarPublishing = 'CALENDAR_PUBLISHING',
    Merchandising = 'MERCHANDISING',
    AdvertisingPRCorporatePublishing = 'ADVERTISING_PR_CORPORATE_PUBLISHING',
    MagazinesNewspapersTV = 'MAGAZINES_NEWSPAPERS_TV',
    Miscellaneous = 'MISCELLANEOUS'
}
