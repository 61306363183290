import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    Output
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Webseries } from '../../models/webseries';
import { WindowRefService } from '../../services/window-ref.service';
import { ImageUtilsService } from '../../services/image-utils.service';

/**
 * Component that displays the webseries list
 */
@Component({
    selector: 'st-webseries-list',
    templateUrl: './webseries-list.component.html',
    styleUrls: ['./webseries-list.component.scss']
})
export class WebseriesListComponent implements DoCheck, AfterViewInit {
    @Output()
    detail = new EventEmitter<any>();

    @Input('webseries')
    set setInputAssets(webseries: Webseries[]) {
        this.setWebseries(webseries);
    }

    public webseries: any[] = [];

    private defaultHeight = 300;
    defaultMarginWidth = 10;
    private currentHostWidth = 0;

    @HostListener('window:resize', [])
    onResize() {
        this.checkHostSizeChange();
    }

    constructor(
        private hostElement: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: any,
        private window: WindowRefService,
        private imageUtils: ImageUtilsService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.checkHostSizeChange();
        }, 1);
    }

    /**
     * Sets the webseries in the component
     * @param webseries
     */
    public setWebseries(webseries) {
        if (!Array.isArray(webseries)) {
            webseries = [];
        }

        if (JSON.stringify(webseries) === JSON.stringify(this.webseries)) {
            return;
        }

        if (!webseries.length) {
            this.webseries = [];
            return;
        }

        const tmpWebseries = [];
        let tmpPromiseCounter = 0;

        for (const webserie of webseries) {
            tmpPromiseCounter++;
            this.imageUtils.getSizeByUrl(webserie.coverUrl).then(data => {
                tmpPromiseCounter--;

                if (data.success) {
                    tmpWebseries.push({
                        webserie: webserie,
                        width: data.width,
                        height: data.height,
                        sizeFactor: data.width / data.height
                    });
                }

                if (tmpPromiseCounter === 0) {
                    this.webseries = tmpWebseries;
                    this.refreshSize();
                }
            });
        }
    }

    /**
     * Triggers a size refresh for the webseries
     */
    public refreshSize() {
        if (!this.webseries || !this.webseries.length) {
            return;
        }

        let combinedWidth = 0;
        let webseriesRow = [];
        const webseriesTmp = [];

        for (const webserie of this.webseries) {
            const newWidth = this.defaultHeight * webserie.sizeFactor;

            if (
                combinedWidth + newWidth + this.defaultMarginWidth >
                this.currentHostWidth
            ) {
                const newHeight = Math.floor(
                    this.defaultHeight * (this.currentHostWidth / combinedWidth)
                );
                this.setWebseriesRowHeight(
                    webseriesRow,
                    webseriesTmp,
                    newHeight
                );

                combinedWidth = 0;
                webseriesRow = [];
            }

            combinedWidth += newWidth;

            webseriesRow.push(webserie);
        }

        if (webseriesRow.length > 0) {
            this.setWebseriesRowHeight(
                webseriesRow,
                webseriesTmp,
                this.defaultHeight
            );
        }

        this.webseries = webseriesTmp;

        this.changeDetectorRef.detectChanges();
    }

    private setWebseriesRowHeight(webseriesRow, webseriesTmp, height) {
        for (const webserie of webseriesRow) {
            webserie.displayHeight = height + 'px';
            webserie.displayWidth =
                ((height * webserie.sizeFactor) / this.currentHostWidth) * 100 +
                '%';
            // webserie.marginWidth = this.defaultMarginWidth + 'px';

            webseriesTmp.push(webserie);
        }
    }

    public reset() {
        this.webseries = [];
    }

    public addWebseries(webseries) {
        this.setWebseries(this.webseries.concat(webseries));
    }

    public getBoundingClientRect(): ClientRect {
        if (!this.hostElement.nativeElement.getBoundingClientRect) {
            const rect = {
                bottom: 0,
                height: 0,
                left: 0,
                right: 0,
                top: 0,
                width: 0
            };

            return rect as ClientRect;
        }
        return this.hostElement.nativeElement.getBoundingClientRect();
    }

    ngDoCheck() {
        this.checkHostSizeChange();
    }

    /**
     * Checks for the host component size refresh and triggers a size refresh in case of needed
     */
    checkHostSizeChange() {
        if (!(typeof window !== 'undefined' && window.document)) {
            return;
        }

        const style = getComputedStyle(this.hostElement.nativeElement);
        const paddingX =
            parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);

        const rect = this.getBoundingClientRect();
        const newWidth = rect.width - paddingX;

        if (this.currentHostWidth === newWidth) {
            return;
        }

        this.currentHostWidth = newWidth;

        this.refreshSize();
    }

    showDetail(webserieId: number) {
        this.detail.emit({
            webserieId: webserieId
        });
    }
}
