import { Component, OnInit, Input, HostBinding, ViewEncapsulation, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'st-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LanguageSwitcherComponent implements OnInit {

    @HostBinding('class.language-switcher-root') hasRootClass = true;

    @Input() availableLanguages: string[] = this.sdkOptions.availableLanguages;

    @Input() hasBorder = false;

    get selectedLanguage() {
        return this.translateService.currentLang;
    }

    constructor(@Inject(SDK_OPTIONS) private sdkOptions: SdkOptions,
                private translateService: TranslateService) {
    }

    ngOnInit() {}

    onLanguageClicked(e: Event, lang: string) {
        e.preventDefault();
        this.translateService.use(lang);
    }

    flagCode(lang: string) {
        if (lang === 'en') {
            return 'gb';
        } else {
            return lang;
        }
    }


}
