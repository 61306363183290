import { Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { skip, startWith, takeUntil } from 'rxjs/operators';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';

export const DEFAULT_DATA = {
    exclusive: false
};

@Component({
    selector: 'st-asset-search-exclusive-filter',
    templateUrl: './asset-search-exclusive-filter.component.html',
    styleUrls: ['./asset-search-exclusive-filter.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(
                () => AssetSearchExclusiveFilterComponent
            ),
            multi: true
        }
    ]
})
export class AssetSearchExclusiveFilterComponent
    extends AssetFilterPartialComponent
    implements OnInit, OnDestroy, ControlValueAccessor {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    constructor(private formBuilder: FormBuilder) {
        super();
        this.form = this.formBuilder.group({
            exclusive: [],
        });
    }

    @Input() set filter(filter) {
        if (filter) {
            let exclusive = {exclusive: filter.exclusive};


            this.form.setValue({
                ...DEFAULT_DATA,
                ...exclusive,
            }, { emitEvent: false });

        }
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }

    writeValue(value: any) {
        if (value) {
            this.form.patchValue(value, { emitEvent: false });
        }
    }

    registerOnChange(fn: Function) {
    }

    registerOnTouched(fn: Function) {
    }

    onChange() {
        this.changes.emit({ exclusive: this.form.value.exclusive });
    }

}
