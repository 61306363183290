import { Component, Input, OnInit } from '@angular/core';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { TinyColor } from '@ctrl/tinycolor';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
@Component({
    selector: 'st-asset-color-filter',
    templateUrl: './asset-color-filter.component.html',
    styleUrls: ['./asset-color-filter.component.scss']
})
export class AssetColorFilterComponent extends AssetFilterPartialComponent implements OnInit {

    @Input() colors: string[] = [
        'ff0000',
        'f0ff00',
        '0fff00',
        '0046ce',
        '9900c9'
    ];

    constructor(private sanitzier: DomSanitizer) {
        super();
    }

    ngOnInit() {
    }

    getCurrentFilterColor(color: string): SafeStyle {
        const c = new TinyColor(`#${color}`);

        const isNearWhite = c.getBrightness() > 245;
        const isNearBlack = c.getBrightness() < 10;

        const leftMultiplier = (isNearWhite) ? 2 : 1;
        const rightMultiplier = (isNearBlack) ? 3 : 1;

        const leftColor = c.darken(10 * leftMultiplier).toHexString();
        const rightColor = c.lighten(10 * rightMultiplier).toHexString();
        return this.sanitzier.bypassSecurityTrustStyle(`linear-gradient(90deg, ${leftColor}, ${rightColor})`);
    }

    onColorClicked(color: string) {
        this.changes.emit({ color });
    }
}
