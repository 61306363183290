import {Component, HostBinding, Input, OnInit} from '@angular/core';

/**
 * Component that displays the asset search result count
 */
@Component({
    selector: 'st-asset-search-result-count',
    templateUrl: './asset-search-result-count.component.html',
    styleUrls: ['./asset-search-result-count.component.css']
})
export class AssetSearchResultCountComponent implements OnInit {

    @Input() count: number = null;

    constructor() {
    }

    ngOnInit() {
    }

}
