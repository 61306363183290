/**
 * Model representing a cms block content in a dynamic component
 */
import {OnChanges} from '@angular/core';
import {WebseriesOverlayPositioning, WebSeriesSliderConfig} from '../components/webseries-slider/webseries-slider.component';
import {SectionContentPositioning} from '../components/cms-section/cms-section.component';
import {SafeResourceUrl} from '@angular/platform-browser';

export interface CMSBlockContentComponent extends OnChanges {
    data: any;
    metaData?: any;
    children?: any;
    isDraftView?: boolean;
}

export interface CMSRawContentComponent<T> {
    type: string;
    data: T;
    children?: any;
    metaData?: any;
}

export interface CMSBlockData {
    customClass: string;
}

export interface CMSBlockDataRichText extends CMSBlockData {
    text: string;
    position: CMSPositionType;
    backgroundColor: string;
    color: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };
    fontUrl: {
        url: string;
    };
    fontFamily: string;
}

export interface CMSBlockDataHeading extends CMSBlockData {
    text: string;
    level: number;
    color: string;
    id: string;
    position: CMSPositionType;
    border: {
        color: string,
        gap: number,
        height: number,
        width: number,
        position: CMSPositionType,
    };
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };
    backgroundColor: string;
}

export enum CMSPositionType {
    Left = 'left',
    Right = 'right',
    Center = 'center'
}

export enum CMSHorizontalPositionType {
    Left = 'left',
    Right = 'right',
    Center = 'center'
}

export enum CMSVerticalPositionType {
    Top = 'top',
    Center = 'center',
    Bottom = 'bottom'
}

export interface CMSBlockDataColumn extends CMSBlockData {
    customClass: string;
    width: {
        col: string;
        'col-sm': string;
        'col-md': string;
        'col-lg': string;
        'col-xl': string;
    };
    border: {
        color: string;
        width: number;
        radius: number;
    };
    backgroundColor: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };
    contentPositioning: {
        vertical: CMSVerticalPositionType;
        horizontal: CMSHorizontalPositionType;
    };
}

export interface CMSBlockDataRow extends CMSBlockData {
    backgroundColor: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };

}

export enum CMSWebseriesSliderLinkType {
    Internal = 'internalLink',
    External = 'externalLink'
}

export interface CMSWebseriesSlider extends CMSBlockData {
    customClass: string;
    linkType: CMSWebseriesSliderLinkType;
    linkUrl: string;
    overlayPositioning: WebseriesOverlayPositioning;
    sliderConfig: WebSeriesSliderConfig;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };

}

export enum CmsSectionBackgroundTypes {
    AssetId = 'assetId',
    AssetUrl = 'assetUrl',
    Color = 'color'
}

export interface CmsSection {
    backgroundType: CmsSectionBackgroundTypes;
    backgroundValue: string;
    customClass: string;
    height: string;
    isDarkSection: boolean;
    webseriesId: string;
    linkUrl: string;
    linkType: CmsLinkTypes;
    isBoxed: boolean;
    assetSize: string;
    contentPositioning: SectionContentPositioning;
    hasOverlay: boolean;
    overlayOpacity: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };
}

export enum CmsLinkTypes {
    ExternalUrl = 'external',
    InternalUrl = 'internal',
    MailToUrl = 'mailto',
    Anchor = 'anchor'
}

export interface CmsButton extends CMSBlockData {
    title: string;
    linkUrl: string;
    linkType: CmsLinkTypes;
    anchor: string;
    position: string;
    queryParams: string;
    outlets: string;
    backgroundColor: string;
    hoverBackgroundColor: string;
    textColor: string;
    hoverTextColor: string;
    borderColor: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };
}

export interface CmsIcon extends CMSBlockData {
    name: string;
    size: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };
}

export interface CmsImage extends CMSBlockData {
    src: string;
    width: string;
    height: string;
    linkUrl: string;
    linkType: CmsLinkTypes;
    queryParams: string;
    outlets: string;
    draggable: boolean;
    alt: string;
    spacing: {
        marginLeft: number;
        marginTop: number;
        marginRight: number;
        marginBottom: number;
        paddingLeft: number;
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
    };

}

export interface CMSHTMLData {
    customClass: string;
    content: string;
}

export interface CMSVideoData {
    customClass: string;
    url: SafeResourceUrl;
    height: number;
}

export interface CmsMenu {
    title: string;
    customClass: string;
}

export enum CmsMenuItemLinkTypes {
    ExternalUrl = 'externalLink',
    InternalUrl = 'internalLink'
}

export interface CmsMenuItem {
    title: string;
    customClass: string;
    linkType: CmsMenuItemLinkTypes;
    linkUrl: string;
    authorizedLink: string;
    queryParams: string;
    outlets: string;
}

export interface CmsMenuGroup {
    title: string;
    customClass: string;
}

export interface CmsContentTeaser {
    showAsTeaser: boolean
}
