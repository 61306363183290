import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'st-cms-preview',
    templateUrl: './cms-preview.component.html',
    styleUrls: ['./cms-preview.component.scss']
})
export class CMSPreviewComponent implements OnInit{

    @Input() slug: string;

    @Input() lang: string;

    @Input() isDraftView: boolean;

    constructor(
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {

    }

}
