import {Injectable} from '@angular/core';

import {SupplierService} from './supplier.service';
import {Supplier} from '../models/supplier';

/**
 * Service that interacts with the supplier api using SupplierService
 * Caches requested suppliers and returns them instead of making a new Http Request
 */
@Injectable()
export class SupplierDataService {
    private suppliers: Promise<Supplier[]> = null;

    constructor(private supplierService: SupplierService) {}

    /**
     * Get suppliers
     * @returns
     */
    public getSuppliers(): Promise<Supplier[]> {
        if (this.suppliers === null) {
            this.suppliers = this.supplierService.getSuppliers();
        }

        return this.suppliers;
    }
}
