import {Injectable} from '@angular/core';
import {UserLoginService} from './user-login.service';
import {User} from '../models/user';
import {LocalStorageService} from 'ngx-webstorage';
import {UserService} from './user.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, pluck} from 'rxjs/operators';

/**
 * Service that interacts with the user api using UserService
 * Stores user data
 */
@Injectable()
export class UserDataService {
    private tokenUserData = 'user_data';

    private _user: BehaviorSubject<User> = new BehaviorSubject(null);
    public user: Observable<User> = this._user
        .asObservable()
        .pipe(distinctUntilChanged());

    constructor(
        private userLogin: UserLoginService,
        private userService: UserService,
        private storage: LocalStorageService
    ) {
        this.userLogin.status.subscribe(() => {
            this.load();
        });
    }

    /**
     * Returns if the user is logged in
     * @returns
     */
    public isLoggedIn() {
        return this.userLogin.loggedIn;
    }

    /**
     * Returns the user is logged in status as observable
     */
    isLoggedInAsObservable(): Observable<boolean> {
        return this._user.pipe(map(user => {
            return !!user;
        }));
    }

    /**
     * Returns if the user data is loaded
     * @returns
     */
    public isLoaded() {
        const user = this.getUser();

        return user && user.id;
    }

    /**
     * Returns _user BehaviourSubject value
     * @returns
     */
    public getUser() {
        return this._user.value;
    }

    /**
     * Returns the list of user functions
     */
    getUserFunctions(): Observable<number[]> {
        return this._user.pipe(pluck('userFunction'));
    }

    /**
     * Loads user data
     * @returns
     */
    private load() {
        if (!this.userLogin.isLoggedIn()) {
            this._user.next(null);
            return;
        }

        const token = this.userLogin.getToken();
        if (!token) {
            this._user.next(null);
            return;
        }

        const userData = this.storage.retrieve(this.tokenUserData);
        if (userData && userData.token === token) {
            this._user.next(userData);
            return false;
        }

        this.userService.getUser().then(user => {
            this._user.next(user);
            user['token'] = token;
            this.storage.store(this.tokenUserData, user);
        });
    }

    /**
     * Reloads user data
     * @returns
     */
    public reload() {
        let currentUser = this.getUser();
        if (!currentUser || !currentUser['token']) {
            return;
        }

        this.userService.getUser().then(user => {
            this._user.next(user);
            user['token'] = currentUser['token'];
            this.storage.store(this.tokenUserData, user);
        });
    }
}
