import { Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CmsLinkTypes, CmsSectionBackgroundTypes } from '../../models/cms.model';
import { CmsBlockContent } from '../cms-block/cms-block-content';

@Component({
    selector: 'st-cms-section',
    templateUrl: './cms-section.component.html',
    styleUrls: ['./cms-section.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CmsSectionComponent extends CmsBlockContent implements OnInit {

    @Input() set children(data: any) {
        this.components = data;
    }

    components: any[] = [];

    classes: string;

    hovered: boolean;

    get isColorBackground() {
        return this.data.backgroundType === CmsSectionBackgroundTypes.Color;
    }

    get isBackgroundUrl() {
        return this.data.backgroundType === CmsSectionBackgroundTypes.AssetUrl;
    }

    @HostBinding('class.cms-section') rowClass = true;

    constructor(el: ElementRef, private router: Router) {
        super(el);
    }

    ngOnInit() {
        if (this.data.customClass) {
            this.classes = this.data.customClass;
        }
    }

    handleClick(e) {
        e.preventDefault();
        if (this.data.linkUrl && this.data.linkType && !this.isDraftView) {
            this.data.linkType === CmsLinkTypes.InternalUrl
                ? this.router.navigateByUrl(this.data.linkUrl)
                : window.open(this.data.linkUrl, '_blank');
        }
    }


}

export interface SectionContentPositioning {
    vertical: string;
    horizontal: string;
}
