import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';

/**
 * Component hat displays the user indicator
 */
@Component({
    selector: 'st-user-indicator',
    templateUrl: './user-indicator.component.html',
    styleUrls: ['./user-indicator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserIndicatorComponent implements OnInit {

    @HostBinding('class.user-indicator-root') hasRootClass = true;

    @Input() iconOnly = false;

    @Input() showIcon = true;

    @Output() indicatorClicked: EventEmitter<boolean> = new EventEmitter();

    constructor(public user: UserDataService) {
    }

    ngOnInit() {
    }

    /**
     * Called when the indicator was clicked
     */
    onIndicatorClicked(e: Event) {
        e.preventDefault();
        this.indicatorClicked.next(this.user.isLoggedIn());
    }

}
