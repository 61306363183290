import {
    Component,
    EventEmitter, Input, OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Article} from '../../models/article';
import {PreferencesService} from '../../services/preferences.service';
import {CartDataService} from '../../services/cart-data.service';
import { empty as observableEmpty, Observable, Subscription} from 'rxjs';
import {Order} from '../../models/order';
import {OrderService} from '../../services/order.service';

@Component({
    selector: 'st-cart-promotion-code',
    templateUrl: './cart-promotion-code.component.html',
    styleUrls: ['./cart-promotion-code.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CartPromotionCodeComponent implements OnInit, OnDestroy {

    @Output() checkout: EventEmitter<void> = new EventEmitter();

    @Input() orderId: number;

    public order$: Observable<Order>;

    order: Order;

    currentPromotionCode: string;

    isPromotionCodeInvalid: boolean;

    isPromotionCodeValid: boolean;

    calculatedDiscount: number;

    dataSubscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private preferenceService: PreferencesService,
        private cartDataService: CartDataService,
        private orderService: OrderService,
        private route: ActivatedRoute
    ) {
    }

    private loadOrder(orderId: number) {
        if (!(orderId > 0)) {
            this.order$ = observableEmpty();
        }

        this.order$ = this.orderService.getOrder(orderId);
    }


    ngOnInit() {
        this.loadOrder(this.orderId);
        this.dataSubscription = this.order$.subscribe(order => {
            this.order = order;
        })
    }

    /**
     * Return thumbnail url (contentUrl)
     */
    getThumbnailUrl(article: Article) {
        if (
            !article ||
            !article.asset ||
            !article.asset.id ||
            !article.asset.associatedMedia ||
            !Array.isArray(article.asset.associatedMedia)
        ) {
            return '';
        }

        for (const media of article.asset.associatedMedia) {
            if (media.additionalType === 'preview') {
                // 'preview'
                return media.contentUrl;
            }
        }

        return '';
    }

    /**
     * Checks if the article is restricted
     * @param article
     */
    isArticleRestricted(article: Article) {
        if (!article.asset.categories.length) {
            return false;
        }

        for (const category of article.asset.categories) {
            if (category.id === 1) {
                return true;
            }
        }

        return false;
    }

    calculateSize(article: Article) {
        let width = article.asset.width;
        let height = article.asset.height;

        if (article.maxSize > 0) {
            if (article.maxSizeType === 'megaPixel') {
                const factor = width / height;
                height = Math.sqrt((article.maxSize * 1024 * 1024) / factor);
                width = height * factor;
            } else {
                if (width > height) {
                    const factor = height / width;
                    width = article.maxSize;
                    height = width * factor;
                } else {
                    const factor = width / height;
                    height = article.maxSize;
                    width = height * factor;
                }
            }
        }

        if (width > article.asset.width || height > article.asset.height) {
            width = article.asset.width;
            height = article.asset.height;
        }

        return {
            width: Math.round(width),
            height: Math.round(height)
        };
    }

    calculateRawFileSize(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        const rawSize = imgSize.width * imgSize.height * 3;

        if (rawSize < 1024) {
            return rawSize + ' B';
        } else if (rawSize < 1024 * 1024) {
            return Math.round(rawSize / 1024) + ' KB';
        } else {
            return Math.round((rawSize / 1024 / 1024) * 10) / 10 + ' MB';
        }
    }

    calculateMegapixel(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        return (
            Math.round(
                ((imgSize.width * imgSize.height) / (1024 * 1024)) * 10
            ) /
            10 +
            ' MP'
        );
    }

    calculatePrintSize(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        const dpi = article.dpiSize > 0 ? article.dpiSize : 300;
        return (
            Math.round(imgSize.width * (2.54 / dpi) * 100) / 100 +
            'cm x ' +
            Math.round(imgSize.height * (2.54 / dpi) * 100) / 100 +
            'cm @ ' +
            dpi +
            ' DPI'
        );
    }

    async addPromotionCode(e: Event) {
        e.preventDefault();
        const updatedOrder = await this.orderService.updatePromotionCodeForOrder(this.order, this.currentPromotionCode);
        if (updatedOrder.code && updatedOrder.message) {
            this.isPromotionCodeInvalid = true;
            this.isPromotionCodeValid = false;
        } else {
            this.isPromotionCodeValid = true;
            this.isPromotionCodeInvalid = false;
            this.order = updatedOrder;
        }
    }

    async continueCheckout(e: Event) {
        e.preventDefault();
        this.checkout.next();
    }

    async removePromotionCode() {
        this.order = await this.orderService.updatePromotionCodeForOrder(this.order, '');
        this.isPromotionCodeValid = false;
        this.currentPromotionCode = '';
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
}
