import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AssetFilterPartialComponent } from '../asset-filter-partial-component/asset-filter-partial.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, skip, startWith, takeUntil } from 'rxjs/operators';
import { AssetService } from '../../services/asset.service';
import { SDK_OPTIONS, SdkOptions } from '../../models/sdk-options';

export const DEFAULT_DATA = {
    restrictions: ''
};

@Component({
    selector: 'st-asset-search-restrictions-filter',
    templateUrl: './asset-search-restrictions-filter.component.html',
    styleUrls: ['./asset-search-restrictions-filter.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AssetSearchRestrictionsFilterComponent extends AssetFilterPartialComponent implements OnInit, OnDestroy {

    form: FormGroup;

    componentDestroyed$ = new Subject<boolean>();

    @Input() set filter(filter) {
        if (filter) {
            this.form.setValue({
                restrictions: filter.restrictions || 'all'
            }, {emitEvent: false});
        }
    }

    restrictions$: Observable<{ [key: string]: number }>;

    isOccurrencesEnabled$: Observable<boolean>;

    constructor(private formBuilder: FormBuilder,
                private assetService: AssetService,
                @Inject(SDK_OPTIONS) private sdkOptions: SdkOptions) {
        super();
        this.restrictions$ = this.assetService.getLoadedRestrictionsTotal();
        this.isOccurrencesEnabled$ = of(this.sdkOptions.assetSearchFilter && this.sdkOptions.assetSearchFilter.isOccurrencesEnabled);
        this.form = this.formBuilder.group(DEFAULT_DATA);
    }

    ngOnInit(): void {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                skip(1),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => this.onChange());
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
    }

    onChange() {
        this.changes.emit({
            restrictions: (this.form.value.restrictions !== 'all') ? this.form.value.restrictions : ''
        });
    }
}
