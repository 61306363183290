import { Component, Input } from '@angular/core';
import { SectionContentPositioning } from '../cms-section/cms-section.component';

@Component({
    selector: 'st-asset-background-section',
    templateUrl: './asset-background-section.component.html',
    styleUrls: ['./asset-background-section.component.scss'],
})
export class AssetBackgroundSectionComponent {

    @Input() assetId: string;
    @Input() webseriesId: number;
    @Input() assetSize = 1024;
    @Input() height: string;
    @Input() backgroundUrl: string;
    @Input() contentPositioning: SectionContentPositioning;

    constructor() {
    }
}
