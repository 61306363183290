import {BehaviorSubject, combineLatest, empty as observableEmpty, Observable, Subscription} from 'rxjs';
import {
    Component,
    EventEmitter, Inject,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {debounce, debounceTime, distinctUntilChanged, filter, map, publishReplay, refCount} from 'rxjs/operators';
import {Order} from '../../models/order';
import {UserLoginService} from '../../services/user-login.service';
import {WindowRefService} from '../../services/window-ref.service';
import {CartDataService} from '../../services/cart-data.service';
import {OrderDataService} from '../../services/order-data.service';
import {OrderService} from '../../services/order.service';
import {Asset} from '../../models/asset';
import {Article} from '../../models/article';
import {AssetCreditQueryParams} from '../download-list/download-list.component';
import {CollectionDataService} from '../../services/collection-data.service';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';
import {TranslateService} from '@ngx-translate/core';

export enum OrderListMetaInformation {
    SIZE,
    MEDIA_TYPE
}

/**
 * Component that displays the list of orders
 */
@Component({
    selector: 'st-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderListComponent implements OnInit, OnDestroy {

    @Input() orderType = 'final';

    @Input() locale: string;

    @Input() hideMetaInformationRows = [OrderListMetaInformation.MEDIA_TYPE, OrderListMetaInformation.SIZE];

    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

    @Output() showAssetDetails: EventEmitter<string> = new EventEmitter();

    /**
     * Observable of the orders
     */
    public orders$: Observable<Array<Order>> = observableEmpty();

    /**
     * Observable of the filtered oders (by search text)
     */
    filteredOrders$: Observable<Order[]>;

    /**
     * Behaviour subject of the current search text state
     */
    orderSearchText: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    /**
     * Order list meta informtion enum
     */
    orderListMetaInformation = OrderListMetaInformation;

    assetQueryParams: {
        [id: string]: AssetCreditQueryParams;
    } = {};

    dataSubscription: Subscription;
    orderSearch: any;
    termsLink: string;

    constructor(
        private orderService: OrderService,
        private userLogin: UserLoginService,
        private cartDataService: CartDataService,
        private windowRefService: WindowRefService,
        private orderDataService: OrderDataService,
        private collectionDataService: CollectionDataService,
        private translate: TranslateService,
        @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions
    ) {
        this.termsLink = this.sdkOptions.baseUrl + this.translate.currentLang + this.sdkOptions.termsLink;
    }

    ngOnInit() {
        this.userLogin.status
            .pipe(distinctUntilChanged())
            .subscribe(userStatus => {
                if (userStatus === 'login') {
                    this.loadOrders();
                } else {
                    this.orders$ = observableEmpty();
                }
            });
        this.dataSubscription = this.orders$.pipe(filter(Boolean)).subscribe((orders) => {
            this.calculateAssetCreditQueryParams(orders);
        });

        this.filteredOrders$ = combineLatest(this.orders$, this.orderSearchText).pipe(debounceTime(300), map(([orders, searchText]) => {
            if (searchText && searchText.length > 0) {
                return orders.reduce((list, order) => {
                    const matches = order.articles.filter(article => {
                        if (this.sdkOptions.assetDetails.useHeadlineForDescription) {
                            return (article.articleName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                                || (article.asset && article.asset.headline.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
                        } else {
                            return (article.articleName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                                || (article.asset && article.asset.caption.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
                        }
                    });

                    if (matches.length) {
                        const filteredOrder = {
                            ...order,
                            articles: matches
                        };
                        list.push(filteredOrder);
                    }
                    return list;
                }, []);
            } else {
                return orders;
            }
        }), distinctUntilChanged(), publishReplay(1), refCount());
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    /**
     * Calculates the query params for the credit
     * @param orders
     */
    async calculateAssetCreditQueryParams(orders: Order[]) {
        const collections = await this.collectionDataService.getCopyrightCollections();
        orders.map(order => {
            order.articles.map(article => {
                if (!article.asset) {
                    return;
                }
                if (this.sdkOptions.assetDetails.credit.alwaysUseCollectionData && article.asset.collection) {
                    const collection = collections.find(collection => collection.id.indexOf(article.asset.collection.id) > -1);
                    this.assetQueryParams = {
                        ...this.assetQueryParams,
                        [article.asset.id]: {
                            ...this.assetQueryParams[article.asset.id],
                            collectionQueryParam: (collection) ? {coll: [collection.id]} : {},
                        }
                    };
                } else if (!article.asset.collection && article.asset.copyright !== '' && article.asset.byline === '') {
                    this.assetQueryParams = {
                        ...this.assetQueryParams,
                        [article.asset.id]: {
                            ...this.assetQueryParams[article.asset.id],
                            supplierQueryParam: {pgid: article.asset.supplier.id}
                        }
                    };
                } else if (!article.asset.collection && article.asset.copyright !== '' && article.asset.byline !== '') {
                    this.assetQueryParams = {
                        ...this.assetQueryParams,
                        [article.asset.id]: {
                            ...this.assetQueryParams[article.asset.id],
                            supplierQueryParam: {pgid: article.asset.supplier.id},
                            keywordQueryParam: {q: `"${article.asset.byline}"`}
                        }
                    };
                } else if (article.asset.collection) {
                    const collection = collections.find(collection => collection.id.indexOf(article.asset.collection.id) > -1);
                    if (article.asset.byline === '') {
                        this.assetQueryParams = {
                            ...this.assetQueryParams,
                            [article.asset.id]: {
                                ...this.assetQueryParams[article.asset.id],
                                collectionQueryParam: (collection) ? {coll: [collection.id]} : {},
                            }
                        };
                    } else {
                        this.assetQueryParams = {
                            ...this.assetQueryParams,
                            [article.asset.id]: {
                                ...this.assetQueryParams[article.asset.id],
                                collectionQueryParam: (collection) ? {coll: [collection.id]} : {},
                                keywordQueryParam: {q: `"${article.asset.byline}"`}
                            }
                        };
                    }
                }
            });
        });
    }

    get currentLang() {
        return this.translate.currentLang;
    }

    /**
     * Loads the order to be displayed
     */
    loadOrders() {
        this.orders$ = this.orderService.getOrders({
            type: this.orderType,
            paymentStatus: this.orderType === 'proposal' ? 'none' : null
        }).pipe(publishReplay(1), refCount());
    }

    /**
     * Emits the photograper id
     */
    searchPhotographer(photographerId: number): void {
        this.onSearch.next({pgid: photographerId});
    }

    /**
     * Emits the collection id
     */
    searchCollection(collectionId: number): void {
        this.onSearch.next({coll: collectionId});
    }

    /**
     * Emits the copyright
     */
    searchCopyright(copyright: string): void {
        this.onSearch.next({copyright: copyright});
    }

    /**
     * Returns given asset media type
     * Defaults to 'Other'
     */
    getMediaType(asset: Asset) {
        if (!asset) {
            return '';
        }

        if (asset.fileFormat.substr(0, 6) === 'image/') {
            return 'Photograph';
        } else if (asset.fileFormat.substr(0, 6) === 'video/') {
            return 'Video';
        } else if (asset.fileFormat.substr(0, 6) === 'audio/') {
            return 'Audio';
        }

        return 'Other';
    }

    /**
     * Returns article price
     */
    getArticlePrice(article: Article, order: Order) {
        if (this.sdkOptions.orderList.hideTaxesPerArticle) {
            return article.price;
        } else {
            return Number(article.price) + (order.vatPercentage / 100) * article.price;
        }
    }

    /**
     * Returns the fitting asset file
     * @param asset
     * @param requiredHeight
     */
    getFittingAssetFile(asset: any, requiredHeight: number): any {
        if (!asset) {
            return '/assets/img/image-no-longer-avail.png';
        }

        let assetFile = null;
        for (const tmpFile of asset.associatedMedia) {
            if (
                tmpFile.fileFormat !== 'image/jpeg' ||
                tmpFile.additionalType.indexOf('watermarked') >= 0 ||
                tmpFile.additionalType === 'original'
            ) {
                continue;
            }
            if (
                !assetFile ||
                (tmpFile.height >= requiredHeight &&
                    assetFile.height > tmpFile.height) ||
                (assetFile.height < requiredHeight &&
                    assetFile.height < tmpFile.height)
            ) {
                assetFile = tmpFile;
            }
        }

        return assetFile.contentUrl;
    }

    downloadAsset(order: Order, article: Article) {
        this.orderService.downloadArticle(order.id, article.id);
    }

    /**
     * Calculates given article size
     * @param article
     */
    calculateSize(article: Article) {
        let width = 0;
        let height = 0;

        if (article.asset) {
            if (article.maxSize > 0) {
                if (article.maxSizeType === 'sideLength') {
                    if (article.asset.width > article.asset.height) {
                        width = Number(article.maxSize);
                        height =
                            (article.asset.height / article.asset.width) *
                            width;
                    } else {
                        height = Number(article.maxSize);
                        width =
                            (article.asset.width / article.asset.height) *
                            height;
                    }
                } else {
                    const tmpSize = this.getDimensionsFromMegapixel(
                        article,
                        Number(article.maxSize)
                    );
                    width = tmpSize.width;
                    height = tmpSize.height;
                }
            } else if (article.asset.width > 0) {
                width = article.asset.width;
                height = article.asset.height;
            }
        }

        return {
            width: Math.round(width),
            height: Math.round(height)
        };
    }

    /**
     * Return the caluclated raw file size
     * @param article
     */
    calculateRawFileSize(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        const rawSize = imgSize.width * imgSize.height * 3;

        if (rawSize < 1024) {
            return rawSize + ' B';
        } else if (rawSize < 1024 * 1024) {
            return Math.round(rawSize / 1024) + ' KB';
        } else {
            return Math.round((rawSize / 1024 / 1024) * 10) / 10 + ' MB';
        }
    }

    /**
     * Return the mega pixel for the image
     * @param article
     */
    calculateMegapixel(article: Article): string {
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        return (
            Math.round(
                ((imgSize.width * imgSize.height) / (1024 * 1024)) * 10
            ) /
            10 +
            ' MP'
        );
    }

    /**
     * Return the caluclated print size for the image
     * @param article
     */
    calculatePrintSize(article: Article): string {
        // 9.83" x 3.00" @ 72 DPI
        const imgSize = this.calculateSize(article);
        if (!imgSize) {
            return null;
        }

        let dpi = 300;
        if (article.dpiSize > 0) {
            dpi = article.dpiSize;
        }
        const sizeX = Math.round(imgSize.width * (2.54 / dpi) * 100) / 100;
        const sizeY = Math.round(imgSize.height * (2.54 / dpi) * 100) / 100;

        return `${sizeX} cm x ${sizeY} cm @ ${dpi} dpi`;
    }

    /**
     * Return the image dimensions based on the mega pixels
     * @param article
     * @param megapixel
     */
    getDimensionsFromMegapixel(article: Article, megapixel: number) {
        let width = 0;
        let height = 0;

        if (article.asset && article.asset.width > 0) {
            const pixelCount = megapixel * 1024 * 1024;

            width = Math.round(
                Math.sqrt(
                    pixelCount * (article.asset.width / article.asset.height)
                )
            );
            height = Math.round(
                Math.sqrt(
                    pixelCount * (article.asset.height / article.asset.width)
                )
            );
        }

        return {
            width: width,
            height: height
        };
    }

    showAssetDetail(asset: Asset): void {
        if (!asset) {
            return;
        }
        this.showAssetDetails.next(asset.id);
    }

    /**
     * Checks if articles expiration date is over
     * @param article
     */
    isArticleExpired(article: Article) {
        return article.expirationDate && (Date.now() > article.expirationDate.getTime());
    }

    /**
     * Checks if articles has an expiration date
     * @param article
     */
    articleHasExpirationDate(article: Article) {
        return article.expirationDate && (article.expirationDate.getTime() > 0);
    }

    /**
     * Downloads the order pdf
     * @param order
     */
    downloadOrderPdf(order: Order) {
        this.orderService.downloadPdf(order.id);
    }

    /**
     * Download order zip
     * @param order
     */
    downloadZipPdf(order: Order) {
        this.orderService.downloadZip(order.id);
    }

    /**
     * Moves the order to the cart
     * @param order
     */
    moveOrderToCart(order: Order) {
        this.orderDataService.changeOrderToCart(order.id).then(() => {
            this.loadOrders();
        });
    }

    /**
     * Returns if the asset is downloadable
     * @param order
     * @param article
     */
    isAssetDownloadable(order: Order, article: Article) {
        return (
            article.asset &&
            ((order.type === 'final' && !this.isArticleExpired(article)) ||
                (order.type === 'proposal' &&
                    order.proposalDownloadMaxSize !== 0))
        );
    }

    /**
     * Return if the model release is available for the article
     * @param article
     */
    isModelReleaseAvailable(article: Article) {
        return (
            article.asset &&
            [2, 4, 8].indexOf(Number(article.asset.modelReleased)) !== -1
        );
    }

    /**
     * Returns if the property release is available for the article
     * @param article
     */
    isPropertyReleaseAvailable(article: Article) {
        return (
            article.asset &&
            [2, 4, 8].indexOf(Number(article.asset.propertyReleased)) !== -1
        );
    }

    /**
     * Moves and order to cart through the api
     * @param order
     * @param article
     */
    moveToCart(order: Order, article: Article) {
        const cart = this.cartDataService.getCart();
        if (!cart || !cart.id) {
            return;
        }

        this.orderDataService
            .moveOrderArticleToCart(order.id, article.id, cart.id)
            .then(success => {
                if (!success) {
                    return;
                }

                this.loadOrders();
            });
    }

    /**
     * Deletes an article through the api
     * @param order
     * @param article
     */
    deleteArticle(order: Order, article: Article) {
        this.orderService.deleteArticle(order.id, article.id).then(success => {
            if (!success) {
                return;
            }

            this.loadOrders();
        });
    }

    /**
     * Called when the order search text changed
     * @param data
     */
    onOrderSearchChanged(data: any) {
        this.orderSearchText.next(data);
    }

    trackByFn(index, item) {
        return index; // or item.id
    }
}
