import {Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {CmsBlockContent} from '../cms-block/cms-block-content';

@Component({
    selector: 'lib-cms-faq-entry',
    templateUrl: './cms-faq-entry.component.html',
    styleUrls: ['./cms-faq-entry.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CmsFaqEntryComponent extends CmsBlockContent implements OnInit, OnChanges {

    @HostBinding('class.cms-faq-entry') rootClassName = true;

    @Input() data: any;
    @Input() children: any;

    /**
     * Should the content (answer) for the question be displayed
     */
    hideContent = true;

    constructor(el: ElementRef) {
        super(el);
    }

    ngOnInit() {
        this.hideContent = !this.data.isOpened;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    toggleContent() {
        this.hideContent = !this.hideContent;
    }

}
