import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

const lazySizes = require('lazysizes');

lazySizes.cfg.lazyClass = 'st-lazy-image';
lazySizes.cfg.loadMode = 2;

/**
 * Wrapper service for lazysizes library
 */
@Injectable()
export class LazysizesService {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }
}

