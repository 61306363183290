import { Component, OnInit } from '@angular/core';
import {UserLoginService} from '../../../services/user-login.service';

/**
 * Component that includes a button for the sign out
 */
@Component({
  selector: 'st-sign-out-button',
  templateUrl: './sign-out-button.component.html',
  styleUrls: ['./sign-out-button.component.css']
})
export class SignOutButtonComponent implements OnInit {

  constructor(private userLogin: UserLoginService) { }

  ngOnInit() {
  }

  onSignOut(e: Event) {
      e.preventDefault();
      this.userLogin.logout();
  }

}
