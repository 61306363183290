import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {PaymentOption} from '../models/payment_option';
import {Order} from '../models/order';
import {SodaApiService} from './soda-api.service';
import {Cart} from '../models/cart';

// temporary interface till we know more info
export interface OrdersApiResponse {
    downloadUrl: string;
}

/**
 * Service that interacts with order api
 */
@Injectable()
export class OrderService {
    private API_PATH = 'orders';

    constructor(private http: SodaApiService) {}

    /**
     *
     * Prepares given order setting created, modification dates to Date obj
     * Creates an empty list if there is no articles in given order
     * Creates a Date obj for each orders article
     */
    private prepareOrder = (order: Order) => {
        if (order && !Array.isArray(order.articles)) {
            order.articles = [];
        }

        order.createdOn = new Date(order.createdOn);
        order.modifiedOn = new Date(order.modifiedOn);

        order.articles.forEach(article => {
            article.expirationDate = article.expirationDate ? new Date(article.expirationDate) : null;
        });
    }

    /**
     * Get orders
     * @param options options object from which HttpRequest params are created
     * @returns
     */
    getOrders(options: any = {}): Observable<Order[]> {
        const params = new HttpParams({
            fromObject: options
        });

        return this.http.get<Order[]>(this.API_PATH, { params }).pipe(
            map((orders: Order[]) => {
                orders.forEach(this.prepareOrder);

                return orders;
            })
        );
    }

    /**
     * Get an order by id
     * @param id order id
     * @returns
     */
    getOrder(id): Observable<Order> {
        return this.http.get<Order>(`${this.API_PATH}/${id}`).pipe(
            map((order: Order) => {
                this.prepareOrder(order);

                return order;
            })
        );
    }

    /**
     * Moves an order to cart
     * @param orderId order id
     * @param articleId article id
     * @param cartId cart id
     * @returns
     */
    public moveToCart(orderId: number, articleId: number, cartId: number) {
        const url = `${
            this.API_PATH
        }/${orderId}/articles/${articleId}/move-to-cart`;

        const params = new HttpParams({
            fromObject: {
                cartId: String(cartId)
            }
        });

        return this.http
            .post<any>(url, '', { params, observe: 'response' })
            .toPromise()
            .then(response => {
                return response.status === 204;
            });
    }

    /**
     * Deletes an article in given order
     * @param orderId order id
     * @param articleId article id
     * @returns
     */
    public deleteArticle(orderId: number, articleId: number) {
        const url = `${this.API_PATH}/${orderId}/articles/${articleId}`;

        return this.http
            .delete<any>(url, { observe: 'response' })
            .toPromise()
            .then(response => response.status === 204)
            .catch(this.handleError);
    }

    /**
     * Downloads an article from given order
     * @param orderId
     * @param articleId
     * @returns
     */
    public downloadArticle(orderId: number, articleId: number) {
        const url = `${
            this.API_PATH
        }/${orderId}/articles/${articleId}/download`;

        return this.http
            .get<any>(url)
            .toPromise()
            .then((response: OrdersApiResponse) => {
                this.http.download(response.downloadUrl);
            })
            .catch(this.handleError);
    }

    /**
     * Downloads an order as pdf
     * @param orderId
     * @returns
     */
    public downloadPdf(orderId: number) {
        const url = `${this.API_PATH}/${orderId}/pdf`;

        return this.http
            .get<any>(url)
            .toPromise()
            .then((response: OrdersApiResponse) => {
                this.http.download(response.downloadUrl);
            })
            .catch(this.handleError);
    }

    /**
     * Downloads an order as zip
     * @param orderId
     * @returns
     */
    public downloadZip(orderId: number) {
        const url = `${this.API_PATH}/${orderId}/zip`;

        return this.http
            .get<any>(url)
            .toPromise()
            .then((response: OrdersApiResponse) => {
                this.http.download(response.downloadUrl);
            })
            .catch(this.handleError);
    }

    /**
     * Custom error handler
     * @paramerror error to be handled
     * @returns
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    /**
     * Gets order options
     * @param orderId order id
     * @returns
     */
    getOptions(orderId: number): Observable<PaymentOption[]> {
        const url = `${this.API_PATH}/${orderId}/payment/options`;

        return this.http.get<PaymentOption[]>(url);
    }

    /**
     * Gets form data from api?
     * @param  orderId
     * @param  paymentType
     * @returns
     */
    getFormData(orderId: number, paymentType: string) {
        const url = `${
            this.API_PATH
        }/${orderId}/payment/form/${encodeURIComponent(paymentType)}`;

        return this.http.get(url);
    }

    public changeOrderToCart(orderId: number) {
        const url = `${this.API_PATH}/${orderId}/change-to-cart`;

        return this.http.post(url, '').toPromise();
    }

    /**
     * Updates an order
     * @param  order to be updated
     * @returns
     */
    public updateOrder(order: Order) {
        if (!order.id) {
            return;
        }

        const url = `${this.API_PATH}/${order.id}`;

        order.articles = null;

        return this.http.patch(url, order).toPromise();
    }

    updatePromotionCodeForOrder(order: Order, promotionCode: string): Promise<any> {
        return this.updateOrder({
            ...order,
            promoCode: promotionCode
        }).then(successResponse => {
            return successResponse;
        }, errorResponse => {
            return errorResponse.error;
        });
    }
}
