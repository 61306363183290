import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
    OnDestroy,
    ViewChild, EventEmitter, Output, Inject
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LightboxDataService} from '../../services/lightbox-data.service';
import {LightboxService} from '../../services/lightbox.service';
import {PreferencesService} from '../../services/preferences.service';
import {UserLoginService} from '../../services/user-login.service';
import {Lightbox} from '../../models/lightbox';
import {LightboxAsset} from '../../models/lightbox_asset';
import {Asset} from '../../models/asset';
import {MatAccordion} from '@angular/material';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {takeUntil, filter, debounce, debounceTime} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AssetActionPlugin} from '../../models/asset-action-plugin';
import {SDK_OPTIONS, SdkOptions} from '../../models/sdk-options';

export const AVAILABLE_LAYOUTS = ['masonry-grid', 'grid'];

/**
 * Component that displays the overview of the users galleries
 */
@Component({
    selector: 'st-galleries-overview',
    templateUrl: './galleries-overview.component.html',
    styleUrls: ['./galleries-overview.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GalleriesOverviewComponent implements OnInit, OnChanges, OnDestroy {
    public lightboxes: Lightbox[];
    public lightboxForms = {};
    public sortForm;
    private activeLightboxes = [];
    private initialLoad = true;
    private activeGalleryId: string;
    private activeGalleryId$ = new BehaviorSubject<string>(null);
    private componentDestroyed$ = new Subject<boolean>();
    movedToCart: boolean;
    sorting: {
        [id: number]: boolean;
    } = {};

    lightboxesSearch: {
        [id: number]: string;
    } = {};

    loadingDetails: {
        [id: number]: boolean;
    } = {};

    expandedLightboxes: {
        [id: number]: boolean;
    } = {};

    private firstItemLoaded: boolean;

    selectedLigthboxId: number;

    @Input()
    set galleryId(id: string) {
        this.activeGalleryId = id;
        if (!!this.route.snapshot.queryParams.edit) {
            this.activeGalleryId$.next(this.activeGalleryId);
        }
    }

    @Input() assetActionPlugins: AssetActionPlugin[] = [];

    @Input() urlGenerator: Function;

    @Input() hidePreviewLink: boolean;

    @Output() toggleSimilaritySearch: EventEmitter<string> = new EventEmitter<string>();

    @Output() previewLinkClicked: EventEmitter<Asset> = new EventEmitter<Asset>();

    @Input() displayAssetName = false;

    showCreationDate: boolean;

    displayShowMoreButton: boolean;

    useSearchInsideLightbox: boolean;

    public lightboxSelections = {};

    displaySelections: boolean;

    entered: boolean;

    lightboxErrors: {};

    public formCreate: FormGroup;

    errorMessage: string;

    displayCreateNewLightboxForm: boolean;

    dataSubscription: Subscription = new Subscription();

    @ViewChild('accordion') private accordion: MatAccordion;

    private _layout = AVAILABLE_LAYOUTS[0];

    isSelectedGalleryId(lightbox: Lightbox) {
        return (Number(this.activeGalleryId) === lightbox.id) || (this.lightboxes.length === 1 && this.activeGalleryId && this.activeGalleryId.length === 40) || (!this.activeGalleryId && this.lightboxes[0].id === lightbox.id);
    }

    @Input()
    set layout(value: string) {
        this._layout = AVAILABLE_LAYOUTS.indexOf(value) > 0 ? value : AVAILABLE_LAYOUTS[0];
    }

    get layout() {
        return this._layout;
    }

    constructor(
        private route: ActivatedRoute,
        private lightboxDataService: LightboxDataService,
        private lightboxService: LightboxService,
        private preferenceService: PreferencesService,
        private fb: FormBuilder,
        public userLogin: UserLoginService,
        private changeDetector: ChangeDetectorRef,
        @Inject(SDK_OPTIONS) public sdkOptions: SdkOptions
    ) {
        if (this.sdkOptions.lightbox) {
            this.showCreationDate = this.sdkOptions.lightbox.showCreationDate;
            this.useSearchInsideLightbox = this.sdkOptions.lightbox.useSearch;
        }
        if (this.sdkOptions.layout && this.sdkOptions.layout.lightbox) {
            this.displayShowMoreButton = this.sdkOptions.layout.lightbox.displayShowMoreButton;
        }
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        if (this.dataSubscription) this.dataSubscription.unsubscribe();
    }

    ngOnInit() {

        this.sortForm = this.fb.group({
            sort: this.getSortColumn()
        });

        this.dataSubscription.add(this.sortForm.valueChanges.subscribe(data => {
            switch (data.sort) {
                case 'all':
                    for (const lightbox of this.lightboxes) {
                        this.setGalleryActive(lightbox.id, true);
                    }
                    this.resetSortField();
                    this.accordion.openAll();
                    break;

                case 'none':
                    for (const lightbox of this.lightboxes) {
                        this.setGalleryActive(lightbox.id, false);
                        this.accordion.closeAll();

                    }
                    this.resetSortField();
                    break;

                default:
                    this.setSortColumn(data.sort);
            }
        }));

        this.dataSubscription.add(this.lightboxDataService.getSelectedLightboxObservable().subscribe(selectedLigthbox => {
            if (selectedLigthbox) {
                this.selectedLigthboxId = selectedLigthbox.id;
            }
        }));
        this.formCreate = this.fb.group({
            name: '',
            comment: ''
        });
    }

    /**
     * Initializes the gallery. uses LightboxDataService to fetch the lightboxes
     */
    initGallery() {
        if (this.activeGalleryId && this.activeGalleryId.length === 40) {
            this.lightboxService
                .getLightbox(this.activeGalleryId)
                .subscribe(result => {
                    this.lightboxes = [result];
                    this.createLightboxForms();
                    this.setSortColumn(this.getSortColumn());
                });
        } else {
            this.dataSubscription.add(this.lightboxDataService
                .getLightboxListObservable()
                .pipe(debounceTime(100))
                .subscribe(lightboxList => {
                    this.lightboxes = this.sortLightboxes(
                        lightboxList,
                        this.getSortColumn()
                    );
                    this.createLightboxForms();
                    this.setSortColumn(this.getSortColumn());

                    // load the details of the first lightbox, as it will be expanded automatically
                    // and the afterExpanded is not triggered
                    if (this.lightboxes.length > 0 && !this.firstItemLoaded) {
                        this.firstItemLoaded = true;
                        this.loadLightboxDetails(this.lightboxes[0]);
                    }

                    this.changeDetector.markForCheck();

                    if (this.initialLoad) {
                        this.setGalleryActive(
                            this.lightboxDataService.getSelectedLightboxId(),
                            true
                        );
                        this.initialLoad = false;
                    }
                }));
        }
    }

    resetSortField() {
        this.sortForm.patchValue({sort: this.getSortColumn()});
    }

    switchReverseSort() {
        this.setReverseSort(!this.getReverseSort());
    }

    getReverseSort(): boolean {
        const sortAsc = this.preferenceService.get('lightbox_sort_asc');

        return sortAsc === null ? true : !!sortAsc;
    }

    setReverseSort(sortAsc: boolean) {
        this.preferenceService.set('lightbox_sort_asc', sortAsc);
        this.lightboxes = this.sortLightboxes(
            this.lightboxes,
            this.getSortColumn()
        );
    }

    /**
     * Creates the forms needed for actions on the lightboxes
     */
    createLightboxForms() {
        this.lightboxForms = {};

        for (let lightbox of this.lightboxes) {
            if (this.lightboxForms[lightbox.id]) {
                continue;
            }

            let actionList = {action: ''};

            for (let lightboxSelect of this.lightboxes) {
                actionList['lb_' + lightboxSelect.id] = false;
            }

            this.lightboxForms[lightbox.id] = {
                'action': '',
                'errorMessage': null,
                'forms': {
                    'action': this.fb.group(actionList),

                    'comment': this.fb.group({
                        comment: ''
                    }),

                    'send': this.fb.group({
                        email: '',
                        comment: ''
                    }),

                    'rename': this.fb.group({
                        name: ''
                    }),

                    'highResZip': this.fb.group({
                        usage: ''
                    })
                }
            };


            this.dataSubscription.add(this.lightboxForms[lightbox.id].forms.action.valueChanges
                .subscribe(data => {
                    this.resetErrorMessage(lightbox.id);
                    this.movedToCart = false;
                    if (data.action == 'comment') {
                        this.lightboxForms[lightbox.id].forms.comment.setValue({
                            'comment': lightbox.comment
                        });
                        this.lightboxForms[lightbox.id].action = 'comment';

                    } else if (data.action == 'rename') {
                        this.lightboxForms[lightbox.id].forms.rename.setValue({
                            'name': lightbox.name
                        });
                        this.lightboxForms[lightbox.id].action = 'rename';

                    } else if (data.action == 'delete') {
                        this.lightboxForms[lightbox.id].action = 'delete';

                    } else if (data.action == 'pdf') {
                        this.lightboxForms[lightbox.id].action = 'pdf';
                        this.downloadPdf(lightbox.id);

                    } else if (data.action == 'zip') {
                        this.lightboxForms[lightbox.id].action = 'zip';
                        this.downloadZip(lightbox.id);

                    } else if (data.action == 'highResZip') {
                        this.lightboxForms[lightbox.id].action = 'highResZip';

                    } else if (data.action == 'duplicate') {
                        this.lightboxForms[lightbox.id].action = 'duplicate';
                        this.duplicate(lightbox.id);

                    } else if (data.action == 'cart') {
                        this.lightboxForms[lightbox.id].action = 'cart';
                        this.copyToCart(lightbox.id);

                    } else if (data.action == 'send') {
                        this.lightboxForms[lightbox.id].forms.send.setValue({
                            'email': '',
                            'comment': ''
                        });
                        this.lightboxForms[lightbox.id].action = 'send';

                    } else if (data.action == 'copy') {
                        this.displaySelections = true;
                        this.lightboxForms[lightbox.id].action = 'copy';

                    } else if (data.action == 'move') {
                        this.displaySelections = true;
                        this.lightboxForms[lightbox.id].action = 'move';

                    } else if (data.action == 'deleteSelections') {
                        this.lightboxForms[lightbox.id].action = 'deleteSelections';
                        this.removeSelection(lightbox.id);

                    } else {
                        this.lightboxForms[lightbox.id].action = '';
                    }

                }));
        }

        this.activeGalleryId$.pipe(
            filter(Boolean),
            takeUntil(this.componentDestroyed$)
        ).subscribe((galleryId => {
            if (this.lightboxForms[galleryId]) {
                this.lightboxForms[galleryId].forms.action.patchValue({
                    action: 'comment'
                });
            }
        }));
    }

    downloadPdf(lightboxId) {
        this.lightboxService.downloadPdf(lightboxId).then(() => {
            this.cancelAction(lightboxId);
        });
    }

    downloadZip(lightboxId) {
        this.lightboxService.downloadZip(lightboxId).then(() => {
            this.cancelAction(lightboxId);
        });
    }

    downloadHighResZip(lightboxId) {
        const formData = this.lightboxForms[lightboxId].forms.highResZip.getRawValue();

        if (formData.usage.length > 0) {
            this.lightboxService.downloadHighResZip(lightboxId, formData).then(() => {
                this.cancelAction(lightboxId);
                this.lightboxForms[lightboxId].forms.highResZip.patchValue({
                    usage: ''
                });
            });
        } else {
            this.lightboxForms[lightboxId].forms.highResZip.get('usage').markAsTouched();
        }
    }

    duplicate(lightboxId) {
        this.lightboxService.getLightbox(lightboxId).subscribe(data => {
            const lightbox = this.cleanLightboxForDuplicate(data);

            this.lightboxDataService.createLightbox(lightbox).then(() => {
                this.cancelAction(lightboxId);
            });
        });
    }

    copyToCart(lightboxId) {
        this.lightboxService.copyToCart(lightboxId).then(() => {
            this.cancelAction(lightboxId);
            this.movedToCart = true;
        });
    }

    removeSelection(lightboxId) {
        const selections = this.lightboxSelections[lightboxId];
        for (let i = 0; i < selections.length; i++) {
            this.lightboxDataService.deleteAsset(lightboxId, selections[i]);
        }
    }

    /**
     * Cleanups a lightbox setting it to 'pristine' state
     * @param lightbox
     */
    cleanLightboxForDuplicate(lightbox: Lightbox): Lightbox {
        lightbox.id = null;
        lightbox.creationDate = null;
        lightbox.modificationDate = null;
        lightbox.name = lightbox.name + ' - Duplicate';

        if (lightbox.assets && lightbox.assets.length > 0) {
            const newAssets = [];
            for (const asset of lightbox.assets) {
                asset.asset = null;
                asset.creationTime = null;
                asset.modificationTime = null;
                newAssets.push(asset);
            }
            lightbox.assets = newAssets;
        }

        return lightbox;
    }

    resetErrorMessage(lightboxId) {
        this.setErrorMessage(lightboxId, null);
    }

    setErrorMessage(lightboxId, message) {
        this.lightboxForms[lightboxId].errorMessage = message;
    }

    sortLightboxes(lightboxes: Lightbox[], sortBy) {
        const sortAsc = this.getReverseSort();

        lightboxes.sort((a, b) => {
            if (this.activeGalleryId && this.activeGalleryId.length > 0) {
                if (a.id === +this.activeGalleryId) {
                    return -1;
                } else if (b.id === +this.activeGalleryId) {
                    return 1;
                }
            }

            let valA;
            let valB;

            if (sortAsc) {
                valA = a[sortBy];
                valB = b[sortBy];
            } else {
                valA = b[sortBy];
                valB = a[sortBy];
            }

            if (valA && valA.getTime) {
                valA = valA.getTime();
                valB = valB.getTime();

                return valB - valA;
            } else if (Array.isArray(valA) && Array.isArray(valB)) {
                return valA.length - valB.length;
            }

            valA = valA && valA.toUpperCase ? valA.toUpperCase() : '';
            valB = valB && valB.toUpperCase ? valB.toUpperCase() : '';

            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }

            return 0;
        });

        return lightboxes;
    }

    isValidSort(sort) {
        // 'modificationDate'
        return (
            ['name', 'creationDate', 'assets'].indexOf(
                sort
            ) > -1
        );
    }

    getSortColumn(): string {
        if (this.sdkOptions.lightbox && this.sdkOptions.lightbox.defaultSorting) {
            return this.sdkOptions.lightbox.defaultSorting;
        } else {
            const sort = this.preferenceService.get('lightbox_sort');

            return this.isValidSort(sort) ? sort : 'name';
        }


    }

    setSortColumn(column: string) {
        if (!this.isValidSort(column)) {
            return;
        }

        this.preferenceService.set('lightbox_sort', column);
        this.lightboxes = this.sortLightboxes(
            this.lightboxes,
            this.getSortColumn()
        );
    }

    getAssets(lightboxId: number): Asset[] {
        const assets = [];
        const lightbox = this.getLightboxData(lightboxId);
        if (!lightbox || !lightbox.assets || !lightbox.assets.length) {
            return assets;
        }

        for (const lightboxAsset of lightbox.assets) {
            if (lightboxAsset && lightboxAsset.asset) {
                assets.push(lightboxAsset.asset);
            }
        }

        return assets;
    }

    getFilteredAssets(lightboxId: number): Asset[] {
        const assets = this.getAssets(lightboxId);
        if (!this.useSearchInsideLightbox) {
            return assets;
        }
        const searchText = this.lightboxesSearch[lightboxId] || '';
        return assets.filter(asset => {
            if (this.sdkOptions.assetDetails.useHeadlineForDescription) {
                return (asset.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                    || (asset && asset.headline.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
            } else {
                return (asset.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                    || (asset && asset.caption.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
            }
        });
    }

    isGalleryActive(lightboxId) {
        return !!this.activeLightboxes[lightboxId];
    }

    setGalleryActive(lightboxId, status) {
        this.activeLightboxes[lightboxId] = !!status;
    }

    switchGalleryActive(lightboxId) {
        this.setGalleryActive(lightboxId, !this.isGalleryActive(lightboxId));
    }

    async saveSorting(lightboxId: number, assetData: any) {
        this.lightboxErrors = {};
        if (assetData.oldLightboxId) {
            this.sorting[lightboxId] = true;
            // drop into another lightbox
            this.lightboxDataService.addAsset(assetData.assetId, '', lightboxId, assetData.to).then(() => {
                this.sorting[lightboxId] = false;
                this.lightboxDataService.deleteAsset(assetData.oldLightboxId, assetData.assetId).catch((error) => {
                    this.lightboxErrors[assetData.oldLightboxId] = 'lightboxes.errors.generalError';
                });
            }).catch((error) => {
                if (error.error.message === 'Conflict') {
                    this.lightboxErrors[lightboxId] = 'lightboxes.errors.alreadyExistsError';
                } else {
                    this.lightboxErrors[lightboxId] = 'lightboxes.errors.generalError';
                }
                this.sorting[lightboxId] = false;
                this.resetLightboxes([lightboxId, +assetData.oldLightboxId]);
            });
        } else {
            this.lightboxDataService
                .updateAsset(
                    lightboxId,
                    assetData['assetId'],
                    null,
                    assetData['to'] + 1
                )
                .then(() => {
                });
        }

    }

    async resetLightboxes(lightboxIds: number[]) {
        for (let i = 0; i < lightboxIds.length; i++) {
            const lightbox = await this.lightboxService.getLightbox(lightboxIds[i]).toPromise();
            const position = this.lightboxes.findIndex(item => item.id === lightbox.id);
            this.lightboxes[position].assets = lightbox.assets;
        }
    }

    cancelAction(lightboxId) {
        this.lightboxForms[lightboxId].action = '';
        this.lightboxForms[lightboxId].forms.action.patchValue({action: ''});
    }

    getLightboxData(lightboxId) {
        for (const lightbox of this.lightboxes) {
            if (lightbox.id === lightboxId) {
                return lightbox;
            }
        }

        return null;
    }

    setLightboxData(lightboxId, data) {
        const pos = this.getLightboxPos(lightboxId);
        if (pos < 0) {
            return false;
        }

        this.lightboxes[pos] = data;

        return true;
    }

    getLightboxPos(lightboxId): number {
        for (const pos in this.lightboxes) {
            if (this.lightboxes[pos].id === lightboxId) {
                return Number(pos);
            }
        }

        return -1;
    }

    deleteLightbox(lightboxId) {
        this.lightboxDataService.deleteLightbox(lightboxId).then(() => {
            const pos = this.getLightboxPos(lightboxId);
            if (pos < 0) {
                return false;
            }

            this.lightboxes.splice(pos, 1);
            // this.createLightboxForms();
        });
    }

    updateComment(lightboxId) {
        const lightbox = this.getLightboxData(lightboxId);
        const formData = this.lightboxForms[
            lightboxId
            ].forms.comment.getRawValue();

        formData.id = lightbox.id;
        formData.name = lightbox.name;

        this.lightboxDataService.updateLightbox(formData).then(
            data => {
                this.setLightboxData(data.id, data);
                this.cancelAction(lightboxId);
            },
            data => {
                if (data.status === 409) {
                    this.setErrorMessage(
                        lightboxId,
                        'A gallery with that name already exists.'
                    );
                }
            }
        );
    }

    /**
     * Renames a lightbox through the api
     * @param lightboxId
     */
    renameLightbox(event, lightboxId) {
        event.preventDefault();
        const lightbox = this.getLightboxData(lightboxId);
        const formData = this.lightboxForms[
            lightboxId
            ].forms.rename.getRawValue();

        formData.id = lightbox.id;
        formData.comment = lightbox.comment;

        this.lightboxDataService.updateLightbox(formData).then(
            data => {
                this.setLightboxData(data.id, data);
                this.cancelAction(lightboxId);
            },
            data => {
                if (data.status === 409) {
                    this.setErrorMessage(
                        lightboxId,
                        'A gallery with that name already exists.'
                    );
                }
            }
        );
    }

    sendMail(lightboxId) {
        const formData = this.lightboxForms[
            lightboxId
            ].forms.send.getRawValue();

        this.lightboxService
            .sendMail(lightboxId, formData.email, formData.comment)
            .then(
                () => {
                    this.cancelAction(lightboxId);
                },
                data => {
                    this.setErrorMessage(lightboxId, 'Could not send mail');
                }
            );
    }

    isEmpty(lightbox): boolean {
        return !lightbox || !lightbox.assets || !lightbox.assets.length;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initGallery();
    }

    setLightboxSelection(lightboxId: number, event): void {
        this.lightboxSelections[lightboxId] = event;
    }

    moveLightboxes(lightboxId: number, copy: boolean): void {
        this.displaySelections = false;
        this.entered = false;

        copy = !!copy;

        if (!this.lightboxSelections[lightboxId] || !this.lightboxSelections[lightboxId].length) {
            return;
        }

        for (let lightbox of this.lightboxes) {
            if (!this.lightboxForms[lightboxId].forms.action.controls['lb_' + lightbox.id].value) {
                continue;
            }

            for (let assetId of this.lightboxSelections[lightboxId]) {
                let asset = this.getLightboxAsset(lightboxId, assetId);
                if (!asset) {
                    continue;
                }

                this.lightboxDataService.addAsset(assetId, asset.comment, lightbox.id).then(() => {
                    if (!copy) {
                        this.lightboxDataService.deleteAsset(lightboxId, assetId);
                    }
                });
            }
        }

        this.cancelAction(lightboxId);
    }

    getLightboxAsset(lightboxId: number, assetId: string): LightboxAsset {
        for (let lightbox of this.lightboxes) {
            if (lightbox.id != lightboxId) {
                continue;
            }

            for (let asset of lightbox.assets) {
                if (asset.id == assetId) {
                    return asset;
                }
            }
        }

        return null;
    }

    closeSelectLightboxesMenu(id: number) {
        if (this.entered) {
            this.entered = false;
            this.displaySelections = false;
            this.lightboxForms[id].action = '';
        }
        if (this.displaySelections) {
            this.entered = true;
        }
    }

    hasSubscriptionInLightbox(lightboxId: number): boolean {
        return !!this.getAssets(lightboxId).find(asset => asset.subscriptions && asset.subscriptions.length > 0);
    }

    hasNoSubscriptionInLightbox(lightboxId: number): boolean {
        return !this.getAssets(lightboxId).find(asset => asset.subscriptions && asset.subscriptions.length === 0);
    }

    hasRestrictionInLightbox(lightboxId: number): boolean {
        return !!this.getAssets(lightboxId).find(asset => this.hasRestriction(asset));
    }

    hasOnlyRestrictionInLightbox(lightboxId: number): boolean {
        return !!this.getAssets(lightboxId).every(asset => this.hasRestriction(asset));
    }

    hasRestriction(asset: Asset): boolean {
        if (asset && asset.restrictions && asset.restrictions.length) {
            return !!asset.restrictions.find(restriction => restriction.type === 'block' || restriction.type === 'reservation' || restriction.type === 'block_internaly');
        } else {
            return false;
        }
    }

    toggleDisplayNewLightboxForm() {
        if (this.displayCreateNewLightboxForm) {
            this.formCreate.patchValue({
                name: '',
                comment: ''
            });
        }
        this.displayCreateNewLightboxForm = !this.displayCreateNewLightboxForm;

    }

    createLightbox() {
        const formData = this.formCreate.getRawValue();
        this.lightboxDataService.createLightbox(formData).then(
            () => {
                this.toggleDisplayNewLightboxForm();
            },
            data => {
                if (data.status === 409) {
                    const body = data.error;
                    if (body.message === 'Name is empty') {
                    } else {
                    }
                }
            }
        );
    }

    onToggleSimilaritySearch(asset: Asset) {
        this.toggleSimilaritySearch.next(asset.id);
    }

    loadLightboxDetails(lightbox: Lightbox) {
        this.loadingDetails[lightbox.id] = true;
        this.lightboxService.getLightbox(lightbox.id).toPromise().then(lightbox => {
            this.lightboxDataService.setLightbox(lightbox);
            this.loadingDetails[lightbox.id] = false;
        }).catch(() => {
            this.loadingDetails[lightbox.id] = false;
        });

    }

    trackLightboxes(index: number, elem: Lightbox) {
        if (elem) {
            return elem.id;
        } else {
            return index;
        }
    }

    onPreviewLinkCLicked(asset: Asset) {
        this.previewLinkClicked.next(asset);
    }

    onLightboxSearchChanged(event: any, lightboxId: string) {
        this.lightboxesSearch[lightboxId] = event;
    }
}
