import {
    Component,
    Input,
    OnChanges,
    OnInit,
    Output,
    EventEmitter,
    ElementRef,
    HostBinding,
    HostListener,
    ViewChild,
    NgZone, OnDestroy, Inject
} from '@angular/core';
import {CMSService} from '../../services/cms.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as _ from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, filter, map, take} from 'rxjs/operators';
import {DomSanitizer, Meta, SafeResourceUrl, SafeStyle, SafeUrl, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'st-cms-container',
    templateUrl: './cms-container.component.html',
    styleUrls: ['./cms-container.component.css']
})
export class CmsContainerComponent implements OnChanges, OnInit, OnDestroy {

    @Input() slug: string;

    @Input() lang: string;

    @Input() draft: boolean;

    @Input() isDraftView: boolean;

    @Output() error = new EventEmitter<HttpErrorResponse>();

    @ViewChild('wrapper') wrapper: ElementRef;

    /**
     * Config for rendering
     */
    config: Observable<any>;

    error$: Observable<any>;

    isHovering: boolean;

    dataSubscription: Subscription;

    fontFamily: SafeStyle | any;

    fontUrlLinkRef: HTMLLinkElement;

    previousSlug: string;

    @HostListener('mouseover', ['$event']) mouseover(event: Event) {
        event.stopPropagation();
        if (this.isDraftView) {
            this.isHovering = true;
        }
    }

    @HostListener('mouseout', ['$event']) mouseleave(event: Event) {
        event.stopPropagation();
        if (this.isDraftView) {
            this.isHovering = false;
        }
    }

    constructor(private cmsService: CMSService, private el: ElementRef, private ngZone: NgZone, private title: Title,
                private meta: Meta,
                private sanitizer: DomSanitizer,
                @Inject(DOCUMENT) private document: any
    ) {

    }

    ngOnChanges(changes) {
        if ((changes['slug'] || changes['lang']) && this.slug) {
            this.previousSlug = this.slug;
            const lang = this.lang ? this.lang : 'en';
            if (this.draft) {
                this.cmsService.loadDraftConfig(this.slug, lang);
                this.cmsService.listenOnLiveInput(lang);
            } else {
                this.cmsService.loadConfig(this.slug, lang);
            }
            this.config = this.cmsService.getConfigForSlug(this.cmsService.getRealSlug(this.slug), lang);
            this.error$ = this.cmsService.getErrorForSlug(this.cmsService.getRealSlug(this.slug), lang);
        }
    }

    ngOnInit(): void {
        this.dataSubscription = this.config.pipe(debounceTime(100), filter(Boolean)).subscribe(config => {
            if (config) {
                if (config.metaData) {
                    this.wrapper.nativeElement.setAttribute('data-blok-c', JSON.stringify(config.metaData));
                    this.wrapper.nativeElement.setAttribute('data-blok-uid', config.metaData.id + '-' + config.metaData.uid);
                }
                if (config.metaTitle) {
                    this.title.setTitle(config.metaTitle || '');
                }
                if (config.metaDescription) {
                    this.meta.updateTag({name: 'description', content: config.metaDescription || ''});
                }

                if (config.fontFamily) {
                    this.fontFamily = config.fontFamily;
                }

                if (config.fontUrl
                    && Object.keys(config.fontUrl).length > 0
                    && !!config.fontUrl.url
                    && this.document.querySelectorAll('link[href^="' + config.fontUrl.url + '"]').length === 0) {
                    this.fontUrlLinkRef = this.document.createElement('link');
                    this.fontUrlLinkRef.href = config.fontUrl.url;
                    this.fontUrlLinkRef.rel = 'stylesheet';
                    this.document.head.appendChild(this.fontUrlLinkRef);
                }
            }
        });


        this.dataSubscription.add(this.error$.pipe(filter(Boolean)).subscribe(error => {
            this.error.emit(error);
        }));
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();
    }
}
